import React, {
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import {
  ComboBoxChangeEvent,
  ComboBoxCloseEvent,
  MultiColumnComboBox,
  MultiColumnComboBoxHandle,
  MultiColumnComboBoxProps,
} from "@progress/kendo-react-dropdowns";

interface Props extends Omit<MultiColumnComboBoxProps, "ref"> {
  ariaColumnIndex?: number
}

const GridDropdown = React.forwardRef<MultiColumnComboBoxHandle, Props>(
  ({ data, value, textField, dataItemKey, ariaColumnIndex, fillMode="outline", onClose, ...props }, ref) => {
    const comboboxRef = useRef<MultiColumnComboBoxHandle>(null);
    useImperativeHandle(
      ref,
      () => comboboxRef.current as MultiColumnComboBoxHandle
    );

    const [placeholder, setPlaceholder] = useState<string>('');
    const [controlledValue, setControllledValue] = useState(value);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [opened, setOpened] = useState<boolean>(false);

    useEffect(() => {
      setPlaceholder('');
      setControllledValue(value);
    }, [value]);

    useLayoutEffect(() => {
      const element = comboboxRef.current?.element;

      function handleKeyPress(event: KeyboardEvent) {
        const { key, altKey, metaKey, ctrlKey } = event;
        // console.log(`GridDropdown:key : ${key}`);
        if ((key !== "Backspace" && key !== "Enter" && key !== "Tab" && key.length > 1) || altKey || metaKey || ctrlKey) {

          if ((key === 'ArrowDown') && !altKey && !value && element?.firstElementChild?.ariaExpanded === 'false') {
            event.stopPropagation();
          }

          if (
            (key === 'ArrowDown' || key === 'ArrowUp') &&
            data?.length &&
            textField &&
            dataItemKey &&
            !altKey &&
            element?.firstElementChild?.ariaExpanded === 'true'
          ) {
            let currentValueIndex = -1
            if (value?.[dataItemKey]) {
              currentValueIndex = data.findIndex(dataItem => dataItem[dataItemKey] === value[dataItemKey])
            } else if (!value?.[dataItemKey]) {
              currentValueIndex = -1
            }

            const nextValueIndex = key === 'ArrowDown' ? currentValueIndex + 1 : currentValueIndex -1;

            if (nextValueIndex === data.length && key === 'ArrowDown') {
              return
            }

            if (nextValueIndex === -1 && key === 'ArrowUp') {
              return
            }
            const currentVal = data[currentValueIndex];
            const nextVal = data[nextValueIndex];

            if (currentVal?.[textField] === nextVal?.[textField] || data.filter(item => item?.[textField] === nextVal?.[textField])?.length > 1) {
              setPlaceholder(data[nextValueIndex]?.[textField]);
              setControllledValue(data[nextValueIndex]);
              const e = event as unknown as ComboBoxChangeEvent;
              props.onChange?.({
                ...e,
                value: data[nextValueIndex],
                target: {
                  ...e.target,
                  value: data[nextValueIndex],
                }
              });
              setTimeout(() => {
                setPlaceholder(data[nextValueIndex]?.[textField]);
                setControllledValue(data[nextValueIndex]);
                const e = event as unknown as ComboBoxChangeEvent;
                props.onChange?.({
                  ...e,
                  value: data[nextValueIndex],
                  target: {
                    ...e.target,
                    value: data[nextValueIndex],
                  }
                });
              }, 10);
              event.preventDefault();
              event.stopPropagation();
              return;
            }
          }
          return;
        }

        if (!data || !textField) return;

        if (!isMounted) setIsMounted(true);

        const target = event.target as HTMLInputElement;
        const { selectionStart, selectionEnd } = target;
        let prevText = target.value;

        if (prevText === '') {
          if (key === "Backspace") {
            setPlaceholder('');

            const item = document.querySelector("ul.k-table.k-table-list li.k-table-row") as HTMLLIElement;
            if (item) {
              item.classList.remove("k-selected");
            }
            return;
          } else if (key === "Enter" || key === "Tab") {
            if (element?.firstElementChild?.ariaExpanded) return;
            setControllledValue(null);
            const e = event as unknown as ComboBoxChangeEvent;
            props.onChange?.({
              ...e,
              value: null,
            });
            if (opened) {
              setOpened(false);
              setPlaceholder('');
            }
            event.preventDefault();
            return;
          }
        }

        if (selectionStart === selectionEnd) {
          if (key === 'Backspace') {
            prevText = prevText.slice(0, prevText.length - 1);
          }
        } else {
          if (key !== 'Enter' && key !== 'Tab') {
            prevText = prevText.slice(0, selectionStart ?? 0) + prevText.slice(selectionEnd ?? 0);
          }
        }

        const text = prevText + (key.length > 1 ? '' : key);

        const suggestionIndex = text === ''
          ? 0
          : data.findIndex(value => String(value[textField]).toLowerCase().startsWith(text.toLowerCase()))

        if (suggestionIndex === -1 && !prevText) {
          event.preventDefault();
          return;
        }
        if (suggestionIndex === -1) {
          if (key !== "Backspace" && isMounted) event.preventDefault();
          return;
        }

        const suggestionText = data[suggestionIndex][textField] as string;

        setPlaceholder(text + suggestionText.slice(text.length));

        if (key === "Enter" || key === "Tab") {
          if (element?.firstElementChild?.ariaExpanded === "true") {
            if (data.filter(item => item?.[textField] === data?.[suggestionIndex]?.[textField])?.length > 1) {
              return;
            }
            setPlaceholder(suggestionText);
            setControllledValue(data[suggestionIndex]);
            const e = event as unknown as ComboBoxChangeEvent;
            setTimeout(() => {
              props.onChange?.({
                ...e,
                value: suggestionIndex === -1 ? null : data[suggestionIndex],
              });
              event.preventDefault();
            }, 10);
            return;
          }
          return;
        }

        setTimeout(() => {
          const items = document.querySelectorAll("ul.k-table.k-table-list li.k-table-row");
          const suggestItem = items[suggestionIndex] as HTMLLIElement;

          if (suggestItem) {
            suggestItem.scrollIntoView();
            suggestItem.classList.add("k-selected");
          }
        }, 10);
      }

      if (element && data?.length) {
        element?.addEventListener("keydown", handleKeyPress);
      }
  
      return () => {
        if (element) {
          element?.removeEventListener("keydown", handleKeyPress);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comboboxRef, isMounted, opened, value]);

    const handleClose = (e: ComboBoxCloseEvent) => {
      setPlaceholder('');
      onClose?.(e);
      setOpened(false);
    }

    return (
      <div
        className="grid-dropdown"
        aria-colindex={ariaColumnIndex}
      >
        <MultiColumnComboBox
          {...props}
          ref={comboboxRef}
          data={data}
          value={controlledValue}
          opened={opened}
          onChange={(e) => {
            if (e.nativeEvent instanceof KeyboardEvent) {
              const event = e.nativeEvent as KeyboardEvent;
              const target = event.target as HTMLInputElement;
              const { key, altKey } = event;
              if ((key === 'ArrowDown' || key === 'ArrowUp') && !altKey && target?.ariaExpanded === 'false') {
                return;
              }
            }
            if (!e.target?.value && !e.value) return;
            props.onChange?.(e);
            setPlaceholder('');
          }}
          textField={textField}
          clearButton={false}
          size="small"
          fillMode={fillMode}
          popupSettings={{
            className: "headless",
            height: 301,
          }}
          onClose={handleClose}
          onOpen={(e) => {
            setOpened(true);
            props.onOpen?.(e);
          }}
        />
        <span className="placeholder">
          {placeholder}
        </span>
      </div>
    );
  }
);

export default GridDropdown;
