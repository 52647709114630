import { ComponentProps, ForwardedRef, forwardRef, PropsWithChildren, useRef, useState } from 'react';
import MovableDialog from '../../MovableDialog'
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import Button from '../../FormButton';
import ReactFocusLock from 'react-focus-lock';
import { TemplateService } from '../../../../services/templateServices';
import OverlayLoader from '../../OverlayLoader';

export const CustomInsertImageDialog = (props: {
  onClose: () => void;
  onSave: (imageString: string) => void;
}) => {
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const onFileChange = (e: InputChangeEvent) => {
    const files = Array.from(e.target?.element?.files ?? []);
    setUploadedFile(files[0]);
  }

  const onSave = async (e: React.MouseEvent) => {
    if (!uploadedFile) return;

    e.preventDefault();

    try {
      setLoading(true);
      const url = await TemplateService.uploadImage(uploadedFile, getFileExtension(uploadedFile.name));
      setLoading(false);
      props.onSave(url);
    } catch (e) {
      setLoading(false);
    }
  }

  return <MovableDialog
    width={500}
    height={500}
    title="Insert Image"
    onClose={props.onClose}
  >
    <ReactFocusLock className='w-full h-full'>
      <FormContent
        ref={formRef}
      >
        <Input
          className='!h-fit flex-shrink-0'
          type='file'
          accept='image/png, image/jpeg'
          onChange={onFileChange}
        />


        <div className='flex justify-center items-center w-full min-h-0'>
          {uploadedFile ?
            <div className='flex w-fit border border-[#e6e6e6] h-full min-h-0'>
              <img
                style={{ objectFit: "contain", height: '100%', maxWidth: '100%' }}
                src={uploadedFile ? URL.createObjectURL(uploadedFile) : ""} alt='Preview' />
            </div>
            : <span className='text-2xl'>No Image</span>
          }
        </div>
        <ButtonsRow>
          <Button
            onClick={onSave}
            disabled={!uploadedFile}
          >
            Save
          </Button>
        </ButtonsRow>
      </FormContent>
    </ReactFocusLock>

    {loading && <OverlayLoader />}
  </MovableDialog>
}

const FormContent = forwardRef((props: PropsWithChildren & ComponentProps<'form'>, ref: ForwardedRef<HTMLFormElement>) => {
  return <form
    {...props}
    className='grid grid-rows-[auto_1fr_auto] gap-2 w-full h-full p-4 border border-[#e6e6e6] min-h-0'
    ref={ref}>
    {props.children}
  </form>
})

const ButtonsRow = (props: PropsWithChildren) => {
  return <div className='flex justify-end'>
    {props.children}
  </div>
}

function getFileExtension(name: string) {
  return name.match(/.*\.(\w+)$/)?.[1] ?? '';
}