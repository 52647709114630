
import { Loader } from "@progress/kendo-react-indicators";

const OverlayLoader = () => {
  return (
    <div className="flex items-center justify-center w-full h-full absolute inset-0  bg-black bg-opacity-25 z-[50]">
      <Loader type="pulsing" size="large" />
    </div>
  );
}

export default OverlayLoader;