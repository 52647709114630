import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import { plusIcon } from '@progress/kendo-svg-icons';

import { useKeyPress } from '../../../../hooks';

import { ScheduleData, ScheduleDataTemplate } from '../../../../data/types';
import { columns } from './model';

import { Button, ListingGrid } from '../../../common';

import { ButtonWrapper, ListingGridWrapper } from '../PersonnelCodes.styled';
import { schedulesService } from '../../../../services/schedulesService';
import { getObjectFromKey } from '../../../../utils';
import ScheduleDialog from './SchedulesDialog';

type SelectionType = { field?: string; dataItem: any };

interface SchedulesProps {
  selectedSchedule: ScheduleData | undefined;
  setSelectedSchedule: React.Dispatch<
    React.SetStateAction<ScheduleData | undefined>
  >;
}

const Schedules: FC<SchedulesProps> = ({
  selectedSchedule,
  setSelectedSchedule,
}) => {
  const [originData, setOriginData] = useState<ScheduleData[]>([]);
  const [filterData, setFilterData] = useState<ScheduleData[]>([]);
  const [dataTemplate, setDataTemplate] = useState<ScheduleDataTemplate>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();

  const offset = useRef({ left: 0, top: 0 });

  async function fetchData() {
    try {
      const data: ScheduleData[] = await schedulesService.getList();
      const template: ScheduleDataTemplate = await schedulesService.getCombos();
      // setOriginData(payGroupList ?? []);
      // setFilterData(payGroupList ?? []);
      const translatedData: ScheduleData[] = data.map(record => ({
        ...record,
        SchedIntv: getObjectFromKey(record.SchedIntv ?? '', template?.SchedInterval ?? [])?.name,
        WeekStart: getObjectFromKey(record.WeekStart ?? '', template?.DayOfWeek ?? [])?.name,
        WeekEO: getObjectFromKey(record.WeekEO ?? '', template?.ShiftWeekEO ?? [])?.name
      }))
      setDataTemplate(template);
      setOriginData(translatedData ?? []);
      setFilterData(translatedData ?? []);
    } catch (e) { }

    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {
    },
    () => {
    },
    () => {
    },
    () => {
      setSelectedSchedule({ IDNo: 0 });
    }
  );

  return (
    <ListingGridWrapper>
      {isLoading ? (
        <></>
      ) : (
        <>
          <ListingGrid
            rowHeight={24}
            data={sort ? orderBy(filterData, sort) : filterData}
            dataItemKey="SchedCode"
            onRowClick={(e) => {
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.SchedCode === e.dataItem.SchedCode,
                }))
              );
            }}
            onRowDoubleClick={(e) => {
              setSelectedSchedule(e.dataItem);
            }}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            onContextMenu={(event) => {
              handleContextMenuOpen(event.syntheticEvent);
              const { dataItem, field } = event;
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.SchedCode === dataItem.SchedCode,
                }))
              );
              setSelectedItem({ field, dataItem });
            }}
          >
            {columns.map((column, index) => {
              return <GridColumn {...column} key={index} />;
            })}
          </ListingGrid>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedSchedule({ IDNo: 0 });
              }}
              id="create-btn"
              title="Add New"
              svgIcon={plusIcon}
            >
              ADD <u>N</u>EW
            </Button>
          </ButtonWrapper>
        </>
      )}

      <ContextMenu
        show={showContext}
        offset={offset.current}
        onSelect={handleOnSelect}
        onClose={() => setShowContext(false)}
        className="context-menu"
      >
        <MenuItem
          text="Sort Ascending"
          data={{
            action: 'sortAsc',
          }}
        />
        <MenuItem
          text="Sort Descending"
          data={{
            action: 'sortDesc',
          }}
        />
        <MenuItem
          text="Filter In Selected"
          data={{
            action: 'filterIn',
          }}
        />
        <MenuItem
          text="Filter Out Selected"
          data={{
            action: 'filterOut',
          }}
        />
        <MenuItem
          text="Remove All Filters"
          data={{
            action: 'removeFilters',
          }}
        />
      </ContextMenu>
      {selectedSchedule && (
        <ScheduleDialog
          dataTemplate={dataTemplate}
          handleClose={(shouldRefreshData?: boolean) => {
            setSelectedSchedule(undefined);
            if (shouldRefreshData) {
              fetchData();
            }
          }}
          data={selectedSchedule}
          isEdit={selectedSchedule?.SchedCode ? true : false}
        />
      )}
    </ListingGridWrapper>
  );
};

export default Schedules;
