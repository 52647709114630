import { Dialog } from '@progress/kendo-react-dialogs';
import styled from 'styled-components';

export const DialogWrapper = styled(Dialog)`
  .k-window-content.k-dialog-content {
    padding: 0px;
  }
`;

export const DialogContent = styled.form`
  display: flex;
  flex-direction: column;

  .form-fields {
    display: grid;
    // padding: 12px 20px 20px 20px;

    .k-floating-label-container {
      padding-top: 8px;
    }

    .fields-row {
      display: flex;
      justify-content: center;
      gap: 8px;

      padding: 12px 0px 20px 0px;
      border-bottom: 2px solid #d6d6d6;
    }

    .date-field {
      display: flex;
      padding: 12px 20px 20px 20px;
      gap: 10px;

      .date-field-label {
        display: flex;
        justify-content: center;
        align-items: center;
        color: blue;
        margin-top: 5px;
        font-size: 12px;
      }

      .date-field-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        label {
          text-align: center;
          font-size: 13px;
        }

        .month-input-field {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }

        .date-input-field {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
      }

      .date-field-right {
        display: flex;
        flex-direction: column;
      }
    }
  }

  div[data-focus-lock-disabled] {
    display: flex;
    flex-direction: row;
    align-items: start;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  gap: 8px;
  border-top: 2px solid #d6d6d6;
  padding: 15px;
  flex-direction: row;
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0px !important;
  }
`;
