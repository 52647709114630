import React, { FC, useEffect, useRef, useState } from 'react';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';

import { IAcctDefaultsProps } from './AcctDefaults.types';
import { IAcctDefaultInput } from '../../../data/types';

import {
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../hooks';
import {
  formatAcctDefaultsData,
  generateAcctDefaultsData,
  getChangedValues,
  handleKeyDown,
  validateDefaultSettingsForm,
} from '../../../utils';
import { useUpdateAcctDefaults } from '../../../services/hooks';

import {
  // DatePickerWrapper,
  Button,
  CustomColComboBox,
} from '../../common';

import {
  ButtonWrapper,
  FormContainer,
  Form,
  LeftLabelInputWrapper,
  FiscalLeftLabelInputWrapper,
  FormFieldsWrapper,
} from './AcctDefaults.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';
import CustomizedDatePicker, {
  CustomDatePickerChangeEvent,
} from '../../common/CustomizedDatePicker';
import moment from 'moment';

const AcctDefaults: FC<IAcctDefaultsProps> = ({
  title,
  currentForm,
  acctDefaults,
  combosData,
  onClose,
  onNextPage,
  onPreviousPage,
}) => {
  const {
    TermsCodes,
    ARAccounts,
    APAccounts,
    AssetsNotFixedAccounts,
    BankAccounts,
    ExpensesNoCoGSAccounts,
    CreditCardAccounts,
    IncomeExpenseAccounts,
    CurrentLiability,
    PayrollAccounts,
  } = combosData;
  const [formState, setFormState] = useState<IAcctDefaultInput>({});
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const formContainerRef = useRef<HTMLDivElement>(null);

  const { mutate: updateAcctDefaults } = useUpdateAcctDefaults(onClose);

  useHighlightInput('FiscalYrStartDate');
  useDisableRightClick(formContainerRef);

  useKeyPress(
    () => {
      if (isValid && isChanged) {
        onSubmit(formState);
      }
    },
    () => {
      onClose();
    }
  );

  useEffect(() => {
    console.log(formState);
  }, [formState]);

  useEffect(() => {
    setFormState({});
  }, [currentForm]);

  useEffect(() => {
    if (!acctDefaults || !TermsCodes || !currentForm) {
      return;
    }

    setFormState(generateAcctDefaultsData(acctDefaults, combosData));
  }, [acctDefaults, TermsCodes, currentForm, combosData]);

  useEffect(() => {
    setIsValid(validateDefaultSettingsForm(formState));
  }, [formState]);

  const onSubmit = (variables: IAcctDefaultInput) => {
    console.log(variables);
    try {
      const formattedData = formatAcctDefaultsData(
        variables,
        combosData.TermsCodes
      );
      const changes = getChangedValues(formattedData, acctDefaults);

      if (Object.keys(changes).length > 0) {
        updateAcctDefaults({
          ...changes,
        });
      }
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const handleOnChange = (
    event:
      | ComboBoxChangeEvent
      | DatePickerChangeEvent
      | CustomDatePickerChangeEvent
      | { value: string },
    key: string
  ) => {
    setIsChanged(true);
    setFormState((prevState) => ({
      ...prevState,
      [key]: event.value,
    }));
  };

  const handleUpdateMainForm = (key: string, value: string | number | Date) => {
    console.log(value);
    setIsChanged(true);
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (

      <FormContainer ref={formContainerRef}>
        <Form onKeyDown={(e) => handleKeyDown(e, formRef)} ref={formRef}>
          <div className="form-section-header-container">
            <div className="form-title">{title}</div>
            <div className="form-header-btn-wrapper">
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={onClose}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div className="form-content">
            <FormFieldsWrapper>
              <div className="sections-wrapper">
                <div className="section">
                  <h2>General</h2>
                  <CustomGrid
                    cols="324px 324px"
                    colGap="28px"
                    rowGap="8px"
                    style={{ margin: '0px auto' }}
                    width="676px"
                  >
                    <FiscalLeftLabelInputWrapper>
                      <label htmlFor="FiscalYrStartDate">
                        Fiscal Year Start
                      </label>
                      <CustomizedDatePicker
                        tabIndex={1}
                        width="140px"
                        name="FiscalYrStartDate"
                        id="FiscalYrStartDate"
                        placeholder=""
                        value={
                          formState.FiscalYrStartDate
                            ? moment(formState.FiscalYrStartDate).toDate()
                            : new Date()
                        }
                        onChange={(e: CustomDatePickerChangeEvent) =>
                          handleOnChange(e, 'FiscalYrStartDate')
                        }
                      />
                    </FiscalLeftLabelInputWrapper>
                    <LeftLabelInputWrapper className='left-label-wrapper'>
                      <label htmlFor="DefUnclassified">
                        Unclassified Expenses
                      </label>
                      <CustomColComboBox
                        tabIndex={2}
                        comboBoxType="AllAccounts"
                        dataList={ExpensesNoCoGSAccounts}
                        value={formState.DefUnclassified}
                        defaultValue={formState.DefUnclassified}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefUnclassified', e.value);
                        }}
                        width={140}
                        id="DefUnclassified"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefRetPayAcct">Returned Payments</label>
                      <CustomColComboBox
                        tabIndex={3}
                        comboBoxType="AllAccounts"
                        dataList={ExpensesNoCoGSAccounts}
                        value={formState.DefRetPayAcct}
                        defaultValue={formState.DefRetPayAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefRetPayAcct', e.value);
                        }}
                        width={140}
                        id="DefRetPayAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefSTPayAcct">Sales Tax Payable</label>
                      <CustomColComboBox
                        tabIndex={4}
                        comboBoxType="AllAccounts"
                        dataList={ExpensesNoCoGSAccounts}
                        value={formState.DefSTPayAcct}
                        defaultValue={formState.DefSTPayAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefSTPayAcct', e.value);
                        }}
                        width={140}
                        id="DefSTPayAcct"
                      />
                    </LeftLabelInputWrapper>
                  </CustomGrid>
                </div>
                <div className="section">
                  <h2>Invoices and Receivables</h2>
                  <CustomGrid
                    cols="324px 324px"
                    colGap="28px"
                    rowGap="8px"
                    style={{ margin: '0px auto' }}
                    width="676px"
                  >
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefARTerms">Invoice Payment Terms</label>
                      <CustomColComboBox
                        tabIndex={5}
                        comboBoxType="TermsCodes"
                        dataList={TermsCodes}
                        value={TermsCodes.find(
                          (term) => term.TermsCode === formState.DefARTerms
                        )}
                        defaultValue={TermsCodes.find(
                          (term) => term.TermsCode === formState.DefARTerms
                        )}
                        onChange={(e) => {
                          if (e.value?.TermsCode)
                            handleUpdateMainForm(
                              'DefARTerms',
                              e.value?.TermsCode
                            );
                        }}
                        width={140}
                        id="DefARTerms"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefARWOAcct">Invoice Write-Offs</label>
                      <CustomColComboBox
                        tabIndex={6}
                        comboBoxType="AllAccounts"
                        dataList={IncomeExpenseAccounts}
                        value={formState.DefARWOAcct}
                        defaultValue={formState.DefARWOAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefARWOAcct', e.value);
                        }}
                        width={140}
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefARAcct">Receivables Account</label>
                      <CustomColComboBox
                        tabIndex={7}
                        comboBoxType="AllAccounts"
                        dataList={ARAccounts}
                        value={formState.DefARAcct}
                        defaultValue={formState.DefARAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefARAcct', e.value);
                        }}
                        width={140}
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefARDiscAcct">Invoice Discounts</label>
                      <CustomColComboBox
                        tabIndex={8}
                        comboBoxType="AllAccounts"
                        dataList={IncomeExpenseAccounts}
                        value={formState.DefARDiscAcct}
                        defaultValue={formState.DefARDiscAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefARDiscAcct', e.value);
                        }}
                        width={140}
                        id="DefARDiscAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefUndepFundsAcct">
                        Undeposited Payments
                      </label>
                      <CustomColComboBox
                        tabIndex={9}
                        comboBoxType="AllAccounts"
                        dataList={AssetsNotFixedAccounts}
                        value={formState.DefUndepFundsAcct}
                        defaultValue={formState.DefUndepFundsAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefUndepFundsAcct', e.value);
                        }}
                        width={140}
                        id="DefUndepFundsAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefUnappliedCreditAcct">
                        Unapplied Payments
                      </label>
                      <CustomColComboBox
                        tabIndex={10}
                        comboBoxType="AllAccounts"
                        dataList={AssetsNotFixedAccounts}
                        value={formState.DefUnappliedCreditAcct}
                        defaultValue={formState.DefUnappliedCreditAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm(
                              'DefUnappliedCreditAcct',
                              e.value
                            );
                        }}
                        width={140}
                        id="DefUnappliedCreditAcct"
                      />
                    </LeftLabelInputWrapper>
                  </CustomGrid>
                </div>
                <div className="section">
                  <h2>Bills and Payables</h2>
                  <CustomGrid
                    cols="324px 324px"
                    colGap="28px"
                    rowGap="8px"
                    style={{ margin: '0px auto' }}
                    width="676px"
                  >
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefAPTerms">Bill Payment Terms</label>
                      <CustomColComboBox
                        tabIndex={11}
                        comboBoxType="TermsCodes"
                        dataList={TermsCodes}
                        value={TermsCodes.find(
                          (term) => term.TermsCode === formState.DefAPTerms
                        )}
                        defaultValue={TermsCodes.find(
                          (term) => term.TermsCode === formState.DefAPTerms
                        )}
                        onChange={(e) => {
                          if (e.value?.TermsCode)
                            handleUpdateMainForm(
                              'DefAPTerms',
                              e.value?.TermsCode
                            );
                        }}
                        width={140}
                        id="DefAPTerms"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefAPWOAcct">Bill Write-Offs</label>
                      <CustomColComboBox
                        tabIndex={12}
                        comboBoxType="AllAccounts"
                        dataList={IncomeExpenseAccounts}
                        value={formState.DefAPWOAcct}
                        defaultValue={formState.DefAPWOAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefAPWOAcct', e.value);
                        }}
                        width={140}
                        id="DefAPWOAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefAPAcct">Payables Account</label>
                      <CustomColComboBox
                        tabIndex={13}
                        comboBoxType="AllAccounts"
                        dataList={APAccounts}
                        value={formState.DefAPAcct}
                        defaultValue={formState.DefAPAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefAPAcct', e.value);
                        }}
                        width={140}
                        id="DefAPAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefAPDiscAcct">Bill Discounts</label>
                      <CustomColComboBox
                        tabIndex={14}
                        comboBoxType="AllAccounts"
                        dataList={IncomeExpenseAccounts}
                        value={formState.DefAPDiscAcct}
                        defaultValue={formState.DefAPDiscAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefAPDiscAcct', e.value);
                        }}
                        width={140}
                        id="DefAPDiscAcct"
                      />
                    </LeftLabelInputWrapper>
                  </CustomGrid>
                </div>
                <div className="section">
                  <h2>Cash and Credit</h2>
                  <CustomGrid
                    cols="324px 324px"
                    colGap="28px"
                    style={{ margin: '0px auto' }}
                    width="676px"
                  >
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefBankAcct">Primary Bank Account</label>
                      <CustomColComboBox
                        tabIndex={15}
                        comboBoxType="AllAccounts"
                        dataList={BankAccounts}
                        value={formState.DefBankAcct}
                        defaultValue={formState.DefBankAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefBankAcct', e.value);
                        }}
                        width={140}
                        id="DefBankAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefCreditCardAcct">
                        Primary Credit Card
                      </label>
                      <CustomColComboBox
                        tabIndex={16}
                        comboBoxType="AllAccounts"
                        dataList={CreditCardAccounts}
                        value={formState.DefCreditCardAcct}
                        defaultValue={formState.DefCreditCardAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefCreditCardAcct', e.value);
                        }}
                        width={140}
                        id="DefCreditCardAcct"
                      />
                    </LeftLabelInputWrapper>
                  </CustomGrid>
                </div>
                <div className="section">
                  <h2>Payroll</h2>
                  <CustomGrid
                    cols="324px 324px"
                    colGap="28px"
                    rowGap="8px"
                    style={{ margin: '0px auto' }}
                    width="676px"
                  >
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefFUTAAcct">FUTA Expenses</label>
                      <CustomColComboBox
                        tabIndex={17}
                        comboBoxType="AllAccounts"
                        dataList={CurrentLiability}
                        value={formState.DefFUTAAcct}
                        defaultValue={formState.DefFUTAAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefFUTAAcct', e.value);
                        }}
                        width={140}
                        id="DefFUTAAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefSUTAAcct">SUTA Expenses</label>
                      <CustomColComboBox
                        tabIndex={18}
                        comboBoxType="AllAccounts"
                        dataList={CurrentLiability}
                        value={formState.DefSUTAAcct}
                        defaultValue={formState.DefSUTAAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefSUTAAcct', e.value);
                        }}
                        width={140}
                        id="DefSUTAAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefPayExpAcct">Payroll Expenses</label>
                      <CustomColComboBox
                        tabIndex={17}
                        comboBoxType="AllAccounts"
                        dataList={PayrollAccounts}
                        value={formState.DefPayExpAcct}
                        defaultValue={formState.DefPayExpAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefPayExpAcct', e.value);
                        }}
                        width={140}
                        id="DefPayExpAcct"
                      />
                    </LeftLabelInputWrapper>
                    <LeftLabelInputWrapper>
                      <label htmlFor="DefPayTaxExpAcct">Payroll Tax Expenses</label>
                      <CustomColComboBox
                        tabIndex={18}
                        comboBoxType="AllAccounts"
                        dataList={PayrollAccounts}
                        value={formState.DefPayTaxExpAcct}
                        defaultValue={formState.DefPayTaxExpAcct}
                        onChange={(e) => {
                          if (e.value?.AccountNo)
                            handleUpdateMainForm('DefPayTaxExpAcct', e.value);
                        }}
                        width={140}
                        id="DefPayTaxExpAcct"
                      />
                    </LeftLabelInputWrapper>
                  </CustomGrid>
                </div>
              </div>
              <div className="form-footer">
                <ButtonWrapper>
                  <Button
                    disabled={!isValid}
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit(formState);
                    }}
                    id="save-btn"
                    title="Save"
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        onSubmit(formState);
                      }
                    }}
                    tabIndex={18}
                  >
                    <u>S</u>ave
                  </Button>
                  <Button
                    onClick={onClose}
                    type="button"
                    id="cancel-btn"
                    title="Cancel"
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        onClose();
                      }
                    }}
                    tabIndex={19}
                  >
                    <u>C</u>ancel
                  </Button>
                </ButtonWrapper>
              </div>
            </FormFieldsWrapper>
          </div>
        </Form>
      </FormContainer>

  );
};

export default AcctDefaults;
