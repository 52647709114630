import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { plusIcon } from '@progress/kendo-svg-icons';

import { useKeyPress } from '../../../hooks';

import { IPaymentTermsProps } from './PaymentTerms.types';
import { ITermsCode } from '../../../data/types';

import { Button, ListingGrid } from '../../common';
import PaymentTermsCodesDialog from './PaymentTermsCodesDialog';

import { columns } from './model';

import {
  useCreatePaymentTerm,
  useUpdatePaymentTerms,
  useDeletePaymentTermById,
} from '../../../services/hooks/useGetPaymentTerms';

import {
  FormContainer,
  Form,
  ListingGridContainer,
  ButtonWrapper,
} from './PaymentTerm.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';

type SelectionType = { field?: string; dataItem: any };

const PaymentTerms: FC<IPaymentTermsProps> = ({
  title,
  resultData,
  onClose,
  onNextPage,
  onPreviousPage
}) => {
  const [originData, setOriginData] = useState<ITermsCode[]>(
    resultData[0].data
  );
  const [filterData, setFilterData] = useState<ITermsCode[]>(
    resultData[0].data
  );
  const [showContext, setShowContext] = useState<boolean>(false);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [selectedItem, setSelectedItem] = useState<SelectionType>();
  const [showDialog, setShowDialog] = useState<{
    show: boolean;
    isEdit: boolean;
    data: ITermsCode | null;
  }>({
    show: false,
    isEdit: false,
    data: null,
  });

  const offset = useRef({ left: 0, top: 0 });

  useEffect(() => {
    setOriginData(resultData[0].data);
    setFilterData(resultData[0].data);
  }, [resultData]);

  const refetchResults = () => {
    resultData.forEach((result) => result.refetch());
  };

  const handleOpenDialog = (isEdit: boolean, data: ITermsCode | null) => {
    setShowDialog({
      show: true,
      isEdit,
      data,
    });
  };

  const handleCloseDialog = (shouldRefetch?: boolean) => {
    setShowDialog({
      show: false,
      isEdit: false,
      data: null,
    });
    if (shouldRefetch) {
      refetchResults();
    }
  };

  const { mutate: updatePaymentTerms } =
    useUpdatePaymentTerms(handleCloseDialog);
  const { mutate: createPaymentTerms } =
    useCreatePaymentTerm(handleCloseDialog);
  const { mutate: deletePaymentTerms } =
    useDeletePaymentTermById(handleCloseDialog);

  const handleSubmitPaymentTerm = (params: {
    isNew: boolean;
    data: any;
    id?: any;
  }) => {
    const { isNew, data, id } = params;
    try {
      if (isNew) {
        createPaymentTerms({
          formattedData: data,
        });
      } else {
        updatePaymentTerms({
          data,
          id,
        });
      }
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const handleDeletePaymentTerm = (params: { id: any }) => {
    const { id } = params;

    try {
      deletePaymentTerms({ id });
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {
    },
    () => {
    },
    () => {
    },
    () => {
      handleOpenDialog(false, null);
    }
  );

  return (
    <>
      <FormContainer>
        <Form>
          <div className="form-section-header-container">
            <div className="form-title">{title}</div>
            <div className="form-header-btn-wrapper">
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={onClose}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div className="form-content">
            <CustomGrid
              cols="800px"
              colGap="0px"
              width="auto"
              style={{ padding: '30px 0px 30px 30px' }}
            >
              <ListingGridContainer>
                <ListingGrid
                  rowHeight={24}
                  data={sort ? orderBy(filterData, sort) : filterData}
                  dataItemKey="IDNo"
                  onRowClick={(e) => {
                    setFilterData((prev) =>
                      prev.map((item) => ({
                        ...item,
                        selected: item.IDNo === e.dataItem.IDNo,
                      }))
                    );
                  }}
                  onRowDoubleClick={(e) => {
                    handleOpenDialog(true, e.dataItem);
                  }}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuOpen(event.syntheticEvent);
                    const { dataItem, field } = event;
                    setFilterData((prev) =>
                      prev.map((item) => ({
                        ...item,
                        selected: item.IDNo === dataItem.IDNo,
                      }))
                    );
                    setSelectedItem({ field, dataItem });
                  }}
                >
                  {columns.map((column, index) => (
                    <GridColumn {...column} key={index} />
                  ))}
                </ListingGrid>
                <ContextMenu
                  show={showContext}
                  offset={offset.current}
                  onSelect={handleOnSelect}
                  onClose={() => setShowContext(false)}
                  className="context-menu"
                >
                  <MenuItem
                    text="Sort Ascending"
                    data={{
                      action: 'sortAsc',
                    }}
                  />
                  <MenuItem
                    text="Sort Descending"
                    data={{
                      action: 'sortDesc',
                    }}
                  />
                  <MenuItem
                    text="Filter In Selected"
                    data={{
                      action: 'filterIn',
                    }}
                  />
                  <MenuItem
                    text="Filter Out Selected"
                    data={{
                      action: 'filterOut',
                    }}
                  />
                  <MenuItem
                    text="Remove All Filters"
                    data={{
                      action: 'removeFilters',
                    }}
                  />
                </ContextMenu>
              </ListingGridContainer>
              <ButtonWrapper>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenDialog(false, null);
                  }}
                  id="create-btn"
                  title="Add New"
                  svgIcon={plusIcon}
                >
                  ADD <u>N</u>EW
                </Button>
              </ButtonWrapper>
            </CustomGrid>
          </div>
        </Form>
      </FormContainer>
      {showDialog.show && (
        <PaymentTermsCodesDialog
          isEdit={showDialog.isEdit}
          data={showDialog.data}
          handleClose={handleCloseDialog}
          handleSubmitPaymentTerm={handleSubmitPaymentTerm}
          handleDeletePaymentTerm={handleDeletePaymentTerm}
        />
      )}
    </>
  );
};

export default PaymentTerms;
