import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { vendorsImportService } from "../../../services/customFormService";
import { EntityImportItemType } from "../../../data/types";

type VendorsContextType = {
    vendorsList: EntityImportItemType[];
    refresh?: boolean;
    setRefresh: (refresh: boolean) => void;
};

export const VendorsContext = createContext<VendorsContextType>({
    vendorsList: [],
    refresh: false,
    setRefresh: () => {},
});

export const VendorsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [vendorsList, setVendorsList] = useState<any[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const getVendorList = async () => {
        const response = await vendorsImportService.getVendors();
        setVendorsList(response);
    };

    useEffect(() => {
        setRefresh(false);
        getVendorList();
        if (refresh) {
            getVendorList();
        }
    }, [refresh])

    return (
        <VendorsContext.Provider
            value={{ vendorsList, setRefresh }}
        >
            {children}
        </VendorsContext.Provider>
    );
};