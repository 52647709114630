import type { GridColumnProps } from '@progress/kendo-react-grid';
import { CheckCell } from '../../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'PayCode',
    width: 100,
    className: 'text-left',
  },
  {
    title: 'Description',
    field: 'PayDesc',
    width: 200,
    className: 'text-left',
  },
  {
    title: 'Method',
    field: 'PayMethod',
    width: 125,
    className: 'text-left',
  },
  {
    title: 'Rate',
    field: 'PayRate',
    width: 125,
    className: 'text-right',
    format: `{0:n2}`,
  },
  {
    title: 'Taxable',
    field: 'PayTaxableYN',
    width: 125,
    cell: CheckCell,
  },
  {
    title: 'Account',
    field: 'AccountNo',
    className: 'text-left',
  },
];
