import { useEffect } from 'react';
import { WindowHandle } from '@progress/kendo-react-dialogs';

const useDisableEscape = (dialogRef: React.RefObject<WindowHandle>) => {
  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        event.stopPropagation();
        return;
      }
    }

    dialogRef.current?.element?.addEventListener('keydown', handleKeyPress);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dialogRef.current?.element?.removeEventListener(
        'keydown',
        handleKeyPress
      );
    };
  }, [dialogRef]);
};

export default useDisableEscape;
