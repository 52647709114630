import { EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { textboxIcon } from '@progress/kendo-svg-icons';
import { Button } from '../..';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Popup, PopupProps, PopupWithoutContext } from '@progress/kendo-react-popup';
import { useDetectClickOutside } from '../../../../hooks/useDetectClickOutside';

export const FieldSelectorTool = (props: EditorTools.InsertImageProps) => {
  const [showPopup, setShowPopup] = useState(false);

  const { view } = props;
  const nodeType = view && view.state.schema.nodes["text"] as any;
  const canInsert = (view && nodeType) && EditorUtils.canInsert(view.state, nodeType);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowPopup(true)
  }

  useEffect(() => {
    const button = document.querySelector('#field-select-tool') as HTMLButtonElement;
    buttonRef.current = button;
  }, [])

  const onClose = useCallback(() => {
    setShowPopup(false)
  }, [])

  const onFieldSelect = useCallback((field: string) => {
    EditorUtils.insertNode(view!, view!.state.schema.text(field) as any);
  }, [view])

  return (
    <>
      <Button
        id="field-select-tool"
        onClick={onClick}
        disabled={!canInsert}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={"Insert Image"}
        svgIcon={textboxIcon}
      />
      {showPopup &&
        <SelectorPopup
          anchor={buttonRef.current}
          onClose={onClose}
          onFieldSelect={onFieldSelect} />}
    </>
  );
}

const AVAILABLE_FIELDS = {
  "Your_Name": "Your Name",
  "Your_Title": "Your Title",
  "Your_Company": "Your Company",
  "Your_Phone_Number": "Your Phone Number",
  "Your_Email": "Your Email Address",
  "Website_URL": "Website URL",
  "Recipient_Name": "Recipient's Name",
}

const SelectorPopup = (props: PopupProps & {
  onFieldSelect: (field: string) => void;
  onClose: () => void
}) => {
  const { onClose } = props

  const onClickOutside = useCallback(() => {
    onClose();
  }, [onClose])

  const containerRef = useDetectClickOutside(onClickOutside);
  return <Popup
    ref={(el: PopupWithoutContext) => {
      if (el) {
        containerRef.current = el?.element
      }
    }}
    show
    anchorAlign={{ horizontal: 'left', vertical: 'bottom' }}
    {...props}
  >
    <div className='flex flex-1 gap-2 flex-wrap max-w-[500px] h-fit w-fit p-4'>
      {Object.entries(AVAILABLE_FIELDS).map(([key, value]) => {
        const formattedKey = ` {${key}} `
        return <span
          onClick={() => props.onFieldSelect(formattedKey)}
          className='w-fit h-fit px-2 py-1 rounded-sm bg-slate-100 cursor-pointer hover:bg-slate-200 active:bg-slate-300'
        >{value}</span>
      }
      )}
    </div>
  </Popup>
}