import React, { FC, ReactElement, useEffect, useState } from 'react';
import {
  GridColumn as Column,
  GridCellProps,
} from '@progress/kendo-react-grid';
import { plusIcon } from '@progress/kendo-svg-icons';

import { useKeyPress } from '../../../../../hooks';

import { ListingGrid, Button } from '../../../../common';

import { columns } from './model';

import {
  ButtonWrapper,
  CustomSpanCellTableWrapper,
  ListingGridWrapper,
} from '../../UserPerm.styled';
import { permsService } from '../../../../../services/perm/permsService';
import { SelectableCheckboxCell } from '../../components/SelectableCheckBoxCell';
import { IRole } from '../../../../../data/types';
import { Loader } from '../../../../common/Loader';
import SelectableGrid from '../../../../common/SelectableGrid';
import RoleDialog from '../../forms/RoleDialog';
import {
  useCreateRole,
  useDeleteRoleById,
  useUpdateRole,
} from '../../../../../services/hooks/useRoles';

interface PermCode {
  PermCategory?: string;
  PermCode?: string;
  PermDescription?: string;
  PermSortOrder?: number;
  ProductID: number;
  categoryCellRowSpan?: number | undefined;
  categoryCellClassName?: string;
  className?: string;
  checked?: boolean;
}

interface RolePermCode {
  GroupRole?: string;
  GroupRoleCode?: string;
  IDNo?: number;
  PermCategory?: string;
  PermCode?: string;
  PermDescription?: string;
  PermGroupDesc?: string;
  PermSortOrder?: number;
}

const mergeCells = (data: PermCode[]) => {
  let looped = 1;
  for (let i = 0; i < data.length; i += looped) {
    let rowSpan = 1;
    looped = 1;
    for (let j = i + 1; j < data.length; j++) {
      if (data[i].PermCategory === data[j].PermCategory) {
        looped++;
        rowSpan++;
      } else {
        break;
      }
    }
    data[i].categoryCellRowSpan =
      rowSpan === 1 ? (looped === 1 ? 1 : undefined) : rowSpan;
  }
  return data;
};

export interface RolesProps {
  onClose: () => void;
}

const Roles: FC<RolesProps> = ({ onClose }) => {
  const [rolesList, setRolesList] = useState<IRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<IRole | null>(null);
  const [permCodes, setPermCodes] = useState<PermCode[]>([]);
  const [assignedPerms, setAssignedPerms] = useState<RolePermCode[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRoleLoading, setIsRoleLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isGridChanged, setIsGridChanged] = useState<boolean>(false);

  const [showDialog, setShowDialog] = useState<{
    show: boolean;
    isEdit: boolean;
    data: IRole | null;
  }>({
    show: false,
    isEdit: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const rolesResp = await permsService.getRoles();
      const allPerms = await permsService.getPerms();
      if (rolesResp?.data?.length) {
        let firstRole: IRole | null = null;
        const rolesToSet = rolesResp.data.map((role: IRole, index: number) => {
          if (index === 0) {
            firstRole = { ...role };
            return {
              selected: true,
              ...role,
            };
          }
          return role;
        });
        if (firstRole && allPerms?.data?.length) {
          await fetchPermsAssignedToRole(firstRole, allPerms?.data);
        }
        setRolesList(rolesToSet);
        setSelectedRole(firstRole);
      } else {
        console.log('Error in fetching Roles');
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Error: ', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedRole && permCodes?.length) {
      fetchPermsAssignedToRole(selectedRole, permCodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  const fetchPermsAssignedToRole = async (
    selectedRole: IRole,
    allPerms: PermCode[]
  ) => {
    setIsRoleLoading(true);
    const rolePermsResp = await permsService.getRolePerms(
      selectedRole.PermRoleCode
    );
    const rolePermsList: any[] = rolePermsResp.data;

    setAssignedPerms(rolePermsList);
    let allPermsList = allPerms.map((permCode) => {
      if (
        rolePermsList.some(
          (rolePerm) => rolePerm.PermCode === permCode.PermCode
        )
      ) {
        return { ...permCode, checked: true };
      }
      return { ...permCode, checked: false };
    });

    setPermCodes(mergeCells(allPermsList));
    setIsRoleLoading(false);
  };

  // const updateItem = (item: PermCode) => {
  //   const updatedProducts = [...permCodes];
  //   const index = updatedProducts.findIndex(
  //     (record) => record.PermCategory === item.PermCategory
  //   );
  //   updatedProducts[index] = item;
  //   return updatedProducts;
  // };

  const getItemIndex = (dataItem: PermCode): number => {
    return permCodes.findIndex(
      (record) => record.PermCategory === dataItem.PermCategory
    );
  };

  const hoverMergedCellByIndex = (index: number, hover: any) => {
    let currentIndex = index;
    let currentDataItem = permCodes[currentIndex];
    while (!currentDataItem.categoryCellRowSpan) {
      currentIndex--;
      currentDataItem = permCodes[currentIndex];
    }
    currentDataItem.categoryCellClassName = hover ? 'k-hover' : undefined;
    update(currentDataItem);
  };

  // const updateNextItems = (index: number, count: number, hover: any) => {
  //   for (let i = index; i < index + count; i++) {
  //     const dataItem = gridData[i];
  //     dataItem.className = hover ? 'k-hover' : undefined;
  //     update(dataItem);
  //   }
  // };

  // const handleMergedHover = (
  //   dataItem: PermCode,
  //   rowSpanNumber: number,
  //   hover: boolean
  // ) => {
  //   const index = getItemIndex(dataItem);
  //   updateNextItems(index, rowSpanNumber, hover);
  // };

  const handleCellHover = (dataItem: PermCode, hover: any) => {
    if (dataItem.categoryCellRowSpan) {
      return;
    }
    let index = getItemIndex(dataItem);
    hoverMergedCellByIndex(index, hover);
  };

  const update = (dataItem: PermCode) => {
    // const updatedData = updateItem(dataItem);
    // setPermCodes(updatedData);
  };

  const cellRender = (
    cell: 'null' | ReactElement<HTMLTableCellElement> | any,
    props: GridCellProps
  ) => {
    const { dataItem, field } = props;

    if (field === 'PermCategory') {
      if (dataItem.categoryCellRowSpan) {
        return (
          <td
            {...cell?.props}
            rowSpan={dataItem.categoryCellRowSpan}
            className={`${
              dataItem.className || dataItem.categoryCellClassName
            } vertical-align-base spanned-cell text-left`}
            onMouseOver={() => {
              // handleMergedHover(
              //   dataItem,
              //   dataItem.categoryCellRowSpan!,
              //   true
              // );
            }}
            onMouseOut={() => {
              // handleMergedHover(
              //   dataItem,
              //   dataItem.categoryCellRowSpan!,
              //   false
              // );
            }}
            onClick={(e) => {
              if (dataItem?.categoryCellRowSpan) {
                handleClickSpanCell(dataItem);
              }
            }}
          >
            {cell?.props?.children}
          </td>
        );
      } else {
        return null;
      }
    }

    return (
      <td
        {...cell?.props}
        className={`${dataItem.className} text-left unspanned-cell`}
        colSpan={props.colSpan}
        onMouseOver={() => {
          handleCellHover(props.dataItem, true);
        }}
        onMouseOut={() => {
          handleCellHover(props.dataItem, false);
        }}
      >
        {cell?.props.children}
      </td>
    );
  };

  useKeyPress(
    () => {},
    () => {},
    () => {},
    () => {
      handleOpenDialog(false, null);
    }
  );

  const onChangeCell = async (e: any) => {
    if (isRoleLoading || isSaving) {
      return;
    }
    const selectedPerm = e?.dataItem;
    const shouldCheck = e?.value ? true : false;

    if (shouldCheck) {
      let newPerms = permCodes.map((code) => {
        if (code.PermCode === selectedPerm?.PermCode) {
          return { ...code, checked: e?.value ? true : false };
        } else {
          return code;
        }
      });

      setPermCodes(mergeCells(newPerms));
    } else if (!shouldCheck) {
      let newPerms = permCodes.map((code) => {
        if (code.PermCode === selectedPerm?.PermCode) {
          return { ...code, checked: e?.value ? true : false };
        } else {
          return code;
        }
      });

      setPermCodes(mergeCells(newPerms));
    }
    setIsGridChanged(true);
  };

  const handleClickSpanCell = (dataItem: PermCode) => {
    const PermCategory = dataItem.PermCategory;
    if (PermCategory) {
      let newPerms = permCodes.filter(
        (item) => item.PermCategory === PermCategory
      );
      const isAllCatPermsChecked = newPerms.every((item) => item.checked);

      newPerms = permCodes.map((code) => {
        if (code.PermCategory === PermCategory) {
          return { ...code, checked: !isAllCatPermsChecked };
        } else {
          return code;
        }
      });

      setPermCodes(mergeCells(newPerms));
      setIsGridChanged(true);
    }
  };

  const handleOpenDialog = (isEdit: boolean, data: IRole | null) => {
    setShowDialog({
      show: true,
      isEdit,
      data,
    });
  };

  const handleCloseDialog = (shouldRefetch?: boolean) => {
    setShowDialog({
      show: false,
      isEdit: false,
      data: null,
    });
    if (shouldRefetch) {
      fetchData();
    }
  };

  const handleSubmit = (params: { isNew: boolean; data: any; id?: any }) => {
    const { isNew, data, id } = params;
    try {
      if (isNew) {
        createRole({
          formattedData: data,
        });
      } else {
        updateRole({
          data,
          id,
        });
      }
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const handleDelete = (params: { id: any }) => {
    const { id } = params;

    try {
      deleteRole({ id });
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const { mutate: createRole } = useCreateRole(handleCloseDialog);
  const { mutate: updateRole } = useUpdateRole(handleCloseDialog);
  const { mutate: deleteRole } = useDeleteRoleById(handleCloseDialog);

  const handleSaveAllPerms = async () => {
    if (isSaving || isRoleLoading) {
      return;
    }
    try {
      setIsSaving(true);

      const savedPerms = permCodes.filter((code) => code.checked);

      const promises = assignedPerms.map(async (item) => {
        const resp = await permsService.unAssignPermToGroupRole(item.IDNo);
        if (resp.status === 200) {
          return resp.status;
        }
      });

      await Promise.all(promises);

      const savedPermsPayload = savedPerms.map((perm) => {
        return {
          GroupRole: 'R',
          GroupRoleCode: selectedRole?.PermRoleCode,
          PermCode: perm?.PermCode,
        };
      });

      await permsService.saveAllPerms(savedPermsPayload);

      if (selectedRole) {
        const rolePermsResp = await permsService.getRolePerms(
          selectedRole.PermRoleCode
        );
        const rolePermsList: any[] = rolePermsResp.data;

        setAssignedPerms(rolePermsList);
        setIsGridChanged(false);
      }
      setIsSaving(false);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  const handleResetPerms = () => {
    let allPermsList = permCodes.map((permCode) => {
      if (
        assignedPerms.some(
          (rolePerm) => rolePerm.PermCode === permCode.PermCode
        )
      ) {
        return { ...permCode, checked: true };
      }
      return { ...permCode, checked: false };
    });

    setPermCodes(mergeCells(allPermsList));
    setIsGridChanged(false);
  };

  return (
    <>
      <ListingGridWrapper>
        {!isLoading ? (
          <>
            <ListingGrid
              data={rolesList}
              dataItemKey="PermRoleCode"
              onRowClick={(e) => {
                if (isRoleLoading || isSaving) {
                  return;
                }
                setIsGridChanged(false);
                setSelectedRole(e.dataItem);
                setRolesList((prev) =>
                  prev.map((item) => ({
                    ...item,
                    selected: item.PermRoleCode === e.dataItem.PermRoleCode,
                  }))
                );
              }}
              onRowDoubleClick={(e) => {
                handleOpenDialog(true, e.dataItem);
              }}
            >
              {columns.map((column, index) => (
                <Column {...column} key={`${new Date().getTime()}${index}`} />
              ))}
            </ListingGrid>

            <CustomSpanCellTableWrapper>
              <SelectableGrid
                data={permCodes}
                dataItemKey="PermCode"
                cellRender={cellRender}
                style={{ height: '100%', borderWidth: '1px' }}
                className="listing-grid"
                size="small"
                rowHeight={30}
                detailRowHeight={30}
                enterEdit={() => {}}
                exitEdit={() => {}}
                undoEdit={() => {}}
                tabIndex={1}
              >
                <Column
                  field="ProductID"
                  title=" "
                  width="46px"
                  cell={(props) => (
                    <SelectableCheckboxCell
                      {...props}
                      onChange={onChangeCell}
                    />
                  )}
                />
                <Column field="PermCategory" title="Category" width="120px" />
                <Column
                  field="PermDescription"
                  title="Permission"
                  width="250px"
                />
              </SelectableGrid>
            </CustomSpanCellTableWrapper>
          </>
        ) : (
          <Loader />
        )}
      </ListingGridWrapper>

      <ButtonWrapper>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleOpenDialog(false, null);
          }}
          id="create-btn"
          title="Add New"
          svgIcon={plusIcon}
        >
          ADD <u>N</u>EW
        </Button>
        <div className="perm-buttons">
          {isGridChanged ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleResetPerms();
              }}
              id="create-btn"
              title="Reset permissions grid"
            >
              RESET
            </Button>
          ) : (
            <></>
          )}
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSaveAllPerms();
            }}
            id="create-btn"
            title="Save all permissions"
          >
            <u>S</u>AVE
          </Button>
        </div>
      </ButtonWrapper>
      {showDialog.show && (
        <RoleDialog
          isEdit={showDialog.isEdit}
          data={showDialog.data}
          handleClose={handleCloseDialog}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default Roles;
