import React from 'react';
import { Grid, GridProps } from '@progress/kendo-react-grid';

interface ListingGridProps extends GridProps {
  children: any;
}

export const ListingGrid = React.forwardRef<Grid, ListingGridProps>(
  ({ children, ...props }, ref) => {
    return (
      <Grid
        ref={ref}
        size="small"
        navigatable={true}
        selectable={{
          enabled: true,
          mode: 'single',
        }}
        rowHeight={30}
        detailRowHeight={30}
        style={{ height: '100%', borderWidth: '1px' }}
        className="listing-grid"
        selectedField="selected"
        {...props}
      >
        {children}
      </Grid>
    );
  }
);
