import { GridCellProps } from '@progress/kendo-react-grid';

export const ImportCheckCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, className, onContextMenu } = props;

  if (!field) return null;

  return (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      className={className ? `k-table-td ${className}` : 'k-table-td'}
      style={{ textAlign: 'center' }}
      role="gridcell"
      onContextMenu={(e) => onContextMenu?.(e, dataItem, field)}
    >
      {dataItem[field] ? (
        <span className="k-icon k-i-checkmark" style={{ color: 'green' }}/>
      ) : (
        <span className="k-icon k-i-close" style={{ color: 'red' }}/>
      )}
    </td>
  );
}