import type { GridCellProps } from "@progress/kendo-react-grid";
import { TextBox } from "@progress/kendo-react-inputs";

export const ReadOnlyCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render, className } = props;

  if (!render || !field) return null;

  const value = dataItem[field] || '';

  const defaultRendering = (
    <td
      style={{ padding: 0 }}
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      aria-readonly={true}
      className={className}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextBox
          style={{
            backgroundColor: "transparent",
            margin: "0",
            borderWidth: "0 2px",
            height: "28px"
          }}
          value={value}
          readOnly
          size="small"
          fillMode="flat"
          maxLength={100}
        />
      </div>
    </td>
  );

  return render.call(undefined, defaultRendering, props);
}
