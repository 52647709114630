import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { PayCodeDeductsDialogProps } from './PayCodeDeductsDialog.types';
import {
  Button,
  InputWrapper,
  CustomColComboBox,
} from '../../../../common';
import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validatePayCodeDeductForm } from '../../../../../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

import {
  DialogContent,
  StyledTitle,
  CustomButtonWrapper,
  FieldRow,
} from './PayCodeDeductsDialog.styled';
import { payCodeDeductServices } from '../../../../../services/payCodeDeductServices';
import { getObjectFromName } from '../apiTranslators';
import { PayCodeDeductMethod } from '../../PayCodeDeducts/PayCodeDeducts.types';
import { PayCodeDeductibility } from '../../PayCodeDeducts/PayCodeDeducts.types';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MoveableDialog from '../../../../common/MovableDialog';
import { DeductDataTemplate } from '../../../../../data/types';
import { deductSvcCodes } from '../../../../../constants';

const PayCodeDeductDialog: React.FC<PayCodeDeductsDialogProps> = ({
  inputDefaults,
  isEdit,
  data,
  handleClose,
}) => {
  const [errorText, setErrorText] = useState('');
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    IDNo: isEdit && data ? data.IDNo : 0,
    PayCode: isEdit && data ? data.PayCode : '',
    PayDesc: isEdit && data ? data.PayDesc : '',
    AccountNo: {
      AccountNo:
        isEdit && data.AccountNo
          ? data.AccountNo
          : inputDefaults.AccountNo.AccountNo,
    },
    Method:
      isEdit && data.DeductMethod ? data.DeductMethod : inputDefaults.DedMethod,
    Rate: isEdit && data.DeductRate ? data.DeductRate : inputDefaults.Rate,
    DeductTaxHandling:
      isEdit && data.DeductTaxHandling
        ? data.DeductTaxHandling
        : inputDefaults.Deductibility,
    DeductEntity:
      isEdit && data.DeductEntityName
        ? { IDNo: data.DeductEntityID, EntityName: data.DeductEntityName }
        : inputDefaults.DeductEntity,
    SvcCode: isEdit && data ? data.SvcCode : '',
  });

  const [isValid, setIsValid] = useState<boolean>(true);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const dialogRef = useRef<any>(null);

  const [deductDataCombos, setDeductDataCombos] =
    useState<DeductDataTemplate>();

  useEffect(() => {
    const fetchData = async () => {
      const deductData = await payCodeDeductServices.getDeductTemplate(
        data.PayCode
      );
      setDeductDataCombos(deductData);
    };
    fetchData();
  }, [data.PayCode]);

  const expenses = deductDataCombos?.ExpensesWithLiabilitiesAndAssets ?? [];
  const deductMethods: PayCodeDeductMethod =
    deductDataCombos?.PayCodeDedMethod ?? [];
  const deductibilities: PayCodeDeductibility =
    deductDataCombos?.PayCodeDeductibility ?? [];

  function getIntValue(initValue?: string): number | null {
    if (!initValue) return null;

    const parsed = parseInt(initValue);
    return Number.isNaN(parsed) ? null : parsed;
  }

  useHighlightInput('code');
  useDisableEscape(dialogRef);
  useDisableRightClick(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    setIsValid(validatePayCodeDeductForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();
    try {
      if (formState.PayCode) {
        const payLoad = {
          PayCode: formState.PayCode,
          PayDesc: formState.PayDesc,
          AccountNo: formState.AccountNo.AccountNo,
          DeductMethod: getObjectFromName(deductMethods, formState.Method)?.key,
          DeductRate: formState.Rate,
          DeductTaxHandling: getIntValue(
            getObjectFromName(deductibilities, formState.DeductTaxHandling)?.key
          ),
          DeductEntityID: getIntValue(formState.DeductEntity.IDNo),
          DeductEntityName: formState.DeductEntity.EntityName || null,
          SvcCode: formState.SvcCode
        };

        setIsSaving(true);
        const saved = data.PayCode
          ? await payCodeDeductServices.updatePayCodeDeducts(
              data.IDNo,
              formState.PayCode,
              payLoad
            )
          : await payCodeDeductServices.createPayCodeDeducts(
              formState.PayCode,
              payLoad
            );

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e: any) {
      console.error(e);
      const errorMessage = e?.response?.data?.errorMessage;
      if (
        errorMessage?.includes(
          'InternalServerError: Violation of PRIMARY KEY constraint'
        )
      ) {
        setErrorText('Pay code already exists');
      } else {
        setErrorText('Something went wrong');
      }
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo) {
        setIsDeleting(true);
        const status = await payCodeDeductServices.deletePayCodeDeducts(
          formState.IDNo
        );

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event:
      | InputChangeEvent
      | NumericTextBoxChangeEvent
      | ComboBoxChangeEvent
      | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    const comboBoxValue = ['Method', 'DeductTaxHandling'].includes(key)
      ? event.value.name
      : null;

    if (key === 'PayCode') {
      const upperInputValue = event.value.toUpperCase();
      setFormState((prevState) => ({
        ...prevState,
        [key]: upperInputValue?.replace(/[^a-zA-Z0-9]/g, ''),
      }));
    } else if (key === 'DeductEntity') {
      setFormState((prevState) => ({
        ...prevState,
        [key]:
          event.value.EntityName === 'None'
            ? { EntityName: '', IDNo: '' }
            : event.value,
      }));
    } else if (key === 'Method' || key === 'DeductTaxHandling') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: comboBoxValue,
      }));  
    } else if (key === 'SvcCode') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value.value,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>Setup Pay Code - Deduction</span>
        {isEdit && (
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
          ></button>
        )}
      </StyledTitle>
    );
  };

  return (
    <MoveableDialog
      onClose={() => handleClose()}
      className="paycode-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <DialogContent ref={formRef} onKeyDown={(e) => handleKeyDown(e, formRef)}>
        <ReactFocusLock>
          <div
            style={{
              padding: `12px 20px 20px 20px`,
              borderBottom: '2px solid #d6d6d6',
            }}
          >
            <FieldRow>
              <InputWrapper
                maxWidth="100px"
                width="100px"
                name="PayCode"
                label="Code"
                onChange={handleOnChange}
                value={formState.PayCode}
                id="code"
                autoComplete="off"
              />
              <InputWrapper
                maxWidth="200px"
                width="200px"
                name="PayDesc"
                label="Description"
                onChange={handleOnChange}
                value={formState.PayDesc}
                id="code"
                autoComplete="off"
              />
              <CustomColComboBox 
                name="SvcCode"
                comboBoxType="KEY_NAME"
                dataList={deductSvcCodes}
                width={170}
                onChange={(e) => handleOnChange(e, 'SvcCode')}
                value={deductSvcCodes.find((d) => d.value === formState.SvcCode)}
                label="Service Code"
                // defaultValue={formState.SvcCode}
                filterable={true}
              />
            </FieldRow>
          </div>
          <div className="form-fields">
            <FieldRow>
              <CustomColComboBox
                name="Method"
                comboBoxType="KEY_NAME"
                dataList={deductMethods}
                width={120}
                onChange={(e) => handleOnChange(e, 'Method')}
                label="Method"
                value={deductMethods.find((d) => d.name === formState.Method)}
                defaultValue={formState.Method}
                filterable={true}
              />
              <NumericTextBox
                width="120px"
                name="Rate"
                inputStyle={{ textAlign: 'right' }}
                label="Rate"
                onChange={(event) => handleOnChange(event, 'Rate')}
                value={formState.Rate}
                id="rate"
                spinners={false}
                format={{
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'decimal',
                }}
              />
            </FieldRow>
            <FieldRow>
              <CustomColComboBox
                comboBoxType="AllAccounts"
                dataList={expenses}
                value={formState.AccountNo}
                defaultValue={formState.AccountNo}
                onChange={(e) => {
                  handleOnChange(e, 'AccountNo');
                }}
                width={120}
                label="GL Account"
              />
              <CustomColComboBox
                name="DeductTaxHandling"
                comboBoxType="KEY_NAME"
                dataList={deductibilities}
                width={120}
                onChange={(e) => handleOnChange(e, 'DeductTaxHandling')}
                label="Deductibility"
                value={deductibilities.find(
                  (d) => d.name === formState.DeductTaxHandling
                )}
                defaultValue={formState.DeductTaxHandling}
                filterable={true}
              />
            </FieldRow>
          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>
            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </ReactFocusLock>
      </DialogContent>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}

      {errorText && (
        <GenericDialog
          type="Error"
          onClose={() => {
            setErrorText('');
            setIsSaving(false);
          }}
          primaryMessage={errorText}
        />
      )}
    </MoveableDialog>
  );
};

export default PayCodeDeductDialog;
