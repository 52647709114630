import React, { FC } from 'react';

import { IProps } from './FormButton.types';

import { StyledButton } from './FormButton.styled';

const Button: FC<IProps> = ({ children, variant = 'primary', ...props }) => {
  return (
    <StyledButton variant={variant} {...props} title={undefined}>
      {children}
    </StyledButton>
  );
};

export default Button;
