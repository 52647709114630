import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const paymentTermsService = {
  getPaymentTerms: async () => {
    const response = await axiosClient().get('/Setup/TermsCodes/GetList', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
  createPaymentTerm: (params: any) =>
    axiosClient().patch(
      '/Setup/TermsCodes',
      { ...params },
      {
        params: {
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    ),
  updatePaymentTerms: (params: any) => {
    const id = params.data.IDNo;
    return axiosClient().patch(
      '/Setup/TermsCodes',
      { ...params.data },
      {
        params: {
          IDNo: id,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deletePaymentTermById: (id: number) =>
    axiosClient().delete('/Setup/TermsCodes', {
      params: {
        IDNo: id,
      },
      headers: { ...commonHeaders },
    }),
};
