import React, { FC, useEffect, useState } from 'react';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { plusIcon } from '@progress/kendo-svg-icons';

import { Button, ListingGrid } from '../../../../common';

import { userColumns } from './model';

import { ButtonWrapper, ListingGridWrapper } from '../../UserPerm.styled';
import { IRole, IUserListType } from '../../../../../data/types';
import { permsService } from '../../../../../services/perm/permsService';
import { Loader } from '../../../../common/Loader';
import UserDialog from '../../forms/UserDialog';
import {
  useCreateUser,
  useDeleteUserById,
  useUpdateUser,
} from '../../../../../services/hooks/useUsers';
import SelectableGrid from '../../../../common/SelectableGrid';
import { SelectableCheckboxCell } from '../../components/SelectableCheckBoxCell';

interface UsersProps {
  onClose: () => void;
}

const Users: FC<UsersProps> = ({ onClose }) => {
  const [usersList, setUsersList] = useState<IUserListType[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUserListType | null>(null);
  const [rolesList, setRolesList] = useState<IRole[]>([]);
  const [assignedRoles, setAssignedRoles] = useState<IRole[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetchingUserRoles, setIsFetchingUserRoles] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isGridChanged, setIsGridChanged] = useState<boolean>(false);

  const [showUserDialog, setShowUserDialog] = useState<{
    show: boolean;
    isEdit: boolean;
    data: IUserListType | null;
  }>({
    show: false,
    isEdit: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const usersResp = await permsService.getUsers();
      const allRoles = await permsService.getRoles();

      if (usersResp?.data?.length) {
        let firstUser: IUserListType | null = null;
        const usersToSet = usersResp.data.map(
          (user: IUserListType, index: number) => {
            if (index === 0) {
              firstUser = { ...user };
              return {
                selected: true,
                ...user,
              };
            }
            return user;
          }
        );
        if (firstUser && allRoles?.data?.length) {
          await fetchRolesAssignedToUser(firstUser, allRoles?.data);
        }
        setUsersList(usersToSet);
        setSelectedUser(firstUser);
      } else {
        console.log('Error in fetching Users');
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Error: ', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUser && rolesList?.length) {
      fetchRolesAssignedToUser(selectedUser, rolesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const fetchRolesAssignedToUser = async (
    selectedUser: IUserListType,
    allRoles: IRole[]
  ) => {
    setIsFetchingUserRoles(true);
    const userRolesResp = await permsService.getUserRoles(selectedUser.IDNo);
    const userRolesList: any[] = userRolesResp.data;
    setAssignedRoles(userRolesList)

    console.log('PERM: 1, allRoles', allRoles);
    console.log('PERM: 2, userRolesList', userRolesList);

    let allRolesList = allRoles.map((role) => {
      if (
        userRolesList.some(
          (userRole) => userRole.PermRoleCode === role.PermRoleCode
        )
      ) {
        return { ...role, checked: true };
      }
      return { ...role, checked: false };
    });

    setRolesList(allRolesList);
    setIsFetchingUserRoles(false);
  };

  const handleOpenUserDialog = (
    isEdit: boolean,
    data: IUserListType | null
  ) => {
    setShowUserDialog({
      show: true,
      isEdit,
      data,
    });
  };

  const handleCloseUserDialog = (shouldRefetch?: boolean) => {
    setShowUserDialog({
      show: false,
      isEdit: false,
      data: null,
    });
    if (shouldRefetch) {
      fetchData();
    }
  };

  const handleSubmitUser = (params: {
    isNew: boolean;
    data: any;
    id?: any;
  }) => {
    const { isNew, data, id } = params;
    const userData = { ...data };
    delete userData.LoginUserPW;
    try {
      if (isNew) {
        createUser({
          formattedData: userData,
        });
      } else {
        updateUser({
          data: userData,
          id,
        });
      }
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const handleDeleteUser = (params: { id: any }) => {
    const { id } = params;
    if (!id) {
      return;
    }

    try {
      deleteUser({ id });
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const { mutate: createUser } = useCreateUser(handleCloseUserDialog);
  const { mutate: updateUser } = useUpdateUser(handleCloseUserDialog);
  const { mutate: deleteUser } = useDeleteUserById(handleCloseUserDialog);

  const onChangeCell = async (e: any) => {
    if (isFetchingUserRoles || isSaving) {
      return;
    }
    // setIsSaving(true);
    const selectedPerm = e?.dataItem;
    const shouldCheck = e?.value ? true : false;
    console.log('PERM: 1, shouldCheck', shouldCheck);

    if (!selectedUser?.IDNo || !selectedPerm?.PermRoleCode) {
      setIsSaving(false);
      return;
    }

    const newRoles = rolesList.map((code) => {
      if (code.PermRoleCode === selectedPerm?.PermRoleCode) {
        return { ...code, checked: shouldCheck };
      } else {
        return code;
      }
    });

    setRolesList(newRoles);

    setIsGridChanged(true);
    // if (shouldCheck) {
    //   const payloadData = {
    //     UserID: selectedUser?.IDNo,
    //     PermRoleCode: selectedPerm?.PermRoleCode,
    //   };
    //   console.log('PERM: 1a, payloadData', payloadData);
    //   const resp = await permsService.assignRoleToUser(payloadData);
    //   console.log('PERM: 2, resp', resp);
    // } else if (!shouldCheck) {
    //   const resp = await permsService.unAssignRoleToUser(
    //     selectedUser?.IDNo,
    //     selectedPerm?.PermRoleCode
    //   );
    //   console.log('PERM: 2, resp', resp);
    // }
    // setIsSaving(false);
  };

  const handleSaveAllRoles = async () => {
    if (isSaving || isFetchingUserRoles) {
      return;
    }
  
    try {
      setIsSaving(true);
  
      if (!selectedUser?.IDNo) {
        setIsSaving(false);
        return;
      }
  
      const savedRoles = rolesList.filter((role) => role.checked);
  
      const rolesToDelete = assignedRoles.filter(
        (assignedRole) =>
          !savedRoles.some((savedRole) => savedRole.PermRoleCode === assignedRole.PermRoleCode)
      );
  
      const rolesToDeletePromises = rolesToDelete.map(async (role) => {
        const resp = await permsService.unAssignRoleToUser(selectedUser.IDNo, role.PermRoleCode);
        if (resp.status === 200) {
          return resp.status;
        }
      });
  
      await Promise.all(rolesToDeletePromises);
  
      const assignRolePromises = savedRoles.map(async (role) => {
        const payloadData = {
          UserID: selectedUser?.IDNo,
          PermRoleCode: role.PermRoleCode,
        };
        const resp = await permsService.assignRoleToUser(payloadData);
        if (resp.status === 200) {
          return resp.status;
        }
      });

      console.log('assigned roles', assignedRoles)
  
      await Promise.all(assignRolePromises);
  
      if (selectedUser) {
        const userAssignedRolesResp = await permsService.getUserRoles(selectedUser.IDNo);
        const userAssignedRoles = userAssignedRolesResp.data;
  
        setAssignedRoles(userAssignedRoles);
      }
  
      setIsSaving(false);
      setIsGridChanged(false);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  const handleResetRoles = () => {
    console.log('PERM: 1, handleResetRoles');
    let allRolesList = rolesList.map((role) => {
      if (
        assignedRoles.some(
          (userRole) => userRole.PermRoleCode === role.PermRoleCode
        )
      ) {
        return { ...role, checked: true };
      }
      return { ...role, checked: false };
    });

    setRolesList(allRolesList);
    setIsGridChanged(false);
  };

  return (
    <>
      <ListingGridWrapper>
        {!isLoading ? (
          <>
            <ListingGrid
              data={usersList}
              dataItemKey="IDNo"
              onRowClick={(e) => {
                if (isFetchingUserRoles) {
                  return;
                }
                setIsGridChanged(false);
                setSelectedUser(e.dataItem);
                setUsersList((prev) =>
                  prev.map((item) => ({
                    ...item,
                    selected: item.IDNo === e.dataItem.IDNo,
                  }))
                );
              }}
              onRowDoubleClick={(e) => {
                handleOpenUserDialog(true, e.dataItem);
              }}
            >
              {userColumns.map((column, index) => (
                <Column {...column} key={`${new Date().getTime()}${index}`} />
              ))}
            </ListingGrid>
            <div>
              <SelectableGrid
                data={rolesList}
                dataItemKey="PermRoleCode"
                style={{ height: '100%', borderWidth: '1px' }}
                className="listing-grid"
                size="small"
                rowHeight={30}
                detailRowHeight={30}
                enterEdit={() => {}}
                exitEdit={() => {}}
                undoEdit={() => {}}
                tabIndex={1}
              >
                <Column
                  field="checked"
                  title=" "
                  width="46px"
                  cell={(props) => (
                    <SelectableCheckboxCell
                      {...props}
                      onChange={onChangeCell}
                    />
                  )}
                />
                <Column field="PermRoleCode" title="Code" width="120px" />
                <Column
                  field="PermRoleDesc"
                  title="Description"
                  width="250px"
                />
              </SelectableGrid>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </ListingGridWrapper>
      <ButtonWrapper>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleOpenUserDialog(false, null);
          }}
          id="create-btn"
          title="Add New"
          svgIcon={plusIcon}
        >
          ADD NEW
        </Button>
        <div className="perm-buttons">
          {isGridChanged ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleResetRoles();
              }}
              id="create-btn"
              title="Reset permissions grid"
            >
              RESET
            </Button>
          ) : (<></>)}
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSaveAllRoles();
            }}
            id="create-btn"
            title="Save all permissions"
          >
            <u>S</u>AVE
          </Button>
        </div>
      </ButtonWrapper>
      {showUserDialog.show && (
        <UserDialog
          isEdit={showUserDialog.isEdit}
          data={showUserDialog.data}
          handleClose={handleCloseUserDialog}
          handleSubmit={handleSubmitUser}
          handleDelete={handleDeleteUser}
        />
      )}
    </>
  );
};

export default Users;
