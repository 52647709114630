import { useEffect } from 'react';

export const useHighlightInput = (nameFieldId: string) => {
  useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById(nameFieldId) as HTMLInputElement;
      if (input && input.select) {
        input.select();
      }
    }, 300);
  }, [nameFieldId]);
};
