import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const customFormService = {
  getCustomForms: async () => {
    const response = await axiosClient().get(
      `Custom/CustomFormFields/GetList`,
      {
        headers: { ...commonHeaders },
        params: {
          FormName: 'Customers',
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return response.data.data.records;
  },
  createCustomForm: (payload: any) => {
    return axiosClient().patch(
      'Custom/CustomFormFields',
      { ...payload, FormName: 'Customers' },
      {
        params: {
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  updateCustomForm: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Custom/CustomFormFields',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteCustomForm: (IDNo: number) =>
    axiosClient().delete('Custom/CustomFormFields', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    }),
  getSingleCustomForm: async (IDNo: number) => {
    const response = await axiosClient().get(`Custom/CustomFormFields`, {
      headers: { ...commonHeaders },
      params: {
        IDNo,
      },
    });
    return response.data.data;
  },
};

export const customVendorsFormService = {
  getVendorCustomForms: async () => {
    const response = await axiosClient().get(
      `Custom/CustomFormFields/GetList`,
      {
        params: {
          FormName: 'Vendors',
        },
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return response.data.data.records;
  },
  createCustomVendorsForm: (payload: any) => {
    return axiosClient().patch(
      'Custom/CustomFormFields',
      { ...payload, FormName: 'Vendors' },
      {
        params: {
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  updateCustomVendorsForm: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Custom/CustomFormFields',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteCustomVendorsForm: (IDNo: number) =>
    axiosClient().delete('Custom/CustomFormFields', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    }),
  getSingleVendorsCustomForm: async (IDNo: number) => {
    const response = await axiosClient().get(`Custom/CustomFormFields`, {
      headers: { ...commonHeaders },
      params: {
        IDNo,
      },
    });
    return response.data.data;
  },
};

export const customLeadsFormService = {
  getLeadsCustomForms: async () => {
    const response = await axiosClient().get(
      `Custom/CustomFormFields/GetList`,
      {
        params: {
          FormName: 'Leads',
        },
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return response.data.data.records;
  },
  createCustomLeadsForm: (payload: any) => {
    return axiosClient().patch(
      'Custom/CustomFormFields',
      { ...payload, FormName: 'Leads' },
      {
        params: {
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  updateCustomLeadsForm: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Custom/CustomFormFields',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteCustomLeadsForm: (IDNo: number) =>
    axiosClient().delete('Custom/CustomFormFields', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    }),
  getSingleLeadsCustomForm: async (IDNo: number) => {
    const response = await axiosClient().get(`Custom/CustomFormFields`, {
      headers: { ...commonHeaders },
      params: {
        IDNo,
      },
    });
    return response.data.data;
  },
};

export const customersImportService = {
  getCustomers: async () => {
    const response = await axiosClient().get(
      `Entity/GetList?ListType=Customer&Status=All`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data.records;
  },
  importCustomers: async (payload: any[]) => {
    const formData = new FormData();

    payload.forEach((item) => {
      formData.append('CusList', JSON.stringify(item));
    });
    console.log(payload);
    const response = await axiosClient().post(
      'Setup/Import/ImportCustomers',
      formData,
      {
        headers: {
          ...commonHeaders,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  },
};

export const vendorsImportService = {
  getVendors: async () => {
    const response = await axiosClient().get(
      `Entity/GetList?ListType=Vendor&Status=All`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data.records;
  },
  importCustomers: async (payload: any[]) => {
    const formData = new FormData();

    payload.forEach((item) => {
      formData.append('CusList', JSON.stringify(item));
    });
    console.log(payload);
    const response = await axiosClient().post(
      'Setup/Import/ImportCustomers',
      formData,
      {
        headers: {
          ...commonHeaders,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  },
};
