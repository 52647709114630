import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { IFieldProperties, IFieldProperty } from '../../data/types';
import { commonHeaders } from '../common';

export const companiesService = {
  updateCompany: (params: any) => {
    delete params.id;

    return axiosClient().patch(
      '/Setup/CompanyInfo',
      { ...params },
      {
        headers: { ...commonHeaders },
        params: {
          bNew: params ? false : true,
        }
      }
    );
  },
  getCompanyById: async (id: number) => {
    const response = await axiosClient().get('/Setup/CompanyInfo', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const fieldProperties: IFieldProperty[] =
      response?.data?.data?.fieldProperties;

    const fieldPropertiesObj: IFieldProperties = {};

    if (fieldProperties?.length) {
      fieldProperties.forEach((item) => {
        const { columnName, dataType, maxLength, decimalPlaces, required } =
          item;
        fieldPropertiesObj[columnName] = {
          dataType,
          maxLength,
          decimalPlaces,
          required,
        };
      });
    }

    return {
      data: {
        record: response?.data?.data?.record,
        fieldProperties: fieldPropertiesObj || {},
      },
    };
  },
};
