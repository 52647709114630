import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  DateInputProps,
  DatePickerChangeEvent,
  TimePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';
import {
  MaskedTextBox,
  MaskedTextBoxChangeEvent,
  MaskedTextBoxEvent,
  MaskedTextBoxHandle,
} from '@progress/kendo-react-inputs';

const basicDateFormat = 'hh:mm A';

const formatDigit = (number: number, digit: number) => {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: digit,
    useGrouping: false,
  });
};

const CustomTimeInput = (props: DateInputProps) => {
  const { onChange } = props;

  const maskedTextBoxInputRef = useRef<MaskedTextBoxHandle>(null);

  const [displayValue, setDisplayValue] = useState('');
  const [innerDisplayValue, setInnerDisplayValue] = useState('');
  const [focusedSegment, setFocusedSegment] = useState<string | null>(null);

  useEffect(() => {
    setDisplayValue(
      props?.value ? moment(props.value).format(basicDateFormat) : '',
    );
    setInnerDisplayValue('');
  }, [props?.value]);

  const focusSection = useCallback((type: 'hh' | 'mm' | 'aa') => {
    const inputElement = maskedTextBoxInputRef?.current?.element;
    const selectionValues = {
      hh: { start: 0, end: 2 },
      mm: { start: 3, end: 5 },
      aa: { start: 6, end: 8 },
    };
    setTimeout(() => {
      inputElement?.setSelectionRange(
        selectionValues[type].start,
        selectionValues[type].end,
      );
    }, 10);
    setFocusedSegment(type);
  }, []);

  const focusAndFormat = useCallback(
    (segment: Parameters<typeof focusSection>[0]) => {
      focusSection(segment);
      setInnerDisplayValue(innerDisplayValue || displayValue);
    },
    [displayValue, focusSection, innerDisplayValue],
  );

  useEffect(() => {
    const inputElement = maskedTextBoxInputRef?.current?.element;

    const handleInputKeyDown = (event: KeyboardEvent) => {
      const { key, ctrlKey } = event;

      const isArrowRight = key === 'ArrowRight';
      const isArrowLeft = key === 'ArrowLeft';
      const isArrowUp = key === 'ArrowUp' || key === '+';
      const isArrowDown = key === 'ArrowDown' || key === '-';
      const isSlash = key === '/';
      const isLetter = new RegExp(/^[a-zA-Z]$/).test(key);

      if (isLetter && !event.ctrlKey) {
        event.preventDefault();
      }

      if (key === 'Delete') {
        setDisplayValue('__:__ AM');
        setInnerDisplayValue('');
        setTimeout(() => {
          focusSection('hh');
        }, 10);
      }

      if (ctrlKey && (key === 'N' || key === 'n')) {
        event.preventDefault();
        const e = event as unknown as TimePickerChangeEvent;
        onChange?.({
          ...e,
          value: new Date(),
          target: {
            ...e.target,
            value: new Date(),
          },
        });
        setTimeout(() => {
          focusSection('hh');
        }, 10);
      }

      const isHourFocused = focusedSegment === 'hh';
      const isMinuteFocused = focusedSegment === 'mm';
      const isAmPmFocused = focusedSegment === 'aa';

      if (isAmPmFocused) {
        event.preventDefault();
      }

      if (isSlash) {
        event.stopPropagation();
        event.preventDefault();
      }

      if ((isArrowRight || isSlash) && isHourFocused) {
        focusAndFormat('mm');
      }
      if ((isArrowRight || isSlash) && isMinuteFocused) {
        focusAndFormat('aa');
      }
      if (isArrowRight && isAmPmFocused) {
        focusAndFormat('aa');
      }
      if (isArrowLeft && isAmPmFocused) {
        focusAndFormat('mm');
      }
      if (isArrowLeft && isMinuteFocused) {
        focusAndFormat('hh');
      }
      if (isArrowLeft && isHourFocused) {
        focusAndFormat('hh');
      }
      if (key === 'End') {
        focusAndFormat('aa');
      }
      if (key === 'Home') {
        focusAndFormat('hh');
      }

      if (key === '_') {
        event.stopPropagation();
        event.preventDefault();
      }

      if (isArrowUp || isArrowDown) {
        event.preventDefault();
        event.stopPropagation();

        setDisplayValue('');
        setInnerDisplayValue(prev => {
          const splitDate = displayValue
            ? displayValue.split(':')
            : prev.split(':');
          let hour = splitDate[0];
          let minute = splitDate[1]?.split(' ')?.[0] || '__';
          let ampm = splitDate[1]?.split(' ')?.[1] || 'AM';
          const incrementor = isArrowDown ? -1 : 1;

          if (focusedSegment === 'aa') {
            let newValue = ampm === 'AM' ? 'PM' : 'AM';
            focusSection('aa');
            return `${hour}:${minute} ${newValue}`;
          } else if (focusedSegment === 'hh') {
            focusSection('hh');
            let newValue = formatDigit(+hour + incrementor, 2);
            if (hour === '__' || hour === '' || hour.includes('_')) {
              newValue = isArrowUp ? '01' : '12';
            }
            if (hour === '01' && isArrowDown) {
              newValue = '12';
            }
            if (hour === '12' && !isArrowDown) {
              newValue = '01';
            }
            return `${newValue}:${minute} ${ampm}`;
          } else if (focusedSegment === 'mm') {
            focusSection('mm');
            let newValue = formatDigit(+minute + incrementor, 2);
            if (minute === '__' || minute === '' || minute.includes('_')) {
              newValue = isArrowUp ? '00' : '59';
            }
            if (minute === '00' && isArrowDown) {
              newValue = '59';
            }
            if (minute === '59' && !isArrowDown) {
              newValue = '00';
            }
            return `${hour}:${newValue} ${ampm}`;
          }
          return prev;
        });
      }
    };

    const handleClick = () => {
      const selectionStart = inputElement?.selectionStart;

      if (selectionStart === null || selectionStart === undefined) {
        return;
      }
      if (selectionStart < 3) {
        focusAndFormat('hh');
      } else if (selectionStart > 2 && selectionStart < 6) {
        focusAndFormat('mm');
      } else if (selectionStart > 5 && selectionStart < 11) {
        focusAndFormat('aa');
      }
    };

    if (inputElement) {
      inputElement.addEventListener('keydown', handleInputKeyDown);
      inputElement.addEventListener('click', handleClick);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleInputKeyDown);
        inputElement.removeEventListener('click', handleClick);
      }
    };
  }, [
    displayValue,
    focusAndFormat,
    focusSection,
    focusedSegment,
    innerDisplayValue,
    onChange,
  ]);

  const formatHour = (hour: string) => {
    let tempHour = hour;

    if (Number(tempHour[0]) > 1 && focusedSegment === 'hh') {
      tempHour = `0${tempHour[0]}`;
      focusSection('mm');
    }

    if (tempHour[0] !== '0' && tempHour[0] !== '1' && tempHour[0] !== '_') {
      tempHour = `0${tempHour[1]}`;
    }

    if (tempHour[0] === '1' && tempHour[1] !== '_' && Number(tempHour[1]) > 2) {
      tempHour = `0${tempHour[1]}`;
    }

    if (Number(tempHour) < 1) tempHour = '01';
    else if (Number(tempHour) > 12) tempHour = '12';

    return tempHour;
  };

  const formatMinute = (minute: string) => {
    let tempMinute = minute;

    if (Number(tempMinute[0]) > 5 && focusedSegment === 'mm') {
      tempMinute = `0${tempMinute[0]}`;
      focusSection('aa');
    }

    if (Number(tempMinute) < 0) tempMinute = '01';
    else if (Number(tempMinute) > 59) tempMinute = '59';

    return tempMinute;
  };

  const handleDisplayValueChange = (e: MaskedTextBoxChangeEvent) => {
    let newValue = e.value;

    const splitDate = newValue.split(':');
    let hour = splitDate[0];
    let minute = splitDate[1].split(' ')[0];
    let ampm = splitDate[1].split(' ')[1];

    if (focusedSegment === 'aa') {
      focusSection('aa');
      return;
    }

    if (hour) {
      hour = formatHour(hour);
    }

    if (minute) {
      minute = formatMinute(minute);
    }

    setDisplayValue('');
    setInnerDisplayValue(`${hour}:${minute} ${ampm}`);

    if (
      e.selectionStart > 2 &&
      e.selectionStart < 4 &&
      focusedSegment !== 'hh'
    ) {
      focusSection('hh');
    } else if (
      e.selectionStart > 1 &&
      e.selectionStart < 6 &&
      focusedSegment !== 'mm'
    ) {
      focusSection('mm');
    } else if (e.selectionStart > 4 && focusedSegment !== 'aa') {
      focusSection('aa');
    }
  };

  return (
    <div className='k-dateinput k-input'>
      <MaskedTextBox
        className={props.className}
        disabled={props.disabled}
        readonly={props.readonly}
        style={{ width: '100%' }}
        value={(displayValue ? displayValue : innerDisplayValue) || '__:__ AM'}
        onChange={handleDisplayValueChange}
        mask='00:00 AA'
        tabIndex={props.tabIndex}
        onBlur={() => {
          const newDate = moment(innerDisplayValue, basicDateFormat);

          if (newDate.isValid()) {
            const originalValue = props?.value;
            const newValue = newDate.toDate();

            const formatOriVal = moment(originalValue,basicDateFormat)
            const formatNewVal = moment(newValue, basicDateFormat)
            if(formatOriVal.format(basicDateFormat) === formatNewVal.format(basicDateFormat)){
              return;
            }

            setDisplayValue(newDate.format(basicDateFormat));
            setInnerDisplayValue('');

            if (props?.onChange && originalValue !== newValue) {
              const e = {} as unknown as DatePickerChangeEvent;
              props.onChange({
                ...e,
                value: newValue,
                target: {
                  ...e.target,
                  value: newValue,
                },
              });
            }
          } else if (
            innerDisplayValue === '__:__ AM' ||
            innerDisplayValue.includes('_')
          ) {
            const e = {} as unknown as DatePickerChangeEvent;
            if (props.onChange) {
              props.onChange({
                ...e,
                value: null,
              });
            }
            return;
          }
        }}
        onFocus={(e: MaskedTextBoxEvent) => {
          maskedTextBoxInputRef.current?.element?.setSelectionRange(0, 2);
          setFocusedSegment('hh');
          setTimeout(() => {
            if (
              e.target.element?.selectionStart ===
              e.target.element?.selectionEnd
            ) {
              maskedTextBoxInputRef.current?.element?.setSelectionRange(0, 2);
              setFocusedSegment('hh');
            }
          }, 10);
        }}
        ref={maskedTextBoxInputRef}
      />
    </div>
  );
};

export default CustomTimeInput;
