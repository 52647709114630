import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';
import { Button, InputWrapper, CustomColComboBox } from '../../../../common';
import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validatePayCodeAddsForm } from '../../../../../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  DialogContent,
  StyledTitle,
  CustomButtonWrapper,
} from './PayCodesAddsDialog.styled';
import { PAYAD_INPUT_DEFAULTS } from '../../../../../constants';

import {
  AddDataTemplate,
  IPayCodesAddsDialogProps,
  PayCodesAddsFormState,
} from './PayCodesAddsDialog.types';
import { payCodesAddsService } from '../../../../../services/payCodesAddsService';
import { FieldRow } from '../../PayCodeDeducts/PayCodeDeductsDialog/PayCodeDeductsDialog.styled';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';

const PayCodeAddsDialog: React.FC<IPayCodesAddsDialogProps> = ({
  inputDefaults,
  isEdit,
  data,
  handleClose,
}) => {
  const [errorText, setErrorText] = useState('');
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState<PayCodesAddsFormState>({
    IDNo: isEdit && data ? data.IDNo : 0,
    PayCode: isEdit && data ? data.PayCode : '',
    AccountNo: {
      AccountNo:
        isEdit && data.AccountNo
          ? data.AccountNo
          : inputDefaults.AccountNo.AccountNo,
    },
    Method:
      isEdit && data.PayMethod
        ? data.PayMethod
        : PAYAD_INPUT_DEFAULTS.PayMethod,
    Rate: isEdit && data.PayRate ? data.PayRate : PAYAD_INPUT_DEFAULTS.Rate,
    PayTaxableYN:
      isEdit && data ? data.PayTaxableYN : PAYAD_INPUT_DEFAULTS.Taxable,
    PayAD: isEdit && data ? data.PayAD : '',
    PayDesc: isEdit && data ? data.PayDesc : '',
  });

  console.log(data);

  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const dialogRef = useRef<any>(null);

  const [addDataCombos, setAddDataCombos] = useState<AddDataTemplate>();

  useEffect(() => {
    const fetchAddData = async () => {
      const addData = await payCodesAddsService.getSinglePayCode(data.PayCode);
      setAddDataCombos(addData);
      console.log(addData);
    };
    fetchAddData();
  }, [data.PayCode]);

  useHighlightInput('code');
  useDisableEscape(dialogRef);
  useDisableRightClick(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    setIsValid(validatePayCodeAddsForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayCode) {
        setIsSaving(true);
        const payload = {
          PayCode: formState.PayCode,
          AccountNo: formState.AccountNo?.AccountNo,
          PayMethod: addDataCombos?.PayCodeAddMethod.find(
            (option: any) => option.name === formState.Method
          )?.key,
          PayRate: formState.Rate,
          PayTaxableYN: formState.PayTaxableYN,
          PayAD: formState.PayAD,
          PayDesc: formState.PayDesc,
        };
        const saved = data.PayCode
          ? await payCodesAddsService.updatePayCode(formState.IDNo, payload)
          : await payCodesAddsService.createPayCode(formState.PayCode, payload);

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e: any) {
      console.error(e);
      const errorMessage = e?.response?.data?.errorMessage;
      if (
        errorMessage?.includes(
          'InternalServerError: Violation of PRIMARY KEY constraint'
        )
      ) {
        setErrorText('Pay code already exists');
      } else {
        setErrorText('Something went wrong');
      }
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo) {
        setIsDeleting(true);
        const status = await payCodesAddsService.deletePayCode(formState.IDNo);

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    const comboBoxValue = ['Method'].includes(key) ? event.value.name : null;

    if (key === 'PayCode') {
      const upperInputValue = event.value.toUpperCase();
      setFormState((prevState) => ({
        ...prevState,
        [key]: upperInputValue?.replace(/[^a-zA-Z0-9]/g, ''),
      }));
    } else if (key === 'Method') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: comboBoxValue,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>Setup Pay Code - Addition</span>
        {isEdit && (
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
          ></button>
        )}
      </StyledTitle>
    );
  };

  return (
    <MovableDialog
      resizable={false}
      onClose={() => handleClose()}
      className="paycode-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <DialogContent ref={formRef} onKeyDown={(e) => handleKeyDown(e, formRef)}>
        <ReactFocusLock>
          <div
            style={{
              padding: `12px 20px 20px 20px`,
              borderBottom: '2px solid #d6d6d6',
            }}
          >
            <FieldRow>
              <InputWrapper
                maxWidth="100px"
                width="100px"
                name="PayCode"
                label="Pay Code"
                onChange={handleOnChange}
                value={formState.PayCode}
                id="code"
                autoComplete="off"
              />
              <InputWrapper
                maxWidth="200px"
                width="200px"
                name="PayDesc"
                label="Description"
                onChange={handleOnChange}
                value={formState.PayDesc}
                id="code"
                autoComplete="off"
              />
            </FieldRow>
          </div>
          <div className="form-fields">
            <FieldRow>
              <CustomColComboBox
                name="Method"
                comboBoxType="KEY_NAME"
                dataList={addDataCombos?.PayCodeAddMethod}
                width={120}
                onChange={(e) => handleOnChange(e, 'Method')}
                label="Pay Method"
                value={addDataCombos?.PayCodeAddMethod.find(
                  (d) => d.name === formState.Method
                )}
                defaultValue={formState.Method}
                filterable={true}
              />
              <NumericTextBox
                spinners={false}
                inputStyle={{ textAlign: 'right' }}
                name="Rate"
                label="Pay Rate"
                value={Number(formState?.Rate)}
                onChange={(e) => {
                  handleOnChange(e, 'Rate');
                }}
                style={{ width: '120px' }}
                format={{
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'decimal',
                }}
              />
            </FieldRow>
            <FieldRow>
              <CustomColComboBox
                comboBoxType="AllAccounts"
                dataList={addDataCombos?.ExpensesNoCoGSAccounts}
                value={formState.AccountNo}
                defaultValue={formState.AccountNo}
                onChange={(e) => {
                  handleOnChange(e, 'AccountNo');
                }}
                width={120}
                label="GL Account"
              />
              <CustomColComboBox
                name="PayTaxableYN"
                width={120}
                dataList={[]}
                comboBoxType="CUSTOM_YesNo"
                label="Taxable"
                filterable={true}
                onChange={(e) => {
                  setIsChanged(true);
                  setFormState((prevState: any) => ({
                    ...prevState,
                    PayTaxableYN: e.value === 'Yes' ? true : false,
                  }));
                }}
                value={formState.PayTaxableYN === true ? 'Yes' : 'No'}
              />
            </FieldRow>
          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              type="button"
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>
            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </ReactFocusLock>
      </DialogContent>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
      {errorText && (
        <GenericDialog
          type="Error"
          onClose={() => {
            setErrorText('');
            setIsSaving(false);
          }}
          primaryMessage={errorText}
        />
      )}
    </MovableDialog>
  );
};

export default PayCodeAddsDialog;
