import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import ReactFocusLock from 'react-focus-lock';
import {
    useDisableEscape,
    useDisableRightClick,
    useHighlightInput,
    useKeyPress,
} from '../../../../hooks';
import { Button } from '@progress/kendo-react-buttons';
import MovableDialog from '../../../common/MovableDialog';
import EditableGrid from '../../../common/EditableGrid';
import {
    Grid,
    GridColumn as Column,
    GridItemChangeEvent,
} from '@progress/kendo-react-grid';
import { columns } from './model';
import { EntityImportItemType } from '../../../../data/types';
import { DialogContent, ButtonWrapper, ImportGridContainer, } from './CustomersImportDialog.styled';
import { customersImportService } from '../../../../services/customFormService';

import { CustomersContext } from '../CustomerContext';

type GridItemType = Record<string, any>;

interface ICustomersImportProps {
    currentData?: any[];
    importedData?: EntityImportItemType[];
    handleClose: (shouldRefreshData?: boolean) => void;
};

const CustomersImportModal: React.FC<ICustomersImportProps> = ({
    currentData,
    importedData,
    handleClose,
}) => {
    const filteredImportedData = importedData?.filter(item => item.Email !== '');
    const truncatedValue = (value: string, maxLength: number) => value ? value.substring(0, maxLength) : '';
    const [gridItems, setGridItems] = useState(filteredImportedData?.map(item => ({
        ...item,
        Name: truncatedValue(item.Name ?? '', 50),
        Address: truncatedValue(item.Address ?? '', 50),
        City: truncatedValue(item.City ?? '', 50),
        State: truncatedValue(item.State ?? '', 2),
        Contact: truncatedValue(item.Contact ?? '', 25),
        Phone: truncatedValue(item.Phone ?? '', 25),
        Email: truncatedValue(item.Email ?? '', 50),
        Error: true,
        Reason: '',
        Action: false,
    })));

    const { customersList, setRefresh } = useContext(CustomersContext);
    const [selectedDataItem, setSelectedDataItem] = useState<EntityImportItemType | undefined>(undefined);
    const [selectedRow, setSelectedRow] = useState<{
        prev: EntityImportItemType | undefined;
        current: EntityImportItemType | undefined;
    }>({
        prev: undefined,
        current: undefined,
    });

    const dialogRef = useRef<any>(null);
    const gridRef = useRef<Grid>(null);

    useHighlightInput('account-name');
    useDisableRightClick(dialogRef);
    useDisableEscape(dialogRef);

    useKeyPress(
        () => {
        },
        () => {
            handleClose();
        },
        () => {
        }
    );

    useEffect(() => {
        const compareCharts = async () => {
            if (importedData) {
                if (customersList && customersList.length > 0) {

                    const emptyEmails = gridItems?.filter(item => item.Email === '');

                    const existingEmails = customersList.filter(item =>
                        importedData.some(importedItem => importedItem.Email === item.EmailMain)
                    );

                    if (existingEmails.length > 0) {
                        setGridItems(prevList =>
                            prevList?.map(item => {
                                if (existingEmails.some(customer => customer.EmailMain === item.Email)) {
                                    return {
                                        ...item,
                                        Error: false,
                                        Reason: 'Email exists',
                                        Action: true,
                                    }
                                }
                                return item;
                            })
                        )
                    }

                    if (emptyEmails && emptyEmails.length > 0) {
                        setGridItems(prevList =>
                            prevList?.map(item => {
                                if (emptyEmails.some(customer => customer.Email === item.Email)) {
                                    return {
                                        ...item,
                                        Error: false,
                                        Reason: 'Email blank',
                                        Action: true,
                                    }
                                }
                                return item;
                            })
                        )
                    }

                }
            }
        }
        compareCharts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importedData]);

    const itemChange = useCallback(
        (event: GridItemChangeEvent) => {
            console.log('DataGrid: onItemChange', event);
            const dataItem = event.dataItem;

            if (gridItems) {
                const newData = gridItems?.map((item) => {
                    if (item.Email === dataItem.Email && event.field) {
                        return {
                            ...item,
                            [event.field]: event.value,
                        };
                    }
                    return item;
                });

                setGridItems(newData);

                const newDataItem = newData?.find(item => item?.Email === dataItem.Email);
                if (newDataItem) {
                    setSelectedRow(prevData => ({
                        prev: prevData.current,
                        current: newDataItem,
                    }));
                }
            }
        },
        [gridItems, setGridItems, setSelectedRow],
    );

    const enterEdit = (dataItem: GridItemType, field: string | undefined) => {
        console.log('enterEdit -', { dataItem, field });
        console.log(selectedDataItem, selectedRow);
        setSelectedDataItem(dataItem as EntityImportItemType);
        setSelectedRow(prevData => {
            return {
                prev: prevData.current,
                current: dataItem as EntityImportItemType,
            };
        });

        setTimeout(() => {
            setGridItems(prev =>
                prev?.map(item => ({
                    ...item,
                    inEdit: item.EmailMain === dataItem.EmailMain ? field : undefined,
                })),
            );
        }, 50);
    };

    const exitEdit = (dataItem: GridItemType) => {
        console.log('exitEdit -', dataItem);
        console.log(selectedDataItem, selectedRow);
        setSelectedDataItem(dataItem as EntityImportItemType);
        setSelectedRow(prevData => {
            return {
                prev: prevData.current,
                current: dataItem as EntityImportItemType,
            };
        });

        let newData = gridItems?.map(item => ({ ...item, inEdit: undefined }));
        setGridItems(newData);
    };

    const handleImport = async () => {
        // filtering  valid items
        const validList = gridItems?.filter(item => item.Error === true && item.Action === false)
        const payload = validList?.map(item => ({
            EmailMain: item.Email,
            EntityName: item.Name,
            EntityType: "C",
            City: item.City,
            State: item.State,
            PrimaryContact: item.Contact || '',
            MainPhone: item.Phone,
            MI: '',
            Zip: item.Zip,
            Website: '',
            Address1: item.Address,
            Address2: '',
            LastName: '',
            FirstName: '',
        }));

        try {
            const response = await customersImportService.importCustomers(payload ?? []);
            if (response) {
                setRefresh(true);
            } else {
                console.log('Import Customers failed');
            }
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <MovableDialog
            onClose={() => handleClose()}
            title={'Import Customers'}
            ref={dialogRef}
        >
            <ReactFocusLock>
                <DialogContent>
                    <ImportGridContainer>
                        <div>
                            <EditableGrid
                                scrollable='scrollable'
                                style={{ pointerEvents: 'auto', height: '600px', }}
                                onItemChange={itemChange}
                                enterEdit={enterEdit}
                                exitEdit={exitEdit}
                                undoEdit={() => { }}
                                data={gridItems}
                                tabIndex={-1}
                                ref={gridRef}
                            >
                                {columns.map((column, index) => (
                                    <Column {...column} key={index} />
                                ))}
                            </EditableGrid>
                        </div>
                    </ImportGridContainer>

                    <ButtonWrapper>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                handleImport();
                                handleClose();
                            }}
                            id="save-btn"
                            title="Save"
                        >
                            <>
                                Import
                            </>
                        </Button>
                    </ButtonWrapper>
                </DialogContent>
            </ReactFocusLock>
        </MovableDialog>
    );
};

export default CustomersImportModal;