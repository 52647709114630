import React, { useEffect, useRef, useState } from 'react';
import {
    InputChangeEvent,
    NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';
import CustomNumericInput, { CustomNumericInputChangeEvent } from '../../../../common/CustomNumericInput';
import { Button, InputWrapper } from '../../../../common';
import {
    useDisableEscape,
    useDisableRightClick,
    useHighlightInput,
    useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validateSalesEventCodesForm } from '../../../../../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
    DialogContent,
    StyledTitle,
    CustomButtonWrapper,
    FieldRow,
} from './SalesEventCodesDialog.styled';

import {
    ISalesEventCodeProps,
} from './SalesEventCodesDialog.types';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';

import { salesEventCodesService, salesStatusCodesService } from '../../../../../services/salesCodesService';
import { IFieldProperty, ISalesEventCodeType, ISalesStatusCodeType } from '../../../../../data/types';
import { QUALSTATS } from '../../../../../constants';

const SalesEventCodesDialog: React.FC<ISalesEventCodeProps> = ({
    isEdit,
    data,
    handleClose,
    eventCodeList,
    EntType,
}) => {
    const [errorText, setErrorText] = useState('');
    const [visible, setVisible] = useState<boolean>(false);
    const [formState, setFormState] = useState<ISalesEventCodeType>({
        IDNo: isEdit && data ? data.IDNo : 0,
        EventCode: isEdit && data ? data.EventCode : '',
        EventDesc: isEdit && data ? data.EventDesc : '',
        MinPotential: isEdit && data ? data.MinPotential : 0,
        QualStat: isEdit && data ? QUALSTATS.find(stat => stat.name === data.QualStat)?.key : '',
        SystemYN: isEdit && data ? data.SystemYN : false,
    });

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [fieldProperties, setFieldProperties] = useState<IFieldProperty[]>([]);
    const [statusCodeList, setStatusCodeList] = useState<ISalesStatusCodeType[]>([]);
    const [codeExist, setCodeExist] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);
    const dialogRef = useRef<any>(null);

    useHighlightInput(isEdit ? 'description' : 'event-code');
    useDisableEscape(dialogRef);
    useDisableRightClick(dialogRef);

    useEffect(() => {
        const getFieldProperties = async () => {
            const response = await salesEventCodesService.getFieldProperties(data.IDNo || 0);
            setFieldProperties(response.fieldProperties);
            // console.log(response);
        };

        const getStatusCodes = async () => {
            const response = await salesStatusCodesService.getList(EntType);
            setStatusCodeList(response);
            // console.log(response);
        };

        Promise.all([getFieldProperties(), getStatusCodes()]);
    }, [data.IDNo, EntType]);

    const checkExistingCode = async () => {
        if (eventCodeList.map((code: any) => code.EventCode).includes(formState.EventCode?.trim())) {
            setCodeExist(true);
        } else {
            setCodeExist(false);
        }
    };

    useKeyPress(
        () => {
            if (isValid && isChanged && !isSaving && !isDeleting) {
                handleSave();
            };
        },
        () => {
            handleClose();
        },
        () => {
            toggleDialog();
        }
    );

    useEffect(() => {
        console.log(formState)
        setIsValid(validateSalesEventCodesForm(formState));
        checkExistingCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    const handleSave = async (e?: React.MouseEvent) => {
        e?.preventDefault();

        try {
            if (formState.EventCode) {
                setIsSaving(true);
                const payload = {
                    EntType: EntType,
                    EventCode: formState.EventCode,
                    EventDesc: formState.EventDesc,
                    MinPotential: formState.MinPotential,
                    QualStat: QUALSTATS.find((stat) => stat.key === formState.QualStat)?.key,
                };

                if (data.EventCode) {
                    // updating record
                    await salesEventCodesService.updateEventCode(formState.IDNo, payload);
                } else {
                    // create record
                    if (codeExist) {
                        setErrorText('Code already exists');
                        return;
                    }
                    const saved = await salesEventCodesService.createEventCode(payload);
                    if (!saved) {
                        setErrorText('Failed to save record');
                        return;
                    }
                };
                setIsSaving(false);
                handleClose(true);
            }
        } catch (e: any) {
            console.error(e);
            const errorMessage = e?.response?.data?.errorMessage;
            if (
                errorMessage?.includes(
                    'InternalServerError: Violation of PRIMARY KEY constraint'
                )
            ) {
                setErrorText('Code already exists');
            } else {
                setErrorText('Something went wrong');
            }
        }
    };

    const handleOnDelete = async (e?: React.MouseEvent) => {
        e?.preventDefault();

        try {
            if (formState.EventCode) {
                setIsDeleting(true);
                const status = await salesEventCodesService.deleteEventCode(formState.IDNo);

                if (status) {
                    setIsDeleting(false);
                    handleClose(true);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleOnChange = (
        event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent | CustomNumericInputChangeEvent,
        key: string
    ) => {
        setIsChanged(true);

        if (key === 'EventCode') {
            const upperInputValue = event.value.toUpperCase();
            setFormState((prevState) => ({
                ...prevState,
                [key]: upperInputValue?.replace(/[^a-zA-Z0-9]/g, ''),
            }));
        } else if (key === 'StatusCode') {
            console.log(event.value)
            setFormState((prevState) => ({
                ...prevState,
                [key]: event.value,
                StatusDesc: statusCodeList.find((code) => code.StatusCode === event.value)?.StatusDesc,
            }))
        } else {
            setFormState((prevState) => ({
                ...prevState,
                [key]: event.value,
            }));
        }
    };

    const toggleDialog = (state?: boolean) => {
        if (state) {
            handleOnDelete();
        }
        setVisible(!visible);
    };

    const CustomWindowTitle = () => {
        return (
            <StyledTitle>
                <span>{EntType === 'L' ? 'Lead' : 'Prospect'} Event Code</span>
                {isEdit && (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            toggleDialog();
                        }}
                        className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
                    ></button>
                )}
            </StyledTitle>
        );
    };

    return (
        <MovableDialog
            resizable={false}
            onClose={() => handleClose()}
            className="paycode-dialog"
            title={<CustomWindowTitle />}
            ref={dialogRef}
        >
            <DialogContent ref={formRef} onKeyDown={(e) => handleKeyDown(e, formRef)}>
                <ReactFocusLock>
                    <div
                        style={{
                            padding: `15px 25px 20px 25px`,
                            // borderBottom: '2px solid #d6d6d6',
                        }}
                    >
                        <FieldRow>
                            <InputWrapper
                                maxWidth="100px"
                                width="100px"
                                name="EventCode"
                                label="Code"
                                onChange={handleOnChange}
                                value={formState.EventCode}
                                id="event-code"
                                autoComplete="off"
                                maxLength={fieldProperties.find((prop: any) => prop.columnName === 'EventCode')?.maxLength || 6}
                                isDisabled={isEdit}
                            />
                            <InputWrapper
                                maxWidth="175px"
                                width="175px"
                                name="EventDesc"
                                label="Description"
                                onChange={handleOnChange}
                                value={formState.EventDesc}
                                id="description"
                                autoComplete="off"
                                maxLength={fieldProperties.find((prop: any) => prop.columnName === 'EventDesc')?.maxLength || 50}
                            />
                            <CustomNumericInput
                                label='Min Potential'
                                style={{ width: '100px' }}
                                value={formState.MinPotential}
                                onChange={(e) => handleOnChange(e, "MinPotential")}
                                min={0}
                                maxLength={19}
                                // maxdecimals={4}
                                // mindecimals={2}
                                className='event-numeric-textbox'
                                disabled={formState.SystemYN}
                            />
                        </FieldRow>
                    </div>
                    {/* <div
                        style={{
                            padding: `12px 20px 20px 20px`,
                            borderBottom: '2px solid #d6d6d6',
                        }}
                    >
                        <FieldRow>
                            <CustomColComboBox
                                comboBoxType='StatusCodes'
                                label='Status'
                                width={110}
                                dataList={statusCodeList ?? []}
                                onChange={(e) => {
                                    handleOnChange(e, 'StatusCode')
                                }}
                                value={formState.StatusCode}
                            />
                            <div className='highlight'>
                                <p>{formState.StatusDesc}</p>
                            </div>
                        </FieldRow>
                    </div>
                    <div className="form-fields">
                        <FieldRow>
                            <CustomNumericInput
                                label='Min Potential'
                                style={{ width: '150px' }}
                                value={formState.MinPotential}
                                onChange={(e) => handleOnChange(e, "MinPotential")}
                                min={0}
                                maxLength={19}
                                maxdecimals={4}
                                mindecimals={2}
                            />
                            {EntType === 'L' && (
                                <CustomColComboBox
                                    comboBoxType='QualStat'
                                    label='Qualify'
                                    width={150}
                                    dataList={QUALSTATS}
                                    onChange={(e) => {
                                        handleOnChange(e, 'QualStat')
                                    }}
                                    value={QUALSTATS.find(stat => stat.key === formState.QualStat)?.key}
                                    id='QualStat'
                                />
                            )}
                        </FieldRow>
                    </div> */}
                    <CustomButtonWrapper>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                handleSave();
                            }}
                            disabled={!isValid || isSaving || isDeleting || !isChanged}
                            type="button"
                            id="save-btn"
                            title="Save"
                        >
                            <>
                                <u>S</u>ave
                            </>
                        </Button>
                        <Button
                            onClick={() => handleClose()}
                            type="button"
                            id="cancel-btn"
                            title="Cancel"
                            disabled={isSaving || isDeleting}
                        >
                            <u>C</u>ancel
                        </Button>
                        {isEdit && (
                            <Button
                                onClick={() => toggleDialog()}
                                type="button"
                                id="delete-btn"
                                title="Delete"
                                tabIndex={-1}
                            >
                                <u>D</u>elete
                            </Button>
                        )}
                    </CustomButtonWrapper>
                </ReactFocusLock>
            </DialogContent>
            {visible && (
                <GenericDialog
                    type="Confirmation"
                    onCancel={() => setVisible(false)}
                    onConfirmed={() => toggleDialog(true)}
                    confirmButtonText="Delete"
                    primaryMessage="Delete record?"
                />
            )}
            {errorText && (
                <GenericDialog
                    type="Error"
                    onClose={() => {
                        setErrorText('');
                        setIsSaving(false);
                    }}
                    primaryMessage={errorText}
                />
            )}
        </MovableDialog>
    );
};

export default SalesEventCodesDialog;
