import React, { useEffect, useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

import { ISalesTaxProps } from './SalesTaxDialog.types';
import { IFieldProperties } from '../../../../data/types';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../hooks';
import { handleKeyDown, validateSalesTaxForm } from '../../../../utils';
import { Button, CustomColComboBox, InputWrapper } from '../../../common';
import CustomNumericInput, {
  CustomNumericInputChangeEvent,
} from '../../../common/CustomNumericInput';

import {
  DialogContent,
  StyledTitle,
  CustomButtonWrapper,
} from './SalesTaxDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../common/MovableDialog';
import { salesTaxService } from '../../../../services/setup/salesTaxService';
import { customAxios } from '../../../../services/loginService';
import AddCompanyDialog from '../../ChartOfAccounts/AccountDialog/AddCompanyDialog';

type ComboType = {
  vendors: VendorsType[];
  fieldProperties: IFieldProperties[];
};

type VendorsType = {
  IDNo: number;
  EntityName: string;
  Department?: string;
  PrimaryContact?: string;
  EmailMain?: string;
  JobTitle?: string;
  Address1?: string;
  MainPhone?: string;
  City?: string;
  State?: string;
  Zip?: string;
  Notes?: string;
  TermsCodeID?: number;
};

const SalesTaxDialog: React.FC<ISalesTaxProps> = ({
  isEdit,
  data,
  handleClose,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    IDNo: isEdit && data ? data.IDNo : 0,
    Jurisdiction: isEdit && data ? data.Jurisdiction : '',
    PayToID: isEdit && data ? data.PayToID : 0,
    PayToName: isEdit && data ? data.PayToName : '',
    FromZip: isEdit && data ? data.FromZip : '',
    ToZip: isEdit && data ? data.ToZip : '',
    Rate: isEdit && data ? data.Rate : 0,
  });
  const [entityData, setEntityData] = useState<any>(null);
  const [showCompanyDialog, setShowCompanyDialog] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput('jurisdiction');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  const [comboData, setComboData] = useState<ComboType>({
    vendors: [],
    fieldProperties: [],
  });

  useHighlightInput('jurisdiction');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  const fetchSalesTax = async () => {
    const response = await salesTaxService.getSalesTax(formState.IDNo);

    const updatedVendors = [
      { IDNo: 0, EntityName: '' },
      ...response.combos.Vendors,
    ];

    setComboData({
      vendors: updatedVendors,
      fieldProperties: response.fieldProperties,
    });
  };

  // returns length of decimal places for min display value
  function minDecimalPlaces(rate: number): number {
    const rateStr: string = rate.toString();
    const decimal: string[] = rateStr.split('.');
    if (decimal.length === 1) {
      return 0;
    }
    return decimal[1].length;
  }

  useEffect(() => {
    async function fetchEntityData(ID = 1) {
      const data = await customAxios().get(`/v3/Entity/GetVendor?IDNo=${ID}`);
      setEntityData(data.data.data);
    }
    fetchEntityData();
    fetchSalesTax();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsValid(validateSalesTaxForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayToID) {
        setIsSaving(true);

        const saved = await salesTaxService.createSalesTax(
          isEdit ? formState.IDNo : 0,
          {
            Jurisdiction: formState.Jurisdiction,
            PayToID: formState.PayToID,
            PayToName: formState.PayToName,
            FromZip: formState.FromZip,
            ToZip: formState.ToZip,
            Rate: formState.Rate,
          }
        );

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayToID) {
        setIsDeleting(true);
        const status = await salesTaxService.deleteSalesTax(formState.IDNo);

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event:
      | InputChangeEvent
      | NumericTextBoxChangeEvent
      | ComboBoxChangeEvent
      | CustomNumericInputChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    if (key === 'PayToName') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value.EntityName,
        PayToID: comboData.vendors.find(
          (vendor) => vendor.EntityName === event.value.EntityName
        )?.IDNo,
      }));
    } else if (key === 'FromZip' || key === 'ToZip') {
      const numericValue = event.value.replace(/[^0-9]/g, '');
      setFormState((prevState) => ({
        ...prevState,
        [key]: numericValue,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>{isEdit ? 'Edit' : 'Add'} Sales Tax</span>
        {isEdit && (
          <button
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
          ></button>
        )}
      </StyledTitle>
    );
  };

  return (
    <MovableDialog
      onClose={() => handleClose()}
      className="sales-tax-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <ReactFocusLock>
        <DialogContent>
          <div
            className="form-fields"
            onKeyDown={(e) => handleKeyDown(e, formRef)}
            ref={formRef}
          >
            <div className="row">
              <InputWrapper
                maxWidth="150px"
                width="150px"
                name="Jurisdiction"
                label="Jurisdiction"
                onChange={handleOnChange}
                value={formState.Jurisdiction}
                id="jurisdiction"
                autoComplete="off"
              />
              <CustomColComboBox
                width={250}
                comboBoxType="Vendors"
                dataList={comboData.vendors}
                onChange={(e) => handleOnChange(e, 'PayToName')}
                filterable
                value={comboData.vendors.find(
                  (vendor) => vendor.EntityName === formState.PayToName
                )}
                name="PayToName"
                label="Vendor"
                headerOnClick={() => {
                  setShowCompanyDialog(true);
                }}
              />
            </div>
            <div className="row">
              <InputWrapper
                maxWidth="150px"
                width="150px"
                name="FromZip"
                label="From Zip"
                onChange={(e) => handleOnChange(e, 'FromZip')}
                value={formState.FromZip}
                id="account-name"
                autoComplete="off"
              />
              <InputWrapper
                maxWidth="121px"
                width="121px"
                name="ToZip"
                label="To Zip"
                onChange={(e) => handleOnChange(e, 'ToZip')}
                value={formState.ToZip}
                id="account-name"
                autoComplete="off"
              />
              <CustomNumericInput
                className="input-text-center"
                label="Rate"
                name="Rate"
                value={formState?.Rate || 0}
                onChange={(e) => {
                  handleOnChange(e, 'Rate');
                }}
                fillMode="solid"
                size="small"
                style={{ width: '121px' }}
                maxLength={11}
                maxdecimals={4}
                mindecimals={minDecimalPlaces(formState.Rate)}
              />
            </div>
          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>

            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </DialogContent>
      </ReactFocusLock>

      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}

      {showCompanyDialog && (
        <AddCompanyDialog
          entityData={entityData}
          onClose={() => setShowCompanyDialog(false)}
          taxable
        />
      )}
    </MovableDialog>
  );
};

export default SalesTaxDialog;
