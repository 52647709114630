import { GridColumnProps } from "@progress/kendo-react-grid";

export const columns: GridColumnProps[] = [
    {
      title: 'Account Type as Displayed',
      field: 'AcctTypeDisplay',
      width: 400,
      className: 'text-left',
    },
    {
      title: 'System Account Type',
      field: 'AcctTypeDesc',
      className: 'text-left',
    }
  ];