import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const companySetupService = {
  getSICCodes: async () => {
    const response = await axiosClient().get('/Entity/GetSICCodes', {
      headers: { ...commonHeaders },
    });
    return response.data.data.records;
  },
  uploadCompanyLogo: async (formData: FormData) => {
    const response = await axiosClient().post(`Document/UploadCompFiles`, formData);
    return response;
  },
};
