import { TextBox, TextBoxChangeEvent, TextBoxProps, TextBoxHandle } from '@progress/kendo-react-inputs';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { forwardRef, useEffect, useState } from 'react';

export type CustomNumericInputChangeEvent = {
  value: number | null;
}

interface CustomNumericInputProps extends Omit<TextBoxProps, "onChange" | "value" | "maxLength" | "onBlur" | "maxdecimals"> {
  fillmode?: "outline" | "solid" | "flat";
  mindecimals?: number;
  maxdecimals?: number;
  allowBlank?: boolean;
  maxLength: number;
  value: number | null | undefined;
  label?: string;
  onChange: (value: CustomNumericInputChangeEvent) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CustomNumericInput = forwardRef<TextBoxHandle, CustomNumericInputProps>(
  (props, ref) => {
    const { fillmode, value, mindecimals, maxLength, maxdecimals, onChange, label, onBlur, className, allowBlank = false } = props;

    const [displayValue, setDisplayValue] = useState<string>(Number(value ?? 0).toFixed(mindecimals));
    const handleOnchange = (event: TextBoxChangeEvent) => {
      const incomingValue = `${event.value}`;
      const currentValue = displayValue;
      const fullLength = incomingValue.length;
      const intLength = incomingValue.split('.')[0].length;
      const decimalLength = incomingValue.split('.')[1]?.length ?? 0;

      if (allowBlank && incomingValue === "") {
        setDisplayValue('');
        onChange({ value: null })
        return;
      }

      if (isNaN(Number(incomingValue))) return;

      if (intLength + decimalLength > maxLength
        || decimalLength > (maxdecimals || 0)
        || (decimalLength === 0 && fullLength > maxLength)
        || intLength > maxLength - (maxdecimals || 0)
      ) {
        onChange({ value: Number(currentValue) });
      } else {
        const realValue = incomingValue;
        setDisplayValue(`${realValue}`);
        onChange({ value: Number(incomingValue) });
      }
    }

    useEffect(() => {
      if (Number(value) !== Number(displayValue)){
          setDisplayValue(value || !allowBlank ? Number(value ?? 0).toFixed(mindecimals) : '');
      }
    }, [allowBlank, displayValue, mindecimals, value])

    return (<FloatingLabel
      editorValue={displayValue}
      label={label}>
      <TextBox
        ref={ref}
        {...props}
        autoComplete="off"
        maxLength={maxLength + 1}
        className={className ? `${className}` : '!text-right'}
        fillMode={fillmode}
        value={displayValue}
        onChange={handleOnchange}
        onBlur={(event) => {
          if (allowBlank && displayValue === "") setDisplayValue('')
          else setDisplayValue(`${Number(displayValue).toFixed(mindecimals) ?? ''}`)

          if (onBlur) {
            onBlur(event);
          }
        }
        }
      />
    </FloatingLabel>);
  }
);
export default CustomNumericInput;