import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const statesService = {
  getStates: async () => {
    const response = await axiosClient().get('Misc/GetStates', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
};
