import React, { useEffect, useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { InputChangeEvent } from '@progress/kendo-react-inputs';

import { IProjectStatusCodesDialogProps } from '../ProjectStatusCodes.types';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../hooks';
import {
  handleKeyDown,
  validateProjectStatusCodesForm,
} from '../../../../utils';
import { Button, InputWrapper, CustomColComboBox } from '../../../common';
import CustomNumericInput, {
  CustomNumericInputChangeEvent,
} from '../../../common/CustomNumericInput';
import { NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

import {
  DialogContent,
  StyledTitle,
  CustomButtonWrapper,
  FieldRow,
} from './ProjectStatusCodesDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../common/MovableDialog';
import { projectStatusCodesService } from '../../../../services/operations/projectService';
import { CustomColorPicker } from '../../../common/CustomColorPicker';

const ProjectStatusCodesDialog: React.FC<IProjectStatusCodesDialogProps> = ({
  isEdit,
  data,
  handleClose,
  handleUpdateColor,
}) => {
  const [errorText, setErrorText] = useState('');
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    IDNo: isEdit && data ? data.IDNo : 0,
    StatusCode: isEdit && data ? data.StatusCode : '',
    StatusDesc: isEdit && data ? data.StatusDesc : '',
    DefaultYN: isEdit && data ? data.DefaultYN : false,
    FinalYN: isEdit && data ? data.FinalYN : false,
    StatusPct: isEdit && data ? data.StatusPct * 100 : 0,
    StatusColor: isEdit && data ? data.StatusColor : '',
    UsedDefColor: false,
  });
  const [fieldProperties, setFieldProperties] = useState<any[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput(isEdit ? 'description' : 'status-code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  const fetchProject = async () => {
    const response = await projectStatusCodesService.getStatusCode(
      formState.IDNo
    );
    if (response.defColor) {
      setFormState((prev) => ({
        ...prev,
        StatusColor: response.defColor,
        UsedDefColor: true,
      }));
    }
    setFieldProperties(response.fieldProperties);
  };

  useEffect(() => {
    fetchProject();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsValid(validateProjectStatusCodesForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo || formState.IDNo === 0) {
        setIsSaving(true);

        const saved = await projectStatusCodesService.CreateUpdateStatusCode(
          isEdit ? formState.IDNo : 0,
          {
            StatusCode: formState.StatusCode,
            StatusDesc: formState.StatusDesc,
            DefaultYN: formState.DefaultYN,
            FinalYN: formState.FinalYN,
            StatusPct: formState.StatusPct ? formState.StatusPct / 100 : null,
            StatusColor: formState.StatusColor,
          },
          formState.UsedDefColor
        );

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdate = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo || formState.IDNo === 0) {
        setIsSaving(true);

        const saved = await projectStatusCodesService.CreateUpdateStatusCode(
          isEdit ? formState.IDNo : 0,
          {
            StatusCode: formState.StatusCode,
            StatusDesc: formState.StatusDesc,
            DefaultYN: formState.DefaultYN,
            FinalYN: formState.FinalYN,
            StatusPct: formState.StatusPct ? formState.StatusPct / 100 : null,
            StatusColor: formState.StatusColor,
          },
          formState.UsedDefColor
        );

        if (saved) {
          setIsSaving(false);
          handleUpdateColor(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo) {
        setIsDeleting(true);
        const status = await projectStatusCodesService.deleteStatusCode(
          formState.IDNo
        );

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event:
      | InputChangeEvent
      | NumericTextBoxChangeEvent
      | ComboBoxChangeEvent
      | CustomNumericInputChangeEvent,
    key: string
  ) => {
    setIsChanged(true);
    setErrorText('');

    const value = event.value;

    if (key === 'StatusCode') {
      const upperInputValue = value.toUpperCase();
      setFormState((prevState) => ({
        ...prevState,
        [key]: upperInputValue?.replace(/[^a-zA-Z0-9]/g, ''),
      }));
    } else if (key === 'DefaultYN') {
      setFormState((prevState) => {
        if (prevState.FinalYN && value === 'Yes') {
          setErrorText('This code is already a "Final"');
          return {
            ...prevState,
            DefaultYN: false,
          };
        } else {
          return {
            ...prevState,
            [key]: value === 'Yes' ? true : false,
          };
        }
      });
    } else if (key === 'FinalYN') {
      setFormState((prevState) => {
        if (prevState.DefaultYN && value === 'Yes') {
          setErrorText('This code is already a "Default"');
          return {
            ...prevState,
            FinalYN: false,
          };
        } else {
          return {
            ...prevState,
            [key]: value === 'Yes' ? true : false,
          };
        }
      });
    } else if (key === 'StatusPct') {
      let statusPctValue = value;

      if (statusPctValue > 100) {
        statusPctValue = 100;
      } else if (statusPctValue < 0) {
        statusPctValue = 0;
      }

      setFormState((prevState) => ({
        ...prevState,
        StatusPct: statusPctValue,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleColorChange = (color: string, IDNo: number = formState.IDNo) => {
    setIsChanged(true);
    setFormState((prev) => ({
      ...prev,
      IDNo: formState.IDNo,
      StatusColor: color,
      UsedDefColor: false,
    }));
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>{isEdit ? 'Edit' : 'Add'} Project Status Code</span>
        {isEdit && (
          <button
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
          ></button>
        )}
      </StyledTitle>
    );
  };

  return (
    <MovableDialog
      onClose={() => handleClose()}
      className="project-status-types-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <DialogContent ref={formRef} onKeyDown={(e) => handleKeyDown(e, formRef)}>
        <ReactFocusLock>
          <div
            style={{
              padding: `12px 20px 20px 20px`,
              borderBottom: '2px solid #d6d6d6',
            }}
          >
            <FieldRow>
              <InputWrapper
                maxWidth="100px"
                width="100px"
                name="StatusCode"
                label="Code"
                onChange={handleOnChange}
                value={formState.StatusCode}
                id="status-code"
                autoComplete="off"
                maxLength={
                  fieldProperties.find(
                    (prop: any) => prop.columnName === 'StatusCode'
                  )?.maxLength || 6
                }
                isDisabled={isEdit}
              />
              <InputWrapper
                maxWidth="200px"
                width="200px"
                name="StatusDesc"
                label="Description"
                onChange={handleOnChange}
                value={formState.StatusDesc}
                id="description"
                autoComplete="off"
                maxLength={
                  fieldProperties.find(
                    (prop: any) => prop.columnName === 'StatusDesc'
                  )?.maxLength || 50
                }
              />
              <div className="h-[40px] pt-[10px]">
                <CustomColorPicker
                  value={formState.StatusColor}
                  onChange={handleColorChange}
                  handleUpdate={handleUpdate}
                />
              </div>
            </FieldRow>
          </div>
          <div className="form-fields">
            <FieldRow>
              <CustomColComboBox
                name="DefaultYN"
                label="Default"
                width={97}
                dataList={[]}
                comboBoxType="CUSTOM_YesNo"
                onChange={(e) => handleOnChange(e, 'DefaultYN')}
                value={formState.DefaultYN === true ? 'Yes' : 'No'}
                defaultValue={formState.DefaultYN}
              />
              <CustomColComboBox
                name="FinalYN"
                label="Final"
                width={97}
                dataList={[]}
                comboBoxType="CUSTOM_YesNo"
                onChange={(e) => handleOnChange(e, 'FinalYN')}
                value={formState.FinalYN === true ? 'Yes' : 'No'}
                defaultValue={formState.FinalYN}
              />

              <CustomNumericInput
                className="input-text-center"
                label="Progress %"
                name="StatusPct"
                value={formState?.StatusPct || 0}
                onChange={(e) => {
                  handleOnChange(e, 'StatusPct');
                }}
                fillMode="solid"
                size="small"
                style={{ width: '97px' }}
                min={0}
                maxLength={3}
                maxdecimals={0}
                mindecimals={0}
                max={100}
              />
            </FieldRow>
          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              type="button"
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>
            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </ReactFocusLock>
      </DialogContent>

      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}

      {errorText && (
        <GenericDialog
          type="Error"
          onClose={() => {
            setErrorText('');
            setIsSaving(false);
          }}
          primaryMessage={errorText}
        />
      )}
    </MovableDialog>
  );
};

export default ProjectStatusCodesDialog;
