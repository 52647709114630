import React, { useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { WindowHandle } from '@progress/kendo-react-dialogs';

import { handleKeyDown } from '../../../../utils';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../hooks';

import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
  TextAreaChangeEvent,
} from '@progress/kendo-react-inputs';

import { Button, CustomColComboBox, InputWrapper } from '../../../common';

import {
  DialogContent,
  CustomButtonWrapper,
  FieldRow,
} from './AcctTypesDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import MoveableDialog from '../../../common/MovableDialog';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { AccountTypesDialog, AcctTypes } from '../types';
import { accountTypesAPI } from '../../../../services/AccountTypes/accountTypesAPI';

const AcctTypesDialog: React.FC<AccountTypesDialog> = ({
  isEdit,
  data,
  handleClose,
  dataList,
  dataTemplate,
}) => {
  const [errorText, setErrorText] = useState('');
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState<AcctTypes>({
    AcctTypeDisplay: isEdit && data ? data.AcctTypeDisplay : '',
    AcctTypeDesc: isEdit && data ? data.AcctTypeDesc : '',
    BSSort: isEdit && data ? data.BSSort : '',
    PLBS: isEdit && data ? data.PLBS : '',
    PLSort: isEdit && data ? data.PLSort : undefined,
    StartNo: isEdit && data ? data.StartNo : undefined,
    TypeDC: isEdit && data ? data.TypeDC : '',
    TypeGroup: isEdit && data ? data.TypeGroup : '',
  });

  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<WindowHandle>(null);

  useHighlightInput('first-input');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  // eslint-disable-next-line
  const handleOnChange = (
    event:
      | ComboBoxChangeEvent
      | InputChangeEvent
      | NumericTextBoxChangeEvent
      | TextAreaChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    setFormState((prevState) => ({
      ...prevState,
      [key]: event.value,
    }));
    if (key === 'AcctTypeDesc') {
      setFormState((prevState) => ({
        ...prevState,
        AcctTypeDesc: event.value.AcctTypeDesc,
        BSSort: event.value.BSSort,
        PLBS: event.value.PLBS,
        PLSort: event.value.PLSort,
        StartNo: event.value.StartNo,
        TypeDC: event.value.TypeDC,
        TypeGroup: event.value.TypeGroup,
      }));
    }
  };

  useKeyPress(
    () => {
      if (isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose(true);
    },
    () => {
      toggleDialog();
    }
  );

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      setIsSaving(true);
      const payload = {
        AcctTypeDisplay: formState.AcctTypeDisplay,
        AcctTypeDesc: formState.AcctTypeDesc,
        PLBS: formState.PLBS,
        PLSort: formState.PLSort,
        BSSort: formState.BSSort,
        TypeGroup: formState.TypeGroup,
        StartNo: formState.StartNo,
        TypeDC: formState.TypeDC,
      };

      if (!data?.IDNo) {
        const saved = await accountTypesAPI.updateAcctType(0, payload);

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      } else {
        const saved = await accountTypesAPI.updateAcctType(data.IDNo, payload);

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (err) {
      console.error(err);
      setErrorText('An error occurred');
      setIsSaving(false);
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      setIsDeleting(true);

      if (data?.IDNo) {
        const status = await accountTypesAPI.deleteAcctType(data.IDNo);

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
      setIsDeleting(false);
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  return (
    <MoveableDialog
      title="Account Types"
      onClose={() => handleClose()}
      ref={dialogRef}
    >
      <DialogContent>
        <ReactFocusLock>
          <div
            ref={formRef}
            onKeyDown={(e) => handleKeyDown(e, formRef)}
            style={{
              padding: `15px 25px 20px 25px`,
              // borderBottom: '2px solid #d6d6d6',
            }}
          >
            <FieldRow>
              <InputWrapper
                maxWidth="195px"
                width="195px"
                name="AcctTypeDisplay"
                label="Account Type"
                onChange={handleOnChange}
                value={formState.AcctTypeDisplay}
                autoComplete="off"
                maxLength={
                  dataTemplate.fieldProperties.find(
                    (prop: any) => prop.columnName === 'AcctTypeDisplay'
                  )?.maxLength || 50
                }
                // isDisabled={isEdit}
              />
              {/* <InputWrapper
                                maxWidth="125px"
                                width="125px"
                                name="TypeGroup"
                                label="System Acct Type"
                                onChange={handleOnChange}
                                value={formState.TypeGroup}
                                autoComplete="off"
                                // maxLength={fieldProperties.find((prop: any) => prop.columnName === 'EventDesc')?.maxLength || 50}
                                isDisabled={isEdit}
                            /> */}
              <CustomColComboBox
                label="System Type"
                comboBoxType="SystemAcctTypes"
                dataList={dataList}
                value={dataList.find(
                  (i: any) => i.AcctTypeDesc === data?.AcctTypeDesc
                )}
                onChange={(e) => {
                  handleOnChange(e, 'AcctTypeDesc');
                }}
                width={195}
                disabled={isEdit}
              />
            </FieldRow>
          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={isSaving || isDeleting || !isChanged}
              type="button"
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>
            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && !data?.SystemYN && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </ReactFocusLock>
      </DialogContent>

      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
      {errorText && (
        <GenericDialog
          type="Error"
          onClose={() => {
            setErrorText('');
            setIsSaving(false);
          }}
          primaryMessage={errorText}
        />
      )}
    </MoveableDialog>
  );
};

export default AcctTypesDialog;
