import { FC, createContext, useState } from 'react';
// import Sidebar from './layout/Sidebar';
import NewSideBar from './layout/NewSideBar';
import { useGetStates } from '../services/hooks';
import { IState } from '../data/types';
import PagesRenderer from './PagesRenderer';
import Header from './layout/Header';

import { DEV_ONLY_CURRENT_USER } from './pages/Login/Login';
import useImportScript from '../services/checkhq/importScript';

export const FormContext = createContext({
  currentForm: "",
  setCurrentForm: (form: string) => { }
});

interface MainComponentProps {
  companyName?: string;
};

const MainComponent: FC<MainComponentProps> = ({ companyName }) => {
  useImportScript('https://cdn.checkhq.com/component-initialize.js')
  const [currentForm, setCurrentForm] = useState('');

  const { data: states } = useGetStates() as {
    data: Array<IState>;
  };

  const handleCloseForm = () => {
    setCurrentForm('');
  };

  const handleOpenForm = (formType: string) => {
    setCurrentForm(formType);
  };

  return (
    <FormContext.Provider
      value={{ currentForm, setCurrentForm: (form: string) => setCurrentForm(form) }}
    >
      <div style={{ display: 'flex', width: '100%', transition: 'all 0.5s ease-out' }}>
        <NewSideBar
          currentForm={currentForm}
          handleOpenForm={handleOpenForm}
          handleCloseForm={handleCloseForm}
        />
        <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 242px)' }}>
          <Header />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '100%',
              gridTemplateRows: 'auto 50px',
              width: '100%',
              height: 'calc(100vh - 96px)', // height - (header height + footer height)
              borderLeft: '1px solid #EFEFEF'
            }}
          >
            {currentForm && (
              <PagesRenderer
                currentForm={currentForm}
                handleCloseForm={handleCloseForm}
                states={states}
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ height: '50px', width: '100%', backgroundColor: '#0062b0' }}>
        <div className="bottom-sidebar-container">
          <div className="bottom-sidebar-div">

            <div className="bottom-sidebar">
              <label className="bottom-sidebar-label-div">
                {companyName}
              </label>
              <label className="bottom-sidebar-label-div">
                User:  {DEV_ONLY_CURRENT_USER}
              </label>

            </div>
          </div>
        </div>

      </div>
    </FormContext.Provider>
  );
};

export default MainComponent;