import { GridCellProps } from '@progress/kendo-react-grid';

export const ColorCell: React.FC<GridCellProps> = (props) => {
  const {
    ariaColumnIndex,
    columnIndex,
    dataItem,
    field,
    className,
    onContextMenu,
  } = props;

  if (!field) return null;

  const colorStyle = {
    backgroundColor: dataItem.StatusColor,
    width: '18px',
    height: '18px',
    borderRadius: '15%',
};

  return (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      className={className ? `k-table-td ${className}` : 'k-table-td'}
      style={{ textAlign: 'center' }}
      role="gridcell"
      onContextMenu={(e) => onContextMenu?.(e, dataItem, field)}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
        <div style={colorStyle}></div>
      </div>
    </td>
  );
};