import type { GridColumnProps } from '@progress/kendo-react-grid';
import { CheckCell } from '../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Account No',
    field: 'AccountNo',
    width: 100,
    className: 'text-left',
  },
  {
    title: 'Account Name',
    field: 'AccountName',
    width: 250,
    className: 'text-left',
  },
  {
    title: 'Account Type',
    field: 'AccountType',
    width: 160,
    className: 'text-left',
  },
  {
    title: 'Company Name',
    field: 'EntityName',
    className: 'text-left',
  },
  {
    title: 'Inactive',
    field: 'DisabledYN',
    cell: CheckCell,
    width: 70
  },
];

export const filterColumns: GridColumnProps[] = [
  {
    title: 'Account No',
    field: 'AccountNo',
    width: 100,
    className: 'text-left',
  },
  {
    title: 'Account Name',
    field: 'AccountName',
    width: 250,
    className: 'text-left',
  },
  {
    title: 'Account Type',
    field: 'AccountType',
    className: 'text-left',
  },
  {
    title: 'Company Name',
    field: 'EntityName',
    className: 'text-left',
  },
];