import React, { useEffect, useRef, useState } from 'react';
import {
    InputChangeEvent,
    NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';
import { Button, InputWrapper } from '../../../../common';
import {
    useDisableEscape,
    useDisableRightClick,
    useHighlightInput,
    useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validateProductsAndServicesForm } from '../../../../../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
    DialogContent,
    StyledTitle,
    CustomButtonWrapper,
    FieldRow
} from './ProductsDialog.styled';

import {
    IProductsProps,
    PrdouctsFormState
} from './ProductsDialog.types';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';

import { productService } from '../../../../../services/productsAndServicesService';
import { IFieldProperty } from '../../../../../data/types';

const ProductsDialog: React.FC<IProductsProps> = ({
    isEdit,
    data,
    handleClose,
    productList,
}) => {
    const [errorText, setErrorText] = useState('');
    const [visible, setVisible] = useState<boolean>(false);
    const [formState, setFormState] = useState<PrdouctsFormState>({
        IDNo: isEdit && data ? data.IDNo : 0,
        CategoryCode: isEdit && data ? data.CategoryCode : '',
        CategoryDescription: isEdit && data ? data.CategoryDescription : '',
        CatPS: 'P',
    });

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [fieldProperties, setFieldProperties] = useState<IFieldProperty[]>([]);
    const [codeExist, setCodeExist] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);
    const dialogRef = useRef<any>(null);

    useHighlightInput(isEdit ? 'description' : 'category-code');
    useDisableEscape(dialogRef);
    useDisableRightClick(dialogRef);

    useEffect(() => {
        const getFieldProperties = async () => {
            const response = await productService.getFieldProperties(data.IDNo || 0);
            setFieldProperties(response.fieldProperties);
        };
        getFieldProperties();
    }, [data.IDNo]);

    const checkExistingCode = async () => {
        if (productList.map((code: any) => code.CategoryCode).includes(formState.CategoryCode?.trim())) {
            setCodeExist(true);
        } else {
            setCodeExist(false);
        }
    };

    useKeyPress(
        () => {
            if (isValid && isChanged && !isSaving && !isDeleting) {
                handleSave();
            };
        },
        () => {
            handleClose();
        },
        () => {
            toggleDialog();
        }
    );

    useEffect(() => {
        setIsValid(validateProductsAndServicesForm(formState));
        checkExistingCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    const handleSave = async (e?: React.MouseEvent) => {
        e?.preventDefault();

        try {
            if (formState.CategoryCode) {
                setIsSaving(true);
                const payload = {
                    CategoryCode: formState.CategoryCode,
                    CategoryDescription: formState.CategoryDescription,
                    CatPS: 'P'
                };

                if (data.CategoryCode) {
                    // updating record
                    await productService.updateProduct(formState.IDNo, payload);
                } else {
                    // create record
                    if (codeExist) {
                        setErrorText('Code already exists');
                        return;
                    }
                    const saved = await productService.createProduct(formState.CategoryCode, payload);
                    if (!saved) {
                        setErrorText('Failed to save record');
                        return;
                    }
                };
                setIsSaving(false);
                handleClose(true);
            }
        } catch (e: any) {
            console.error(e);
            const errorMessage = e?.response?.data?.errorMessage;
            if (
                errorMessage?.includes(
                    'InternalServerError: Violation of PRIMARY KEY constraint'
                )
            ) {
                setErrorText('Pay code already exists');
            } else {
                setErrorText('Something went wrong');
            }
        }
    };

    const handleOnDelete = async (e?: React.MouseEvent) => {
        e?.preventDefault();

        try {
            if (formState.CategoryCode) {
                setIsDeleting(true);
                const status = await productService.deleteProduct(formState.IDNo);

                if (status) {
                    setIsDeleting(false);
                    handleClose(true);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleOnChange = (
        event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
        key: string
    ) => {
        setIsChanged(true);

        setFormState((prevState) => ({
            ...prevState,
            [key]: event.value,
        }));
    };

    const toggleDialog = (state?: boolean) => {
        if (state) {
            handleOnDelete();
        }
        setVisible(!visible);
    };

    const CustomWindowTitle = () => {
        return (
            <StyledTitle>
                <span>Setup Product</span>
                {isEdit && (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            toggleDialog();
                        }}
                        className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
                    ></button>
                )}
            </StyledTitle>
        );
    };

    return (
        <MovableDialog
            resizable={false}
            onClose={() => handleClose()}
            className="paycode-dialog"
            title={<CustomWindowTitle />}
            ref={dialogRef}
        >
            <DialogContent ref={formRef} onKeyDown={(e) => handleKeyDown(e, formRef)}>
                <ReactFocusLock>
                    <div
                        style={{
                            padding: `12px 20px 20px 20px`,
                        }}
                    >
                        <FieldRow>
                            <InputWrapper
                                maxWidth="100px"
                                width="100px"
                                name="CategoryCode"
                                label="Code"
                                onChange={handleOnChange}
                                value={formState.CategoryCode}
                                id="category-code"
                                autoComplete="off"
                                maxLength={fieldProperties.find((prop: any) => prop.columnName === 'CategoryCode')?.maxLength}
                                isDisabled={isEdit}
                            />
                            <InputWrapper
                                maxWidth="200px"
                                width="200px"
                                name="CategoryDescription"
                                label="Description"
                                onChange={handleOnChange}
                                value={formState.CategoryDescription}
                                id="description"
                                autoComplete="off"
                                maxLength={fieldProperties.find((prop: any) => prop.columnName === 'CategoryDescription')?.maxLength}
                            />
                        </FieldRow>
                    </div>
                    <CustomButtonWrapper>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                handleSave();
                            }}
                            disabled={!isValid || isSaving || isDeleting || !isChanged}
                            type="button"
                            id="save-btn"
                            title="Save"
                        >
                            <>
                                <u>S</u>ave
                            </>
                        </Button>
                        <Button
                            onClick={() => handleClose()}
                            type="button"
                            id="cancel-btn"
                            title="Cancel"
                            disabled={isSaving || isDeleting}
                        >
                            <u>C</u>ancel
                        </Button>
                        {isEdit && (
                            <Button
                                onClick={() => toggleDialog()}
                                type="button"
                                id="delete-btn"
                                title="Delete"
                                tabIndex={-1}
                            >
                                <u>D</u>elete
                            </Button>
                        )}
                    </CustomButtonWrapper>
                </ReactFocusLock>
            </DialogContent>
            {visible && (
                <GenericDialog
                    type="Confirmation"
                    onCancel={() => setVisible(false)}
                    onConfirmed={() => toggleDialog(true)}
                    confirmButtonText="Delete"
                    primaryMessage="Delete record?"
                />
            )}
            {errorText && (
                <GenericDialog
                    type="Error"
                    onClose={() => {
                        setErrorText('');
                        setIsSaving(false);
                    }}
                    primaryMessage={errorText}
                />
            )}
        </MovableDialog>
    );
};

export default ProductsDialog;
