import React from "react";
import { Grid, GridCellProps, GridProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { CellRender, RowRender, HeaderCellRender } from "./renderers";

interface SelectableGridProps extends GridProps {
  enterEdit: (dataItem: GridItemType, field: string | undefined) => void;
  exitEdit: () => void;
  undoEdit: (rowIndex: number) => void;
  checkRequired?: (item: GridItemType) => void;
  onContextMenuOpen?: (e: React.MouseEvent, dataItem: GridItemType) => void;
  tabIndex: number;
};

type GridItemType = Record<string, any>;

const SelectableGrid = React.forwardRef<Grid, SelectableGridProps>(({
  tabIndex,
  enterEdit,
  exitEdit,
  undoEdit,
  checkRequired,
  onContextMenuOpen = () => {},
  children,
  ...props
}, ref) => {

  return (
    <Grid
      ref={ref}
      size="small"
      className="selectable-grid"
      navigatable={true}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      rowHeight={30}
      detailRowHeight={30}
      style={{ height: "100%" }}
      dataItemKey="IDNo"
      headerCellRender={(td: React.ReactNode, props: GridHeaderCellProps) => HeaderCellRender({ td: td, originalProps: props })}
      cellRender={(td: React.ReactElement<HTMLTableCellElement> | null, props: GridCellProps) => (
        <CellRender
          td={td}
          originalProps={props}
          editField="inEdit"
          enterEdit={enterEdit}
          tabIndex={tabIndex}
        />
      )}
      rowRender={(tr, originalProps) => (
        <RowRender
          tr={tr}
          originalProps={originalProps}
          exitEdit={exitEdit}
          undoEdit={undoEdit}
          checkRequired={checkRequired}
          tabIndex={tabIndex}
          onContextMenuOpen={onContextMenuOpen}
        />
      )}
      {...props}
    >
      {children}
    </Grid>
  )
});

export default SelectableGrid;

export { SelectableCheckboxCell } from "./GridCells/SelectableCheckboxCell";
// export { AmountCell } from "./GridCells/AmountCell";
// export { TextCell } from "./GridCells/TextCell";
// export { DateCell } from "./GridCells/DateCell";