import React from "react";
import { Grid, GridCellProps, GridHeaderCellProps, GridProps } from "@progress/kendo-react-grid";
// import CheckImg from "./assets/check.png";
import { CellRender, RowRender } from "./renderers";

type GridItemType = Record<string, any>;

interface HeaderRenderProps {
  td: React.ReactNode | null;
  originalProps: GridHeaderCellProps;
}

const HeaderCellRender = (props: HeaderRenderProps) => {
  if (props.td === undefined) return null;
  return (props.originalProps.field === "SelectedId") ? <img src={''} alt="Check" /> : props.td;
};

interface EditableGridProps extends GridProps {
  enterEdit: (dataItem: GridItemType, field: string | undefined) => void;
  exitEdit: (dataItem: GridItemType) => void;
  undoEdit: (rowIndex: number) => void;
  checkRequired?: (item: GridItemType) => void;
  onContextMenuOpen?: (e: React.MouseEvent, dataItem: GridItemType) => void;
  tabIndex: number;
};

const EditableGrid = React.forwardRef<Grid, EditableGridProps>(({
  tabIndex,
  enterEdit,
  exitEdit,
  undoEdit,
  checkRequired,
  onContextMenuOpen=() => {},
  children,
  ...props
}, ref) => {
  return (
    <Grid
      ref={ref}
      size="small"
      navigatable={true}
      rowHeight={24}
      detailRowHeight={24}
      style={{ height: "100%" }}
      editField="inEdit"
      dataItemKey="IDNo"
      headerCellRender={(td: React.ReactNode, props: GridHeaderCellProps) => HeaderCellRender({td:td, originalProps:props})}
      cellRender={(td: React.ReactElement<HTMLTableCellElement> | null, props: GridCellProps) => (
        <CellRender
          td={td}
          originalProps={props}
          editField="inEdit"
          enterEdit={enterEdit}
          tabIndex={tabIndex}
        />
      )}
      rowRender={(tr, originalProps) => (
        <RowRender
          tr={tr}
          originalProps={originalProps}
          exitEdit={exitEdit}
          undoEdit={undoEdit}
          checkRequired={checkRequired}
          tabIndex={tabIndex}
          onContextMenuOpen={onContextMenuOpen}
        />
      )}
      {...props}
    >
      {children}
    </Grid>
  )
});

export default EditableGrid;

export { YNCell } from "./GridCells/YNCell";
export { CheckboxCell } from './GridCells/CheckboxCell'
export { ItemCell } from "./GridCells/ItemCell";
export { ReadOnlyCell } from "./GridCells/ReadOnlyCell";
// export { AccountCell } from "./GridCells/AccountCell";
// export { AmountCell } from "./GridCells/AmountCell";
// export { ReadOnlyNumericCell } from "./GridCells/ReadOnlyNumericCell";
// export { TextCell } from "./GridCells/TextCell";
// export { TransEntityCell } from "./GridCells/TransEntityCell";