import { EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { imageAddIcon } from '@progress/kendo-svg-icons';
import { Button } from '../..';
import { CustomInsertImageDialog } from './CustomInsertImageDialog';
import { useState } from 'react';

export const CustomInsertImageTool = (props: EditorTools.InsertImageProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const { view } = props;
  const nodeType = view && view.state.schema.nodes["image"] as any;
  const canInsert = (view && nodeType) && EditorUtils.canInsert(view.state, nodeType);
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowDialog(true)
  }

  const onSave = (imageUrl: string) => {
    EditorUtils.insertNode(view!, nodeType.createAndFill({ src: `${imageUrl}` }))
    setShowDialog(false)
  }

  return (
    <>
      <Button
        onClick={onClick}
        disabled={!canInsert}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={"Insert Image"}
        svgIcon={imageAddIcon}
      />
      {showDialog && <CustomInsertImageDialog onClose={() => setShowDialog(false)} onSave={onSave} />}
    </>
  );
}