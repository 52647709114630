export enum EColor {
  BLUE = '#1E88E5',
  DARK_BLUE = '#2c5098',
  DARKER_BLUE = '#254481',
  LIGHT_GRAY = '#F5F5F5',
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  TEAL = '#438DA4',
  DARK_GRAY = '#424242',
  GRAY = '#ededed',
  ORANGE = '#CF6720',
  SKY_BLUE = '#65A1D7',
}
