import { RefObject, useEffect, useRef } from 'react';

export function useDetectClickOutside<T extends HTMLElement>(
  onClickOutside: () => void,
  exceptions?: RefObject<HTMLElement>[],
  /**Return true if onClickOutside should fire */
  exceptionRule?: (targetEl: HTMLElement) => boolean
) {
  const containerRef = useRef<T | null>(null);

  useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      if (!containerRef.current || !e.target) return;
      if (
        !isChildOf(e.target as HTMLElement, containerRef.current) &&
        !exceptions?.some((i) =>
          isChildOf(e.target as HTMLElement, i.current)
        ) &&
        !exceptionRule?.(e.target as HTMLElement)
      ) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [exceptionRule, exceptions, onClickOutside]);

  return containerRef;
}

export function isChildOf(
  el: HTMLElement,
  childOf: HTMLElement | null
): boolean {
  const parentEl = el.parentElement;

  // Is self
  if (el === childOf) return true;

  if (parentEl && parentEl === childOf) return true;
  if (!parentEl) return false;

  return isChildOf(parentEl, childOf);
}
