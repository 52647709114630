import React, { FC, useRef, useState } from 'react';

import { IPersonnelCodesProps } from './PersonnelCodes.types';
import {
  DepartmentListType,
  PayGroupListType,
  ScheduleData,
} from '../../../data/types';

import Departments from './Departments';
import PayGroups from './PayGroups';

import { useDisableRightClick } from '../../../hooks';

import {
  FormContainer,
  Form,
  TabsContainer,
  Tab,
} from './PersonnelCodes.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import Schedules from './Schedules';
import PageNavButton from '../../common/PageNavButton';
import Positions from './Positions';

const PersonnelCodes: FC<IPersonnelCodesProps> = ({
  handleCloseForm,
  onNextPage,
  onPreviousPage,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentListType>();
  const [selectedPayGroup, setSelectedPayGroup] = useState<PayGroupListType>();
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleData>();
  const [selectedPosition, setSelectedPosition] = useState<any>();

  useDisableRightClick(containerRef);

  const handleCurrentTab = (e: React.MouseEvent, tabIndex: number) => {
    e.preventDefault();
    setCurrentTab(tabIndex);
  };

  return (
    <>
      <FormContainer ref={containerRef}>
        <Form>
          <div className="form-section-header-container">
            <div className="form-title">Personnel Codes</div>
            <div className="form-header-btn-wrapper">
              <div className="listing-action-seperate-line" />
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={handleCloseForm}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div className="form-content">
            <CustomGrid
              cols="1fr"
              colGap="0px"
              width="830px"
              style={{ padding: '30px 0px 30px 30px' }}
            >
              <TabsContainer>
                <Tab
                  isActive={currentTab === 0}
                  onClick={(e) => handleCurrentTab(e, 0)}
                >
                  Groups
                </Tab>
                <Tab
                  isActive={currentTab === 1}
                  onClick={(e) => handleCurrentTab(e, 1)}
                >
                  Schedules
                </Tab>
                <Tab
                  isActive={currentTab === 2}
                  onClick={(e) => handleCurrentTab(e, 2)}
                >
                  Departments
                </Tab>
                <Tab
                  isActive={currentTab === 3}
                  onClick={(e) => handleCurrentTab(e, 3)}
                >
                  Positions
                </Tab>
              </TabsContainer>
              {currentTab === 0 && (
                <PayGroups
                  selectedPayGroup={selectedPayGroup}
                  setSelectedPayGroup={setSelectedPayGroup}
                />
              )}
              {currentTab === 1 && (
                <Schedules
                  selectedSchedule={selectedSchedule}
                  setSelectedSchedule={setSelectedSchedule}
                />
              )}
              {currentTab === 2 && (
                <Departments
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                />
              )}
              {currentTab === 3 && (
                <Positions
                  selectedPosition={selectedPosition}
                  setSelectedPosition={setSelectedPosition}
                />
              )}
            </CustomGrid>
          </div>
        </Form>
      </FormContainer>
    </>
  );
};

export default PersonnelCodes;
