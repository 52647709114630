import React, { FC, useRef, useState } from 'react';

import Roles from './sections/Roles';
import Groups from './sections/Groups';
import Users from './sections/Users';

import { useDisableRightClick } from '../../../hooks';

import { FormContainer, Form, TabsContainer, Tab } from './UserPerm.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';

interface IUserPermProps {
  title: string;
  onClose: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const UserPerm: FC<IUserPermProps> = ({
  title,
  onClose,
  onNextPage,
  onPreviousPage,
}) => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);

  useDisableRightClick(containerRef);

  const handleCurrentTab = (e: React.MouseEvent, tabIndex: number) => {
    e.preventDefault();
    setCurrentTab(tabIndex);
  };

  return (
    <>
      <FormContainer ref={containerRef}>
        <Form>
          <div className="form-section-header-container">
            <div className="form-title">{title}</div>
            <div className="form-header-btn-wrapper">
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={onClose}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div className="form-content">
            <CustomGrid
              cols="1fr"
              colGap="0px"
              width="830px"
              style={{ padding: '30px 0px 30px 30px' }}
            >
              <TabsContainer>
                <Tab
                  isActive={currentTab === 0}
                  onClick={(e) => handleCurrentTab(e, 0)}
                >
                  Users
                </Tab>
                <Tab
                  isActive={currentTab === 1}
                  onClick={(e) => handleCurrentTab(e, 1)}
                >
                  Groups
                </Tab>
                <Tab
                  isActive={currentTab === 2}
                  onClick={(e) => handleCurrentTab(e, 2)}
                >
                  Roles
                </Tab>
              </TabsContainer>
              {currentTab === 0 && <Users onClose={onClose} />}
              {currentTab === 1 && <Groups onClose={onClose} />}
              {currentTab === 2 && <Roles onClose={onClose} />}
            </CustomGrid>
          </div>
        </Form>
      </FormContainer>
    </>
  );
};

export default UserPerm;
