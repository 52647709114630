import type { GridColumnProps } from '@progress/kendo-react-grid';
// import { ReversedCheckCell } from '../../../common/ListingView/ReversedCheckCell';
// import { NumericCell } from '../../../common';

// const DATE_COL_WIDTH = 50;
export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'SchedCode',
    width: 100,
    className: 'text-left',
  },
  {
    title: 'Description',
    field: 'SchedDesc',
    className: 'text-left',
    width: 543,
  },
  // {
  //   title: 'M',
  //   field: 'OffMon',
  //   width: DATE_COL_WIDTH,
  //   cell: ReversedCheckCell,
  // },
  // {
  //   title: 'T',
  //   field: 'OffTue',
  //   cell: ReversedCheckCell,
  //   width: DATE_COL_WIDTH,
  // },
  // {
  //   title: 'W',
  //   field: 'OffWed',
  //   cell: ReversedCheckCell,
  //   width: DATE_COL_WIDTH,
  // },
  // {
  //   title: 'T',
  //   field: 'OffThu',
  //   cell: ReversedCheckCell,
  //   width: DATE_COL_WIDTH,
  // },
  // {
  //   title: 'F',
  //   field: 'OffFri',
  //   cell: ReversedCheckCell,
  //   width: DATE_COL_WIDTH,
  // },
  // {
  //   title: 'S',
  //   field: 'OffSat',
  //   cell: ReversedCheckCell,
  //   width: DATE_COL_WIDTH,
  // },
  // {
  //   title: 'S',
  //   field: 'OffSun',
  //   cell: ReversedCheckCell,
  //   width: DATE_COL_WIDTH,
  // },
  {
    title: 'Interval',
    field: 'SchedIntv',
    className: 'text-left',
  }
];
