import React, { useEffect, useRef } from 'react';
import { FormContainer } from './CompanyOnboard.styled';
import FocusLock from 'react-focus-lock';
import { useDisableRightClick } from '../../../hooks';
// import { handleKeyDown } from '../../../utils';
// import PageNavButton from '../../common/PageNavButton';
// import CloseButton from '../../common/CloseButton';
import loadComponent from '../../../services/checkhq/loadComponent';
import { checkService } from '../../../services/checkhq/checkService';

interface Props {
  title: string;
  onClose: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const CompanyOnboard = ({
  title,
  onClose,
  onNextPage,
  onPreviousPage,
}: Props) => {
  // const formRef = useRef<HTMLFormElement>(null);
  const formContainerRef = useRef<HTMLDivElement>(null);
  let user = window.sessionStorage.getItem('SECRET_USERNAME') || '';
  let email = window.sessionStorage.getItem('SECRET_EMAIL') || 'test@email.com';

  // useHighlightInput('company-name');
  useDisableRightClick(formContainerRef);

  const componentDivId = 'company-onboard-holder';

  useEffect(() => {
    let handler: any;

    const getComponentLink = async () => {
      try {
        const signatoryPayload = {
          // "signatory": "sig_HeMFGfJoVZwC49jrOVaP",
          //need the signatory id or these fields
          signer_name: user,
          signer_title: 'User',
          email: email,
        };
        const res = await checkService.getComponent(
          'com_PWWGbKeWZ5MlGMohbWKk',
          'onboard',
          signatoryPayload
        );

        if (res && res.status && res.data && res.data.checkData) {
          const checkData = JSON.parse(res.data.checkData);
          const url = checkData.url;
          handler = loadComponent(url, componentDivId);
        } else {
          console.error('No link found in the response');
          const componentDiv = document.getElementById(componentDivId);
          if (componentDiv) {
            componentDiv.innerText = 'Component not found';
          }
        }
      } catch (error) {
        console.error('No link found in the response');
      }
    };

    getComponentLink();

    return () => {
      if (handler) {
        handler.close();
      }
    };
  }, [componentDivId, user, email]);

  return (
    <>
      <FocusLock>
        <FormContainer ref={formContainerRef} className="temp-form-container">
          {/* <Form onKeyDown={(e) => handleKeyDown(e, formRef)} ref={formRef} className='temp-form'>
          <div className="form-section-header-container">
            <div className="form-title">{title}</div>
            <div className="form-header-btn-wrapper">
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={onClose}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div id={componentDivId} className="form-content"/>
        </Form> */}
          <div id={componentDivId} className="form-content" />
        </FormContainer>
      </FocusLock>
    </>
  );
};

export default CompanyOnboard;
