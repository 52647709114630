import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const payGroupsService = {
  getPayGroups: async () => {
    const response = await axiosClient().get(`Payroll/PayGroups/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  createPayGroup: (payGroupCode: string, payload: any) =>
    axiosClient().post(
      'Payroll/PayGroups/PostPayGroup',
      { ...payload },
      {
        params: {
          PayGroupCode: payGroupCode,
        },

        headers: { ...commonHeaders },
      }
    ),
  updatePayGroup: (payGroupCode: string, payload: any) => {
    return axiosClient().patch(
      'Payroll/PayGroups',
      { ...payload },
      {
        params: {
          PayGroupCode: payGroupCode,
        },

        headers: { ...commonHeaders },
      }
    );
  },
  deletePayGroupById: (payGroupCode: string) =>
    axiosClient().delete('Payroll/PayGroups', {
      params: {
        PayGroupCode: payGroupCode,
      },
      headers: { ...commonHeaders },
    }),
  getPayGroupData: async (payCode: string) => {
    const response = await axiosClient().get(
      `Payroll/PayGroups?PayGroupCode=${payCode}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data;
  },
  postGroupPTOCodes: async (payGroupCode: string, payload: any[]) => {
    const data = new FormData();

    payload.forEach((item) => {
      data.append('CodeList', JSON.stringify(item));
    });

    const response = await axiosClient().post(
      `Payroll/PayGroups/PostGroupPTOCodes`,
      data,
      {
        params: {
          PayGroupCode: payGroupCode,
        },
        headers: {
          ...commonHeaders,
          'Content-Type': 'multipart/form-data',
        }
      }
    );
    return response;
  }
};
