export const CID_VAR = '11201';
export const AK_VAR = '698478YThdfGHBJ3h635Gs332';
export const USER_VAR = 'Willie';
export const CLIENT_PLATFORM = 'Firefox v92.0.2';

export const orgTypeOptions = [
  { value: 'CC', name: 'C Corporation' },
  { value: 'LLC', name: 'Limited Liability Corporation' },
  { value: 'LLP', name: 'Limited Liability Partnership' },
  { value: 'NP', name: 'Non Profit' },
  { value: 'SC', name: 'S Corporation' },
  { value: 'SP', name: 'Sole Proprietorship' },
];

export const statesColumns = [
  {
    field: 'State',
    header: 'Abbreviation',
    width: '70px',
  },
  {
    field: 'StateName',
    header: 'Name',
    width: '130px',
  },
];

export const orgTypeCols = [
  {
    field: 'value',
    header: 'Abbreviation',
    width: '48px',
  },
  {
    field: 'name',
    header: 'Name',
    width: '185px',
  },
];

export const ACCOUNT_COLUMNS = (
  no_width?: number,
  name_width?: number,
  type_width?: number
) => {
  const columns = [
    {
      field: 'AccountNo',
      width: no_width || 144,
    },
    {
      field: 'AccountName',
      header: 'Name',
      width: name_width || 250,
    },
    {
      field: 'AccountType',
      header: 'Account Type',
      width: type_width || 150,
    },
  ];
  if (!type_width) {
    columns.pop();
  }
  return columns;
};

export const ACCRUAL_METHODS = [
  { label: 'None', value: 'N' },
  { label: 'Time Period', value: 'T' },
  { label: 'Hours Worked', value: 'H' },
];

export const ACCRUAL_INTERVALS = [
  { label: 'Each Year', value: 'Y' },
  { label: 'Each Month', value: 'M' },
  { label: 'Each Pay Period', value: 'P' },
  { label: 'Each Hour Worked', value: 'H' },
];

export const PAY_FREQUENCIES = [
  { value: 'W', label: 'Weekly' },
  { value: 'B', label: 'Biweekly' },
  { value: 'M', label: 'Monthly' },
  { value: 'S', label: 'Semimonthly' },
];

export const DAYS_IN_MONTH = [
  { key: '1', value: '1' },
  { key: '2', value: '2' },
  { key: '3', value: '3' },
  { key: '4', value: '4' },
  { key: '5', value: '5' },
  { key: '6', value: '6' },
  { key: '7', value: '7' },
  { key: '8', value: '8' },
  { key: '9', value: '9' },
  { key: '10', value: '10' },
  { key: '11', value: '11' },
  { key: '12', value: '12' },
  { key: '13', value: '13' },
  { key: '14', value: '14' },
  { key: '15', value: '15' },
  { key: '16', value: '16' },
  { key: '17', value: '17' },
  { key: '18', value: '18' },
  { key: '19', value: '19' },
  { key: '20', value: '20' },
  { key: '21', value: '21' },
  { key: '22', value: '22' },
  { key: '23', value: '23' },
  { key: '24', value: '24' },
  { key: '25', value: '25' },
  { key: '26', value: '26' },
  { key: '27', value: '27' },
  { key: '28', value: '28' },
  { key: '29', value: '29' },
  { key: '30', value: '30' },
  { key: '31', value: '31' },
];

export const MONTHS = [
  { month: 'January', value: 1 },
  { month: 'February', value: 2 },
  { month: 'March', value: 3 },
  { month: 'April', value: 4 },
  { month: 'May', value: 5 },
  { month: 'June', value: 6 },
  { month: 'July', value: 7 },
  { month: 'August', value: 8 },
  { month: 'September', value: 9 },
  { month: 'October', value: 10 },
  { month: 'November', value: 11 },
  { month: 'December', value: 12 },
];

export const HOLIDAYS = [
  'Christmas Day',
  'Independence Day',
  'Labor Day',
  'Memorial Day',
  'New Years Day',
  'Thanksgiving',
];

export const DAY_OF_WEEK = [
  { key: 'Monday', value: 'Monday' },
  { key: 'Tuesday', value: 'Tuesday' },
  { key: 'Wednesday', value: 'Wednesday' },
  { key: 'Thursday', value: 'Thursday' },
  { key: 'Friday', value: 'Friday' },
  { key: 'Saturday', value: 'Saturday' },
  { key: 'Sunday', value: 'Sunday' },
];

export const WEEK_OF_MONTH = [
  { key: 'First', value: 'First' },
  { key: 'Second', value: 'Second' },
  { key: 'Third', value: 'Third' },
  { key: 'Fourth', value: 'Fourth' },
  { key: 'Last', value: 'Last' },
];

export const HOLIDAYS_DEFAULTS = {
  HolidayName: '',
  Month: '',
  DateOfMonth: 0,
  DayOfWeek: '',
  WeekOfMonth: '',
  SystemYN: true,
};

export const PAYAD_INPUT_DEFAULTS = {
  AccountNo: { AccountName: 'Unclassified Operating Expense', AccountNo: 0 },
  DedMethod: '',
  PayMethod: '',
  Rate: 0,
  Deductibility: 'None',
  DeductEntity: { IDNo: '', EntityName: '' },
  Taxable: 'Yes',
};

export const QUALSTATS = [
  { key: 'Q', name: 'Qualified' },
  { key: 'D', name: 'Disqualified' },
  { key: 'N', name: 'No Change' },
];

export const PTO_DAYS_IN_MONTH = [
  { key: 1, name: '1' },
  { key: 2, name: '2' },
  { key: 3, name: '3' },
  { key: 4, name: '4' },
  { key: 5, name: '5' },
  { key: 6, name: '6' },
  { key: 7, name: '7' },
  { key: 8, name: '8' },
  { key: 9, name: '9' },
  { key: 10, name: '10' },
  { key: 11, name: '11' },
  { key: 12, name: '12' },
  { key: 13, name: '13' },
  { key: 14, name: '14' },
  { key: 15, name: '15' },
  { key: 16, name: '16' },
  { key: 17, name: '17' },
  { key: 18, name: '18' },
  { key: 19, name: '19' },
  { key: 20, name: '20' },
  { key: 21, name: '21' },
  { key: 22, name: '22' },
  { key: 23, name: '23' },
  { key: 24, name: '24' },
  { key: 25, name: '25' },
  { key: 26, name: '26' },
  { key: 27, name: '27' },
  { key: 28, name: '28' },
  { key: 29, name: '29' },
  { key: 30, name: '30' },
  { key: 31, name: '31' },
];

export const PTO_MONTHS = [
  { key: 1, name: 'January' },
  { key: 2, name: 'February' },
  { key: 3, name: 'March' },
  { key: 4, name: 'April' },
  { key: 5, name: 'May' },
  { key: 6, name: 'June' },
  { key: 7, name: 'July' },
  { key: 8, name: 'August' },
  { key: 9, name: 'September' },
  { key: 10, name: 'October' },
  { key: 11, name: 'November' },
  { key: 12, name: 'December' }, 
];

export const PTO_PERIODS = [
  { key: 'M', name: 'Months' },
  { key: 'Y', name: 'Years' },
];

export const PTO_UNITS = [
  { key: 'D', name: 'Days' },
  { key: 'H', name: 'Hours' },
];

export const PTO_ACCRUAL_START = [
  { key: 'C', name: 'Calendar Date' },
  { key: 'D', name: 'Date of Hire' },
];

export const deductSvcCodes = [
  { value: 'child_support', name: 'Child Support' },
  { value: 'miscellaneous', name: 'Miscellaneous' },
  { value: '125_medical', name: '125 Medical' },
  { value: '125_life', name: '125 Life' },
  // { value: '125_vision', name: '125 Vision' },
  // { value: '125_dental', name: '125 Dental' },
  // { value: '125_disability', name: '125 Disability' },
  // { value: '125_accident', name: '125 Accident' },
  // { value: '125_cancer', name: '125 Cancer' },
  // { value: '125_critical_illness', name: '125 Critical Illness' },
  // { value: '125_hospital', name: '125 Hospital' },
  // { value: '125_medical_other', name: '125 Medical Other' },
  { value: '401k', name: '401(k)' },
  { value: '403b', name: '403(b)' },
  { value: '457', name: '457' },
  { value: 'roth_401k', name: 'Roth 401(k)' },
  { value: 'roth_403b', name: 'Roth 403(b)' },
  { value: 'roth_457', name: 'Roth 457' },
  { value: 'fsa_medical', name: 'FSA Medical' },
  { value: 'fsa_dependent_care', name: 'FSA Dependent Care' },
  { value: 'hsa', name: 'HSA' },
  { value: 'simple_ira', name: 'Simple IRA' },
];