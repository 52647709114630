import React, { useEffect, useRef, useState } from 'react';
import {
    InputChangeEvent,
    NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';
import { Button, CustomColComboBox, InputWrapper } from '../../../../common';
import {
    useDisableEscape,
    useDisableRightClick,
    useHighlightInput,
    useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validateMeasurementUnitsForm } from '../../../../../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
    DialogContent,
    StyledTitle,
    CustomButtonWrapper,
} from './MeasurementUnitsDialog.styled';

import {
    IMeasurementUnitProps,
    MeasurementUnitFormState
} from './MeasurementUnitsDialog.types';
import { FieldRow } from './MeasurementUnitsDialog.styled';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';

import { measurementUnitService } from '../../../../../services/productsAndServicesService';
import { IFieldProperty, MeasurementUnitGroupType } from '../../../../../data/types';

const MeasurementUnitsDialog: React.FC<IMeasurementUnitProps> = ({
    isEdit,
    data,
    handleClose,
    unitList,
}) => {
    const [errorText, setErrorText] = useState('');
    const [visible, setVisible] = useState<boolean>(false);
    const [formState, setFormState] = useState<MeasurementUnitFormState>({
        IDNo: isEdit && data ? data.IDNo : 0,
        UnitCode: isEdit && data ? data.UnitCode : '',
        UnitGroup: isEdit && data ? data.UnitGroup : '',
        UnitDescription: isEdit && data ? data.UnitDescription : '',
        SystemYN: isEdit && data ? data.SystemYN : false,
        HideYN: isEdit && data ? data.HideYN : false,
    });
    
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [fieldProperties, setFieldProperties] = useState<IFieldProperty[]>([]);
    const [combos, setCombos] = useState<{ MeasurementUnitGroups: MeasurementUnitGroupType[] }>();
    const [codeExist, setCodeExist] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);
    const dialogRef = useRef<any>(null);


    useEffect(() => {
        const getFieldProperties = async () => {
            const response = await measurementUnitService.getFieldProperties(data.IDNo || 0);
            setFieldProperties(response.fieldProperties);
            setCombos(response.combos);
        };

        getFieldProperties();
    }, [data.IDNo]);

    const checkExistingCode = async () => {
        if (unitList.map((code: any) => code.UnitCode).includes(formState.UnitCode?.trim())) {
            setCodeExist(true);
        } else {
            setCodeExist(false);
        }
    };

    useHighlightInput(disabled ? 'hideYN' : 'unit-code');
    useDisableEscape(dialogRef);
    useDisableRightClick(dialogRef);

    useKeyPress(
        () => {
            if (isValid && isChanged && !isSaving && !isDeleting) {
                handleSave();
            };
        },
        () => {
            handleClose();
        },
        () => {
            if (disabled) return;
            else toggleDialog();
        }
    );

    useEffect(() => {
        setIsValid(validateMeasurementUnitsForm(formState));
        checkExistingCode();
        console.log(formState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    useEffect(() => {
        if (data.SystemYN) {
            setDisabled(true);
        }
    }, [data.SystemYN]);

    const handleSave = async (e?: React.MouseEvent) => {
        e?.preventDefault();

        try {
            if (formState.UnitCode) {
                setIsSaving(true);
                const payload = {
                    UnitCode: formState.UnitCode,
                    UnitDescription: formState.UnitDescription,
                    UnitGroup: formState.UnitGroup,
                    SystemYN: formState.SystemYN,
                    HideYN: formState.HideYN,
                };

                if (data.UnitCode) {
                    // updating record
                    await measurementUnitService.updateUnit(formState.IDNo, payload);
                } else {
                    // create record
                    if (codeExist) {
                        setErrorText('Code already exists');
                        return;
                    }
                    const saved = await measurementUnitService.createUnit(formState.UnitCode, payload);
                    if (!saved) {
                        setErrorText('Failed to save record');
                        return;
                    }
                };
                setIsSaving(false);
                handleClose(true);
            }
        } catch (e: any) {
            console.error(e);
            const errorMessage = e?.response?.data?.errorMessage;
            if (errorMessage?.includes('InternalServerError: Violation of PRIMARY KEY constraint')) {
                setErrorText('Code already exists');
            } else {
                setErrorText('Something went wrong');
            }
        }
    };

    const handleOnDelete = async (e?: React.MouseEvent) => {
        e?.preventDefault();

        try {
            if (formState.IDNo) {
                setIsDeleting(true);
                const status = await measurementUnitService.deleteUnit(formState.IDNo);

                if (status) {
                    setIsDeleting(false);
                    handleClose(true);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleOnChange = (
        event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
        key: string
    ) => {
        setIsChanged(true);

        setFormState((prevState) => ({
            ...prevState,
            [key]: event.value,
        }));
    };

    const toggleDialog = (state?: boolean) => {
        if (state) {
            handleOnDelete();
        }
        setVisible(!visible);
    };

    const CustomWindowTitle = () => {
        return (
            <StyledTitle>
                <span>Setup Measurement Unit</span>
                {isEdit && (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            toggleDialog();
                        }}
                        className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
                    ></button>
                )}
            </StyledTitle>
        );
    };

    return (
        <MovableDialog
            resizable={false}
            onClose={() => handleClose()}
            className="measurement-unit-dialog"
            title={<CustomWindowTitle />}
            ref={dialogRef}
        >
            <DialogContent ref={formRef} onKeyDown={(e) => handleKeyDown(e, formRef)}>
                <ReactFocusLock>
                    <div
                        style={{
                            padding: `12px 20px 20px 20px`,
                            borderBottom: '2px solid #d6d6d6',
                        }}
                    >
                        <FieldRow>
                            <InputWrapper
                                maxWidth="100px"
                                width="100px"
                                name="UnitCode"
                                label="Code"
                                onChange={handleOnChange}
                                value={formState.UnitCode}
                                id="unit-code"
                                autoComplete="off"
                                isDisabled={disabled || isEdit}
                                maxLength={fieldProperties.find((prop: any) => prop.columnName === 'UnitCode')?.maxLength}
                            />
                            <InputWrapper
                                maxWidth="200px"
                                width="200px"
                                name="UnitDescription"
                                label="Description"
                                onChange={handleOnChange}
                                value={formState.UnitDescription}
                                autoComplete="off"
                                isDisabled={disabled}
                                maxLength={fieldProperties.find((prop: any) => prop.columnName === 'UnitDescription')?.maxLength}
                            />
                        </FieldRow>
                    </div>
                    <div className="form-fields">
                        <FieldRow>
                            <CustomColComboBox
                                name="HideYN"
                                label='Hide'
                                id='hideYN'
                                width={100}
                                dataList={[]}
                                comboBoxType='CUSTOM_YesNo'
                                onChange={(e) => {
                                    setIsChanged(true)
                                    setFormState((prevState: any) => ({
                                        ...prevState,
                                        HideYN: e.value === 'Yes' ? true : false,
                                    }))
                                }}
                                value={formState.HideYN === true ? 'Yes' : 'No'}
                                defaultValue={formState.HideYN}
                            />
                            <CustomColComboBox
                                comboBoxType='MeasurementUnits'
                                dataList={combos?.MeasurementUnitGroups ?? []}
                                width={200}
                                label="Unit Group"
                                name="UnitGroup"
                                onChange={(e) => {
                                    handleOnChange(e, "UnitGroup")
                                }}
                                defaultValue={formState.UnitGroup}
                                value={formState.UnitGroup}
                                disabled={disabled}
                            />
                        </FieldRow>
                    </div>
                    <CustomButtonWrapper>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                handleSave();
                            }}
                            disabled={!isValid || isSaving || isDeleting || !isChanged}
                            type="button"
                            id="save-btn"
                            title="Save"
                        >
                            <>
                                <u>S</u>ave
                            </>
                        </Button>
                        <Button
                            onClick={() => handleClose()}
                            type="button"
                            id="cancel-btn"
                            title="Cancel"
                            disabled={isSaving || isDeleting}
                        >
                            <u>C</u>ancel
                        </Button>
                        {isEdit && (
                            <Button
                                onClick={() => toggleDialog()}
                                type="button"
                                id="delete-btn"
                                title="Delete"
                                tabIndex={-1}
                                disabled={disabled}
                            >
                                <u>D</u>elete
                            </Button>
                        )}
                    </CustomButtonWrapper>
                </ReactFocusLock>
            </DialogContent>
            {visible && (
                <GenericDialog
                    type="Confirmation"
                    onCancel={() => setVisible(false)}
                    onConfirmed={() => toggleDialog(true)}
                    confirmButtonText="Delete"
                    primaryMessage="Delete record?"
                />
            )}
            {errorText && (
                <GenericDialog
                    type="Error"
                    onClose={() => {
                        setErrorText('');
                        setIsSaving(false);
                    }}
                    primaryMessage={errorText}
                />
            )}
        </MovableDialog>
    );
};

export default MeasurementUnitsDialog;
