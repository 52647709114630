import type { GridCellProps } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";

export const SelectableCheckboxCell = (props: GridCellProps) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    if (!render || !field) return null;

    // const value = dataItem[field] || '';

    const onChange = (e: CheckboxChangeEvent) => {
        // console.log(`onChange`, e)
        if (props.onChange) {
            props.onChange({
                dataIndex: props.dataIndex,
                dataItem,
                field,
                syntheticEvent: e.syntheticEvent,
                value: e.value ? dataItem.Paycode : null
            });
        }
        if (props.selectionChange) {
            props.selectionChange(e);
        }
    };

    // const onFocus = (e: CheckboxFocusEvent) => {
    //     // console.log(`onFocus`)
    //     if (props.onChange && dataItem['IsNew'] === true) {
    //         props.onChange({
    //             dataIndex: props.dataIndex,
    //             dataItem,
    //             field,
    //             syntheticEvent: e.syntheticEvent,
    //             value: e.target.value
    //         });
    //     }
    //     if (props.selectionChange) {
    //         props.selectionChange(e);
    //     }
    // };

    const defaultRendering = (
        <td
            style={{ padding: 0 }}
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            tabIndex={6}
            role="gridcell"
        >
            <Checkbox
                onChange={onChange}
                // onFocus={onFocus}
                checked={dataItem.checked}
                style={{ borderColor: "#d6d6d6" }}
            />
        </td>
    );

    return render.call(undefined, defaultRendering, props);
};