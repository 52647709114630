
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const accountTypesAPI = {
    getList: async () => {
        const response = await axiosClient().get(`Setup/AcctTypes/GetList`, {
          headers: { ...commonHeaders },
        });
        return response.data.data.records;
      },
      getSingleAcctType: async (IDNo: number) => {
        const response = await axiosClient().get(`Setup/AcctTypes`, {
          headers: { ...commonHeaders },
          params: {
            IDNo,
          },
        });
        return response.data.data;
      },
      updateAcctType: (IDNo: number, payload: any) => {
        return axiosClient().patch(
          'Setup/AcctTypes',
          { ...payload },
          {
            params: {
              IDNo,
            },
            headers: { ...commonHeaders },
          }
        );
      },
      deleteAcctType: (IDNo: number) =>
        axiosClient().delete('Setup/AcctTypes', {
          params: {
            IDNo: IDNo,
          },
          headers: { ...commonHeaders },
        }),
};
