import {
  ColorGradient,
  FlatColorPickerChangeEvent,
} from '@progress/kendo-react-inputs';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '../../common';
import { CSSProperties, useCallback, useRef, useState } from 'react';
import { useDetectClickOutside } from '../../../hooks/useDetectClickOutside';
import { rgbToHex } from '@mui/material';

type Color = string;

type ColorPickerProps = {
  style?: CSSProperties;
  value: Color;
  onChange: (color: Color) => void;
  handleUpdate: (e?: React.MouseEvent) => void;
};

export const CustomColorPicker = ({
  style,
  onChange,
  handleUpdate,
  value: color,
}: ColorPickerProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {
    setShowPopup(false);
  }, []);
  const popupRef = useDetectClickOutside(onClickOutside, [buttonRef]);

  const onColorChange = (e: FlatColorPickerChangeEvent) => {
    onChange(rgbToHex(e.value));
  };

  return (
    <div
      style={{ width: 30, height: 30, ...style }}
      className="aspect-square border-2 border-[#d6d6d6] p-1 rounded-sm"
      ref={buttonRef}
      onClick={(e) => {
        e.preventDefault();
        setShowPopup(true);
      }}
    >
      <button
        className="w-full h-full rounded-sm"
        style={{ backgroundColor: color }}
      ></button>

      <Popup
        ref={(ref) => {
          if (ref && typeof ref === 'object' && 'element' in ref) {
            popupRef.current = ref.element as HTMLElement;
          }
        }}
        show={showPopup}
        anchor={buttonRef.current}
      >
        <ColorGradient
          opacity={false}
          format="hex"
          onChange={onColorChange}
          value={color}
        />
        <div className="flex justify-center p-2">
          <Button
            onClick={() => {
              handleUpdate();
              setShowPopup(false);
            }}
            type="button"
            id="cancel-btn"
            title="Update"
          >
            Update
          </Button>
        </div>
      </Popup>
    </div>
  );
};
