import styled from 'styled-components';

export const DialogContent = styled.form`
  display: flex;
  flex-direction: column;

  .form-fields {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 20px;

    row-gap: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: center;

  button {
    margin-left: 15px;

    &:first-child {
      margin-left: 0px;
    }
  }
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 60px;

  label {
    width: 90px;
    font-size: 12px;
    color: #424242;
  }
`;

export const TwoLabelsInputWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    font-size: 12px;
    color: #424242;
  }
`;

export const CustomDialogContentWrapper = styled.div`
  width: 100%;
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 2px solid #d6d6d6;
  padding: 15px 0px;
  width: 100%;

  button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
