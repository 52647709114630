import { createGlobalStyle } from 'styled-components';
import { EColor } from './constants';

const GlobalStyle = createGlobalStyle`
  .k-dropdownlist {
    background-color: ${EColor.WHITE} !important;
  }
  
  .k-floating-label-container,
  :not(.k-empty) > .k-label,
  .k-floating-label-container,
  .k-fill > .k-label,
  .k-floating-label-container:focus-within > .k-label {
    background-color: white;
  }
 
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  
  .k-window-titlebar {
    padding: 0.5rem;
    color: white;
    background-color: ${EColor.DARK_BLUE};
    height: 40px;
    box-sizing: border-box;
  
    .k-window-title {
      font-size: 14px;
      font-weight: 500;
      // height: 20px;
    }
  }
  
  .k-window-titlebar-actions button:nth-child(2) {
    display: none;
  }

  .k-input-md .k-input-button, 
  .k-input-md .k-spinner-increase, 
  .k-input-md .k-spinner-decrease, 
  .k-picker-md .k-input-button, 
  .k-picker-md .k-spinner-increase, 
  .k-picker-md .k-spinner-decrease {
    border-color: transparent;
    color: ${EColor.DARK_GRAY};
    background-color: transparent;
  }
  
  .k-list-item:nth-child(even) {
    background-color: #F5F5F5;
  }

  .k-list-item:nth-child(odd) {
    // background-color: #FAFAFA;
    background-color: #fff;
  }

  .k-list-item {
    box-shadow: unset !important;
    font-size: 13px;
    border-bottom: 1px solid #d6d6d6;
  }

  .k-list-item:hover {
    background-color: #ABB9D6;
  }
  
  .k-list-item.k-selected, .k-selected.k-list-optionlabel {
    color: inherit !important;
    background-color: rgba(44, 80, 152, 0.08) !important;
  }

  .k-list-ul .k-focus {
    background-color: rgba(123, 145, 188, 0.6117647059) !important;
  }
  
  .k-floating-label-container.k-empty > .k-label {
    top: 15.0000000004px;
  }
  
  .k-floating-label-container {
    padding-top: 10.0000000004px;
    color: ${EColor.DARK_GRAY};
  }

  .k-floating-label-container > .k-label,
  .k-floating-label-container.k-focus > .k-label {
      top: -1px;
      left: 8px;
      transform: scale(0.8);
      font-size: 12px !important;
  }

  .k-floating-label-container:focus-within > .k-label {
      top: -1px;
      left: 8px;
      transform: scale(0.8);
      font-size: 12px !important;
      color: #00628c !important;
  }

  .k-button:disabled, .k-button.k-disabled {
    background: #7F7F7F;
    border-color: #7F7F7F;
  }

  .k-input {
    height: 30px; 
  }

  .k-textarea {
    height: 80px; 
  }

  .k-dropdownlist {
    height: 30px;
  }

  .k-datepicker .k-input-inner {
    padding-bottom: 10px;
  }

  .k-datepicker .k-input-inner::placeholder {
    color: ${EColor.DARK_GRAY};
  }

  .state-text-center .k-input-inner {
    text-align: center;
  }

  .input-text-center .k-input-inner {
    text-align: center;
    border-color: #00628c !important;
  }

  .k-textbox .k-input:focus-within .k-input-sm .k-input-outline .k-rounded-md .input-text-center {
    border-color: #00628c !important;
    
  }

  .k-input,
  .k-floating-label-container,
  .k-maskedtextbox,
  .k-dropdownlist {
      input, .k-input-inner {
        font-size: 12px !important;
    }
  }

  .k-maskedtextbox {
      input, .k-input-inner {
        height: 30px;
    }
  }

  // ------combobox and dropdown styles------
  .k-combobox {
    .k-clear-value {
      display: none;
    }
  }

  .k-table-row {
    height: 26px !important;
  }
  
  .k-combobox , .k-table-row {
    box-shadow: unset !important;
  }

  .k-combobox,
  .k-dropdownlist {
    background: transparent !important;
    background-image: none !important;

    .k-button {
      background: transparent !important;
    }
  }


  .k-animation-container .k-table-md {
    line-height: 0.86;
  }

  .k-animation-container .k-table-body,
  .k-animation-container .k-list-content {
    max-height: 300px !important;
  }

  .k-animation-container .k-list-item {
    height: 30px;
  }

  .k-animation-container .k-list-item-text {
    color: #121212;
  }

  .k-animation-container .k-table-row.k-table-alt-row {
    background: #F5F5F5;

    &:hover {
      background: #ABB9D6;
    }
  }

  .k-animation-container .k-table-row {
    background: #fff;
    font-size: 13px;
    outline: 1px solid #d6d6d6;

    &:hover {
      background: #ABB9D6;
    }
  }

  .k-table-tbody .k-table-row.k-selected > .k-ttable-td,
  .k-table-list .k-table-row.k-selected {
    background-color: #8096C1 !important;
  }

  .k-table-tbody .k-table-row.k-focus > .k-ttable-td,
  .k-table-list .k-table-row.k-focus {
    background-color: #ABB9D6 !important;
  }

  .k-animation-container .k-focus::before ,.k-animation-container .k-focus::after {
    background-color: transparent;
  }
  
  .k-time-header .k-button-text {
    color: #ff6358 !important;
  }

  .k-time-footer button {
    border: none;

    &.k-time-accept span{
      color: white !important;
    }
  }

  .k-timepicker .k-input-inner {
    padding-right: 0 !important;
  }

  .k-time-list .k-item:hover {
      background-color: #0000000a;
      color: #000000de;
  }

  .k-animation-container .k-list-item.k-selected {
    background-color: #8096C1 !important;
  } 

  .k-datepicker-popup .k-calendar-td:hover {
    .k-link {
      background-color: #F5F5F5;
    }
  }

  .k-datepicker-popup .k-selected .k-link {
    background-color: #8096C1 !important;
  }

  form * {
    font-family: 'Inter', sans-serif;
  }

  .k-animation-container {
    color: #121212;
    font-family: 'Inter', sans-serif;
  }
  
  .k-animation-container{
    z-index: 11111 !important;
  }

  .k-data-table {
    .k-table-header {
      display: none;
    }
    .k-table-body li {
      .k-table-td:nth-child(1) {
        outline: 1px solid #d6d6d6;
      }
    }
  }

  //****************************/

  // ----------------- Grid/Table ------------------------------------/
  
  .k-grid .k-table-row.k-selected > .k-table-td {
    background-color: rgba(44, 80, 152, 0.6) !important;
    color: #fff !important;
  }
  
  .k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover {
    background-color: rgba(44, 80, 152, 0.4) !important;
  }

  // ------------------ Grid Context Menu -------------------------------/
  
  .k-input-solid,
  .k-picker-solid {
    border-width: 2px;
    border-color: #d6d6d6;
  }
  
  .k-input-solid:hover,
  .k-input-solid.k-hover {
    border-color: rgba(0, 0, 0, 0.16);
  }
  
  .k-input-solid:focus-within,
  .k-picker-solid:focus,
  .k-picker-solid.k-focus {
    border: 2px solid ${EColor.DARK_BLUE};
    box-shadow: none;
  }
  
  .k-focus .k-label {
    color: ${EColor.DARK_BLUE};
  }
  
  .k-table-tbody .k-table-row > .k-ttable-td,
  .k-table-list .k-table-row {
    height: 30px;
  }

  .group-dialog {
    .k-dialog {
      width: 299px;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  .role-dialog {
    .k-dialog {
      width: 299px;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  .user-dialog {
    .k-dialog {
      width: 299px;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  .payment-terms-codes-dialog {
    .k-dialog {
      width: 360px;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  .pto-code-dialog {
    .k-dialog {
      width: fit-content;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  .locations-dialog {
    .k-dialog {
      width: 1024px;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  .account-dialog {
    .k-dialog {
      width: 360px;
    }

    .k-dialog-content {
      padding: 0px;
    }
  }

  .import-dialog {
    .k-dialog {
      width: 360px;
    }

    .k-dialog-content {
      padding: 0px;
    }
  }

  .department-dialog {
    .k-dialog {
      width: 360px;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  .paygroup-dialog {
    .k-dialog {
      width: 360px;
    }
    
    .k-dialog-content {
      padding: 0px;
    }
  }

  form:has(.form-section-header-container) {
      width: fit-content !important;
      padding: 0px !important;
      
      .form-content {
        padding-right: 30px !important;
      }
  }
`;

export default GlobalStyle;
