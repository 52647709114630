import styled from 'styled-components';

export const DialogContent = styled.form`
  display: flex;
  flex-direction: column;

  .form-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 20px;
    row-gap: 15px;

    .RequiredInput {
      margin-top: 8px;
    }
  }
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 850px;
  column-gap: 15px;

  label {
    color: #424242;
    font-size: 12px;
    max-width: 360px;
    flex: 1;
    text-align: right;
  }

  .minMaxWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 360px;

  p {
    font-size: 11px;
    margin: 0px;
    color: #2c5098;
    font-style: italic;
  }
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 2px solid #d6d6d6;
  padding: 0px 0px;
  padding: 15px 20px;

  button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const FieldRow = styled.div`
  display: flex;
  gap: 8px;

  .highlight {
    width: 190px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #3f48cc;
    padding: 0px;
    margin: 0px;

    p {
      // centers the description
      padding: 10px 0px 0px 0px; 
      margin: 0px;
    }
  }
`;
