import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const salesEventCodesService = {
  getList: async (EntType: string) => {
    const response = await axiosClient().get(
      `Setup/SalesEventCodes/GetList?EntType=${EntType}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data.records;
  },
  getFieldProperties: async (IDNo: number) => {
    const response = await axiosClient().get(
      `Setup/SalesEventCodes?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
  createEventCode: (payload: any) =>
    axiosClient().patch(
      'Setup/SalesEventCodes',
      { ...payload },
      {
        params: {
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateEventCode: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Setup/SalesEventCodes',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteEventCode: (IDNo: number) => {
    return axiosClient().delete('Setup/SalesEventCodes', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
};

export const salesStatusCodesService = {
  getList: async (EntType: string) => {
    const response = await axiosClient().get(
      `Setup/SalesStatusCodes/GetList?EntType=${EntType}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data.records;
  },
  getFieldProperties: async (IDNo: number, EntType: 'L' | 'P' | 'X' = 'L') => {
    const response = await axiosClient().get(
      `Setup/SalesStatusCodes?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
  createStatusCode: (payload: any, DefColor?: boolean) =>
    axiosClient().patch(
      'Setup/SalesStatusCodes',
      { ...payload },
      {
        params: {
          IDNo: 0,
          DefColor,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateStatusCode: (IDNo: number, payload: any, DefColor?: boolean) => {
    return axiosClient().patch(
      'Setup/SalesStatusCodes',
      { ...payload },
      {
        params: {
          IDNo,
          DefColor,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteStatusCode: (IDNo: number) => {
    return axiosClient().delete('Setup/SalesStatusCodes', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
};
