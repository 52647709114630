import type { GridColumnProps } from '@progress/kendo-react-grid';

export const columns: GridColumnProps[] = [
  {
    title: 'Group Code',
    field: 'PermGroupCode',
    width: 142,
  },
  {
    title: 'Group Description',
    field: 'PermGroupDesc',
  },
];
