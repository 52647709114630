import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import { plusIcon } from '@progress/kendo-svg-icons';
import { useKeyPress } from '../../../hooks';

import { Button, ListingGrid } from '../../common';

import { IProjectTypesProps } from './ProjectTypes.types';
import { IProjectTypes } from '../../../data/types';
import { columns } from './model';

import {
  ButtonWrapper,
  Form,
  FormContainer,
  ListingGridContainer,
} from './ProjectTypes.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';
import ProjectTypesDialog from './ProjectTypesDialog';
import { projectTypesService } from '../../../services/operations/projectService';

type SelectionType = { field?: string; dataItem: any };

const ProjectTypes: FC<IProjectTypesProps> = ({
  title,
  onNextPage,
  onPreviousPage,
  handleCloseForm,
}) => {
  const [originData, setOriginData] = useState<IProjectTypes[]>([]);
  const [filterData, setFilterData] = useState<IProjectTypes[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProjectTypes>();
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();

  const offset = useRef({ left: 0, top: 0 });

  async function fetchData() {
    try {
      const projectTypesList: IProjectTypes[] =
        await projectTypesService.getProjectTypesList();

      setOriginData(projectTypesList ?? []);
      setFilterData(projectTypesList ?? []);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {},
    () => {},
    () => {},
    () => {
      setSelectedProject({ IDNo: 0 });
    }
  );

  return (
    <FormContainer>
      <Form>
        <div className="form-section-header-container">
          <div className="form-title">{title}</div>
          <div className="form-header-btn-wrapper">
            <PageNavButton
              className="listing-action-button"
              onClick={(e) => {
                e.preventDefault();
                onPreviousPage();
              }}
              title="Previous Page"
            >
              <span
                className="k-icon k-i-arrow-left k-button-icon"
                role="presentation"
              />
            </PageNavButton>
            <PageNavButton
              className="listing-action-button"
              onClick={(e) => {
                e.preventDefault();
                onNextPage();
              }}
              title="Next Page"
            >
              <span
                className="k-icon k-i-arrow-right k-button-icon"
                role="presentation"
              />
            </PageNavButton>
            <CloseButton
              className="listing-action-button"
              onClick={handleCloseForm}
              title="Close"
            >
              <span
                className="k-icon k-i-close k-button-icon"
                role="presentation"
              />
            </CloseButton>
          </div>
        </div>
        <div className="form-content">
          <CustomGrid
            cols="800px"
            colGap="0px"
            width="auto"
            style={{ padding: '30px 0px 30px 30px' }}
          >
            <ListingGridContainer>
              {isLoading ? (
                <></>
              ) : (
                <ListingGrid
                  rowHeight={24}
                  data={sort ? orderBy(filterData, sort) : filterData}
                  dataItemKey="IDNo"
                  onRowClick={(e) => {
                    setFilterData((prev) =>
                      prev.map((item) => ({
                        ...item,
                        selected: item.IDNo === e.dataItem.IDNo,
                      }))
                    );
                  }}
                  onRowDoubleClick={(e) => {
                    setSelectedProject(e.dataItem);
                  }}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuOpen(event.syntheticEvent);
                    const { dataItem, field } = event;
                    setFilterData((prev) =>
                      prev.map((item) => ({
                        ...item,
                        selected: item.IDNo === dataItem.IDNo,
                      }))
                    );
                    setSelectedItem({ field, dataItem });
                  }}
                >
                  {columns.map((column, index) => {
                    return <GridColumn {...column} key={index} />;
                  })}
                </ListingGrid>
              )}

              <ContextMenu
                show={showContext}
                offset={offset.current}
                onSelect={handleOnSelect}
                onClose={() => setShowContext(false)}
                className="context-menu"
              >
                <MenuItem
                  text="Sort Ascending"
                  data={{
                    action: 'sortAsc',
                  }}
                />
                <MenuItem
                  text="Sort Descending"
                  data={{
                    action: 'sortDesc',
                  }}
                />
                <MenuItem
                  text="Filter In Selected"
                  data={{
                    action: 'filterIn',
                  }}
                />
                <MenuItem
                  text="Filter Out Selected"
                  data={{
                    action: 'filterOut',
                  }}
                />
                <MenuItem
                  text="Remove All Filters"
                  data={{
                    action: 'removeFilters',
                  }}
                />
              </ContextMenu>

              {selectedProject && (
                <ProjectTypesDialog
                  handleClose={(shouldRefreshData?: boolean) => {
                    setSelectedProject(undefined);
                    if (shouldRefreshData) {
                      fetchData();
                    }
                  }}
                  data={selectedProject}
                  isEdit={selectedProject?.IDNo ? true : false}
                />
              )}
            </ListingGridContainer>
            {isLoading ? (
              <></>
            ) : (
              <ButtonWrapper>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedProject({ IDNo: 0 });
                  }}
                  id="create-btn"
                  title="Add New"
                  svgIcon={plusIcon}
                >
                  ADD <u>N</u>EW
                </Button>
              </ButtonWrapper>
            )}
          </CustomGrid>
        </div>
      </Form>
    </FormContainer>
  );
};

export default ProjectTypes;
