import { useMutation } from '@tanstack/react-query';
import { permsService } from '../perm/permsService';

export const useCreateGroup = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(
    async (params: any) => permsService.createGroup(params?.formattedData),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};

export const useUpdateGroup = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(async (params: any) => permsService.updateGroup(params), {
    onSuccess: () => {
      callback(true);
    },
  });
};

export const useDeleteGroupById = (
  callback: (shouldRefetch?: boolean) => void
) => {
  return useMutation(
    async (params: any) => {
      return await permsService.deleteGroupById(params.id);
    },
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};
