import React, { FC, useEffect, useRef, useState, useCallback } from 'react';
import { ICustomersProps, ICustomers } from './Customers.types';
import CustomersDialog from './CustomersDialog'
import CloseButton from '../../common/CloseButton';
import { ListingGrid, Button } from '../../common';
import { GridColumn } from '@progress/kendo-react-grid';
import { plusIcon } from '@progress/kendo-svg-icons';
import { importIcon } from '@progress/kendo-svg-icons';
import { CustomGrid } from '../../../themes/common.styled';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { columns } from './model';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent
} from '@progress/kendo-react-layout';
import {
  FormContainer,
  Form,
  ListingGridContainer,
  ButtonWrapper
} from './Customers.styled';

import { customFormService } from '../../../services/customFormService';
import { useKeyPress } from '../../../hooks';
import PageNavButton from '../../common/PageNavButton';
import CustomersImportModal from './CustomersImportDialog';
import * as XLSX from 'xlsx';

import { EntityImportItemType } from '../../../data/types';

type SelectionType = { field?: string; dataItem: any };

const Customers: FC<ICustomersProps> = ({
  title,
  onClose,
  onNextPage,
  onPreviousPage
}) => {
  const [dataTemplate, setDataTemplate] = useState<any[]>([])

  const [originData, setOriginData] = useState<ICustomers[]>([]);
  const [filterData, setFilterData] = useState<ICustomers[]>([]);
  const [importedData, setImportedData] = useState<EntityImportItemType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showContext, setShowContext] = useState<boolean>(false);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [selectedItem, setSelectedItem] = useState<SelectionType>();
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<{
    show: boolean;
    isEdit: boolean;
    data: ICustomers | null;
  }>({
    show: false,
    isEdit: false,
    data: null,
  });

  const inputFile = useRef<HTMLInputElement | null>(null);

  const offset = useRef({ left: 0, top: 0 });

  const fetchData = async () => {
    try {
      const customForms: any = await customFormService.getCustomForms()
      setOriginData(customForms ?? [])
      setFilterData(customForms ?? [])
      if (customForms) {
        setIsLoading(false)
      }

      const customFormsData: any = await customFormService.getSingleCustomForm(1)
      setDataTemplate(customFormsData)
    } catch (err) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOpenDialog = (isEdit: boolean, data: | null) => {
    setShowDialog({
      show: true,
      isEdit,
      data,
    });
  };

  const handleCloseDialog = (shouldRefetch?: boolean) => {
    setShowDialog({
      show: false,
      isEdit: false,
      data: null,
    });
    if (shouldRefetch) {
      fetchData();
    }
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {
    },
    () => {
    },
    () => {
    },
    () => {
      handleOpenDialog(false, null);
    }
  );

  const handleShowFileBrowser = () => {
    if (inputFile) {
      inputFile?.current?.click();
    }
  };

  const handleFileToJSON = (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e?.target?.result;
        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          const workbook = XLSX.read(data, { type: "array" });
          const fileName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[fileName];
          const jsonData: EntityImportItemType[] = XLSX.utils.sheet_to_json(sheet);
          setImportedData(jsonData);
        } else if (file.name.endsWith('.csv')) {
          const jsonData = csvToArray(data);
          setImportedData(jsonData);
        } else {
          console.error('Unsupported file type');
          return;
        }

        // reset input value and show modal
        const inputElement = document.getElementById('import-file') as HTMLInputElement;
        if (inputElement) {
          inputElement.value = '';
        }
        setShowImportModal(true);
      };

      // Read file as text or array buffer depending on file type
      if (file.name.endsWith('.csv')) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const csvToArray = (csvData: any) => {
    // Split CSV data into lines
    const lines = csvData.split(/\r\n|\n/);
    // Extract headers
    const headers = lines[0].split(',');
    // Initialize JSON array to hold data
    const jsonData: any[] = [];
    // Iterate over remaining lines
    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(',');
      const obj: { [key: string]: any } = {}; // Explicitly define obj type
      // Map data to headers
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = data[j];
      }
      jsonData.push(obj);
    }
    return jsonData;
  };

  return (
    <>
      <FormContainer>
        <Form>
          <div className="form-section-header-container">
            <div className="form-title">{title}</div>
            <div className="form-header-btn-wrapper">
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={onClose}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div className="form-content">
            <CustomGrid
              cols="800px"
              colGap="0px"
              width="auto"
              style={{ padding: '30px 0px 30px 30px' }}
            >
              <ListingGridContainer>
                {isLoading ? (
                  <></>
                ) : (
                  <ListingGrid
                    data={sort ? orderBy(filterData, sort) : filterData}
                    dataItemKey="FieldLabel"
                    onRowClick={(e) => {
                      setFilterData((prev) =>
                        prev.map((item) => ({
                          ...item,
                          selected: item.FieldLabel === e.dataItem.FieldLabel,
                        }))
                      );
                    }}
                    onRowDoubleClick={(e) => {
                      handleOpenDialog(true, e.dataItem);
                    }}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    onContextMenu={(event) => {
                      handleContextMenuOpen(event.syntheticEvent);
                      const { dataItem, field } = event;
                      setFilterData((prev) =>
                        prev.map((item) => ({
                          ...item,
                          selected: item.FieldLabel === dataItem.FieldLabel,
                        }))
                      );
                      setSelectedItem({ field, dataItem });
                    }}
                  >
                    {columns.map((column, index) => (
                      <GridColumn {...column} key={index} />
                    ))}
                  </ListingGrid>
                )}
                <ContextMenu
                  show={showContext}
                  offset={offset.current}
                  onSelect={handleOnSelect}

                  onClose={() => setShowContext(false)}
                  className="context-menu"
                >
                  <MenuItem
                    text="Sort Ascending"
                    data={{
                      action: 'sortAsc',
                    }}
                  />
                  <MenuItem
                    text="Sort Descending"
                    data={{
                      action: 'sortDesc',
                    }}
                  />
                  <MenuItem
                    text="Filter In Selected"
                    data={{
                      action: 'filterIn',
                    }}
                  />
                  <MenuItem
                    text="Filter Out Selected"
                    data={{
                      action: 'filterOut',
                    }}
                  />
                  <MenuItem
                    text="Remove All Filters"
                    data={{
                      action: 'removeFilters',
                    }}
                  />
                </ContextMenu>
              </ListingGridContainer>
              {isLoading ? (
                <></>
              ) : (
                <ButtonWrapper>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenDialog(false, null);
                    }}
                    id="create-btn"
                    title="Add New"
                    svgIcon={plusIcon}
                  >
                    ADD <u>N</u>EW
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowFileBrowser();
                    }}
                    id="import-btn"
                    title="Import"
                    svgIcon={importIcon}
                  >
                    IMPORT CUSTOMERS
                  </Button>
                  <input type='file' id='import-file' ref={inputFile} style={{ display: 'none' }} onChange={handleFileToJSON}
                    accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </ButtonWrapper>
              )}
            </CustomGrid>
          </div>
        </Form>
      </FormContainer>
      {showDialog.show && (
        <CustomersDialog
          isEdit={showDialog.isEdit}
          data={showDialog.data}
          handleClose={handleCloseDialog}
          dataTemplate={dataTemplate}
          dataList={originData}
        />
      )}
      {showImportModal && (
        <CustomersImportModal
          currentData={filterData}
          importedData={importedData}
          handleClose={() => setShowImportModal(false)}
        />
      )}
    </>
  );
};

export default Customers;