import React, { useState, useEffect, useRef } from 'react';
import { AppBar, AppBarSpacer } from '@progress/kendo-react-layout';
import { default as ProfileIcon } from '../../../assets/images/Profile_Icon.svg';
import ReplyIcon from '@mui/icons-material/Reply';

import { GenericDialog } from '../../common/GenericDialog/GenericDialog';
import { Popup } from '@progress/kendo-react-popup';
import LogoutIcon from '@mui/icons-material/Logout';
import { userLogout } from '../../../services/loginService';

const Header = () => {
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const handleLogout = async () => {
    try {
      const response = await userLogout();
      if (response.status === true) {
        window.localStorage.clear();
        window.sessionStorage.clear();

        let homeURL = `${process.env.REACT_APP_HOME_URL}?token=sessionloggedout`;

        window.open(homeURL, '_self');
      } else {
        console.log('Logout Error');
      }
    } catch (e) {
      console.log('Logout Error', e);
    }
  };

  const anchor = useRef<HTMLButtonElement>(null);
  const [show, setShow] = useState(false);

  const handleLogoutButton = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (anchor.current && !anchor.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const goToHome = () => {
    let homeURL = process.env.REACT_APP_HOME_URL;

    const ak = window.sessionStorage.getItem('SECRET_AK') ?? '';
    if (ak) {
      homeURL = `${homeURL}?token=${encodeURIComponent(ak)}`;
    }
    window.open(homeURL, '_self');
  };

  return (
    <header>
      <AppBar className="main-header">
        <AppBarSpacer />

        <div className="header-utils">
          <h1>System Setup</h1>
          <div className="top-bar-divider" />
          <button onClick={() => goToHome()}>
            <ReplyIcon style={{ width: '22px', color: '#528FCB' }} />
          </button>
          <button
            onClick={handleLogoutButton}
            ref={anchor}
            title="Proflie"
            className="profile-button"
          >
            {show}
            <img
              src={ProfileIcon}
              alt="Profile Icon"
              style={{ width: '16px', color: '#528FCB', margin: '4px' }}
            />
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={'popup-content'}
            className="rounded-md"
          >
            <div className="py-2">
              <div
                onClick={() => setShowLogoutModal(true)}
                className="flex flex-row w-full items-center 
                px-4 py-2 gap-2 h-[33.5px] leading-[33.5px]
                hover:bg-gray-200 hover:cursor-pointer
                focus:bg-gray-300 focus:outline-none"
              >
                <LogoutIcon fontSize='small' />
                <div>Log Out</div>
              </div>
            </div>
          </Popup>
        </div>
        {showLogoutModal && (
          <GenericDialog
            type="Confirmation"
            primaryMessage="Are you sure you want to Log Out?"
            onConfirmed={() => handleLogout()}
            onCancel={() => setShowLogoutModal(false)}
          />
        )}
      </AppBar>
    </header>
  );
};

export default Header;
