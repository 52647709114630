import { useMutation } from '@tanstack/react-query';
import { permsService } from '../perm/permsService';

export const useCreateRole = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(
    async (params: any) => permsService.createRole(params?.formattedData),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};

export const useUpdateRole = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(async (params: any) => permsService.updateRole(params), {
    onSuccess: () => {
      callback(true);
    },
  });
};
 
export const useDeleteRoleById = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(
    async (params: any) => await permsService.deleteRoleById(params.id),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};
