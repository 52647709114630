import { GridCellProps } from '@progress/kendo-react-grid';

export const NumericCell: React.FC<GridCellProps> = (props) => {
  const {
    ariaColumnIndex,
    columnIndex,
    dataItem,
    field,
    onContextMenu,
    className,
  } = props;

  if (!field) return null;

  return (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      className={className ? `k-table-td ${className}` : 'k-table-td'}
      role="gridcell"
      onContextMenu={(e) => onContextMenu?.(e, dataItem, field)}
    >
      {dataItem[field]?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      })}
    </td>
  );
};

export const WholeNumericCell: React.FC<GridCellProps> = (props) => {
  const {
    ariaColumnIndex,
    columnIndex,
    dataItem,
    field,
    onContextMenu,
    className,
  } = props;

  if (!field) return null;

  return (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      className={className ? `k-table-td ${className}` : 'k-table-td'}
      role="gridcell"
      onContextMenu={(e) => onContextMenu?.(e, dataItem, field)}
    >
      {dataItem[field]?.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </td>
  );
};
