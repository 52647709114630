import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
    ContextMenu,
    MenuItem,
    MenuSelectEvent,
} from '@progress/kendo-react-layout';

import { useKeyPress } from '../../../../hooks';

import { Button, ListingGrid } from '../../../common';
import { ItemsType } from '../../../../data/types';
import { columns } from './model';
import { ButtonWrapper, ListingGridWrapper } from '../ProductsAndServices.styled';
import { plusIcon } from '@progress/kendo-svg-icons';
import { bundlesService } from '../../../../services/productsAndServicesService';
import BundlesDialog from './BundlesDialog';

type SelectionType = { field?: string; dataItem: any };

interface ProductProps {
    selectedBundle: ItemsType | undefined;
    setSelectedBundle: React.Dispatch<
        React.SetStateAction<ItemsType | undefined>
    >;
};

const Bundles: FC<ProductProps> = ({
    selectedBundle,
    setSelectedBundle,
}) => {
    const [originData, setOriginData] = useState<ItemsType[]>([]);
    const [filterData, setFilterData] = useState<ItemsType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sort, setSort] = useState<SortDescriptor[]>();
    const [showContext, setShowContext] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<SelectionType>();
    const [bundleList, setBundleList] = useState<ItemsType[]>([]);

    const offset = useRef({ left: 0, top: 0 });

    async function fetchData() {
        try {
            const bundlesList: ItemsType[] = await bundlesService.getBundles();
            setBundleList(bundlesList)
            setOriginData(bundlesList ?? []);
            setFilterData(bundlesList ?? []);
        } catch (e) {
            console.log(e);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContextMenuOpen = (e: React.MouseEvent) => {
        e.preventDefault();
        offset.current = {
            left: e.pageX,
            top: e.pageY,
        };
        setShowContext(true);
    };

    const handleOnSelect = useCallback(
        (e: MenuSelectEvent) => {
            if (selectedItem) {
                const { field, dataItem } = selectedItem;
                switch (e.item.data.action) {
                    case 'sortAsc':
                        setSort([
                            {
                                dir: 'asc',
                                field: field ?? '',
                            },
                        ]);
                        break;
                    case 'sortDesc':
                        setSort([
                            {
                                dir: 'desc',
                                field: field ?? '',
                            },
                        ]);
                        break;
                    case 'filterIn':
                        if (field) {
                            setFilterData(
                                originData.filter(
                                    (data: any) => data[field] === dataItem[field]
                                )
                            );
                        }
                        break;
                    case 'filterOut':
                        if (field) {
                            setFilterData(
                                originData.filter(
                                    (data: any) => data[field] !== dataItem[field]
                                )
                            );
                        }
                        break;
                    case 'removeFilters':
                        setFilterData(originData);
                        break;
                    default:
                }
            }
            setShowContext(false);
        },
        [selectedItem, originData]
    );

    useKeyPress(
        () => {
        },
        () => {
        },
        () => {
        },
        () => {
            setSelectedBundle({ IDNo: 0 });
        }
    );

    return (
        <ListingGridWrapper>
            {isLoading ? (
                <></>
            ) : (
                <>
                    <ListingGrid
                        rowHeight={24}
                        data={sort ? orderBy(filterData, sort) : filterData}
                        dataItemKey="CategoryCode"
                        onRowClick={(e) => {
                            setFilterData((prev) =>
                                prev.map((item) => ({
                                    ...item,
                                    selected: item.IDNo === e.dataItem.IDNo,
                                }))
                            );
                        }}
                        onRowDoubleClick={(e) => {
                            setSelectedBundle(e.dataItem);
                        }}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                        onContextMenu={(event) => {
                            handleContextMenuOpen(event.syntheticEvent);
                            const { dataItem, field } = event;
                            setFilterData((prev) =>
                                prev.map((item) => ({
                                    ...item,
                                    selected: item.IDNo === dataItem.IDNo,
                                }))
                            );
                            setSelectedItem({ field, dataItem });
                        }}
                    >
                        {columns.map((column, index) => {
                            return (
                                <GridColumn
                                    {...column}
                                    key={index}
                                    sortable={true}
                                />
                            );
                        })}
                    </ListingGrid>
                    <ButtonWrapper>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedBundle({ IDNo: 0 });
                            }}
                            id="create-btn"
                            title="Add New"
                            svgIcon={plusIcon}
                        >
                            ADD <u>N</u>EW
                        </Button>
                    </ButtonWrapper>
                </>
            )}

            <ContextMenu
                show={showContext}
                offset={offset.current}
                onSelect={handleOnSelect}
                onClose={() => setShowContext(false)}
                className="context-menu"
            >
                <MenuItem
                    text="Sort Ascending"
                    data={{
                        action: 'sortAsc',
                    }}
                />
                <MenuItem
                    text="Sort Descending"
                    data={{
                        action: 'sortDesc',
                    }}
                />
                <MenuItem
                    text="Filter In Selected"
                    data={{
                        action: 'filterIn',
                    }}
                />
                <MenuItem
                    text="Filter Out Selected"
                    data={{
                        action: 'filterOut',
                    }}
                />
                <MenuItem
                    text="Remove All Filters"
                    data={{
                        action: 'removeFilters',
                    }}
                />
            </ContextMenu>

            {selectedBundle && (
                <BundlesDialog
                    handleClose={(shouldRefreshData?: boolean) => {
                        setSelectedBundle(undefined);
                        if (shouldRefreshData) {
                            fetchData();
                        }
                    }}
                    data={selectedBundle}
                    isEdit={selectedBundle?.CategoryCode ? true : false}
                    bundleList={bundleList}
                />
            )}

        </ListingGridWrapper>
    );
};

export default Bundles;