import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';

import { HolidaysDialogProps } from './HolidaysDialog.types';
import { Button, CustomColComboBox, InputWrapper } from '../../../../common';
import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validateHolidayForm } from '../../../../../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  ButtonWrapper,
  DialogContent,
  StyledTitle,
} from './HolidaysDialog.styled';
import { holidaysService } from '../../../../../services/holidaysService';
import { getValueFromMonth } from '../apiTranslators';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MoveableDialog from '../../../../common/MovableDialog';
import { DAYS_IN_MONTH } from '../../../../../constants';

const getDates = (currentMonth: number) => {
  const currentYear = new Date().getFullYear();
  const totalDays = [];
  const numberOfDays = new Date(currentYear, currentMonth, 0).getDate();

  for (let day = 1; day < numberOfDays + 1; day++) {
    totalDays.push(String(day));
  }
  return totalDays.map((day) => ({ key: day, value: day }));
};

const HolidaysDialog: React.FC<HolidaysDialogProps> = ({
  inputDefaults,
  isEdit,
  data,
  dataTemplate,
  handleClose,
}) => {
  const dayOfWeek = dataTemplate?.dayOfWeek ?? [];
  const weekOfMonth = dataTemplate?.weekOfMonth ?? [];
  const months = dataTemplate?.months ?? [];

  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    IDNo: isEdit && data ? data.IDNo : 0,
    Company_ID: isEdit && data ? data.Company_ID : 0,
    PayCode: isEdit && data.PayCode ? data.PayCode : '',
    PayDesc: isEdit && data.PayDesc ? data.PayDesc : '',
    SystemYN: isEdit && data.SystemYN ? data.SystemYN : inputDefaults?.SystemYN,
    Month: isEdit && data.Month ? data.Month : inputDefaults?.Month,
    DateOfMonth:
      isEdit && data.DateOfMonth
        ? DAYS_IN_MONTH.find((day) => Number(day.key) === data.DateOfMonth)
        : null,
    DayOfWeek:
      isEdit && data.DayOfWeek
        ? dayOfWeek.find((day) => day.key === data.DayOfWeek)
        : inputDefaults?.DayOfWeek,
    WeekOfMonth:
      isEdit && data.WeekOfMonth
        ? weekOfMonth.find((week) => week.key === data.WeekOfMonth)
        : inputDefaults?.WeekOfMonth,
  });
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const currentMonthDays = getDates(getValueFromMonth(months, formState.Month));
  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput('code');
  useDisableEscape(dialogRef);
  useDisableRightClick(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();
    try {
      const payLoad = {
        PayCode: formState.PayCode,
        PayDesc: formState.PayDesc,
        SystemYN: formState.SystemYN,
        Month: getValueFromMonth(months, formState.Month),
        DayOfWeek:
          dayOfWeek.find((day) => day === formState.DayOfWeek)?.key || '',
        DateOfMonth:
          DAYS_IN_MONTH.find(
            (day) => Number(day?.key) === Number(formState?.DateOfMonth?.key)
          )?.key || null,
        WeekOfMonth:
          weekOfMonth.find((week) => week === formState.WeekOfMonth)?.key || '',
      };

      setIsSaving(true);
      const saved = data.IDNo
        ? await holidaysService.updateHoliday(
            formState.IDNo,
            payLoad,
            formState.PayCode
          )
        : await holidaysService.createHoliday(payLoad, formState.PayCode);

      if (saved) {
        setIsSaving(false);
        handleClose(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo) {
        setIsDeleting(true);
        const status = await holidaysService.deleteHoliday(formState.IDNo);

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event:
      | InputChangeEvent
      | NumericTextBoxChangeEvent
      | ComboBoxChangeEvent
      | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    if (key === 'PayCode') {
      const upperInputValue = event.value.toUpperCase();
      setFormState((prevState) => ({
        ...prevState,
        [key]: upperInputValue?.replace(/[^a-zA-Z0-9]/g, ''),
      }));
    } else if (key === 'Month') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value.month,
      }));
    } else if (key === 'DateOfMonth') {
      if (event.value) {
        setFormState((prevState) => ({
          ...prevState,
          [key]: event.value,
          DayOfWeek: '',
          WeekOfMonth: '',
        }));
      }
    } else if (key === 'DayOfWeek' || key === 'WeekOfMonth') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
        DateOfMonth: null,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>Setup Holidays</span>
      </StyledTitle>
    );
  };

  useEffect(() => {
    setIsValid(validateHolidayForm(formState));
  }, [formState]);

  return (
    <MoveableDialog
      onClose={() => handleClose()}
      className="holiday-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <DialogContent>
        <ReactFocusLock>
          <div className="content-wrapper">
            <div
              className="form-fields"
              ref={formRef}
              onKeyDown={(e) => handleKeyDown(e, formRef)}
            >
              <div className="fields-row">
                <InputWrapper
                  maxWidth="100px"
                  width="100px"
                  label="Code"
                  name="PayCode"
                  onChange={handleOnChange}
                  value={formState.PayCode}
                  maxLength={6}
                  id="code"
                  autoComplete="off"
                  isDisabled={data.IDNo} // disable if existing form
                />
                <InputWrapper
                  maxWidth="200px"
                  width="200px"
                  label="Holiday"
                  name="PayDesc"
                  onChange={handleOnChange}
                  value={formState.PayDesc}
                  maxLength={25}
                  id="code"
                  autoComplete="off"
                />
              </div>

              <div className="date-field">
                <div className="date-field-left">
                  <div className="month-input-field">
                    <label>Month</label>
                    <CustomColComboBox
                      dataList={months}
                      width={118}
                      name="Month"
                      comboBoxType="MONTH_VALUE"
                      onChange={(e) => handleOnChange(e, 'Month')}
                      defaultValue={formState.Month}
                      value={months.find((m) => m.month === formState.Month)}
                      allowBlank={false}
                      filterable
                    />
                  </div>
                  <div className="date-input-field">
                    <label>Date</label>
                    <CustomColComboBox
                      dataList={currentMonthDays}
                      width={80}
                      name="DateOfMonth"
                      comboBoxType="KEY_VALUE"
                      onChange={(e) => handleOnChange(e, 'DateOfMonth')}
                      defaultValue={formState.DateOfMonth}
                      value={formState.DateOfMonth}
                      allowBlank={false}
                      filterable
                    />
                  </div>
                </div>

                <div className="date-field-label">
                  <label>OR:</label>
                </div>

                <div className="date-field-right">
                  <CustomColComboBox
                    dataList={weekOfMonth}
                    width={140}
                    name="WeekOfMonth"
                    comboBoxType="KEY_VALUE"
                    onChange={(e) => handleOnChange(e, 'WeekOfMonth')}
                    defaultValue={formState.WeekOfMonth}
                    value={formState.WeekOfMonth}
                    allowBlank={false}
                    filterable
                    label="Week Of Month"
                  />
                  <CustomColComboBox
                    dataList={dayOfWeek}
                    width={140}
                    name="DayOfWeek"
                    comboBoxType="KEY_VALUE"
                    onChange={(e) => handleOnChange(e, 'DayOfWeek')}
                    defaultValue={formState.DayOfWeek}
                    value={formState.DayOfWeek}
                    filterable
                    label="Day Of Week"
                  />
                </div>
              </div>
            </div>
            <ButtonWrapper>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
                disabled={!isValid || isSaving || isDeleting || !isChanged}
                id="save-btn"
                title="Save"
                onKeyDown={(event) => {
                  if (
                    event.key === 'Enter' &&
                    (isValid || !isSaving || !isDeleting || isChanged)
                  ) {
                    event.preventDefault();
                    handleSave();
                  }
                }}
              >
                {isSaving ? (
                  'Saving...'
                ) : isDeleting ? (
                  'Deleting'
                ) : (
                  <>
                    <u>S</u>ave
                  </>
                )}
              </Button>
              <Button
                onClick={() => handleClose()}
                type="button"
                id="cancel-btn"
                title="Cancel"
                disabled={isSaving || isDeleting}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && (!isSaving || !isDeleting)) {
                    event.preventDefault();
                    handleClose();
                  }
                }}
              >
                <u>C</u>ancel
              </Button>
              {isEdit && (
                <Button
                  onClick={() => toggleDialog()}
                  type="button"
                  id="delete-btn"
                  title="Delete"
                  tabIndex={-1}
                >
                  <u>D</u>elete
                </Button>
              )}
            </ButtonWrapper>
          </div>
        </ReactFocusLock>
      </DialogContent>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => handleOnDelete()}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MoveableDialog>
  );
};

export default HolidaysDialog;
