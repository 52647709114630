import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const accountsService = {
  getAccountsByType: async (accountType: string = 'Bank') => {
    const response = await axiosClient().get('GLAccount/GetList', {
      params: {
        Status: 'All',
      },
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
  getAccountsList: async (accountNo: string) => {
    const response = await axiosClient().get(`GLAccount`, {
      params: {
        AccountNo: accountNo || 'accountNo',
      },
      headers: { ...commonHeaders },
    });
    return response.data.data;
  },
  getAccountsByGroup: async (accountGroup: string = 'Income') => {
    const response = await axiosClient().get('GLAccount/GetList', {
      params: {
        GLAccountGroup: accountGroup,
        Status: 'All',
      },
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
  getAccounts: async (
    accountType = 'All',
    accountGroup = 'All',
    status = 'Enabled'
  ) => {
    const response = await axiosClient().get(`GLAccount/GetList`, {
      params: {
        GLAccountType: accountType,
        GLAccountGroup: accountGroup,
        Status: status,
      },
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  createAccount: (accountNo: string, payload: any) =>
    axiosClient().post(
      'GLAccount',
      { ...payload },
      {
        params: {
          AccountNo: accountNo,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateAccount: (accountNo: string, payload: any) => {
    return axiosClient().patch(
      'GLAccount',
      { ...payload },
      {
        params: {
          AccountNo: accountNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  getSetupCOA: async (status: string) => {
    const response = await axiosClient().get(`GLAccount/GetSetupCOA?Status=${status}`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  importAccounts: async (payload: any[]) => {
    const formData = new FormData();

    payload.forEach((item) => {
      formData.append('COAList', JSON.stringify(item));
    });
    
    const response = await axiosClient().post(
      'Setup/Import/ImportChartOfAccounts',
      formData,
      {
        headers: {
          ...commonHeaders,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  },
  deleteAccountById: (accountNo: string) =>
    axiosClient().delete('GLAccount', {
      params: {
        AccountNo: accountNo,
      },
      headers: { ...commonHeaders },
    }),
};
