import styled from 'styled-components';

export const FormContainer = styled.div`
  display: block;
  position: relative;
  width: calc(100% - 20px);
  padding: 10px;

  .table-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    height: 40px;

    span {
      text-wrap: wrap;
      white-space: normal;
      display: block;
      text-align: center;
    }
  }
`;

export const Form = styled.form`
  border: 1px solid #d6d6d6;
  height: calc(100vh - 116px);

  .form-section-header-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(209 213 219 / 1);
    border-color:;
    background-color: rgb(250 250 250 / 1);
    padding-left: 1.25rem;
    padding-right: 0.375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    .form-title {
      font-weight: 600;
    }

    .form-header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .listing-action-button {
        background-color: transparent;
        border: 1px solid rgb(209, 213, 219);
        cursor: pointer;
        padding: 4px;
        display: flex;
        height: 30px;
        width: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        color: rgb(51 51 51 / 1);

        &:hover {
          border-color: rgb(44 80 152 / 1);
          color: rgb(44 80 152 / 1);
        }
      }

      .listing-action-seperate-line {
        height: 30px;
        width: 1px;
        background-color: rgb(209, 213, 219);
      }
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 75px);
    overflow-y: hidden;
    box-sizing: border-box;
  }

  .form-footer {
    padding: 15px 20px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const ListingGridContainer = styled.div`
height: calc(100vh - 278px);
max-height:calc(100vh);
overflow: hidden;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-start;
  gap: 8px;
  padding: 10px;
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  border-bottom: 2px solid #2c5098;

  #create-btn {
    width: 112px;
  }
`;

export const FileUploadWrapper = styled.div`
  width: 600px;
  margin: 0px auto;
  padding: 20px 20px 0px 20px;

  .k-upload-dropzone {
    height: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid #c3c3c3;
    background: #f6f5f6;
  }

  .k-upload-status {
    position: absolute !important;
    display: none !important;
    bottom: 14px;
  }

  .k-dropzone-hint {
    display: none;
  }

  .k-button {
    border: none;
    background: none;

    &:focus {
      box-shadow: none !important;
    }
  }

  .upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 28px;
    }

    .k-i-upload {
      margin-bottom: 28px;
      font-size: 46px;
      color: #b4b4bb;
    }

    .bold-message {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 1rem;
      text-transform: none;
    }

    .select-message {
      font-size: 12px;
      color: #2c5098;
      border: 1px solid transparent;
      padding: 0px 4px;
      border-radius: 4px;
      text-transform: none;
    }
  }

  .file-type-message {
    text-align: center;
    color: #878787;
    margin-top: 1rem;
    font-size: 11px;
  }

  .image-size-message {
    text-align: center;
    margin-top: 12px;
    font-size: 11px;
    color: #424242;
  }

  .k-upload-button-wrap button:focus {
    .select-message {
      border-color: #2c5098;
    }
  }
`;