import React, { useEffect, useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { ICustomersDialogProps } from './CustomersDialog.types';
import { CustomFieldsFormState } from '../../../../data/types';
import { handleKeyDown, validateCustomerForm } from '../../../../utils';
import { customFormService } from '../../../../services/customFormService';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress
} from '../../../../hooks';

import {
  InputChangeEvent,
  NumericTextBox,
  TextArea,
  NumericTextBoxChangeEvent,
  TextAreaChangeEvent,
} from '@progress/kendo-react-inputs';

import {
  Button,
  InputWrapper,
  CustomColComboBox,
} from '../../../common';

import {
  DialogContent,
  CustomButtonWrapper,
  LeftLabelInputWrapper,
  LabelWrapper,
} from './CustomersDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import MovableDialog from '../../../common/MovableDialog';

const PaymentTermsCodesDialog: React.FC<ICustomersDialogProps> = ({
  isEdit,
  data,
  handleClose,
  dataList,
  dataTemplate
}) => {
  const [errorText, setErrorText] = useState('')
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState<CustomFieldsFormState>({
    FieldType: isEdit && data ? data.FieldType : '',
    FieldName: isEdit && data ? data.FieldName : '',
    FieldLabel: isEdit && data ? data.FieldLabel : '',
    ListData: isEdit && data ? data.ListData : '',
    MaxCharacters: isEdit && data ? (data.FieldType === 'Year' ? 4 : Number(data.MaxCharacters)) : 0,
    MinDecimals: isEdit && data ? Number(data.MinDecimals) : 0,
    MaxDecimals: isEdit && data ? Number(data.MaxDecimals) : 0,
    RequiredYN: isEdit && data ? data.RequiredYN : false
  })
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput('first-input');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useEffect(() => {
    setIsValid(validateCustomerForm(formState));
  }, [formState]);

  const handleOnChange = (
    event: InputChangeEvent | NumericTextBoxChangeEvent | TextAreaChangeEvent | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    // replaces spaces and special characters in FieldName input field
    if (key === 'FieldName') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: (event.value as string)?.replace(/[^a-zA-Z0-9]/g, '')
      }))
    } else if (key === 'FieldType') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value.FieldType,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
    if (key === 'FieldType' && event.value.FieldType === 'Checkbox') {
      setFormState((prev) => ({
        ...prev,
        ListData: '',
        MinDecimals: 0,
        MaxDecimals: 0,
        MaxCharacters: 0,
      }));
    } else if (key === 'FieldType' && event.value.FieldType === 'Dropdown') {
      // let formattedValues = event.value.split(';').map((option: any) => option.trim());
      setFormState((prev) => ({
        ...prev,
        MinDecimals: 0,
        MaxDecimals: 0,
        MaxCharacters: 0,
      }));
    } else if (key === 'FieldType' && event.value.FieldType === 'Year') {
      setFormState((prev) => ({
        ...prev,
        MaxCharacters: 4,
      }));
    }
  };

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose(true);
    },
    () => {
      toggleDialog();
    }
  );

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.FieldName) {
        setIsSaving(true);
        const payload = {
          FieldType: formState.FieldType,
          FieldName: formState.FieldName,
          FieldLabel: formState.FieldLabel,
          MaxCharacters: formState.MaxCharacters,
          MinDecimals: formState.MinDecimals,
          MaxDecimals: formState.MaxDecimals,
          RequiredYN: formState.RequiredYN,
          ListData: formState.ListData,
        };

        if (!data?.IDNo) {
          if (dataList.length < 8) {
            const saved = await customFormService.createCustomForm(payload);

            if (saved) {
              setIsSaving(false);
              handleClose(true);
            }
          } else {
            setErrorText('There can only be 8 records');
            setIsSaving(false);
          }
        } else {
          const saved = await customFormService.updateCustomForm(data.IDNo, payload);

          if (saved) {
            setIsSaving(false);
            handleClose(true);
          }
        }
      }
    } catch (err) {
      console.error(err);
      setErrorText('An error occurred');
      setIsSaving(false);
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.FieldName) {
        setIsDeleting(true);
        const status = await customFormService.deleteCustomForm(data?.IDNo);

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };


  // disables input depending on Field Type
  const disableInput = (state: string | number, inputType: string) => {
    return state !== inputType;
  }

  // gets the maxLength of characters for input
  const getMaxLength = (inputName: string) => {
    return dataTemplate.fieldProperties.find((prop: any) => prop.columnName === inputName).maxLength
  }

  return (
    <MovableDialog
      title="Setup Custom Field - Customers"
      onClose={() => handleClose()}
      className="payment-terms-codes-dialog"
      ref={dialogRef}
    >
      <DialogContent>
        <ReactFocusLock>
          <div
            className="form-fields"
            ref={formRef}
            onKeyDown={(e) => handleKeyDown(e, formRef, formState.FieldType === 'Dropdown')}
          >
            <LeftLabelInputWrapper>
              <label>Select a Field Type from the list</label>
              <CustomColComboBox
                id="first-input"
                name="FieldType"
                width={130}
                onChange={(e) => {
                  handleOnChange(e, 'FieldType')
                }}
                filterable={true}
                comboBoxType='CustomFieldTypes'
                dataList={dataTemplate?.combos?.CustomFieldTypes}
                value={dataTemplate?.combos?.CustomFieldTypes.find((type: any) =>
                  type.FieldType === formState.FieldType
                )}
                defaultValue={formState.FieldType}
              />
            </LeftLabelInputWrapper>
            <LeftLabelInputWrapper>
              <LabelWrapper>
                <label>The name of this custom field</label>
                <p>50 characters max, no special characters, no spaces</p>
              </LabelWrapper>
              <InputWrapper
                maxWidth="400px"
                width="400px"
                name="FieldName"
                onChange={handleOnChange}
                value={formState.FieldName}
                maxLength={getMaxLength('FieldName')}
                autoComplete="off"
                spellCheck={false}
              />
            </LeftLabelInputWrapper>
            <LeftLabelInputWrapper>
              <LabelWrapper>
                <label>Description shown for this custom field</label>
                <p>50 characters max</p>
              </LabelWrapper>
              <InputWrapper
                maxWidth="400px"
                width="400px"
                name="FieldLabel"
                onChange={handleOnChange}
                value={formState.FieldLabel}
                maxLength={getMaxLength('FieldLabel')}
                autoComplete="off"
                spellCheck={false}
              />
            </LeftLabelInputWrapper>
            <LeftLabelInputWrapper>
              <LabelWrapper>
                <label>Values to display in dropdown</label>
                <p>- Up to 25 items of up to 50 charactesrs each</p>
                <p>- Use ; between each item</p>
                <p>- no other special characters</p>
              </LabelWrapper>
              <TextArea
                name='ListData'
                value={formState.ListData ?? ''}
                onChange={(e) => {
                  handleOnChange(e, 'ListData')
                }}
                fillMode="solid"
                rows={5}
                aria-autocomplete="none"
                spellCheck={false}
                style={{
                  width: '400px',
                  resize: 'none'
                }}
                disabled={disableInput(formState.FieldType, 'Dropdown')}
              />
            </LeftLabelInputWrapper>
            <LeftLabelInputWrapper>
              <label>Min/Max decimals for numeric fields</label>
              <div className='minMaxWrapper'>
                <NumericTextBox
                  spinners={false}
                  inputStyle={{ textAlign: 'right' }}
                  name="MinDecimals"
                  label="Min"
                  value={Number(formState?.MinDecimals) || 0}
                  style={{ width: '80px' }}
                  onChange={(e) => {
                    handleOnChange(e, 'MinDecimals');
                  }}
                  disabled={disableInput(formState.FieldType, 'Number')}
                />
                <NumericTextBox
                  spinners={false}
                  inputStyle={{ textAlign: 'right' }}
                  name="MaxDecimals"
                  label="Max"
                  value={Number(formState?.MaxDecimals) || 0}
                  style={{ width: '80px' }}
                  onChange={(e) => {
                    handleOnChange(e, 'MaxDecimals');
                  }}
                  disabled={disableInput(formState.FieldType, 'Number')}
                />
              </div>
            </LeftLabelInputWrapper>
            <LeftLabelInputWrapper>
              <label>Max characters/whole numbers</label>
              <NumericTextBox
                spinners={false}
                name="Max Characters"
                value={(formState.FieldType === 'Year' ? 4 : formState.MaxCharacters) || 0}
                label="Max Char."
                inputStyle={{ textAlign: 'right' }}
                onChange={(e) => {
                  handleOnChange(e, 'MaxCharacters');
                }}
                style={{ width: '80px' }}
                format="d"
                max={formState.FieldType === 'Year' ? 4 : 999}
                disabled={
                  formState.FieldType === 'Year' ||
                  formState.FieldType === 'Checkbox' ||
                  formState.FieldType === 'Dropdown'
                }
              />
            </LeftLabelInputWrapper>
            {/* <LeftLabelInputWrapper className='RequiredInput'>
              <label>Is this field required?</label>
              <CustomColComboBox
                name="RequiredYN"
                width={80}
                dataList={[]}
                comboBoxType='CUSTOM_YesNo'
                onChange={(e) => {
                  setIsChanged(true)
                  setFormState((prevState: any) => ({
                    ...prevState,
                    RequiredYN: e.value === 'Yes' ? true : false,
                  }))
                }}
                value={formState.RequiredYN === true ? 'Yes' : 'No'}
                defaultValue={formState.RequiredYN}
              />
            </LeftLabelInputWrapper> */}
          </div>
          <CustomButtonWrapper>
            <Button
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
            >
              <>
                <u>S</u>ave
              </>
            </Button>
            <Button
              onClick={() => handleClose(true)}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </ReactFocusLock>
      </DialogContent>

      {visible && (
        <GenericDialog
          type='Confirmation'
          onCancel={() => setVisible(false)}
          onConfirmed={() => handleOnDelete()}
          confirmButtonText='Delete'
          primaryMessage='Delete record?'
        />
      )}
      {errorText &&
        <GenericDialog
          type='Error'
          onClose={() => {
            setErrorText('');
            setIsSaving(false);
          }}
          primaryMessage={errorText}
        />
      }
    </MovableDialog>
  );
};

export default PaymentTermsCodesDialog;
