import React, { FC } from 'react';
import { Input } from '@progress/kendo-react-inputs';

import { InputWrapperProps } from './InputWrapper.types';

const InputWrapper: FC<InputWrapperProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  isDisabled,
  maxLength,
  width,
  maxWidth,
  className,
  autoComplete,
  tabIndex,
  onFocus,
}) => {
  return (
    <Input
      style={{ width: width || '100%', maxWidth: maxWidth || '100%' }}
      name={name}
      label={label || ''}
      autoComplete={autoComplete || 'new-password'}
      value={value || ''}
      onChange={(e) => onChange(e, name)}
      maxLength={maxLength || 50}
      disabled={isDisabled || false}
      id={id || ''}
      className={className || ''}
      tabIndex={tabIndex || undefined}
      onFocus={onFocus}
      spellCheck={false}
    />
  );
};

export default InputWrapper;
