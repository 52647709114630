import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';
import { IFieldProperties, IFieldProperty } from '../../data/types';

export const locationsService = {
  getLocations: async () => {
    const response = await axiosClient().get('/Setup/Locations/GetList', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
  createLocation: (params: any) => {
    const id = params.LocCode;

    axiosClient().post(
      '/Setup/Locations',
      { ...params },
      {
        params: {
          LocCode: id,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  updateLocation: (params: any) => {
    const id = params.LocCode;

    return axiosClient().patch(
      '/Setup/Locations',
      { ...params },
      {
        params: {
          LocCode: id,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteLocationById: (id: number) =>
    axiosClient().delete('/Setup/Locations', {
      params: {
        LocCode: id,
      },
      headers: { ...commonHeaders },
    }),
  getLocationById: async (id: string) => {
    const response = await axiosClient().get('/Setup/Locations', {
      headers: { ...commonHeaders },
      params: {
        LocCode: id,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const fieldProperties: IFieldProperty[] =
      response?.data?.data?.fieldProperties;

    const fieldPropertiesObj: IFieldProperties = {};

    if (fieldProperties?.length) {
      fieldProperties.forEach((item) => {
        const { columnName, dataType, maxLength, decimalPlaces, required } =
          item;
        fieldPropertiesObj[columnName] = {
          dataType,
          maxLength,
          decimalPlaces,
          required,
        };
      });
    }

    return {
      record: response?.data?.data?.record,
      fieldProperties: fieldPropertiesObj || {},
      combosData: response?.data?.data?.combos,
    };
  },
};
