import axios from 'axios';

export const axiosClient = () =>
  axios.create({
    baseURL: window.sessionStorage.getItem(`apiURL`) + '/v3' ?? '',
    headers: {
      'Content-Type': 'application/json',
      AK: window.sessionStorage.getItem('SECRET_AK'),
      User: window.sessionStorage.getItem('SECRET_USERNAME'),
      ClientTime: new Date().toISOString(),
    },
  });