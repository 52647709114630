import styled from 'styled-components';

export const DialogContent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .form-fields {
    display: flex;
    flex-direction: column;
    padding: 12px 20px 20px 20px;
    border-bottom: 2px solid #d6d6d6;
    height: 80px;
    box-sizing: content-box;

    .row {
      display: flex;
      gap: 8px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  flex-direction: column;

  button {
    margin-bottom: 12px;

    &:last-child {
      margin-left: 0px;
    }
  }
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0px !important;
  }
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;

  column-gap: 8px;
`