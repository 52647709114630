import styled from 'styled-components';

export const DialogOverlay = styled.div`
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000000;
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  font-family: 'Inter', sans-serif;
`;

export const PrimaryMessage = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  text-align: center;
  user-select: none;
`;
export const SecondaryMessage = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: center;
  user-select: none;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 0.75rem;

  .blue-button,
  .green-button,
  .red-button,
  .gray-button {
    cursor: pointer;
    color: white;
    font-weight: bold;
    min-width: 100px;
    min-height: 50px;
    text-transform: uppercase;
    &:focus {
      outline: 4px solid lightblue;
    }
  }

  .blue-button {
    background-color: #3b82f6;

    &:hover {
      background-color: #1d4ed8;
    }
  }

  .red-button {
    background-color: #e40000;
    &:hover {
      background-color: #990000;
    }
  }
  .green-button {
    background-color: #24a23b;
    &:hover {
      background-color: #186b27;
    }
  }

  .gray-button {
    background-color: #6b7280;
    &:hover {
      background-color: #1f2937;
    }
  }
`;

export const DialogButton = styled.button`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 5px;
  border: none;
`;

// DialogContent.Icon = ({ type }: { type: GenericDialogType }) => {

//   return <>
//     {type === "Message" && <InfoOutlinedIcon sx={{ fontSize: 50 }} className='text-blue-500' />}
//     {type === "Error" && <ErrorOutlineOutlinedIcon sx={{ fontSize: 50 }} className='text-red-500' />}
//     {(type === "Confirmation" || type === "UnsavedClose") && <HelpOutlineOutlinedIcon sx={{ fontSize: 50 }} className='text-blue-500' />}
//   </>
// }
