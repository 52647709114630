import React, { FC, useRef, useState } from 'react';

import { ITemplates } from './Templates.types';
import { useDisableRightClick } from '../../../hooks';

import { FormContainer, Form, TabsContainer, Tab } from './Templates.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';

import Template from './Tabs/TemplateSalesProposal';

const Templates: FC<ITemplates> = ({
  onClose,
  onNextPage,
  onPreviousPage,
  title,
  resultData,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState<number>(0);
  // const [selectedStatusCode, setSelectedStatusCode] = useState<ISalesStatusCodeType>();

  useDisableRightClick(containerRef);

  const handleCurrentTab = (e: React.MouseEvent, tabIndex: number) => {
    e.preventDefault();
    setCurrentTab(tabIndex);
  };

  return (
    <>
      <FormContainer ref={containerRef}>
        <Form>
          <div className="form-section-header-container">
            <div className="form-title">{title}</div>
            <div className="form-header-btn-wrapper">
              <div className="listing-action-seperate-line" />
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={onClose}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div className="form-content">
            <CustomGrid
              cols="1fr"
              colGap="0px"
              width="830px"
              style={{ padding: '30px 0px 30px 30px' }}
            >
              <TabsContainer>
                <Tab
                  style={{ width: '112px' }}
                  isActive={currentTab === 0}
                  onClick={(e) => handleCurrentTab(e, 0)}
                >
                  Sales Proposal
                </Tab>
                <Tab
                  style={{ width: '112px' }}
                  isActive={currentTab === 1}
                  onClick={(e) => handleCurrentTab(e, 1)}
                >
                  Sales Order
                </Tab>
                <Tab
                  style={{ width: '112px' }}
                  isActive={currentTab === 2}
                  onClick={(e) => handleCurrentTab(e, 2)}
                >
                  Purchase Order
                </Tab>
                <Tab
                  style={{ width: '112px' }}
                  isActive={currentTab === 3}
                  onClick={(e) => handleCurrentTab(e, 3)}
                >
                  Invoice - Open
                </Tab>
                <Tab
                  style={{ width: '112px' }}
                  isActive={currentTab === 4}
                  onClick={(e) => handleCurrentTab(e, 4)}
                >
                  Invoice - Paid
                </Tab>
                <Tab
                  style={{ width: '112px' }}
                  isActive={currentTab === 5}
                  onClick={(e) => handleCurrentTab(e, 5)}
                >
                  Bill Payment
                </Tab>
                <Tab
                  style={{ width: '112px' }}
                  isActive={currentTab === 6}
                  onClick={(e) => handleCurrentTab(e, 6)}
                >
                  Pay Settlement
                </Tab>
              </TabsContainer>
              {currentTab === 0 && (
                <Template docCode="PROP" handleOnClose={onClose} />
              )}
              {currentTab === 1 && (
                <Template docCode="SALORD" handleOnClose={onClose} />
              )}
              {currentTab === 2 && (
                <Template docCode="PURORD" handleOnClose={onClose} />
              )}
              {currentTab === 3 && (
                <Template docCode="INVOP" handleOnClose={onClose} />
              )}
              {currentTab === 4 && (
                <Template docCode="INVPD" handleOnClose={onClose} />
              )}
              {currentTab === 5 && (
                <Template docCode="BILPMT" handleOnClose={onClose} />
              )}
              {currentTab === 6 && (
                <Template docCode="PAYSET" handleOnClose={onClose} />
              )}
            </CustomGrid>
          </div>
        </Form>
      </FormContainer>
    </>
  );
};

export default Templates;
