import React, { useEffect, useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import {
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
  NumericTextBoxHandle,
} from '@progress/kendo-react-inputs';
import { ComboBoxChangeEvent, MultiColumnComboBoxHandle } from '@progress/kendo-react-dropdowns';

import { IPTOCodesDialogProps, PTOFormState } from './TimeOffCodes.types';

import { Button, InputWrapper, CustomColComboBox } from '../../../../common';
// import CustomizedDatePicker, { CustomDatePickerChangeEvent } from '../../../../common/CustomizedDatePicker';
// import moment from 'moment';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validatePTOCodesForm } from '../../../../../utils';
import { PTOCodesService } from '../../../../../services/setup/PTOCodesService';

import { ButtonWrapper, DialogContent } from './TimeOffCodes.styled';
import { StyledTitle } from './TimeOffCodes.styled';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MoveableDialog from '../../../../common/MovableDialog';
import {
  PTO_UNITS,
  PTO_DAYS_IN_MONTH,
  PTO_MONTHS,
  PTO_PERIODS,
  PTO_ACCRUAL_START
} from '../../../../../constants';

const PTOCodesDialog: React.FC<IPTOCodesDialogProps> = ({
  isEdit,
  data,
  handleClose,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState<PTOFormState>({
    PayCode: isEdit && data ? data.PayCode : '',
    PayDesc: isEdit && data ? data.PayDesc : '',
    AccruePaidYN: isEdit && data ? data.AccruePaidYN : false,
    AccrueYN: isEdit && data ? data.AccrueYN : false,
    PTOQty: isEdit && data ? data.PTOQty : null,
    PTOUnits: isEdit && data ? PTO_UNITS.find((option) => option.name === data.PTOUnits)?.key : 'D',
    IntervalQty: isEdit && data ? data.IntervalQty : null,
    IntervalPeriods: isEdit && data ? data.IntervalPeriods : 'Y',
    AccrualStarts: isEdit && data ? PTO_ACCRUAL_START.find((option) => option.name === data.AccrualStarts)?.key : 'D',
    AccrualMonth: isEdit && data ? data.AccrualMonth : null,
    AccrualDay: isEdit && data ? data.AccrualDay : null,
    TenureQty: isEdit && data ? data.TenureQty : null,
    TenurePeriods: isEdit && data ? PTO_PERIODS.find((option) => option.name === data.TenurePeriods)?.key : 'M',
    MaxRollover: isEdit && data ? data.MaxRollover : null,
    RollOverPeriods: isEdit && data ? PTO_PERIODS.find((option) => option.name === data.TenurePeriods)?.key : 'Y',
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const addAccrualInputRef = useRef<NumericTextBoxHandle>(null);
  const accrueYNInputRef = useRef<MultiColumnComboBoxHandle>(null);

  const [codeInUse, setCodeInUse] = useState<boolean>(false);

  useHighlightInput('effective-date');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    setIsValid(validatePTOCodesForm(formState));
  }, [formState]);

  useEffect(() => {
    const fetchPTOCode = async () => {
      const PTOCodeData = await PTOCodesService.getSinglePTOCode(data.PayCode);
      setCodeInUse(PTOCodeData.bPayCodeInUse);

      const payDescInput = document.getElementById('PayDesc');
      if (PTOCodeData.bPayCodeInUse) {
        payDescInput?.focus();
      }
    };
    fetchPTOCode();
  }, [data.PayCode]);

  useEffect(() => {
    if(formState.AccrueYN){
      addAccrualInputRef.current?.focus();
    }
  }, [formState.AccrueYN])

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayCode) {
        setIsSaving(true);

        const isAccrue = formState.AccrueYN === true

        const payload = {
          PayCode: formState.PayCode,
          PayDesc: formState.PayDesc,
          AccruePaidYN: formState.AccruePaidYN,
          AccrueYN: formState.AccrueYN,
          PTOQty: isAccrue ? formState.PTOQty : null,
          PTOUnits: isAccrue ? formState.PTOUnits : '',
          IntervalQty: isAccrue ? formState.IntervalQty : null,
          IntervalPeriods: isAccrue ? formState.IntervalPeriods : '',
          AccrualStarts: isAccrue ? formState.AccrualStarts : '',
          AccrualMonth: isAccrue ? formState.AccrualMonth : null,
          AccrualDay: isAccrue ? formState.AccrualDay : null,
          TenureQty: formState.TenureQty,
          TenurePeriods: formState.TenurePeriods,
          MaxRollover: isAccrue ? formState.MaxRollover : null,
          RollOverPeriods: isAccrue ? formState.RollOverPeriods ?? "Y" : '',
        };

        const saved = data.PayCode
          ? await PTOCodesService.updatePTOCode(data.PayCode, payload)
          : await PTOCodesService.createPTOCode(formState.PayCode, payload);

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayCode) {
        setIsDeleting(true);
        const status = await PTOCodesService.deletePTOCodeById(
          formState.PayCode
        );

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    // event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent | CustomDatePickerChangeEvent,
    event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    if (key === 'PayCode') {
      const upperInputValue = event.value.toUpperCase();
      setFormState((prevState) => ({
        ...prevState,
        [key]: upperInputValue?.replace(/[^a-zA-Z0-9]/g, ''),
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>Setup Time-Off Code</span>
      </StyledTitle>
    );
  };

  const generateDaysInMonth = (selectedMonthKey: number) => {
    const selectedMonth = PTO_MONTHS.find((month) => month.key === selectedMonthKey);
    if (selectedMonth) {
      const daysInMonth = new Date(new Date().getFullYear(), selectedMonthKey, 0).getDate();
      const daysArray = [];
      for (let i = 1; i <= daysInMonth; i++) {
        daysArray.push({ key: i, name: `${i}` });
      }
      return daysArray;
    } else {
      return PTO_DAYS_IN_MONTH;
    }
  };

  return (
    <MoveableDialog
      onClose={() => handleClose()}
      className="pto-code-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <DialogContent>
        <ReactFocusLock>
          <div
            className="form-fields"
            ref={formRef}
            onKeyDown={(e) => handleKeyDown(e, formRef)}
          >
            <div className="fields-wrapper">
              <div className="fields-row-input-bottom">
                {/* <DatePickerWrapper
                    width="125px"
                    name="Effective"
                    label="Effective Date"
                    id='effective-date'
                    placeholder=""
                    value={formState.EffectiveDate}
                    onChange={(e) => handleOnChange(e, 'EffectiveDate')}
                  /> */}
                {/* <CustomizedDatePicker
                    width="130px"
                    name="Effective"
                    label="Effective"
                    // id='effective-date'
                    placeholder=""
                    value={
                      formState.EffectiveDate
                        ? moment(formState.EffectiveDate).toDate()
                        : new Date()
                    }
                    onChange={(e: CustomDatePickerChangeEvent) =>
                      handleOnChange(e, "EffectiveDate")
                    }
                  /> */}
                <InputWrapper
                  maxWidth="80px"
                  width="90px"
                  label="Code"
                  name="PayCode"
                  onChange={handleOnChange}
                  value={formState.PayCode}
                  maxLength={6}
                  autoComplete="off"
                  isDisabled={codeInUse}
                  id="code"
                />
                <InputWrapper
                  name="PayDesc"
                  maxLength={25}
                  width={'230px'}
                  maxWidth="230px"
                  value={formState.PayDesc}
                  onChange={handleOnChange}
                  label="Description"
                  ref={descriptionRef}
                  id="PayDesc"
                  spellCheck={false}
                  autoComplete="off"
                />
                <CustomColComboBox
                  name="AccruePaidYN"
                  width={80}
                  dataList={[]}
                  comboBoxType="CUSTOM_YesNo"
                  label="Paid"
                  filterable={true}
                  onChange={(e) => {
                    setIsChanged(true);
                    setFormState((prevState: any) => ({
                      ...prevState,
                      AccruePaidYN: e.value === 'Yes' ? true : false,
                    }));
                  }}
                  value={formState.AccruePaidYN === true ? 'Yes' : 'No'}
                />
                <CustomColComboBox
                  ref={accrueYNInputRef}
                  name="AccrueYN"
                  width={80}
                  dataList={[]}
                  comboBoxType="CUSTOM_YesNo"
                  label="Accrues"
                  filterable={true}
                  onChange={(e) => {
                    setIsChanged(true);
                    setFormState((prevState: any) => ({
                      ...prevState,
                      AccrueYN: e.value === 'Yes' ? true : false,
                    }));
                  }}
                  value={formState.AccrueYN === true ? 'Yes' : 'No'}
                />
              </div>
            </div>
            {formState.AccrueYN && <span style={{ borderBottom: '2px solid rgb(214, 214, 214)' }} />}
            {formState.AccrueYN && (
              <div className='fields-wrapper'>
                <div style={{padding: '0px 30px'}}>
                  <div className="fields-row-input-bottom">
                    <label className='left-label-input'>Add</label>
                    <div className='left-label-input'>
                      <NumericTextBox
                        ref={addAccrualInputRef}
                        spinners={false}
                        name="PTOQty"
                        value={formState?.PTOQty || 0.00}
                        onChange={(e) => {
                          handleOnChange(e, 'PTOQty');
                        }}
                        inputStyle={{ textAlign: 'left' }}
                        style={{ width: '70px' }}
                        format="n2"
                        max={99.00}
                        min={0.00}
                      />
                    </div>
                    <div style={{ paddingBottom: '10px' }}>
                      <CustomColComboBox
                        name="PTOUnits"
                        label='Units'
                        dataList={PTO_UNITS}
                        comboBoxType="PTO"
                        width={90}
                        onChange={(e) => handleOnChange(e, 'PTOUnits')}
                        value={formState.PTOUnits}
                        defaultValue={formState.PTOUnits}
                        filterable
                        allowBlank={false}
                      />
                    </div>
                    <label className='left-label-input'>Every</label>
                    <div className='left-label-input'>
                      <NumericTextBox
                        spinners={false}
                        name="IntervalQty"
                        value={formState?.IntervalQty || 0.00}
                        onChange={(e) => {
                          handleOnChange(e, 'IntervalQty');
                        }}
                        inputStyle={{ textAlign: 'left' }}
                        style={{ width: '70px' }}
                        format="n2"
                        defaultValue={0.00}
                        max={99.00}
                        min={0.00}
                      />
                    </div>
                    <div style={{ paddingBottom: '10px' }}>
                      <CustomColComboBox
                        label='Interval'
                        name="IntervalPeriods"
                        dataList={PTO_PERIODS}
                        comboBoxType="PTO"
                        width={100}
                        onChange={(e) => handleOnChange(e, 'IntervalPeriods')}
                        value={formState.IntervalPeriods}
                        defaultValue={formState.IntervalPeriods}
                        filterable
                        allowBlank={false}
                      />
                    </div>
                  </div>
                  <div style={{height: '10px'}} />
                  <div className='title'>Accrual Starts</div>
                  <div className="fields-row-input-bottom">
                    <div className="left-label-input accrual-start">
                      <div style={{ paddingTop: "10px" }}>
                        <CustomColComboBox
                          label=''
                          dataList={PTO_ACCRUAL_START}
                          width={138}
                          name="AccrualStarts"
                          comboBoxType="PTO"
                          onChange={(e) => handleOnChange(e, 'AccrualStarts')}
                          defaultValue={formState.AccrualStarts}
                          value={formState.AccrualStarts}
                          allowBlank={false}
                          filterable
                        />
                      </div>
                    </div>
                    {formState.AccrualStarts === 'C' && (
                      <>
                        <CustomColComboBox
                          dataList={PTO_MONTHS}
                          width={116}
                          name="AccrualMonth"
                          label="Month"
                          comboBoxType="PTO"
                          onChange={(e) => handleOnChange(e, 'AccrualMonth')}
                          defaultValue={formState.AccrualMonth}
                          value={formState.AccrualMonth}
                          allowBlank={false}
                          filterable
                        />
                        <CustomColComboBox
                          label="Day"
                          dataList={generateDaysInMonth(formState.AccrualMonth || 31)}
                          width={65}
                          name="AccrualDay"
                          comboBoxType="PTO"
                          onChange={(e) => handleOnChange(e, 'AccrualDay')}
                          defaultValue={formState.AccrualDay}
                          value={formState.AccrualDay}
                          allowBlank={false}
                          filterable
                        />
                      </>
                    )}
                  </div>
                  <div style={{height: '20px'}} />
                  <div style={{ display: 'flex', gap: "20px", justifyContent: 'center', flexDirection: 'row' }}>
                    <div style={{ width: '47.5%' }}>
                      <div className='title'>Required Tenure</div>
                      <div className='fields-row-input-bottom'>
                        <div style={{ paddingTop: '10px' }}>
                          <NumericTextBox
                            spinners={false}
                            name="TenureQty"
                            value={formState?.TenureQty ?? 0}
                            onChange={(e) => {
                              handleOnChange(e, 'TenureQty');
                            }}
                            inputStyle={{ textAlign: 'left' }}
                            style={{ width: '70px' }}
                            format="n2"
                            max={99}
                          />
                        </div>
                        <CustomColComboBox
                          label='Periods'
                          dataList={PTO_PERIODS}
                          width={100}
                          name="TenurePeriods"
                          comboBoxType="PTO"
                          onChange={(e) => handleOnChange(e, 'TenurePeriods')}
                          defaultValue={formState.TenurePeriods}
                          value={formState.TenurePeriods}
                          allowBlank={false}
                          filterable
                        />
                      </div>

                    </div>
                    <div style={{ width: '47.5%'}}>
                      <div className='title'>Maximum Rollover</div>
                      <div className='fields-row-input-bottom'>
                        <div style={{ paddingTop: '10px' }}>
                          <NumericTextBox
                            spinners={false}
                            name="MaxRollover"
                            value={formState?.MaxRollover ?? 0}
                            onChange={(e) => {
                              handleOnChange(e, 'MaxRollover');
                            }}
                            inputStyle={{ textAlign: 'left' }}
                            style={{ width: '70px' }}
                            format="n2"
                            max={99}
                          />
                        </div>
                        <CustomColComboBox
                          label='Periods'
                          dataList={PTO_PERIODS}
                          width={100}
                          name="RollOverPeriods"
                          comboBoxType="PTO"
                          onChange={(e) => handleOnChange(e, 'RollOverPeriods')}
                          defaultValue={formState.RollOverPeriods}
                          value={formState.RollOverPeriods}
                          allowBlank={false}
                          filterable
                        />
                      </div>
                    </div>
                  </div>
              </div>

              </div>
            )}
          </div>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  (isValid || !isSaving || !isDeleting || isChanged)
                ) {
                  event.preventDefault();
                  handleSave();
                }
              }}
            >
              {isSaving ? (
                'Saving...'
              ) : isDeleting ? (
                'Deleting'
              ) : (
                <>
                  <u>S</u>ave
                </>
              )}
            </Button>
            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && (!isSaving || !isDeleting)) {
                  event.preventDefault();
                  handleClose();
                }
              }}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
                disabled={codeInUse}
              >
                <u>D</u>elete
              </Button>
            )}
          </ButtonWrapper>
        </ReactFocusLock>
      </DialogContent>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => handleOnDelete()}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MoveableDialog>
  );
};
export default PTOCodesDialog;