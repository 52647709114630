import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const accountingDefaultsService = {
  getDefaultsWithCombosData: async () => {
    const response = await axiosClient().get(
      'Setup/AccountingDefaults?FromAppStartup=false',
      {
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return {
      data: {
        data: response?.data?.data?.record,
        combosData: response?.data?.data?.combos,
      },
    };
  },
  updateAcctDefaults: (params: any) => {
    const data = { ...params };

    return axiosClient().patch(
      '/Setup/AccountingDefaults',
      { ...data },
      {
        headers: { ...commonHeaders },
      }
    );
  },
};
