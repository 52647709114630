import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

// project types
export const projectTypesService = {
  getProjectTypesList: async () => {
    const data = await axiosClient().get(`Setup/ProjectTypes/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data.data.data.records;
  },
  getProject: async (IDNo: number) => {
    const data = await axiosClient().get(`Setup/ProjectTypes?IDNo=${IDNo}`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data.data.data;
  },
  CreateUpdateProject: async (IDNo: number, payload: any) => {
    return axiosClient().patch(
      `Setup/ProjectTypes`,
      { ...payload },
      {
        params: {
          IDNo: IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteProject: (IDNo: number) =>
    axiosClient().delete(`Setup/ProjectTypes`, {
      params: {
        IDNo: IDNo,
      },
      headers: { ...commonHeaders },
    }),
};

// project event codes
export const projectEventCodesService = {
  getEventCodes: async () => {
    const data = await axiosClient().get(`Setup/ProjectEventCodes/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data.data.data.records;
  },
  getEventCode: async (IDNo: number) => {
    const data = await axiosClient().get(
      `Setup/ProjectEventCodes?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return data.data.data;
  },
  CreateUpdateEventCode: async (IDNo: number, payload: any) => {
    return axiosClient().patch(
      `Setup/ProjectEventCodes`,
      { ...payload },
      {
        params: {
          IDNo: IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteEventCode: (IDNo: number) =>
    axiosClient().delete(`Setup/ProjectEventCodes`, {
      params: {
        IDNo: IDNo,
      },
      headers: { ...commonHeaders },
    }),
};

// project status codes
export const projectStatusCodesService = {
  getStatusCodes: async () => {
    const data = await axiosClient().get(`Setup/ProjectStatusCodes/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data.data.data.records;
  },
  getStatusCode: async (IDNo: number) => {
    const data = await axiosClient().get(
      `Setup/ProjectStatusCodes?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return data.data.data;
  },
  CreateUpdateStatusCode: async (
    IDNo: number,
    payload: any,
    DefColor?: boolean
  ) => {
    return axiosClient().patch(
      `Setup/ProjectStatusCodes`,
      { ...payload },
      {
        params: {
          IDNo: IDNo,
          DefColor,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteStatusCode: (IDNo: number) =>
    axiosClient().delete(`Setup/ProjectStatusCodes`, {
      params: {
        IDNo: IDNo,
      },
      headers: { ...commonHeaders },
    }),
};
