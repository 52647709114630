import { ListingGrid } from '../../../common';
import { useCallback, useRef, useState } from 'react';
import { GridColumn, GridColumnProps } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { ContextMenu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { CustomButtonWrapper, DialogWrapper, StyledTitle } from './SearchDialog.styled';
import ReactFocusLock from 'react-focus-lock';
import { Button } from '../../../common';

type SICCodeType = {
  SICCode: number;
  SICIndustryTitle: string;
  SICOffice: string;
};

const model: GridColumnProps[] = [
  {
    field: "SICCode",
    title: "Code",
    width: 100,
  },
  {
    field: "SICOffice",
    title: "Office",
    width: 250,
  },
  {
    field: "SICIndustryTitle",
    title: "Industry ",
    width: 400,
  }
]
type SelectionType = { field?: string; dataItem: any };

type Props = {
  data: SICCodeType[];
  onSelected: (selectedCode: number) => void;
  onClose: () => void;
}

const SICSearchDialog = ({ onSelected, onClose, data }: Props) => {
  const [filterData, setFilterData] = useState<SICCodeType[]>(data);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();

  const offset = useRef({ left: 0, top: 0 });

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                data.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                data.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(data);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [data, selectedItem]
  );

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>SIC Codes</span>
      </StyledTitle>
    );
  };

  const handleClose = () => {
    onClose();
  }


  return <DialogWrapper
    title={<CustomWindowTitle />}
    onClose={handleClose}
  >
    <ReactFocusLock >
      <ListingGrid
        style={{ maxHeight: 500 }}
        data={sort ? orderBy(filterData, sort) : filterData}
        dataItemKey="SICCode"
        onRowClick={(e) => {
          setFilterData((prev) =>
            prev.map((item) => ({
              ...item,
              selected: item.SICCode === e.dataItem.SICCode,
            }))
          );
        }}
        onRowDoubleClick={(e) => {
          onSelected(e.dataItem.SICCode);
          handleClose();
        }}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        onContextMenu={(event) => {
          handleContextMenuOpen(event.syntheticEvent);
          const { dataItem, field } = event;
          setFilterData((prev) =>
            prev.map((item) => ({
              ...item,
              selected: item.SICCode === dataItem.SICCode,
            }))
          );
          setSelectedItem({ field, dataItem });
        }}
      >
        {model.map(columnProps => {
          return <GridColumn {...columnProps} />
        })}
      </ListingGrid>

      <CustomButtonWrapper>
        <Button
          id='close-button'
          onClick={(e: any) => {
            e.preventDefault();
            handleClose();
          }}
          title="Close"
        >
          <>
            Close
          </>
        </Button>
      </CustomButtonWrapper>
      <ContextMenu
        show={showContext}
        offset={offset.current}
        onSelect={handleOnSelect}
        onClose={() => setShowContext(false)}
        className="context-menu"
      >
        <MenuItem
          text="Sort Ascending"
          data={{
            action: 'sortAsc',
          }}
        />
        <MenuItem
          text="Sort Descending"
          data={{
            action: 'sortDesc',
          }}
        />
        <MenuItem
          text="Filter In Selected"
          data={{
            action: 'filterIn',
          }}
        />
        <MenuItem
          text="Filter Out Selected"
          data={{
            action: 'filterOut',
          }}
        />
        <MenuItem
          text="Remove All Filters"
          data={{
            action: 'removeFilters',
          }}
        />
      </ContextMenu>
    </ReactFocusLock>
  </DialogWrapper>
}

export default SICSearchDialog;