import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';
import { ComboType } from '../data/types';

export const combosService = {
  getCombo: async (
    type: ComboType,
    status?: `Enabled` | `Disabled` | `All`
  ) => {
    let getString = `/Misc/GetCombo?combo=${type}`;
    getString += status ? `&status=${status}` : ``;
    // console.log("getCombo: ~ getString:", getString)
    const data = await axiosClient().get(getString, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data.data.data[type.toString()];
  },
  getCombos: async (list: ComboType[]) => {
    const promises = list.map(async (type) => {
      return await combosService.getCombo(type);
    });

    return Promise.all(promises);
  },
};
