import styled from 'styled-components';

export const DialogContent = styled.form`
  padding: 20px;
  box-sizing: border-box;

  .form-fields {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .contact-info-wrapper {
    width: 100%;
    padding-top: 20px;

    .contact-info-label {
      font-weight: 600;
      font-size: 16px;
    }

    .contact-info {
      margin: 0px auto;
      margin-top: 8px;
      
      label {
        font-size: 12px;
      }
    }
  }
`;

export const LocationSectionsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const LocationSection = styled.div`
  flex: 1 1 100%;
  border: 1px solid grey;
`;

export const LocationSectionHeader = styled.div`
  border-bottom: 1px solid grey;
  padding: 4px 0px 4px 12px;
  p {
    color: #2c5098;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    text-align: center;
  }
`;

export const LocationSectionContent = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding || '1px 0px 19px 0px'};

  h2 {
    color: #2c5098;
    margin: 16px 0px 8px 8px;
    font-size: 16px;
    align-self: flex-start;

    &:first-child {
      margin: 8px 0px 8px 8px;
    }
  }

  label {
    font-size: 12px !important;
  }
`;

export const AddressFieldWrapper = styled.div<{ width: string }>`
  flex-basis: ${(props) => props.width};
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 8px;
  width: 335px;

  label {
    color: #424242;
    font-size: 13px;
    max-width: 270px;
    padding-right: 16px;
  }
`;

export const LeftLabelInputWrapper2 = styled.div`
  display: flex;
  align-items: center;
  padding-top: 6px;
  width: 100%;

  .k-input {
    width: 100px;
  }

  label {
    color: #424242;
    font-size: 15px;
    width: 124px;
    padding-right: 16px;
    text-align: right;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-top: 2px solid #d6d6d6;

  button {
    margin-left: 15px;

    &:first-child {
      margin-left: 0px;
    }
  }
`;
