import type { GridColumnProps } from "@progress/kendo-react-grid";
import { CheckCell } from "../../common";
import { ColorCell } from "../../common/ListingView/ColorCell";

export const columns: GridColumnProps[] = [
    {
        title: 'Code',
        field: 'StatusCode',
        width: 100,
        className: 'text-left',
        sortable: true,
    },
    {
        title: 'Description',
        field: 'StatusDesc',
        className: 'text-left',
        width: 299,
        sortable: true,
    },
    {
        title: 'Color',
        field: 'StatusColor',
        width: 95,
        sortable: true,
        cell: ColorCell,
    },
    {
        title: 'Default',
        field: 'DefaultYN',
        width: 95,
        sortable: true,
        cell: CheckCell,
    },
    {
        title: 'Final',
        field: 'FinalYN',
        width: 95,
        sortable: true,
        cell: CheckCell,
    },
    {
        title: 'Percent',
        field: 'StatusPct',
        className: 'text-center',
        sortable: true,
    },
];