import { Dialog } from '@progress/kendo-react-dialogs';
import styled from 'styled-components';

export const DialogWrapper = styled(Dialog)`
  .k-window-content.k-dialog-content {
    padding: 0px;
  }
`;

export const DialogContentWrapper = styled.dialog`
  display: flex;
  flex-direction: row;
`;

export const DialogContent = styled.form`
  width: 100%;
  height: 100%;

  .form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;

    row-gap: 4px;
    column-gap: 8px;
    padding: 15px 30px;

    .left-field {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }

    .right-field {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }

    .k-floating-label-container {
      padding-top: 8px;
    }
  }

  div[data-focus-lock-disabled] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-left: 20px;

  button {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0px !important;
  }
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  box-sizing: border-box;
  border-top: 2px solid #d6d6d6;
  padding: 15px 15px;
  width: 100%;
`;
