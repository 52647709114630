import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const holidaysService = {
  getList: async () => {
    const response = await axiosClient().get(`Setup/PTOHolidays/GetList`, {
      headers: { ...commonHeaders },
    });
    return response.data.data.records;
  },
  createHoliday: (payload: any, PayCode: string) =>
    axiosClient().post(
      'Setup/PTOHolidays',
      { ...payload },
      {
        params: {
          PayCode,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateHoliday: (IDNo: number, payload: any, PayCode: string) => {
    return axiosClient().patch(
      'Setup/PTOHolidays',
      { ...payload },
      {
        params: {
          IDNo,
          PayCode,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteHoliday: (IDNo: number) => {
    return axiosClient().delete('Setup/PTOHolidays', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
};
