import React, { useEffect, useRef, useState } from 'react';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';

import { Button, CustomColComboBox, InputWrapper } from '../../../../common';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validateEmail, validateUserForm } from '../../../../../utils';

import { ButtonWrapper, DialogContent } from './UserDialog.styled';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';
import { combosService } from '../../../../../services/combosService';
import { EntityListType, IGroup, IUser } from '../../../../../data/types';
import { Loader } from '../../../../common/Loader';

interface IUserDialogProps {
  isEdit: boolean;
  data: any;
  handleClose: () => void;
  handleSubmit: (params: { isNew: boolean; data: any; id?: any }) => void;
  handleDelete: (params: { id: any }) => void;
}

type CombosType = {
  PermGroups: IGroup[];
  Employees: EntityListType[];
};

const UserDialog: React.FC<IUserDialogProps> = ({
  isEdit,
  data,
  handleClose,
  handleSubmit,
  handleDelete,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState<IUser>({
    IDNo: isEdit && data ? data.IDNo : 0,
    LoginUserEmail: isEdit && data ? data.LoginUserEmail : '',
    LoginUserName: isEdit && data ? data.LoginUserName : '',
    LoginUserPW: isEdit && data ? data.LoginUserPW : '',
    EmployeeID: isEdit && data ? data.EmployeeID : undefined,
    GroupCode: isEdit && data ? data.GroupCode : undefined,
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [combosData, setCombosData] = useState<CombosType>({
    Employees: [],
    PermGroups: [],
  });

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput(isEdit ? 'desc' : 'code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged) {
        const formData: IUser = { ...formState };
        if (formData.EmployeeID) {
          const employee = combosData.Employees.find(
            (emp) => emp.IDNo === formData.EmployeeID
          );

          if (employee?.FirstName) {
            formData.FirstName = employee.FirstName;
          }
          if (employee?.MiddleName) {
            formData.MiddleName = employee.MiddleName;
          }
          if (employee?.LastName) {
            formData.LastName = employee.LastName;
          }
        }
        handleSubmit({
          isNew: data?.IDNo ? false : true,
          data: formData,
          id: data?.IDNo || '',
        });
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    setIsValid(validateUserForm(formState));
  }, [formState]);

  useEffect(() => {
    const fetchCombos = async () => {
      const comboResults = await combosService.getCombos([
        'PermGroups',
        'Employees',
      ]);

      const PermGroups: IGroup[] = comboResults[0];
      const Employees: EntityListType[] = comboResults[1];
      if (comboResults?.length) {
        setCombosData({
          Employees: Employees.filter((employee) => employee?.IDNo),
          PermGroups: PermGroups.filter((item) => item?.PermGroupCode),
        });
      }
      setIsLoading(false);
    };
    fetchCombos();
  }, []);

  const handleOnChange = (event: InputChangeEvent, key: string) => {
    setIsChanged(true);
    setFormState((prevState) => ({
      ...prevState,
      [key]: event.value,
    }));
  };

  const handleUpdateMainForm = (key: string, value: string | number | Date) => {
    setIsChanged(true);
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const toggleDialog = (state?: boolean) => {
    if (state && handleDelete) {
      handleDelete({
        id: data?.IDNo,
      });
    }
    setVisible(!visible);
  };

  return (
    <MovableDialog
      title={`${isEdit ? 'Edit ' : 'Add '}${'User'}`}
      onClose={() => handleClose()}
      className="user-dialog"
      ref={dialogRef}
      width={418}
    >
      {isLoading ? (
        <div
          style={{
            height: 187,
          }}
        >
          <Loader />
        </div>
      ) : (
        <ReactFocusLock>
          <DialogContent>
            <div
              className="form-fields"
              ref={formRef}
              onKeyDown={(e) => handleKeyDown(e, formRef)}
            >
              <div className={validateEmail(formState.LoginUserEmail || '') ? '' : 'invalid-input'}>
                <InputWrapper
                  label="Email"
                  name="LoginUserEmail"
                  onChange={handleOnChange}
                  value={formState.LoginUserEmail || ''}
                  maxLength={25}
                  isDisabled={isEdit}
                  id="code"
                  autoComplete="off"
                />
              </div>
              <InputWrapper
                label="User Name"
                name="LoginUserName"
                onChange={handleOnChange}
                value={formState.LoginUserName || ''}
                maxLength={25}
                id="desc"
                autoComplete="off"
              />

              <CustomColComboBox
                label="Group"
                comboBoxType="PermGroups"
                dataList={combosData.PermGroups}
                value={combosData.PermGroups.find(
                  (item) => item.PermGroupCode === formState.GroupCode
                )}
                defaultValue={combosData.PermGroups.find(
                  (item) => item.PermGroupCode === formState.GroupCode
                )}
                onChange={(e) => {
                  if (e.value?.PermGroupCode)
                    handleUpdateMainForm('GroupCode', e.value?.PermGroupCode);
                }}
                width={187}
                id="GroupCode"
              />
              <CustomColComboBox
                width={187}
                label="Employee"
                comboBoxType="Employees"
                dataList={combosData.Employees}
                onChange={(e) => {
                  if (e.value?.IDNo)
                    handleUpdateMainForm('EmployeeID', e.value?.IDNo);
                }}
                value={combosData.Employees.find(
                  (e) => e.IDNo === formState.EmployeeID
                )}
                name="EmployeeID"
              />
            </div>
          </DialogContent>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                const formData: IUser = { ...formState };
                if (formData.EmployeeID) {
                  const employee = combosData.Employees.find(
                    (emp) => emp.IDNo === formData.EmployeeID
                  );

                  if (employee?.FirstName) {
                    formData.FirstName = employee.FirstName;
                  }
                  if (employee?.MiddleName) {
                    formData.MiddleName = employee.MiddleName;
                  }
                  if (employee?.LastName) {
                    formData.LastName = employee.LastName;
                  }
                }
                handleSubmit({
                  isNew: data?.IDNo ? false : true,
                  data: formData,
                  id: data?.IDNo || '',
                });
              }}
              disabled={!isValid || !isChanged}
              id="save-btn"
              title="Save"
            >
              <u>S</u>ave
            </Button>
            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </ButtonWrapper>
        </ReactFocusLock>
      )}
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MovableDialog>
  );
};

export default UserDialog;
