const loadComponent = (componentLink: string, componentDivId: any) => {
  try {
    // @ts-ignore
    const handler = window.CheckComponent.create({
      link: componentLink,
      appearance: {
        includeCloseButton: false,
      },
      onEvent: (event: any, data: any) => {
        console.log(event, data);
      },
    });

    handler.open();

    const componentElement = document.getElementById('check-component-embedded-div');
    if (componentElement) {
      const componentHolder = document.getElementById(componentDivId);
      componentHolder?.appendChild(componentElement);
    }
    
    return handler;
  } catch (err) {
    console.error('Error loading component', err);
  }
};

export default loadComponent;
