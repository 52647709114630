import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const measurementUnitService = {
  getList: async () => {
    const response = await axiosClient().get(
      `Setup/MeasurementUnits/GetList?MeasurementType=ProductMeasurement`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data.records;
  },
  getFieldProperties: async (IDNo: number) => {
    const response = await axiosClient().get(
      `Setup/MeasurementUnits?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
  createUnit: (UnitCode: string, payload: any) =>
    axiosClient().post(
      'Setup/MeasurementUnits',
      { ...payload },
      {
        params: {
          UnitCode,
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateUnit: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Setup/MeasurementUnits',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteUnit: (IDNo: number) => {
    return axiosClient().delete('Setup/MeasurementUnits', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
};

export const productService = {
  getList: async () => {
    const response = await axiosClient().get(
      `Item/ItemCategories/GetProducts`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data.records;
  },
  getFieldProperties: async (IDNo: number) => {
    const response = await axiosClient().get(
      `Item/ItemCategories?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
  createProduct: (CategoryCode: string, payload: any) =>
    axiosClient().post(
      'Item/ItemCategories',
      { ...payload },
      {
        params: {
          CategoryCode,
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateProduct: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Item/ItemCategories',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteProduct: (IDNo: number) => {
    return axiosClient().delete('Item/ItemCategories', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
};

export const serviceService = {
  getList: async () => {
    const response = await axiosClient().get(
      `Item/ItemCategories/GetServices`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data.records;
  },
  getFieldProperties: async (IDNo: number) => {
    const response = await axiosClient().get(
      `Item/ItemCategories?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
  createService: (CategoryCode: string, payload: any) =>
    axiosClient().post(
      'Item/ItemCategories',
      { ...payload },
      {
        params: {
          CategoryCode,
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateService: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Item/ItemCategories',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteService: (IDNo: number) => {
    return axiosClient().delete('Item/ItemCategories', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
};

export const bundlesService = {
  getBundles: async () => {
    const response = await axiosClient().get(`Item/ItemCategories/GetBundles`, 
      {
        headers: { ...commonHeaders },
      },
    );
    return response.data.data.records
  },
  getFieldProperties: async (IDNo: number) => {
    const response = await axiosClient().get(
      `Item/ItemCategories?IDNo=${IDNo}`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
  createBundle: (CategoryCode: string, payload: any) =>
    axiosClient().post(
      'Item/ItemCategories',
      { ...payload },
      {
        params: {
          CategoryCode,
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateBundle: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Item/ItemCategories',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteBundle: (IDNo: number) => {
    return axiosClient().delete('Item/ItemCategories', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
};