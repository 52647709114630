import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const positionsService = {
  getPositions: async () => {
    const response = await axiosClient().get(`Payroll/PayPositions/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  createPosition: (code: string, payload: any) =>
    axiosClient().post(
      'Payroll/PayPositions',
      { ...payload },
      {
        params: {
          PositionCode: code,
        },
        headers: { ...commonHeaders },
      }
    ),
  updatePosition: (code: string, payload: any) => {
    return axiosClient().patch(
      'Payroll/PayPositions',
      { ...payload },
      {
        params: {
          PositionCode: code,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deletePosition: (code: string) =>
    axiosClient().delete('Payroll/PayPositions', {
      params: {
        PositionCode: code,
      },
      headers: { ...commonHeaders },
    }),

  getSinglePosition: async (code: number) => {
    const response = await axiosClient().get(`Payroll/PayPositions`, {
      headers: { ...commonHeaders },
      params: {
        PositionCode: code,
      },
    });
    return response.data;
  },
  getPositionData: async () => {
    const response = await axiosClient().get(
      `Payroll/PayPositions?PositionCode=1`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
};
