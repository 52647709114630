import styled from 'styled-components';

export const FormContainer = styled.div`
  display: block;
  position: relative;
  width: calc(100% - 20px);
  padding: 10px;

  .table-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    height: 40px;

    span {
      text-wrap: wrap;
      white-space: normal;
      display: block;
      text-align: center;
    }
  }
`;

export const Form = styled.form`
  border: 1px solid #d6d6d6;
  height: 100%;

  .form-section-header-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(209 213 219 / 1);
    border-color:;
    background-color: rgb(250 250 250 / 1);
    padding-left: 1.25rem;
    padding-right: 0.375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    .form-title {
      font-weight: 600;
    }

    .form-header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .listing-action-button {
        background-color: transparent;
        border: 1px solid rgb(209, 213, 219);
        cursor: pointer;
        padding: 4px;
        display: flex;
        height: 30px;
        width: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        color: rgb(51 51 51 / 1);

        &:hover {
          border-color: rgb(44 80 152 / 1);
          color: rgb(44 80 152 / 1);
        }
      }

      .listing-action-seperate-line {
        height: 30px;
        width: 1px;
        background-color: rgb(209, 213, 219);
      }
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 75px);
    overflow-y: hidden;
    box-sizing: border-box;
  }

  .form-footer {
    padding: 15px 20px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const ListingGridContainer = styled.div`
height: calc(100vh - 295px);
max-height:calc(100vh);
overflow: hidden;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-start;
  gap: 8px;
  padding: 10px;
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  border-bottom: 2px solid #2c5098;

  #create-btn {
    width: 112px;
  }
`;