import { useMutation } from '@tanstack/react-query';
import { permsService } from '../perm/permsService';

export const useCreateUser = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(
    async (params: any) => permsService.createUser(params?.formattedData),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};

export const useUpdateUser = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(async (params: any) => permsService.updateUser(params), {
    onSuccess: () => {
      callback(true);
    },
  });
};

export const useDeleteUserById = (
  callback: (shouldRefetch?: boolean) => void
) => {
  return useMutation(
    async (params: any) => {
      return await permsService.deleteUserById(params.id);
    },
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};
