import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { accountsService } from '../../../services/account/accountsService';
import { AccountsType } from "../../../data/types";

type ChartOfAccountsContextType = {
    accountTypesList: any[];
};

export const ChartOfAccountsContext = createContext<ChartOfAccountsContextType>({
    accountTypesList: [],
});

export const ChartOfAccountsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [accountTypesList, setAccountTypesList] = useState<AccountsType[]>([]);

    useEffect(() => {
        const getAccountTypesList = async () => {
            const response = await accountsService.getAccountsList('');
            setAccountTypesList(response.combos.GLAccountTypesAll);
        };
        getAccountTypesList();
    }, [])

    return (
        <ChartOfAccountsContext.Provider
            value={{ accountTypesList }}
        >
            {children}
        </ChartOfAccountsContext.Provider>
    );
};