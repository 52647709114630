import type { GridColumnProps } from '@progress/kendo-react-grid';
import { NumericCell } from '../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Terms Code',
    field: 'TermsCode',
    width: 333,
  },
  {
    title: 'Discount Days',
    field: 'DiscDays',
    width: 150,
    className: 'right-align-text',
  },
  {
    title: 'Discount Percent',
    field: 'DiscPct',
    width: 150,
    className: 'right-align-text',
    cell: NumericCell
  },
  {
    title: 'Days Until Due',
    field: 'DueDays',
    className: 'right-align-text',
  },
];
