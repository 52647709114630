import { ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import moment from 'moment';
import { GenericKeyNameObject } from '../data/types';

export const getChangedValues = <T extends Record<string, any>>(
  values: T,
  initialValues?: T
): T | Partial<T> => {

  if (!initialValues) {
    return values;
  }

  return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
    const hasChanged = initialValues[key as keyof T] !== value;

    if (hasChanged) {
      acc[key as keyof T] = value;
    }

    return acc;
  }, {});
};

export const handleKeyDown = (
  event:
    | React.KeyboardEvent<HTMLFormElement>
    | React.KeyboardEvent<HTMLDivElement>,
  formRef: React.RefObject<HTMLFormElement> | React.RefObject<HTMLDivElement>,
  isCustomDropdown?: boolean, // temp
) => {
  if (['˜', '˜', 'n', 'N'].includes(event.key) && event.altKey) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (event.key === 'Enter' || event.key === 'Tab') {
    console.log('enter');
    event.preventDefault();

    const activeElement = event.target as HTMLElement;
    let inputElements = Array.from(
      formRef.current!.querySelectorAll(
        `input:not([disabled]), .tabable-button ${isCustomDropdown ? `, textarea` : ``}`
      )
    ) as (HTMLInputElement | HTMLButtonElement)[];
    
    console.log('ori list', inputElements);    
    // const tabableBtn: HTMLButtonElement[] = Array.from(
    //   document.querySelectorAll('.tabable-button')
    // ) as HTMLButtonElement[];
    const saveBtn: HTMLButtonElement = document.querySelector(
      '#save-btn'
    ) as HTMLButtonElement;
    const cancelBtn: HTMLButtonElement = document.querySelector(
      '#cancel-btn'
    ) as HTMLButtonElement;
    // const deleteBtn: HTMLButtonElement = document.querySelector(
    //   '#delete-btn'
    // ) as HTMLButtonElement;

    if (saveBtn && !saveBtn.disabled) {
      inputElements.push(saveBtn);
    }
    if (cancelBtn && !cancelBtn.disabled) {
      inputElements.push(cancelBtn);
    }
    // if (deleteBtn && !deleteBtn.disabled) {
    //   inputElements.push(deleteBtn);
    // }

    const currentIndex = inputElements.findIndex(
      (element) => element === activeElement
    );

    if (
      inputElements[currentIndex].tagName === 'BUTTON' &&
      event.key === 'Enter'
    ) {
      inputElements[currentIndex].click();
      return;
    }

    if (currentIndex !== -1) {
      let nextIndex = (currentIndex + 1) % inputElements.length;
      if (event.shiftKey) {
        nextIndex = (currentIndex - 1) % inputElements.length;
      }

      // console.log('nextIndex', { nextIndex, inputElements });

      if (inputElements[nextIndex]?.tagName === 'INPUT') {
        (inputElements[nextIndex] as HTMLInputElement).select();
      } else if (nextIndex === -1) {
        inputElements[inputElements.length - 1]?.focus();
      } else {
        inputElements[nextIndex]?.focus();
      }
    }
  }
};

export const handleStateFilterChange = (
  event: ComboBoxFilterChangeEvent,
  isMultiCol: boolean
) => {
  if (event) {
    let kFocusElement = document.querySelector('.k-table-row.k-focus');
    if (!isMultiCol) {
      kFocusElement = document.querySelector('.k-list-item.k-focus');
    }
    if (kFocusElement) {
      kFocusElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }
};

export function getObjectFromName(
  name: string,
  template: GenericKeyNameObject[]
): GenericKeyNameObject | null {
  return template.find((item) => item.name === name) ?? null;
}

export function getObjectFromKey(
  key: string,
  template: GenericKeyNameObject[]
): GenericKeyNameObject | null {
  return template.find((item) => `${item.key}` === `${key}`) ?? null;
}

export const formatClientTime = (date?: Date | string): string | null => {
  if (!date) {
    return null;
  }
  // converts date into MSSQL format string
  const formattedDate = moment(date).format('HH:mm:ss.SSS');

  return formattedDate;
};

export const formatClientDateTime = (date?: Date | string): string | null => {
  if (!date) {
    return null;
  }
  // converts date into MSSQL format string
  const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');

  return formattedDate;
};

// converts incoming date as strings to dates
// export const convertTimeStringToDate = (timeString: string | null): Date | null => {
//   if (!timeString) return null;

//   const [hours, minutes, seconds] = timeString.split(':');
//   const [secs, ...millisArr] = seconds.split('.');
//   const milliseconds = millisArr.length > 0 ? millisArr[0] : '0';

//   const date = new Date();
//   date.setHours(Number(hours));
//   date.setMinutes(Number(minutes));
//   date.setSeconds(Number(secs));
//   date.setMilliseconds(Number(milliseconds));

//   return date;
// };

// converts incoming date as strings to dates
export const convertTimeStringToDate = (timeString: string | null): Date | null => {
  if (!timeString) return null;
  return moment(timeString, 'HH:mm:ss.SSSSSSS').toDate();
};