import styled from 'styled-components';

export const CustomGrid = styled.div<{
  cols: string;
  colGap?: string;
  rowGap?: string;
  width?: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.cols || '1fr'};
  column-gap: ${(props) => props.colGap || '0rem'};
  row-gap: ${(props) => props.rowGap || '0rem'};
  width: ${(props) => props.width || 'auto'};
`;

export const CustomGridItem = styled.div``;
