import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const PTOCodesService = {
  getPTOCodes: async () => {
    const response = await axiosClient().get('/Setup/PTOCodes/GetList', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  createPTOCode: (PayCode: string, payload: any) =>
    axiosClient().post(
      '/Setup/PTOCodes',
      { ...payload },
      {
        params: {
          PayCode,
        },
        headers: { ...commonHeaders },
      }
    ),
  updatePTOCode: (PayCode: string, payload: any) => {
    return axiosClient().patch(
      '/Setup/PTOCodes',
      { ...payload },
      {
        params: {
          PayCode,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deletePTOCodeById: (PayCode: string) =>
    axiosClient().delete('/Setup/PTOCodes', {
      params: {
        PayCode,
      },
      headers: { ...commonHeaders },
    }),
  getSinglePTOCode: async (payCode: string) => {
    const response = await axiosClient().get('/Setup/PTOCodes', {
      headers: { ...commonHeaders },
      params: {
        PayCode: payCode,
      },
    });
    return response.data;
  },
};
