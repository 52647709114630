import { useMutation, useQuery } from '@tanstack/react-query';
import { accountingDefaultsService } from '../setup/accountingDefaultsService';

export const useGetAcctDefaults = () => {
  return useQuery<any[]>(
    ['get_acct_defaults'],
    async () => {
      const { data } = await accountingDefaultsService.getDefaultsWithCombosData();
      return [...data.data];
    },
    {
      initialData: [],
    }
  );
};

export const useUpdateAcctDefaults = (callback: () => void) => {
  return useMutation(
    async (params: any) => accountingDefaultsService.updateAcctDefaults(params),
    {
      onSuccess: () => {
        callback();
      },
    }
  );
};
