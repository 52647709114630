import type { GridColumnProps } from '@progress/kendo-react-grid';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'CategoryCode',
    width: 100,
    className: 'text-left',
    sortable: true,
  },
  {
    title: 'Description',
    field: 'CategoryDescription',
    className: 'text-left',
    sortable: true,
  },
];