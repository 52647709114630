import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { customersImportService } from "../../../services/customFormService";
import { EntityImportItemType } from "../../../data/types";

type CustomersContextType = {
    customersList: EntityImportItemType[];
    refresh?: boolean;
    setRefresh: (refresh: boolean) => void;
};

export const CustomersContext = createContext<CustomersContextType>({
    customersList: [],
    refresh: false,
    setRefresh: () => { },
});

export const CustomersProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [customersList, setCustomersList] = useState<any[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const getCustomerList = async () => {
        const response = await customersImportService.getCustomers();
        setCustomersList(response);
    };

    useEffect(() => {
        setRefresh(false);
        getCustomerList();
        if (refresh) {
            getCustomerList()
        }
    }, [refresh])

    return (
        <CustomersContext.Provider
            value={{ customersList, setRefresh }}
        >
            {children}
        </CustomersContext.Provider>
    );
};