import type { GridColumnProps } from '@progress/kendo-react-grid';
import { CheckCell } from '../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Field Label',
    field: 'FieldLabel',
   
  },
  {
    title: 'Field Name',
    field: 'FieldName',
   
  },
  {
    title: 'Type',
    field: 'FieldType',
    width: 95
  },
  {
    title: 'Required',
    field: 'RequiredYN',
    cell: CheckCell,
    width: 95
  },
];