import { styled } from 'styled-components';

export default styled.button`
  &&&&&&&&&[title='Close'] {
    // background: white;
    border: 0px solid #f14e4e;
    width: 30px;
    // color: #f14e4e;
    &:hover {
      background: #f14e4e;
      color: white;
      border: 1px solid #f14e4e;
    }
  }
`;
