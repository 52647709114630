import React, { FC, useRef, useState } from 'react';

import { IPayrollSetupProps } from './PayrollSetup.types';
import {
  IPayCodeDeduct,
  PayCodesAddsListType,
  IPTOCode,
  IHolidays
} from '../../../data/types';

import { useDisableRightClick } from '../../../hooks';

import { FormContainer, Form, TabsContainer, Tab } from './PayrollSetup.styled';
import { CustomGrid } from '../../../themes/common.styled';
import PayCodeDeducts from './PayCodeDeducts';
import PayCodeAdd from './PayCodesAdd';
import TimeOffCodes from './TimeOffCodes';
import Holidays from './Holidays';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';


const PersonnelCodes: FC<IPayrollSetupProps> = ({ handleCloseForm, onNextPage, onPreviousPage }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [selectedPayCodeDeduct, setSelectedPayCodeDeduct] = useState<IPayCodeDeduct>();
  const [selectedPayCodeAdd, setSelectedPayCodeAdd] = useState<PayCodesAddsListType>();
  const [selectedPTOCode, setSelectedPTOCode] = useState<IPTOCode>();
  const [selectedHolidays, setSelectedHolidays] = useState<IHolidays>();

  useDisableRightClick(containerRef);

  const handleCurrentTab = (e: React.MouseEvent, tabIndex: number) => {
    e.preventDefault();
    setCurrentTab(tabIndex);
  };

  return (
    <>
      <FormContainer ref={containerRef}>
        <Form>
          <div className="form-section-header-container">
            <div className="form-title">Payroll Codes</div>
            <div className="form-header-btn-wrapper">
              <div className="listing-action-seperate-line" />
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={handleCloseForm}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div >
          </div >
          <div className="form-content">
            <CustomGrid
              cols="1fr"
              colGap="0px"
              width="830px"
              style={{ padding: '30px 0px 30px 30px' }}
            >
              <TabsContainer>
                <Tab
                  isActive={currentTab === 0}
                  onClick={(e) => handleCurrentTab(e, 0)}
                >
                  Deductions
                </Tab>
                <Tab
                  isActive={currentTab === 1}
                  onClick={(e) => handleCurrentTab(e, 1)}
                >
                  Additions
                </Tab>
                <Tab
                  isActive={currentTab === 2}
                  onClick={(e) => handleCurrentTab(e, 2)}
                >
                  Time-Off
                </Tab>
                <Tab
                  isActive={currentTab === 3}
                  onClick={(e) => handleCurrentTab(e, 3)}
                >
                  Holidays
                </Tab>
              </TabsContainer>
              {currentTab === 0 && (
                <PayCodeDeducts
                  setSelectedPayCodeDeduct={setSelectedPayCodeDeduct}
                  selectedPayCodeDeduct={selectedPayCodeDeduct}
                />
              )}
              {currentTab === 1 && (
                <PayCodeAdd
                  selectedPayCode={selectedPayCodeAdd}
                  setSelectedPayCode={setSelectedPayCodeAdd}
                />
              )}
              {currentTab === 2 && (
                <TimeOffCodes
                  selectedPTOCode={selectedPTOCode}
                  setSelectedPTOCode={setSelectedPTOCode}
                />
              )}
              {currentTab === 3 && (
                <Holidays
                  selectedHolidays={selectedHolidays}
                  setSelectedHolidays={setSelectedHolidays}
                />
              )}
            </CustomGrid>
          </div>
        </Form >
      </FormContainer >
    </>
  );
};

export default PersonnelCodes;
