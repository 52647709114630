import type { GridColumnProps } from '@progress/kendo-react-grid';
import { CheckCell } from '../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Field Label',
    field: 'FieldLabel',
    width: 300,
  },
  {
    title: 'Field Name',
    field: 'FieldName',
    width: 300,
  },
  {
    title: 'Type',
    field: 'FieldType',
  },
  {
    title: 'Required',
    field: 'RequiredYN',
    cell: CheckCell,
    width: 95,
  },
];