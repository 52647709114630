import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';

import { useKeyPress } from '../../../../hooks';

import { Button, ListingGrid } from '../../../common';
import HolidaysDialog from './HolidaysDialog';

import { IHolidays, ITranslatedHolidays, HolidayTemplate } from '../../../../data/types';
import { columns } from './model';

import { ButtonWrapper, ListingGridWrapper } from '../PayrollSetup.styled';
import { getMonthFromValue, } from './apiTranslators';
import { HOLIDAYS_DEFAULTS, DAY_OF_WEEK, WEEK_OF_MONTH, MONTHS, HOLIDAYS } from '../../../../constants';
import { holidaysService } from '../../../../services/holidaysService';
import { plusIcon } from '@progress/kendo-svg-icons';

type SelectionType = { field?: string; dataItem: any };

interface HolidayProps {
  selectedHolidays: IHolidays | undefined;
  setSelectedHolidays: React.Dispatch<
    React.SetStateAction<IHolidays | undefined>
  >;
}

const Holidays: FC<HolidayProps> = ({
  selectedHolidays,
  setSelectedHolidays,
}) => {
  const [originData, setOriginData] = useState<ITranslatedHolidays[]>([]);
  const [filterData, setFilterData] = useState<ITranslatedHolidays[]>([]);
  const [dataTemplate, setDataTemplate] = useState<HolidayTemplate>();
  const [inputDefaults,] = useState(HOLIDAYS_DEFAULTS);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();

  const offset = useRef({ left: 0, top: 0 });

  async function fetchData() {
    try {
      const holidayList: IHolidays[] = await holidaysService.getList();
      const dataTemplate: HolidayTemplate = {
        holidayNames: HOLIDAYS,
        months: MONTHS,
        dayOfWeek: DAY_OF_WEEK,
        weekOfMonth: WEEK_OF_MONTH,
      };

      const translatedData: ITranslatedHolidays[] = holidayList.map(item => ({
        ...item,
        Month: getMonthFromValue(dataTemplate.months, item.Month),
      }));



      setDataTemplate(dataTemplate);
      setOriginData(translatedData ?? []);
      setFilterData(translatedData ?? []);

    } catch (e) { }

    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {
    },
    () => {
    },
    () => {
    },
    () => {
      setSelectedHolidays({ IDNo: 0 });
    }
  );


  return (
    <ListingGridWrapper>
      {isLoading ? (
        <></>
      ) : (
        <>
          <ListingGrid
            className='custom-listing-grid-row'
            rowHeight={24}
            data={sort ? orderBy(filterData, sort) : filterData}
            dataItemKey="holiday"
            onRowClick={(e) => {
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.IDNo === e.dataItem.IDNo,
                }))
              );
            }}
            onRowDoubleClick={(e) => {
              setSelectedHolidays(e.dataItem);
            }}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            onContextMenu={(event) => {
              handleContextMenuOpen(event.syntheticEvent);
              const { dataItem, field } = event;
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.IDNo === dataItem.IDNo,
                }))
              );
              setSelectedItem({ field, dataItem });
            }}
          >
            {columns.map((column, index) => {
              return (
                <GridColumn
                  {...column}
                  key={index}
                  sortable={true}
                />
              );
            })}
          </ListingGrid>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedHolidays({ IDNo: 0 });
              }}
              id="create-btn"
              title="Add New"
              svgIcon={plusIcon}
            >
              ADD <u>N</u>EW
            </Button>
          </ButtonWrapper>
        </>
      )}

      <ContextMenu
        show={showContext}
        offset={offset.current}
        onSelect={handleOnSelect}
        onClose={() => setShowContext(false)}
        className="context-menu"
      >
        <MenuItem
          text="Sort Ascending"
          data={{
            action: 'sortAsc',
          }}
        />
        <MenuItem
          text="Sort Descending"
          data={{
            action: 'sortDesc',
          }}
        />
        <MenuItem
          text="Filter In Selected"
          data={{
            action: 'filterIn',
          }}
        />
        <MenuItem
          text="Filter Out Selected"
          data={{
            action: 'filterOut',
          }}
        />
        <MenuItem
          text="Remove All Filters"
          data={{
            action: 'removeFilters',
          }}
        />
      </ContextMenu>
      {selectedHolidays && (
        <HolidaysDialog
          inputDefaults={inputDefaults}
          handleClose={(shouldRefreshData?: boolean) => {
            setSelectedHolidays(undefined);
            if (shouldRefreshData) {
              fetchData();
            }
          }}
          data={selectedHolidays}
          dataTemplate={dataTemplate}
          isEdit={selectedHolidays?.IDNo ? true : false}
        />
      )}
    </ListingGridWrapper>
  );
};

export default Holidays;
