import styled from 'styled-components';

export const FormContainer = styled.div`
  display: block;
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  padding: 10px;
`;

export const Form = styled.form`
  border: 1px solid #d6d6d6;
  height: calc(100vh - 116px);

  .form-section-header-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(209 213 219 / 1);
    background-color: rgb(250 250 250 / 1);
    padding-left: 1.25rem;
    padding-right: 0.375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    .form-title {
      font-weight: 600;
    }

    .form-header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .listing-action-button {
        background-color: transparent;
        border: 1px solid rgb(209, 213, 219);
        cursor: pointer;
        padding: 4px;
        display: flex;
        height: 30px;
        width: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        color: rgb(51 51 51 / 1);

        &:hover {
          border-color: rgb(44 80 152 / 1);
          color: rgb(44 80 152 / 1);
        }
      }

      .listing-action-seperate-line {
        height: 30px;
        width: 1px;
        background-color: rgb(209, 213, 219);
      }
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0px 30px 30px;
  }

  .form-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid #c3c3c3;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  // padding-top: 8px;
  max-width: 324px;
  width: 324px;
  justify-content: space-between;

  label {
    color: #424242;
    font-size: 12px !important;
    max-width: 270px;
    padding-right: 18px;
    flex: 1;
    text-align: right;
  }

  .placeholder {
    left: 6% !important;
    top: 50% !important;
  }
  }
`;

export const FiscalLeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  // padding-top: 8px;
  max-width: 324px;
  width: 324px;
  justify-content: end;
  gap: 12px;

  label {
    color: #424242;
    font-size: 12px !important;
    text-align: right;
  }
`;

export const FormFieldsWrapper = styled.div`
  border: 1px solid #c3c3c3;

  height: calc(100vh - 221px);
  max-height:calc(100vh);
  overflow: hidden;
  width: 800px;
  box-sizing: border-box;
  padding-top: 24px;
  border-bottom: 2px solid #2c5098;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .sections-wrapper {
    padding: 0px 28px;
  }

  .section {
    padding-top: 15px;
    padding-bottom: 15px;

    &:first-child {
      padding-top: 0px;
    }

    &:last-child {
      border-bottom: 0px;
      padding-bottom: 0px;
    }

    h2 {
      color: #2c5098;
      margin: 0px 0px 14px 0px;
      font-size: 16px;
    }
  }

  @media (max-height: 885px) {
    .section {
      padding-bottom: 20px;
      padding-top: 12px;
      h2 {
        margin: 0px 0px 0px 8px;
      }
    }
  }

  .fields-container {
    display: flex;
    justify-content: space-between;
    max-width: 670px;
    margin: 0px auto;
  }
`;