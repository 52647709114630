import { useEffect, useState } from 'react';
import type { GridCellProps } from '@progress/kendo-react-grid';
import {
  ComboBoxChangeEvent,
  ComboBoxFocusEvent,
} from '@progress/kendo-react-dropdowns';
import GridDropdown from './GridDropdown';
// import { AccountImportItemType } from '../../../../data/types';

export type ItemListType = {
  AcctTypeDesc?: string;
  BSSort?: string | null;
  PLBS?: string;
  PLSort?: number;
  TypeGroup?: string;
};

interface ItemCellProps extends GridCellProps {
  dataList: ItemListType[],
  disabled?: boolean;
  fieldKey?: string;
  fileKeys?: string[];
  columns?: any[];
};

const ITEM_COLUMNS = [
  { field: 'AcctTypeDesc', header: 'Account Type' },
];

export const ItemCell = (props: ItemCellProps) => {
  const [accountTypes, setAccountTypes] = useState<ItemListType[]>([]);
  const { ariaColumnIndex, columnIndex, dataItem, render, disabled, columns, fieldKey } = props;

  useEffect(() => {
    setAccountTypes(
      props.dataList.map(item => {
        return {
          ...item,
          AcctTypeDesc: item?.AcctTypeDesc,
        };
      }),
    );
  }, [props.dataList]);

  if (!render) return null;

//   const itemField = (columnTitle: keyof AccountImportItemType) => {
//     const column: any = columns?.find(col => col.title === columnTitle);
//     return column?.field;
// };
  
  const isInEdit = dataItem.inEdit = fieldKey
  // const isInEdit = fieldKey
  const value = dataItem[`${fieldKey}`];
  const accountDoesNotExist = dataItem['Reason'] === 'Invalid Account Type' ? true : false;

  const onChange = (e: ComboBoxChangeEvent) => {
    console.log(columns)
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem,
        field: fieldKey,
        syntheticEvent: e.syntheticEvent,
        value: e.value?.AcctTypeDesc,
      });
    }
  };

  const onFocus = (e: ComboBoxFocusEvent) => {
    if (props.onChange && dataItem['IsNew'] === true) {
      props.onChange({
        dataIndex: 0,
        dataItem,
        field: fieldKey,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value?.fieldKey,
      });
    }
  };

  const defaultRendering = (
    <td
      style={{ padding: 0 }}
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      tabIndex={columnIndex === 0 ? 7 : -1}
    >
      {isInEdit && accountDoesNotExist ? (
        <GridDropdown
          data={accountTypes}
          value={accountTypes.find(item => item?.AcctTypeDesc === value)}
          onChange={onChange}
          onFocus={onFocus}
          columns={ITEM_COLUMNS}
          rounded={null}
          textField='AcctTypeDesc'
          style={{ width: '100%', height: '24px' }}
          required
          disabled={disabled || false}
        />
      ) : value && dataItem['IsNew'] !== true ? (
        <p
          className={
            disabled
              ? 'combobox-grid-cell combobox-grid-cell-disabled'
              : 'combobox-grid-cell'
          }
        >
          {value.toLocaleString()}
        </p>
      ) : (
        ''
      )}
    </td>
  );

  return render.call(undefined, defaultRendering, props);
};