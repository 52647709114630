import React, { FC, useRef, useState } from 'react';

import SalesEventCodes from './SalesEventCodes';
import { ISalesCodes } from './SalesCodes.types';
import { ISalesEventCodeType } from '../../../data/types';

import { useDisableRightClick } from '../../../hooks';

import { FormContainer, Form, TabsContainer, Tab } from './SalesCodes.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';

const SalesCodes: FC<ISalesCodes> = ({ onClose, onNextPage, onPreviousPage, title, resultData }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedEventCode, setSelectedEventCode] = useState<ISalesEventCodeType>();

    useDisableRightClick(containerRef);

    const handleCurrentTab = (e: React.MouseEvent, tabIndex: number) => {
        e.preventDefault();
        setCurrentTab(tabIndex);
    };

    return (
        <>
            <FormContainer ref={containerRef}>
                <Form>
                    <div className="form-section-header-container">
                        <div className="form-title">{title}</div>
                        <div className="form-header-btn-wrapper">
                            <div className="listing-action-seperate-line" />
                            <PageNavButton
                                className="listing-action-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPreviousPage();
                                }}
                                title="Previous Page"
                            >
                                <span
                                    className="k-icon k-i-arrow-left k-button-icon"
                                    role="presentation"
                                />
                            </PageNavButton>
                            <PageNavButton
                                className="listing-action-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onNextPage();
                                }}
                                title="Next Page"
                            >
                                <span
                                    className="k-icon k-i-arrow-right k-button-icon"
                                    role="presentation"
                                />
                            </PageNavButton>
                            <CloseButton
                                className="listing-action-button"
                                onClick={onClose}
                                title="Close"
                            >
                                <span
                                    className="k-icon k-i-close k-button-icon"
                                    role="presentation"
                                />
                            </CloseButton>
                        </div >
                    </div >
                    <div className="form-content">
                        <CustomGrid
                            cols="1fr"
                            colGap="0px"
                            width="830px"
                            style={{ padding: '30px 0px 30px 30px' }}
                        >
                            <TabsContainer>
                                <Tab
                                    style={{ width: '198px' }}
                                    isActive={currentTab === 0}
                                    onClick={(e) => handleCurrentTab(e, 0)}
                                >
                                    Leads Event Codes
                                </Tab>
                                <Tab
                                    style={{ width: '199px' }}
                                    isActive={currentTab === 1}
                                    onClick={(e) => handleCurrentTab(e, 1)}
                                >
                                    Prospects Event Codes
                                </Tab>
                            </TabsContainer>
                            {currentTab === 0 && (
                                <SalesEventCodes
                                    setSelectedEventCode={setSelectedEventCode}
                                    selectedEventCode={selectedEventCode}
                                    EntType={'L'}
                                />
                            )}
                            {currentTab === 1 && (
                                <SalesEventCodes
                                    setSelectedEventCode={setSelectedEventCode}
                                    selectedEventCode={selectedEventCode}
                                    EntType={'P'}
                                />
                            )}
                        </CustomGrid>
                    </div>
                </Form >
            </FormContainer >
        </>
    );
};

export default SalesCodes;