import React, { FC, useEffect, useState, useRef } from 'react';

import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { Input, InputHandle } from '@progress/kendo-react-inputs';
import { Button, CustomInsertImageTool, FieldSelectorTool } from '../../../common';
import { TemplateFormType } from '../Templates.types';
import { ButtonWrapper, ListingGridWrapper } from '../Templates.styled';

import ReactFocusLock from 'react-focus-lock';

import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import { useKeyPress, useHighlightInput } from '../../../../hooks';

import { TemplateService } from '../../../../services/templateServices';

interface TemplateProps {
  docCode: string;
  handleOnClose: () => void;
}

const sanitizeInput = (str: string) =>
  str
    .split('\n')
    .map((s) => `<p>${s}</p>`)
    .reduce((p, c) => p + c, '');

const Template: FC<TemplateProps> = ({
  docCode,
  handleOnClose,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState<TemplateFormType>({
    IDNo: 0,
    EmailDocCode: docCode,
    Subject: '',
    BodyText: sanitizeInput(''),
  });

  const subjectRef = useRef<InputHandle>(null);
  const editorRef = useRef<Editor>(null);

  useHighlightInput('subject');
  useKeyPress(
    () => handleSave(),
    () => { },
    () => toggleDialog(),
    () => { }
  );

  const handleOnChange = (key: string, value: string | any | undefined) => {
    setFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    const getTemplate = async () => {
      try {
        const data = await TemplateService.getTemplate(docCode);
        if (
          data &&
          data.data &&
          data.data.record &&
          data.data.record.length > 0
        ) {
          setFormState((prev) => ({
            ...prev,
            IDNo: data.data.record[0].IDNo,
            EmailDocCode: data.data.record[0].EmailDocCode,
            Subject: data.data.record[0].SubjectText,
            BodyText: data.data.record[0].BodyText,
          }));
        }
      } catch (error) {
        console.log('Error fetching template:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (docCode) {
      getTemplate();
    } else {
      setIsLoading(false);
    }

    subjectRef.current?.focus();
    console.log(subjectRef);
  }, [docCode]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      setIsSaving(true);
      const saved = await TemplateService.createPatchTemplate(
        formState.IDNo ? formState.IDNo : 0,
        docCode,
        {
          EmailDocCode: docCode,
          SubjectText: formState.Subject,
          BodyText: formState.BodyText,
        }
      );

      if (saved) {
        handleOnClose();
      }
    } catch (e) {
      console.log(e);
      setIsSaving(false);
    }
  };

  const handleDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo) {
        setIsDeleting(true);
        const status = await TemplateService.deleteTemplate(formState.IDNo);

        if (status) {
          setIsDeleting(false);
          handleOnClose();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleDelete();
    }
    setVisible(!visible);
  };

  return (
    <ReactFocusLock>
      <ListingGridWrapper>
        {isLoading ? (
          <></>
        ) : (
          <>
            <Input
              id="subject"
              ref={subjectRef}
              label="Subject"
              value={formState.Subject}
              onChange={(e) => handleOnChange('Subject', e.value)}
              style={{ marginBottom: '8px', marginTop: '2px' }}
            />
            <Editor
              ref={editorRef}
              onChange={(e) => handleOnChange('BodyText', e.html)}
              value={formState.BodyText}
              tools={[
                [Bold, Italic, Underline, Strikethrough],
                [Subscript, Superscript],
                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                [Indent, Outdent],
                [Undo, Redo],
                [CustomInsertImageTool],
                [FieldSelectorTool],
                [Print],
              ]}
              defaultEditMode="div"
              style={{
                minHeight: 0,
                height: '100%',
                width: '100%',
                border: '1px solid #d6d6d6',
              }}
            />
            <ButtonWrapper>
              <Button
                onClick={handleSave}
                id="save-btn"
                title="Add New"
                disabled={isSaving || isDeleting}
              // svgIcon={plusIcon}
              >
                <u>S</u>ave
              </Button>
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
                disabled={formState.IDNo === 0}
              >
                <u>D</u>elete
              </Button>
            </ButtonWrapper>
          </>
        )}

        {visible && (
          <GenericDialog
            type="Confirmation"
            onCancel={() => setVisible(false)}
            onConfirmed={() => toggleDialog(true)}
            confirmButtonText="Delete"
            primaryMessage="Delete Template?"
          />
        )}
      </ListingGridWrapper>
    </ReactFocusLock>
  );
};

export default Template;

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  Undo,
  Redo,
  Print,
} = EditorTools;


