import type { GridColumnProps } from '@progress/kendo-react-grid';

export const userColumns: GridColumnProps[] = [
  {
    title: 'Users',
    field: 'LoginUserName',
    width: 142,
  },
  {
    title: 'Assigned Groups',
    field: 'GroupCode',
  },
];
