import { useMutation, useQuery } from '@tanstack/react-query';
import { companiesService } from '../setup/companiesService';

// export const useUpdateCompany = (callback: () => void) => {
//   return useMutation(
//     async (params: any) => companiesService.updateCompany(params),
//     {
//       onSuccess: () => {
//         callback();
//       },
//     }
//   );
// };

export const useUpdateCompany = (callback: () => void) => {
  return useMutation(
    async (params: any) => {
      return companiesService.updateCompany(params);
    },
    {
      onSuccess: () => {
        callback();
      },
    }
  );
};


export const useGetCompanyById = (id: number) => {
  return useQuery<any>([`get_vendor_by_id_${id}`], async () => {
    const { data } = await companiesService.getCompanyById(id);
    return data.record.at(0);
  });
};
