import type { GridColumnProps } from '@progress/kendo-react-grid';
// import { NumericCell } from '../../../common';
import { WholeNumericCell } from '../../../common/ListingView/NumericCell';

export const leadColumns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'EventCode',
    width: 100,
    className: 'text-left',
    sortable: true,
  },
  {
    title: 'Description',
    field: 'EventDesc',
    className: 'text-left',
    width: 360,
    sortable: true,
  },
  {
    title: 'Min Potential',
    field: 'MinPotential',
    className: 'text-right',
    sortable: true,
    // width: 140,
    cell: WholeNumericCell,
  },
  // {
  //   title: 'Qualifying',
  //   field: 'QualStat',
  //   className: 'text-left',
  //   sortable: true,
  // }
];

export const prospectColumns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'EventCode',
    width: 100,
    className: 'text-left',
    sortable: true,
  },
  {
    title: 'Description',
    field: 'EventDesc',
    className: 'text-left',
    width: 360,
    sortable: true,
  },
  {
    title: 'Min Potential',
    field: 'MinPotential',
    className: 'text-right',
    sortable: true,
    cell: WholeNumericCell,
  },
];