import { MultiColumnComboBoxColumn } from '@progress/kendo-react-dropdowns';
import { IAccount, EntityListType, AccountListType } from '../../../data/types';

export const ACCOUNT_COLUMNS = (
	name_width: number,
	dataList: IAccount[],
): MultiColumnComboBoxColumn[] => {
	const columns = [
		{
			field: 'AccountNo',
			width: dataList.length > 10 ? name_width - (name_width * 0.007392) : name_width - (name_width * 0.0403),
		},
		{
			field: 'AccountName',
			width: dataList.length > 10 ? 225 : 210,
		},
	];

	function allSameAccountType(accounts: IAccount[]): boolean {
		if (accounts?.length === 0) return true; // Assuming true for empty array
		const firstType = accounts[0]?.AccountType;
		return accounts?.every(account => account?.AccountType === firstType);
	}

	if (!allSameAccountType(dataList)) {
		columns.push({
			field: 'AccountType',
			width: 130,
		});
	}
	return columns;
};

export const ENTITY_COLUMNS = (
	name_width: number,
	dataList: EntityListType[],
): MultiColumnComboBoxColumn[] => {
		const columns = [
			{
				field: 'EntityName',
				width: name_width,
			},
			{
				field: 'City',
				width: 200,
			},
			{
				field: 'State',
				width: 60,
			},
		];

	if (dataList.filter((item) => item.EntityType)?.length) {
		columns.push({
			field: 'EntityType',
			width: 30,
		});
	}

	return columns;
};

export const SINGLE_COLUMNS = (
	name_width: number,
	field: string
): MultiColumnComboBoxColumn[] => [
		{
			field: field,
			width: name_width - 20,
		},
	];

export const US_STATE_COLUMNS = (name_width: number) => [
	{
		field: 'State',
		width: name_width,
	},
	{
		field: 'StateName',
		width: 130,
	},
];

export const LOCATION_STATE_COLUMNS = (name_width: number) => [
	{
		field: 'state',
		width: name_width,
	},
	{
		field: 'stateName',
		width: 130,
	},
];

export const PAYFREQ_COLUMNS = (name_width: number) => [
	{
		field: 'value',
		width: name_width - 5,
	},
	{
		field: 'label',
		width: 120,
	},
];

export const FICA_COLUMNS = (name_width: number) => [
	{
		field: 'AccountNo',
		width: name_width - 5,
	},
	{
		field: 'AccountName',
		width: 200,
	},
];

export const ORG_OPTIONS_COLUMNS = (name_width: number) => [
	{
		field: 'value',
		width: name_width,
	},
	{
		field: 'name',
		width: 130,
	},
];

export const PERM_GROUPS_COLUMNS = (
	name_width: number
): MultiColumnComboBoxColumn[] => {
	const columns = [
		{
			field: 'PermGroupCode',
			width: name_width - 11,
		},
		{
			field: 'PermGroupDesc',
			width: 150,
		},
	];

	return columns;
};

export const BANKACCT_COLUMNS = (
	name_width: number,
	dataList: AccountListType[]
  ): MultiColumnComboBoxColumn[] => [
	{
	  field: 'AccountNo',
	  width: name_width,
	},
	{
	  field: 'AccountName',
	  width: dataList.length > 10 ? 225 : 210,
	},
];

export const VENDOR_EXPENSE_ACCOUNTS_COLUMNS = (
	name_width: number,
	dataList: any[]
  ): MultiColumnComboBoxColumn[] => [
	{
	  field: 'AccountNo',
	  width: name_width,
	},
	{
	  field: 'AccountName',
	  width: dataList.length > 10 ? 225 : 210,
	},
	{
	  field: 'AccountType',
	  width: dataList.length > 10 ? 168 : 155,
	},
  ];