import { Window, WindowHandle, WindowMoveEvent, WindowProps } from '@progress/kendo-react-dialogs';
import { ForwardedRef, ReactNode, forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

type MoveableDialogProps = {
  children: ReactNode;
} & WindowProps

const MoveableDialog = ({ children, ...props }: MoveableDialogProps, ref: ForwardedRef<WindowHandle | null>) => {
  const windowRef = useRef<WindowHandle>(null);
  const [windowPos, setWindowPos] = useState({ top: 0, left: 0 });
  useImperativeHandle(ref, () => windowRef.current!)

  useLayoutEffect(() => {
    const dialog = windowRef.current?.element;
    setWindowPos({
      top: window.innerHeight / 2 - (dialog?.clientHeight ?? 0) / 2,
      left: window.innerWidth / 2 - (dialog?.clientWidth ?? 0) / 2,
    })
  }, [])

  const handleDrag = (e: WindowMoveEvent) => {
    const dialog = windowRef.current?.element;
    const futureBound = {
      top: e.top,
      bottom: (dialog?.getBoundingClientRect().height || 0) + e.top,
      right: (dialog?.getBoundingClientRect().width || 0) + e.left,
      left: e.left,
    };
    if (futureBound.top > 1 && futureBound.bottom <= window.innerHeight) {
      setWindowPos(prev => ({
        ...prev,
        top: e.top,
      }))
    }
    if (futureBound.left > 1 && futureBound.right <= window.innerWidth) {
      setWindowPos(prev => ({
        ...prev,
        left: e.left,
      }))
    }
  }

  return <Window
    shouldUpdateOnDrag={false}
    modal
    ref={windowRef}
    resizable={false}
    style={{
      height: props.height || 'fit-content',
      width: props.width || 'fit-content',
      minHeight: props.height || 'max-content',
      minWidth: props.width || 'max-content',
    }}
    minimizeButton={() => null}
    maximizeButton={() => null}
    doubleClickStageChange={false}
    {...windowPos}
    onMove={handleDrag}
    {...props}
  >
    {children}
  </Window>
}

export default forwardRef(MoveableDialog);