import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const permsService = {
  // Perm methods
  getPerms: async () => {
    const response = await axiosClient().get('/Perm/PermCodes/GetList', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
  assignPermToGroupRole: (params: any) => {
    return axiosClient().post(
      '/Perm/PermAssigned',
      { ...params },
      {
        headers: { ...commonHeaders },
      }
    );
  },
  unAssignPermToGroupRole: (IDNo: any) => {
    return axiosClient().delete('/Perm/PermAssigned', {
      params: {
        IDNo: IDNo,
      },
      headers: { ...commonHeaders },
    });
  },
  saveAllPerms: (params: any) => {
    const formData = new FormData();

    params.forEach((item: any) => {
      formData.append('PermDataL', JSON.stringify(item));
    });

    return axiosClient().post(
      '/Perm/PermAssigned/SavePerms',
      formData,
      {
        headers: {
          ...commonHeaders,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  // User methods
  getUsers: async () => {
    const response = await axiosClient().get(`Setup/User/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    console.log('testData 1', response);
    console.log('testData 2', response.data.data.records);
    return { data: response.data.data.records };
  },
  getUserRoles: async (userId: string | number) => {
    const response = await axiosClient().get(
      `/Perm/PermUserRoles/GetList?UserID=${userId}`,
      {
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return { data: response.data.data.records };
  },
  assignRoleToUser: (params: any) => {
    return axiosClient().post(
      '/Perm/PermUserRoles',
      { ...params },
      {
        headers: { ...commonHeaders },
      }
    );
  },
  unAssignRoleToUser: (UserID: number, RoleCode: string) => {
    return axiosClient().delete('/Perm/PermUserRoles', {
      params: {
        UserID: UserID,
        RoleCode: RoleCode,
      },
      headers: { ...commonHeaders },
    });
  },
  createUser: (params: any) =>
    axiosClient().patch(
      '/Setup/User',
      { ...params },
      {
        params: {
          IDNo: 0,
          bNew: true,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateUser: (params: any) => {
    const id = params.id;
    return axiosClient().patch(
      '/Setup/User',
      { ...params.data },
      {
        params: {
          IDNo: id,
          bNew: false,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteUserById: (id: number) =>
    axiosClient().delete('/Setup/User', {
      params: {
        IDNo: id,
      },

      headers: { ...commonHeaders },
    }),
  // Group methods
  getGroups: async () => {
    const response = await axiosClient().get('/Perm/PermGroups/GetList', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
  getGroupPerms: async (groupCode: string) => {
    const response = await axiosClient().get(
      `/Perm/PermAssigned/GetGroupPerms?GroupCode=${groupCode}`,
      {
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return { data: response.data.data.records };
  },
  createGroup: (params: any) =>
    axiosClient().post(
      '/Perm/PermGroups',
      { ...params },
      {
        params: {
          GroupCode: params.PermGroupCode,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateGroup: (params: any) => {
    const id = params.id;

    return axiosClient().patch(
      '/Perm/PermGroups',
      { ...params.data },
      {
        params: {
          GroupCode: id,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteGroupById: (id: number) =>
    axiosClient().delete('/Perm/PermGroups', {
      params: {
        GroupCode: id,
      },

      headers: { ...commonHeaders },
    }),
  // Role methods
  getRoles: async () => {
    const response = await axiosClient().get('/Perm/PermRoles/GetList', {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response.data.data.records };
  },
  getRolePerms: async (roleCode: string) => {
    const response = await axiosClient().get(
      `/Perm/PermAssigned/GetRolePerms?RoleCode=${roleCode}`,
      {
        headers: { ...commonHeaders },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      }
    );
    return { data: response.data.data.records };
  },
  createRole: (params: any) =>
    axiosClient().post(
      '/Perm/PermRoles',
      { ...params },
      {
        params: {
          RoleCode: params.PermRoleCode,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateRole: (params: any) => {
    const id = params.id;

    return axiosClient().patch(
      '/Perm/PermRoles',
      { ...params.data },
      {
        params: {
          RoleCode: id,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteRoleById: (id: number) =>
    axiosClient().delete('/Perm/PermRoles', {
      params: {
        RoleCode: id,
      },
      headers: { ...commonHeaders },
    }),
};
