import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  TimePicker,
  TimePickerChangeEvent,
  TimePickerHandle,
  TimePickerProps,
} from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup/dist/npm/Popup';

import CustomTimeInput from './CustomTimeInput';

export type CustomTimePickerChangeEvent = Partial<TimePickerChangeEvent>;

type Props = Omit<TimePickerProps, 'ref' | 'onChange'> & {
  // onChange: (event: CustomTimePickerChangeEvent) => void;
  onChange: (event: TimePickerChangeEvent) => void;
};

const CustomTimePicker = React.forwardRef<TimePickerHandle, Props>(
  (props, ref) => {
    const { onChange, width, tabIndex, disabled } = props;

    const timePickerRef = useRef<TimePickerHandle>(null);
    useImperativeHandle(ref, () => timePickerRef.current as TimePickerHandle);

    const [open, setOpen] = useState<boolean>(false);

    // @info: For opening dropdown with double clicking input
    useEffect(() => {
      const inputElement =
        timePickerRef?.current?.element?.querySelector('input');

      const handleInputDoubleClick = () => {
        if (open) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      };

      if (inputElement) {
        inputElement.addEventListener('dblclick', handleInputDoubleClick);
      }

      return () => {
        const inputElement =
        // eslint-disable-next-line react-hooks/exhaustive-deps
          timePickerRef?.current?.element?.querySelector('input');

        if (inputElement) {
          inputElement.removeEventListener('dblclick', handleInputDoubleClick);
        }
      };
    }, [open]);

    // @info: For opening dropdown on clicking input button
    useEffect(() => {
      const buttonElement =
        timePickerRef?.current?.element?.querySelector('button');

      const handleButtonClick = () => {
        if (open) {
          setOpen(false);
          setTimeout(() => {
            timePickerRef.current?.element?.querySelector('input')?.focus();
          }, 50);
        } else {
          setOpen(true);
        }
      };

      if (buttonElement)
        buttonElement.addEventListener('click', handleButtonClick);

      return () => {
        if (buttonElement)
          buttonElement.removeEventListener('click', handleButtonClick);
      };
    }, [open]);

    // @info: For opening dropdown on pressing Insert key
    useEffect(() => {
      function handleKeyPress(event: KeyboardEvent) {
        const { key } = event;

        if (key === 'Help' || key === 'Insert') {
          event.preventDefault();
          if (open) {
            setOpen(false);
          } else {
            setOpen(true);
          }
        }
      }

      timePickerRef?.current?.element?.addEventListener(
        'keydown',
        handleKeyPress,
      );

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timePickerRef?.current?.element?.removeEventListener(
          'keydown',
          handleKeyPress,
        );
      };
    }, [open, onChange]);

    // @info: For Popup related events
    useEffect(() => {
      const popupEl = document.querySelector('.custom-timepicker-popup') as HTMLDivElement;
      const popupCancelButton = popupEl?.querySelector(
        'button[aria-label="Cancel"]',
      ) as HTMLButtonElement;
      const popupSetButton = popupEl?.querySelector(
        'button[aria-label="Set"]',
      ) as HTMLButtonElement;
      const popupNowButton = popupEl?.querySelector(
        'button[aria-label="Select Now"]',
      ) as HTMLButtonElement;

      const focusCurrentInputElement = () => {
        const inputElement =
          timePickerRef?.current?.element?.querySelector('input');
        inputElement?.focus();
        timePickerRef.current?.focus();
      };

      const handlePopupKeyPress = (event: KeyboardEvent) => {
        const { key } = event;

        if (
          key === 'Help' ||
          key === 'Insert' ||
          key === 'Tab' ||
          key === 'Enter' ||
          key === 'Escape'
        ) {
          if (open) {
            setOpen(false);
            focusCurrentInputElement();
          } else {
            setOpen(true);
          }
        }
      };

      const handleClickPopupButton = () => {
        if (open) {
          setOpen(false);
          focusCurrentInputElement();
        }
      };

      const handleClickOutsidePopup = (event: MouseEvent) => {
        if (
          !(
            timePickerRef?.current?.element?.contains(event.target as Node) ||
            (popupEl?.contains(event.target as Node) && open)
          )
        ) {
          setOpen(false);
          focusCurrentInputElement();
        }
      };

      if (popupEl && open) {
        popupEl.addEventListener('keydown', handlePopupKeyPress);
        document.addEventListener('mousedown', handleClickOutsidePopup);
      }
      if (popupEl && !open) {
        popupEl?.removeEventListener('keydown', handlePopupKeyPress);
        document.removeEventListener('mousedown', handleClickOutsidePopup);
      }

      if (popupCancelButton && open) {
        popupCancelButton.addEventListener('click', handleClickPopupButton);
      }
      if (popupCancelButton && !open) {
        popupCancelButton.removeEventListener('click', handleClickPopupButton);
      }

      if (popupSetButton && open) {
        popupSetButton.addEventListener('click', handleClickPopupButton);
      }
      if (popupSetButton && !open) {
        popupSetButton.removeEventListener('click', handleClickPopupButton);
      }

      if (popupNowButton && open) {
        popupNowButton.addEventListener('click', handleClickPopupButton);
      }
      if (popupNowButton && !open) {
        popupNowButton.removeEventListener('click', handleClickPopupButton);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutsidePopup);
        if (popupEl) {
          popupEl?.removeEventListener('keydown', handlePopupKeyPress);
        }
        if (popupCancelButton) {
          popupCancelButton.removeEventListener(
            'click',
            handleClickPopupButton,
          );
        }
        if (popupSetButton) {
          popupSetButton.removeEventListener('click', handleClickPopupButton);
        }
        if (popupNowButton) {
          popupNowButton.removeEventListener('click', handleClickPopupButton);
        }
      };
    }, [open, setOpen, timePickerRef]);

    return (
      <span className='k-floating-label-container'>
        <label className='k-label'>{props.label}</label>
        <TimePicker
          {...props}
          ref={timePickerRef}
          fillMode='solid'
          label={undefined}
          width={width || 110}
          tabIndex={tabIndex || -1}
          disabled={disabled || false}
          onChange={e => {
            onChange?.(e);
          }}
          show={open}
          dateInput={CustomTimeInput}
          popup={Popup}
          popupSettings={{
            popupClass: 'custom-timepicker-popup',
          }}
        />
      </span>
    );
  },
);

export default CustomTimePicker;
