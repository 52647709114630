import type { GridColumnProps } from "@progress/kendo-react-grid";
import { NumericCell } from "../../common";

export const columns: GridColumnProps[] = [
    {
        title: 'Jurisdiction',
        field: 'Jurisdiction',
        width: '120',
    },
    {
        title: 'Pay To',
        field: 'PayToName',
        width: '363',
    },
    {
        title: 'From',
        field: 'FromZip',
        width: '100',
    },
    {
        title: 'To',
        field: 'ToZip',
        width: '100',
    },
    {
        title: 'Rate',
        field: 'Rate',
        cell: NumericCell,
    },
];