import styled from 'styled-components';

export const Form = styled.form`
  border: 1px solid #d6d6d6;
  height: calc(100vh - 116px);

  .form-section-header-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(209 213 219 / 1);
    /* border-color:; */
    background-color: rgb(250 250 250 / 1);
    padding-left: 1.25rem;
    padding-right: 0.375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    .form-title {
      font-weight: 600;
    }

    .form-header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .listing-action-button {
        background-color: transparent;
        border: 1px solid rgb(209, 213, 219);
        cursor: pointer;
        padding: 4px;
        display: flex;
        height: 30px;
        width: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        color: rgb(51 51 51 / 1);

        &:hover {
          border-color: rgb(44 80 152 / 1);
          color: rgb(44 80 152 / 1);
        }
      }
    }
  }

  .form-content {
    padding: 30px 0px 30px 30px;
    height: calc(100vh - 160px);
    max-height: calc(100vh);
    overflow-y: hidden;
  }

  .form-footer {
    border: 1px solid #c3c3c3;
    border-bottom: 2px solid #2c5098;
    border-top: 0px;

    width: 800px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.16);
  }

  .contacts-wrapper {
    .contacts-label {
      font-size: 16px;
      font-weight: 400;
      color: #2c5098;
    }
  }

  #logo-upload {
    display: block !important;

    &:focus {
      padding-left: 144px;
      width: 1px;
      max-height: 20px;
      opacity: 1;
      bottom: 7px;
      right: 16px;
      border: 1px solid #c3c3c3;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin: 10px 10px 10px 0px;
  align-items: center;
  justify-content: flex-end;
`;

export const FieldWrapper = styled.div<{
  width: string;
}>`
  flex-basis: ${(props) => props.width};
  .k-floating-label-container {
    width: 100%;
  }
`;

export const AddressFieldWrapper = styled.div<{ width: string }>`
  flex-basis: ${(props) => props.width};
  .k-floating-label-container {
    width: 100%;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    & > span {
      flex: 1;
    }
  }
`;

export const CompanyGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
`;

export const CompanyGridItem = styled.div<{
  paddingTop?: string;
  paddingBottom?: string;
  hideBorder?: boolean;
}>`
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  padding-top: ${(props) => props.paddingTop || '0px'};
  border-bottom: ${(props) =>
    props.hideBorder ? '0px' : '2px solid lightgray'};
`;

export const CompanySubGridItem = styled.div``;

export const RightLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  width: 585px;

  label {
    width: 410px;
    font-size: 12px;
    color: #424242;
  }
`;

export const RightLabelGridInputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 410px 175px 30px;
  gap: 8px;
  /* width: 100%; */

  label {
    width: 410px;
    font-size: 12px;
    color: #424242;
  }
`;

export const IdentitySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* padding: 50px; */
`;

export const LeftLabelInputsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CenteredText = styled.p`
  text-align: center;
  margin: 0px;
  font-size: 12px;
  color: #424242;
`;

export const ContactGrid = styled.div`
  max-width: 725px;
  margin: 0px auto;
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 20px 180px 180px 120px 200px;
  gap: 6px;

  span {
    text-align: center;
    color: #424242;
    font-size: 12px;
  }

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AddressCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;

  .k-checkbox-wrap {
    align-self: center;
  }

  label {
    color: #2c5098;
    padding-right: 12px;
    font-size: 12px;
  }

  .k-checkbox:checked,
  .k-checkbox.k-checked {
    border-color: #2c5098;
    color: white;
    background-color: #2c5098;
  }

  .k-checkbox:checked:focus,
  .k-checkbox.k-checked.k-focus {
    box-shadow: 0 0 0 2px #2c509821;
  }
`;
export const FileUploadWrapper = styled.div`
  width: 600px;
  margin: 0px auto;
  margin-top: 12px;

  .k-upload-dropzone {
    height: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid #c3c3c3;
    background: #f6f5f6;
  }

  .k-upload-status {
    position: absolute !important;
    display: none !important;
    bottom: 14px;
  }

  .k-dropzone-hint {
    display: none;
  }

  .k-button {
    border: none;
    background: none;

    &:focus {
      box-shadow: none !important;
    }
  }

  .upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 28px;
    }

    .k-i-upload {
      margin-bottom: 28px;
      font-size: 46px;
      color: #b4b4bb;
    }

    .bold-message {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 1rem;
      text-transform: none;
    }

    .select-message {
      font-size: 12px;
      color: #2c5098;
      border: 1px solid transparent;
      padding: 0px 4px;
      border-radius: 4px;
      text-transform: none;
    }
  }

  .file-type-message {
    text-align: center;
    color: #878787;
    margin-top: 1rem;
    font-size: 11px;
  }

  .image-size-message {
    text-align: center;
    margin-top: 12px;
    font-size: 11px;
    color: #424242;
  }

  .k-upload-button-wrap button:focus {
    .select-message {
      border-color: #2c5098;
    }
  }
`;

export const FormContainer = styled.div`
  display: block;
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  padding: 10px;
`;

export const FormFieldsWrapper = styled.div<{ isActive?: boolean }>`
  border-top: ${(props) => (props.isActive ? '2px solid #2c5098' : '0px')};
  border-bottom: ${(props) => (props.isActive ? '1px solid #c3c3c3' : '0px')};
  border-inline: ${(props) => (props.isActive ? '1px solid #c3c3c3' : '0px')};

  opacity: ${(props) => (props.isActive ? '1' : '0')};
  width: ${(props) => (props.isActive ? '800px' : '0px')};
  position: relative;
  box-sizing: border-box;
  height: ${(props) => (props.isActive ? 'calc(100vh - 310px)' : '0px')};
  max-height: ${(props) => (props.isActive ? 'calc(100vh - 310px)' : '0px')};
  overflow: hidden;

  * {
    ${(props) =>
      !props.isActive &&
      `
    height: 0px !important;
    width: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    opacity: 0 !important;
    border: 0px !important;`}
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 32px;
  line-height: 20px;
  outline: none;
  border: 0px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;

  background: ${(props) => (props.isActive ? '#5A7699' : '#f1f1f3')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
`;
