import { axiosClient } from '../axiosClient';

// const companyID = 'com_dcyzD7hjueLXwbbKF9YC'
export const checkService = {
  getComponent: async (
    companyID: string,
    componentName: string,
    payload: any
  ) => {
    const response = await axiosClient().post(
      `/Payroll/TP_Check/OnboardCheckCompanyComponent?CompanyID=${companyID}&ComponentName=${componentName}`,
      payload
    );
    return response.data;
  },
};
