import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const documentsService = {
  getFileUrl: async (id: string | number) => {
    const response = await axiosClient().get('/Document/GetFileURL', {
      params: {
        FileID: id,
      },
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return { data: response?.data?.data?.fileURL };
  },
};
