import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
  Checkbox,
  CheckboxHandle
} from '@progress/kendo-react-inputs';
import {
  ComboBoxChangeEvent,
} from '@progress/kendo-react-dropdowns';
import ReactFocusLock from 'react-focus-lock';

import { IPayGroupDialogProps, PayGroupCategoryType } from './PayGroupDialog.types';

import {
  Button,
  CustomColComboBox,
  InputWrapper,
} from '../../../../common';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validatePayGroupForm } from '../../../../../utils';
import { payGroupsService } from '../../../../../services/payroll/payGroupsService';
import { PAY_FREQUENCIES } from '../../../../../constants';

import { CustomButtonWrapper, DialogContent } from './PayGroupDialog.styled';
import { StyledTitle } from '../../Departments/DepartmentDialog/DepartmentDialog.styled';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';

import SelectableGrid, { SelectableCheckboxCell } from '../../../../common/SelectableGrid';
import {
  Grid,
  GridColumn,
  GridSelectionChangeEvent,
  GridHeaderCellProps,
  GridKeyDownEvent,
  GridRowDoubleClickEvent,
  GridCustomHeaderCellProps,
} from '@progress/kendo-react-grid';
import { HeaderThElement } from '@progress/kendo-react-data-tools';

type GridItemType = Record<string, any>;

export type FICAFormData = {
  PayGroupCode: string;
  PayGroupDesc: string;
  PayFrequency: string;
  AccountNo: string;
}

const PayGroupDialog: React.FC<IPayGroupDialogProps> = ({
  isEdit,
  data,
  handleClose,
  dataTemplate,
  ptoCodes,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    PayGroupCode: isEdit && data ? data.PayGroupCode : '',
    PayGroupDesc: isEdit && data ? data.PayGroupDesc : '',
    PayFrequency:
      isEdit && data
        ? {
          value: PAY_FREQUENCIES.find(
            (option) => option.label === data.PayFrequency
          )?.value,
        }
        : { value: '' },
    FICAMatch: isEdit && data ? data.FICAMatch : '',
  });
  const [FICAData, setFICAData] = useState<FICAFormData[]>();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [ptoIsChanged, setPTOisChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);
  const timeOffGridRef = useRef<Grid>(null);
  const holidayGridRef = useRef<Grid>(null);
  const checkboxref = useRef<CheckboxHandle>(null);

  const [timeOffList, setTimeOffList] = useState(ptoCodes?.filter((code: any) => code.PayCodeCategory === "T"));
  const [holidayList, setHolidayList] = useState(ptoCodes?.filter((code: any) => code.PayCodeCategory === "H"));

  useHighlightInput('pay-group-code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    const getPayGroup = async () => {
      const response = await payGroupsService.getPayGroupData(data.PayGroupCode || undefined);
      const PTOList = response.data.thisGroupPTOCodes;
      setFICAData(response.data.combos.CurrentLiability);
      //Can't use the reset FICAData state variable, so the reponse must be used directly
      const FICAResponse = response.data.combos.CurrentLiability as FICAFormData[];
      if (response.data.record.length > 0) {
        const FICAMatch = response.data.record[0].FICAMatch;
        if (FICAResponse !== undefined && FICAMatch !== undefined && FICAMatch !== null) {
          setFormState((prevState) => ({ ...prevState, FICAMatch: FICAResponse.find((d) => d.AccountNo === FICAMatch) }));
        }
      }

      if (PTOList) {
        setTimeOffList(prevList =>
          prevList?.map(code => ({
            ...code,
            checked: PTOList.some((pto: any) => pto.PTOCode === code.PayCode),
          }))
        );
        setHolidayList(prevList =>
          prevList?.map(code => ({
            ...code,
            checked: PTOList.some((pto: any) => pto.HolidayCode === code.PayCode),
          }))
        );
      }
    }
    getPayGroup();
  }, [data.PayGroupCode]);

  useEffect(() => {
    setIsValid(validatePayGroupForm(formState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayGroupCode) {
        setIsSaving(true);
        const saved = data.PayGroupCode
          ? await payGroupsService.updatePayGroup(data.PayGroupCode, {
            PayGroupCode: formState.PayGroupCode,
            PayGroupDesc: formState.PayGroupDesc,
            PayFrequency: formState.PayFrequency?.value,
            FICAMatch: formState.FICAMatch.AccountNo
          })
          : await payGroupsService.createPayGroup(formState.PayGroupCode, {
            PayGroupCode: formState.PayGroupCode,
            PayGroupDesc: formState.PayGroupDesc,
            PayFrequency: formState.PayFrequency?.value,
            FICAMatch: formState.FICAMatch.AccountNo
          });

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSavePTOCodes = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayGroupCode && timeOffList && holidayList) {
        const payload: PayGroupCategoryType[] = [...timeOffList, ...holidayList].filter((code) => code.checked === true);

        const mappedPayload: any[] = payload.map((item) => {
          if (item.PayCodeCategory === 'T') {
            return {
              ptoCode: item.PayCode,
              holidayCode: ''
            }
          } else if (item.PayCodeCategory === 'H') {
            return {
              holidayCode: item.PayCode,
              ptoCode: ''
            };
          }
          return {};
        });
        await payGroupsService.postGroupPTOCodes(formState.PayGroupCode, mappedPayload);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.PayGroupCode) {
        setIsDeleting(true);
        const status = await payGroupsService.deletePayGroupById(
          formState.PayGroupCode
        );

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    if (key === 'PayGroupCode') {
      const inputValue = event.value.toUpperCase();
      setFormState((prevState) => ({
        ...prevState,
        [key]: inputValue.replace(/[^a-zA-Z0-9]/g, ''),
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>Setup Pay Group</span>
        {isEdit && (
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
            title="Delete"
            disabled={isSaving || isDeleting}
          ></button>
        )}
      </StyledTitle>
    );
  };

  const getMaxLength = (inputName: string) => {
    return dataTemplate.fieldProperties.find(
      (prop: any) => prop.columnName === inputName
    ).maxLength;
  };

  // const PAY_FREQUENCIES_COLUMNS = [
  //   { field: 'value', width: 80 },
  //   { field: 'label', width: 100 },
  // ];

  // const centerAlignedItem: MultiColumnComboBoxProps['itemRender'] = (
  //   li,
  //   props
  // ) => {
  //   const el = PAY_FREQUENCIES_COLUMNS.map((col, index) => {
  //     return (
  //       <span
  //         key={col.field + index}
  //         className="k-table-td"
  //         style={{
  //           width: col.width,
  //           textAlign:
  //             col.field === 'value'
  //               ? 'center'
  //               : col.field === 'label'
  //                 ? 'left'
  //                 : 'left',
  //         }}
  //       >
  //         {props.dataItem[col.field]}
  //       </span>
  //     );
  //   });

  //   return React.cloneElement(li, { ...li.props }, el);
  // };

  const selectAllHeaderCell = (props: GridHeaderCellProps, list: PayGroupCategoryType[]) => {
    const onChange = (event: any) => {
      props.selectionChange({ field: 'checked', syntheticEvent: event, });
    };
    return (
      <span>
        <Checkbox
          className={''}
          title="checkbox"
          id="selectAllCheckbox"
          ref={checkboxref}
          tabIndex={10}
          checked={list?.every((code) => code?.checked)}
          onChange={onChange}
          onFocus={(e) => {
            setTimeout(() => {
              checkboxref.current?.focus();
            });
          }}
        />
      </span>
    );
  };

  const CheckboxHeaderCell = (props: GridCustomHeaderCellProps) => {
    return (
      <HeaderThElement
        className=""
        columnId={props.thProps?.columnId || ''}
        {...props.thProps}
        style={{ paddingBottom: '6px', paddingLeft: '8px', lineHeight: 1 }}
      >
        {props.children}
      </HeaderThElement>
    );
  };

  const MyHeaderCell = (props: GridCustomHeaderCellProps) => {
    return (
      <HeaderThElement
        columnId={props.thProps?.columnId || ''}
        {...props.thProps}
        style={{ padding: '4px', lineHeight: 1 }}
      >
        {props.children}
      </HeaderThElement>
    );
  };

  const enterEdit = async (
    dataItem: GridItemType,
    field: string | undefined,
    setPTOList: any
  ) => {
    setPTOList((prev: any) =>
      prev.map((code: any) => ({
        ...code,
        inEdit: code.PayCode === dataItem.PayCode ? field : undefined,
      }))
    );
  };

  const exitEdit = (setPTOList: any) => {
    console.log(`exitEdit`);
    setPTOList((prev: any) =>
      prev.map((item: any) => ({
        ...item,
        selected: false,
        inEdit: undefined
      }))
    );
  };

  const undoEdit = (rowIndex: number) => { };

  const onTimeOffSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      if (!event.dataItem) return;
      // console.log(`onSelectionChange`, event.dataItem);
      setIsChanged(true);
      setPTOisChanged(true);
      setTimeOffList((prev) =>
        prev?.map((item) => ({
          ...item,
          checked: item?.PayCode === event?.dataItem?.PayCode
            ? !item.checked
            : item.checked,
          selected:
            item?.PayCode === event?.dataItem?.PayCode
              ? !item.selected
              : item.selected,
        }))
      );
    },
    [setTimeOffList]
  );

  const onHolidaySelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      if (!event.dataItem) return;
      // console.log(`onSelectionChange ${event.dataItem}`);
      setIsChanged(true);
      setPTOisChanged(true);
      setHolidayList((prev) =>
        prev?.map((item) => ({
          ...item,
          checked: item?.PayCode === event?.dataItem?.PayCode
            ? !item.checked
            : item.checked,
          selected:
            item?.PayCode === event?.dataItem?.PayCode
              ? !item.selected
              : item.selected,
        }))
      );
    },
    [setHolidayList]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: any, setGridState: any) => {
      const checked = event.syntheticEvent.value;
      setGridState((prev: any) =>
        prev?.map((item: any) => ({
          ...item,
          checked: checked,
          selected: checked,
        }))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTimeOffList, setHolidayList]
  );

  const gridKeyDown = (ev: GridKeyDownEvent) => {
    //may change
    if (ev.nativeEvent.key === 'Enter') {
      const targetTR = ev.nativeEvent.target.closest('TD');
      var event = new MouseEvent('dblclick', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      if (targetTR) {
        targetTR?.dispatchEvent(event);
      } else {
        const targetInput = ev.nativeEvent.target.closest('input');
        targetInput.click();
      }
    }
  };

  const onRowDoubleClick = (props: GridRowDoubleClickEvent) => {
    // setAdjustInvoicePayment({ ...props.dataItem });
    console.log(props);
  };

  return (
    <MovableDialog
      onClose={() => handleClose()}
      className="paygroup-dialog"
      title={<CustomWindowTitle />}
    >
      <DialogContent>
        <ReactFocusLock>
          <div
            ref={formRef}
            onKeyDown={(e) => handleKeyDown(e, formRef)}
          >
            <div className='form-container'>
              <div className="form-fields">
                <InputWrapper
                  maxWidth="85px"
                  width="85px"
                  name="PayGroupCode"
                  label="Code"
                  id='pay-group-code'
                  onChange={handleOnChange}
                  value={formState.PayGroupCode}
                  maxLength={getMaxLength('PayGroupCode')}
                  autoComplete="off"
                  tabIndex={1}
                  isDisabled={data.PayGroupCode}
                />
                <InputWrapper
                  maxWidth="200px"
                  width="150px"
                  name="PayGroupDesc"
                  label="Description"
                  onChange={handleOnChange}
                  value={formState.PayGroupDesc}
                  maxLength={25}
                  autoComplete="off"
                  tabIndex={2}
                />
                <CustomColComboBox
                  name="PayFrequency"
                  width={80}
                  textField="value"
                  comboBoxType="PAY_FREQUENCY"
                  dataList={PAY_FREQUENCIES}
                  onChange={(e) => handleOnChange(e, 'PayFrequency')}
                  value={formState.PayFrequency}
                  label="Interval"
                  tabIndex={3}
                />
                <CustomColComboBox
                  name='FICA_PAY'
                  width={120}
                  comboBoxType='FICA_PAY'
                  dataList={FICAData}
                  onChange={(e) => handleOnChange(e, 'FICAMatch')}
                  value={formState.FICAMatch}
                  label="FICA Match"
                  tabIndex={4}
                  textField='AccountNo'
                />
              </div>

              <div className='grid-fields'>
                <SelectableGrid
                  rowHeight={30}
                  detailRowHeight={30}
                  className={`listing-grid`}
                  onSelectionChange={onTimeOffSelectionChange}
                  onHeaderSelectionChange={(e) => onHeaderSelectionChange(e, setTimeOffList)}
                  selectedField="selected"
                  onRowDoubleClick={onRowDoubleClick}
                  onKeyDown={gridKeyDown}
                  data={timeOffList?.map(code => ({
                    ...code,
                    inEdit: true,
                  }))}
                  enterEdit={(dataItem, field) => enterEdit(dataItem, field, setTimeOffList)}
                  exitEdit={() => exitEdit(setTimeOffList)}
                  undoEdit={undoEdit}
                  ref={timeOffGridRef}
                  tabIndex={4}
                  style={{ height: '180px' }}
                >
                  <GridColumn
                    field="checked"
                    width="33px"
                    headerCell={(props) => selectAllHeaderCell(props, timeOffList || [])}
                    cell={SelectableCheckboxCell}
                    cells={{ headerCell: CheckboxHeaderCell }}
                  />
                  <GridColumn
                    field="PayDesc"
                    title={String('Time-Off')}
                    width="137px"
                    key={1}
                    cells={{ headerCell: MyHeaderCell }}
                  />
                </SelectableGrid>

                <SelectableGrid
                  size="small"
                  rowHeight={30}
                  detailRowHeight={30}
                  className={`listing-grid`}
                  onSelectionChange={onHolidaySelectionChange}
                  onHeaderSelectionChange={(e) => onHeaderSelectionChange(e, setHolidayList)}
                  selectedField="selected"
                  onRowDoubleClick={onRowDoubleClick}
                  onKeyDown={gridKeyDown}
                  data={holidayList?.map(code => ({
                    ...code,
                    inEdit: true,
                  }))}
                  enterEdit={(dataItem, field) => enterEdit(dataItem, field, setHolidayList)}
                  exitEdit={() => exitEdit(setHolidayList)}
                  undoEdit={undoEdit}
                  ref={holidayGridRef}
                  tabIndex={5}
                  style={{ height: '180px' }}
                >
                  <GridColumn
                    field="checked"
                    width="33px"
                    headerCell={(props) => selectAllHeaderCell(props, holidayList || [])}
                    cell={SelectableCheckboxCell}
                    cells={{ headerCell: CheckboxHeaderCell }}
                  />
                  <GridColumn
                    field="PayDesc"
                    title={String('Holidays')}
                    width="137px"
                    key={1}
                    cells={{ headerCell: MyHeaderCell }}
                  />
                </SelectableGrid>
              </div>
            </div>

          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
                if (ptoIsChanged) {
                  handleSavePTOCodes();
                }

              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  toggleDialog();
                }}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </ReactFocusLock>
      </DialogContent>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MovableDialog>
  );
};

export default PayGroupDialog;