import {
  useState,
  useRef,
  ComponentRef,
  useCallback,
  useEffect,
} from 'react';
import MainComponent from '../../MainComponent';
import { authenticateSetup } from '../../../services/loginService';
import { AxiosError } from 'axios';

import './Login.css'
import { Loader } from '../../common/Loader';

type AuthenticationAPIResponse = {
  status: boolean,
  data: {
    "message": string,
    "companyName": string,
    "userName": string,
    "sessionToken": string,
    "apiURL": string,
    "docURL": string
  }
};

export let DEV_ONLY_CURRENT_USER =
  window.sessionStorage.getItem('SECRET_USERNAME') || '';

export const Login = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const firstInputRef = useRef<ComponentRef<"input">>(null);
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const logUserIn = useCallback((authData: AuthenticationAPIResponse) => {
    setIsAuthenticated(true);

    window.sessionStorage.setItem('apiURL', authData.data.apiURL)
    window.sessionStorage.setItem('docURL', authData.data.docURL)
    window.sessionStorage.setItem('SECRET_AK', authData.data.sessionToken)
    window.sessionStorage.setItem('SECRET_USERNAME', authData.data.userName)
    window.sessionStorage.setItem('companyName', authData.data.companyName)
    setCompanyName(authData.data.companyName);
    DEV_ONLY_CURRENT_USER = authData.data.userName;
  }, [])

  const getSecretsFromToken = async (token: string) => {
    try {
      return await authenticateSetup(token);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data === "Email or Password is not correct") {
        }
      } else {
        console.error('Something went wrong...');
      }
    }
  }
  useEffect(() => {
    const controller = new AbortController();
    async function getAuthData() {
      let [ak] = [
        window.sessionStorage.getItem('SECRET_AK'),
      ]

      const params = new URLSearchParams(window.location.search);
      const authToken = params.get('token');

      if (authToken) {
        window.sessionStorage.setItem('SECRET_AK', authToken);
        try {
          const secrets: AuthenticationAPIResponse = await getSecretsFromToken(authToken);
          const { apiURL, companyName, userName, docURL } = secrets?.data;
          window.sessionStorage.setItem('apiURL', apiURL)
          window.sessionStorage.setItem('docURL', docURL)
          window.sessionStorage.setItem('SECRET_USERNAME', userName)
          window.sessionStorage.setItem('companyName', companyName);
          setCompanyName(companyName);
          ak = authToken;
          DEV_ONLY_CURRENT_USER = userName;

          const url = new URL(window.location.href);
          url.searchParams.delete('token');
          window.history.replaceState({}, '', url);
        } catch (e) {
          ak = null;
          console.error(e);
        }
      }

      const isStillAuthenticated = ak;
      if (isStillAuthenticated) {
        setIsAuthenticated(true);
        setIsLoading(false);
        return;
      }

      window.open(process.env.REACT_APP_HOME_URL, '_self');
    }

    getAuthData();

    return () => {
      controller.abort('skipped');
    }
  }, [logUserIn])

  useEffect(() => {
    firstInputRef.current?.focus();
  }, [isLoading]);

  return isLoading ? <Loader /> : (!isAuthenticated ? <></> : (
    <div className="container">
      {/* <Header /> */}
      <main className="main show-sidebar">
        <MainComponent
          companyName={companyName}
        />
      </main>
    </div>
  ));
};
