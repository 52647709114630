import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';

import { useKeyPress } from '../../../../hooks';

import { Button, ListingGrid } from '../../../common';
import PayCodeDeductDialog from './PayCodeDeductsDialog';

import { payCodeDeductServices } from '../../../../services/payCodeDeductServices';

import { IPayCodeDeduct, PayADInputsDefault } from '../../../../data/types';
import { columns } from './model';

import { ListingGridWrapper, ButtonWrapper } from '../PayrollSetup.styled';
import { PAYAD_INPUT_DEFAULTS } from '../../../../constants';
import { accountingDefaultsService } from '../../../../services/accountingDefaultsService';
import { plusIcon } from '@progress/kendo-svg-icons';

type SelectionType = { 
  field?: string; 
  dataItem: any 
};

interface PayCodeDeductsProps {
  selectedPayCodeDeduct: IPayCodeDeduct | undefined;
  setSelectedPayCodeDeduct: React.Dispatch<
    React.SetStateAction<IPayCodeDeduct | undefined>
  >;
};

const PayCodeDeducts: FC<PayCodeDeductsProps> = ({
  selectedPayCodeDeduct,
  setSelectedPayCodeDeduct,
}) => {
  const [originData, setOriginData] = useState<IPayCodeDeduct[]>([]);
  const [filterData, setFilterData] = useState<IPayCodeDeduct[]>([]);
  const [inputDefaults, setInputDefaults] = useState<PayADInputsDefault>(PAYAD_INPUT_DEFAULTS);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();

  const offset = useRef({ left: 0, top: 0 });

  async function fetchData() {
    try {
      const [payCodeDeducts, accountDefaults]: [IPayCodeDeduct[], any] = await Promise.all(
        [
          payCodeDeductServices.getPayCodeDeducts(),
          accountingDefaultsService.getDefaultsWithCombosData()
        ]
      );

      setInputDefaults({
        ...PAYAD_INPUT_DEFAULTS,
        AccountNo: { AccountNo: accountDefaults.data.data[0].DefUnclassified, AccountName: '' }
      });
        
      setOriginData(payCodeDeducts ?? []);
      setFilterData(payCodeDeducts ?? []);
    } catch (e) { }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {
    },
    () => {
    },
    () => {
    },
    () => {
      setSelectedPayCodeDeduct({ PayCode: '' });
    }
  );

  return (
    <ListingGridWrapper>
      {isLoading ? (
        <></>
      ) : (
        <>
          <ListingGrid
            rowHeight={24}
            data={sort ? orderBy(filterData, sort) : filterData}
            dataItemKey="PayCode"
            onRowClick={(e) => {
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.IDNo === e.dataItem.IDNo,
                }))
              );
            }}
            onRowDoubleClick={(e) => {
              setSelectedPayCodeDeduct(e.dataItem);
            }}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            onContextMenu={(event) => {
              handleContextMenuOpen(event.syntheticEvent);
              const { dataItem, field } = event;
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.PayCode === dataItem.PayCode,
                }))
              );
              setSelectedItem({ field, dataItem });
            }}
          >
            {columns.map((column, index) => {
              return (
                <GridColumn
                  {...column}
                  key={index}
                  sortable={true}
                />
              );
            })}
          </ListingGrid>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedPayCodeDeduct({ PayCode: '' });
              }}
              id="create-btn"
              title="Add New"
              svgIcon={plusIcon}
            >
              ADD <u>N</u>EW
            </Button>
          </ButtonWrapper>
        </>
      )}

      <ContextMenu
        show={showContext}
        offset={offset.current}
        onSelect={handleOnSelect}
        onClose={() => setShowContext(false)}
        className="context-menu"
      >
        <MenuItem
          text="Sort Ascending"
          data={{
            action: 'sortAsc',
          }}
        />
        <MenuItem
          text="Sort Descending"
          data={{
            action: 'sortDesc',
          }}
        />
        <MenuItem
          text="Filter In Selected"
          data={{
            action: 'filterIn',
          }}
        />
        <MenuItem
          text="Filter Out Selected"
          data={{
            action: 'filterOut',
          }}
        />
        <MenuItem
          text="Remove All Filters"
          data={{
            action: 'removeFilters',
          }}
        />
      </ContextMenu>
      {selectedPayCodeDeduct && (
        <PayCodeDeductDialog
          inputDefaults={inputDefaults}
          handleClose={(shouldRefreshData?: boolean) => {
            setSelectedPayCodeDeduct(undefined);
            if (shouldRefreshData) {
              fetchData();
            }
          }}
          data={selectedPayCodeDeduct}
          isEdit={selectedPayCodeDeduct?.PayCode ? true : false}
        />
      )}
    </ListingGridWrapper>
  );
};

export default PayCodeDeducts;
