import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { GridColumn, GridColumnProps } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import { plusIcon } from '@progress/kendo-svg-icons';
// import { uploadIcon } from '@progress/kendo-svg-icons';
// import { exportIcon } from '@progress/kendo-svg-icons';
import { importIcon } from '@progress/kendo-svg-icons';
import { useKeyPress } from '../../../hooks';

import AccountDialog from './AccountDialog';
import { Button, ListingGrid } from '../../common';

import { IChartOfAccountsProps } from './ChartOfAccounts.types';
import { AccountListType } from '../../../data/types';
import { filterColumns, columns } from './model';

import {
  ButtonWrapper,
  Form,
  FormContainer,
  ListingGridContainer,
} from './ChartOfAccounts.styled';
import { CustomGrid } from '../../../themes/common.styled';
import { useGetSetupCOA } from '../../../services/hooks/useAccounts';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';
import AccountsImportModal from './AccountImportDialog';
import * as XLSX from 'xlsx';

type ActiveStatusType = 'Active' | 'Inactive' | 'All';
type SelectionType = { field?: string; dataItem: any };

const ChartOfAccounts: FC<IChartOfAccountsProps> = ({
  title,
  onNextPage,
  onPreviousPage,
  handleCloseForm,
}) => {
  const [activeState, setActiveState] = useState<ActiveStatusType>('Active');
  const [originData, setOriginData] = useState<AccountListType[]>([]);
  const [filterData, setFilterData] = useState<AccountListType[]>([]);
  const [importedData, setImportedData] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<AccountListType>();
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [currentColumns, setCurrentColumns] =
    useState<GridColumnProps[]>(filterColumns);

  const offset = useRef({ left: 0, top: 0 });

  const inputFile = useRef<HTMLInputElement | null>(null);

  // const { data: accountsData, refetch } = useGetAccounts(
  //   'All',
  //   'All',
  //   activeState === 'Active'
  //     ? 'Enabled'
  //     : activeState === 'Inactive'
  //       ? 'Disabled'
  //       : 'All',
  //   `get_${activeState}_accounts`
  // );

  const { data: accountsData, refetch } = useGetSetupCOA(
    activeState === 'Active' ? 'Enabled' : activeState === 'Inactive' ? 'Disabled' : 'All',
    `get_${activeState}_accounts`
  );

  useEffect(() => {
    setOriginData(accountsData ?? []);
    setFilterData(accountsData ?? []);
    if (accountsData) {
      setIsLoading(false);
    }
  }, [accountsData]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
    setCurrentColumns(activeState === 'All' ? columns : filterColumns);
  }, [refetch, activeState]);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleFileToJSON = (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e?.target?.result;
        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          const workbook = XLSX.read(data, { type: "array" });
          const fileName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[fileName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          setImportedData(jsonData);
        } else if (file.name.endsWith('.csv')) {
          const jsonData = csvToArray(data);
          setImportedData(jsonData);
        } else {
          console.error('Unsupported file type');
          return;
        }

        // reset input value and show modal
        const inputElement = document.getElementById('import-file') as HTMLInputElement;
        if (inputElement) {
          inputElement.value = '';
        }
        setShowImportModal(true);
      };

      // Read file as text or array buffer depending on file type
      if (file.name.endsWith('.csv')) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const csvToArray = (csvData: any) => {
    // Split CSV data into lines
    const lines = csvData.split(/\r\n|\n/);
    // Extract headers
    const headers = lines[0].split(',');
    // Initialize JSON array to hold data
    const jsonData: any[] = [];
    // Iterate over remaining lines
    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(',');
      const obj: { [key: string]: any } = {}; // Explicitly define obj type
      // Map data to headers
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = data[j];
      }
      jsonData.push(obj);
    }
    return jsonData;
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {
    },
    () => {
    },
    () => {
    },
    () => {
      setSelectedAccount({ AccountNo: '' });
    }
  );

  const handleShowFileBrowser = () => {
    if (inputFile) {
      inputFile?.current?.click();
    }
  };

  return (
    <FormContainer>
      <Form>
        <div className="form-section-header-container">
          <div className="form-title">{title}</div>
          <div className="form-header-btn-wrapper">
            <DropDownList
              size="small"
              rounded="medium"
              fillMode="outline"
              style={{ width: '100px', borderWidth: '1px' }}
              data={['Active', 'Inactive', 'All']}
              value={activeState}
              onChange={(e) => setActiveState(e.value)}
            />
            <div className="listing-action-seperate-line" />
            <PageNavButton
              className="listing-action-button"
              onClick={(e) => {
                e.preventDefault();
                onPreviousPage();
              }}
              title="Previous Page"
            >
              <span
                className="k-icon k-i-arrow-left k-button-icon"
                role="presentation"
              />
            </PageNavButton>
            <PageNavButton
              className="listing-action-button"
              onClick={(e) => {
                e.preventDefault();
                onNextPage();
              }}
              title="Next Page"
            >
              <span
                className="k-icon k-i-arrow-right k-button-icon"
                role="presentation"
              />
            </PageNavButton>
            <CloseButton
              className="listing-action-button"
              onClick={handleCloseForm}
              title="Close"
            >
              <span
                className="k-icon k-i-close k-button-icon"
                role="presentation"
              />
            </CloseButton>
          </div>
        </div>
        <div className="form-content">
          <CustomGrid
            cols="800px"
            colGap="0px"
            width="auto"
            style={{ padding: '30px 0px 30px 30px' }}
          >
            <ListingGridContainer>
              {isLoading ? (
                <></>
              ) : (
                <ListingGrid
                  rowHeight={24}
                  data={sort ? orderBy(filterData, sort) : filterData}
                  dataItemKey="AccountNo"
                  onRowClick={(e) => {
                    setFilterData((prev) =>
                      prev.map((item) => ({
                        ...item,
                        selected: item.AccountNo === e.dataItem.AccountNo,
                      }))
                    );
                  }}
                  onRowDoubleClick={(e) => {
                    setSelectedAccount(e.dataItem);
                  }}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuOpen(event.syntheticEvent);
                    const { dataItem, field } = event;
                    setFilterData((prev) =>
                      prev.map((item) => ({
                        ...item,
                        selected: item.AccountNo === dataItem.AccountNo,
                      }))
                    );
                    setSelectedItem({ field, dataItem });
                  }}
                >
                  {currentColumns.map((column, index) => {
                    if (activeState !== 'All' && column.field === 'DisabledYN')
                      return null;

                    return <GridColumn {...column} key={index} />;
                  })}
                </ListingGrid>
              )}

              <ContextMenu
                show={showContext}
                offset={offset.current}
                onSelect={handleOnSelect}
                onClose={() => setShowContext(false)}
                className="context-menu"
              >
                <MenuItem
                  text="Sort Ascending"
                  data={{
                    action: 'sortAsc',
                  }}
                />
                <MenuItem
                  text="Sort Descending"
                  data={{
                    action: 'sortDesc',
                  }}
                />
                <MenuItem
                  text="Filter In Selected"
                  data={{
                    action: 'filterIn',
                  }}
                />
                <MenuItem
                  text="Filter Out Selected"
                  data={{
                    action: 'filterOut',
                  }}
                />
                <MenuItem
                  text="Remove All Filters"
                  data={{
                    action: 'removeFilters',
                  }}
                />
              </ContextMenu>
              {selectedAccount && (
                <AccountDialog
                  handleClose={(shouldRefreshData?: boolean) => {
                    setSelectedAccount(undefined);
                    if (shouldRefreshData) {
                      refetch();
                    }
                  }}
                  data={selectedAccount}
                  isEdit={selectedAccount?.AccountNo ? true : false}
                />
              )}
            </ListingGridContainer>
            {isLoading ? (
              <></>
            ) : (
              <ButtonWrapper>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedAccount({ AccountNo: '' });
                  }}
                  id="create-btn"
                  title="Add New"
                  svgIcon={plusIcon}
                >
                  ADD <u>N</u>EW
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowFileBrowser();
                  }}
                  id="create-btn"
                  title="Import"
                  svgIcon={importIcon}
                >
                  IMPORT
                </Button>
                <input type='file' id='import-file' ref={inputFile} style={{ display: 'none' }} onChange={handleFileToJSON}
                  accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </ButtonWrapper>
            )}
          </CustomGrid>
          {showImportModal && (
            <AccountsImportModal
              currentData={filterData}
              importedData={importedData}
              handleClose={(shouldRefreshData?: boolean) => {
                setShowImportModal(false)
                if (shouldRefreshData) {
                  refetch();
                }
              }}
            />
          )}
        </div>
      </Form>
    </FormContainer>
  );
};

export default ChartOfAccounts;