import styled from 'styled-components';

export const DialogContent = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;

  gap: 20px;
`;

export const ImportGridContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`
export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  gap: 15px;

  .column-combo {
    display: flex;
    flex-direction: column;
    gap:1px;
  }
`

export const ButtonWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: end;
gap: 8px;

#save-btn {
  color: #FFFFFF;
  background-color: #65A1D7;
}
`