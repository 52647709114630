import { IUser } from '../data/types';

export const validateEmail = (email: string) => {
  if (!email) return true;
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
  return emailRegex.test(email);
};

const validateEntityName = (entityName: string): boolean => {
  return !!entityName;
};

const validateZipCode = (zip: string): boolean => {
  const regex = /^\d{5}$/;
  return regex.test(zip) || !zip;
};

export const validateForm = (formValue: any): boolean => {
  if (!validateEntityName(formValue.EntityName)) {
    return false;
  }

  return validateZipCode(formValue.ShipZip);
};

export const validateCompanyForm = (formState: any): boolean => {
  if (!validateEntityName(formState.CompanyName)) {
    return false;
  }

  return true;
};

export const validatePaymentTermsForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.TermsCode?.trim()) ||
    !validateEntityName(formState.DueDays)
  ) {
    return false;
  }

  return true;
};

export const evaluateAccrualStart = (formState: any) => {
  return validateEntityName(formState.AccrualStarts?.trim()) &&
    (
      formState.AccrualStarts === "D"
      ||
      (formState.AccrualStarts === "C" && formState.AccrualDay !== null && formState.AcrrualMonth !== null)
    );
}

export const validateAccrue = (formState: any): boolean => {
  return formState.PTOQty > 0 && 
  formState.IntervalQty > 0 && 
  validateEntityName(formState.PTOUnits) && 
  validateEntityName(formState.IntervalPeriods) && 
  evaluateAccrualStart(formState);
}

export const validatePTOCodesForm = (formState: any): boolean => {
  console.log({ formState: formState });
  console.log({ PayCode: validateEntityName(formState.PayCode?.trim()), PayDesc: validateEntityName(formState.PayDesc?.trim()), AccrueYN: !formState.AccrueYN, validateAccrue: validateAccrue(formState) });
  if (
    validateEntityName(formState.PayCode?.trim()) &&
    validateEntityName(formState.PayDesc?.trim()) &&
    (!formState.AccrueYN || validateAccrue(formState))
  ) {
    return true;
  }

  return false;
};

export const validateGroupForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState?.PermGroupCode?.trim()) ||
    !validateEntityName(formState?.PermGroupDesc?.trim())
  ) {
    return false;
  }

  return true;
};

export const validateRoleForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState?.PermRoleCode?.trim()) ||
    !validateEntityName(formState?.PermRoleDesc?.trim())
  ) {
    return false;
  }

  return true;
};

export const validateUserForm = (formState: IUser): boolean => {
  if (
    !validateEntityName(formState?.LoginUserEmail?.trim() || '') ||
    !validateEntityName(formState?.LoginUserName?.trim() || '') ||
    !validateEmail(formState?.LoginUserEmail?.trim() || ' ') ||
    !formState?.GroupCode
  ) {
    return false;
  }

  return true;
};

export const validateLocationForm = (formState: any): boolean => {
  if (!validateEntityName(formState.LocDescription?.trim())) {
    return false;
  }

  return true;
};

export const validateAccountForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.AccountName?.trim()) ||
    !validateEntityName(formState.AccountNo) ||
    !validateEntityName(formState.AccountType)
  ) {
    return false;
  }

  return true;
};

export const validateSalesTaxForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.Jurisdiction?.trim())
  ) {
    return false;
  }
  return true;
};

export const validateProjectTypesForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.ProjType?.trim()) ||
    !validateEntityName(formState.ProjDesc?.trim())
  ) {
    return false;
  }
  return true;
};

export const validateProjectEventCodesForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.EventCode?.trim()) ||
    !validateEntityName(formState.EventDesc?.trim())
  ) {
    return false;
  }
  return true;
};

export const validateProjectStatusCodesForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.StatusCode?.trim()) ||
    !validateEntityName(formState.StatusDesc?.trim())
  ) {
    return false;
  }
  return true;
};

export const validateDepartmentForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.DepartmentCode?.trim()) ||
    !validateEntityName(formState.DepartmentDesc?.trim())
  ) {
    return false;
  }

  return true;
};

export const validatePayGroupForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.PayGroupCode?.trim()) ||
    !validateEntityName(formState.PayGroupDesc?.trim()) ||
    !validateEntityName(formState.PayFrequency?.value?.trim())
  ) {
    return false;
  }

  return true;
};

export const validatePayCodeDeductForm = (formState: any): boolean => {
  const deductRateLength = `${formState.DeductRate}`
    .split('.')
    .reduce((acc, curr) => acc + curr.length, 0);

  if (
    !validateEntityName(formState.PayCode?.trim()) ||
    !formState.DeductTaxHandling ||
    deductRateLength > 9 ||
    !formState.PayDesc
  ) {
    return false;
  }

  return true;
};

export const validatePayCodeAddsForm = (formstate: any): boolean => {
  if (!validateEntityName(formstate.PayCode?.trim()) || !formstate.PayDesc) {
    return false;
  }

  return true;
};

export const validateHolidayForm = (formstate: any): boolean => {
  if (
    !formstate.Month ||
    !(formstate.PayCode && formstate.PayDesc) ||
    !(formstate.DateOfMonth || (formstate.DayOfWeek && formstate.WeekOfMonth))
  ) {
    return false;
  }
  return true;
};

export const validateCustomerForm = (formstate: any): boolean => {
  if (!validateEntityName(formstate.FieldName?.trim())) {
    return false;
  }

  return true;
};

export const validateDefaultSettingsForm = (formstate: any): boolean => {
  const isNotEmpty = (value: any): boolean => {
    return value !== undefined && value !== null && String(value).trim() !== '';
  };

  if (
    isNotEmpty(formstate.FiscalYrStartDate) &&
    isNotEmpty(formstate.DefARTerms) &&
    isNotEmpty(formstate.DefARAcct) &&
    isNotEmpty(formstate.DefARWOAcct) &&
    isNotEmpty(formstate.DefARDiscAcct) &&
    isNotEmpty(formstate.DefAPTerms) &&
    isNotEmpty(formstate.DefAPAcct) &&
    isNotEmpty(formstate.DefAPWOAcct) &&
    isNotEmpty(formstate.DefAPDiscAcct) &&
    isNotEmpty(formstate.DefBankAcct) &&
    isNotEmpty(formstate.DefUndepFundsAcct) &&
    isNotEmpty(formstate.DefUnappliedCreditAcct) &&
    isNotEmpty(formstate.DefUnclassified) &&
    isNotEmpty(formstate.DefCreditCardAcct)
  ) {
    return true;
  }
  return false;
};

export const validateSchedulesForm = (formState: any): boolean => {
  if (!validateEntityName(formState.Code)) {
    return false;
  }

  return true;
};

export const validatePositionForm = (formState: any): boolean => {
  if (
    !validateEntityName(formState.PositionCode?.trim()) ||
    !validateEntityName(formState.PositionDesc?.trim())
  ) {
    return false;
  }

  return true;
};

export const validateMeasurementUnitsForm = (formstate: any): boolean => {
  if (
    !validateEntityName(formstate.UnitCode?.trim()) ||
    // !formstate.UnitDescription ||
    !formstate.UnitGroup
  ) {
    return false;
  }

  return true;
};

export const validateProductsAndServicesForm = (formstate: any): boolean => {
  if (
    !validateEntityName(formstate.CategoryCode?.trim())
    // !formstate.CategoryDescription
  ) {
    return false;
  }

  return true;
};

export const validateItemsForm = (formstate: any): boolean => {
  if (
    !validateEntityName(formstate.CategoryCode?.trim())
    // !formstate.CategoryDescription
  ) {
    return false;
  }

  return true;
};


export const validateSalesEventCodesForm = (formstate: any): boolean => {
  if (!validateEntityName(formstate.EventCode?.trim())) {
    return false;
  }

  return true;
};

export const validateSalesStatusCodesForm = (formstate: any): boolean => {
  if (!validateEntityName(formstate.StatusCode?.trim())) {
    return false;
  }

  return true;
};
