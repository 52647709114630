import styled from 'styled-components';

export const DialogContent = styled.form`
  padding-bottom: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .form-fields {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 12px 20px 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 50px 0px 0px 0px;

  button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0px !important;
  }
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-size: 14px;
    color: #424242;
  }

  margin: 0px 0px 0px 0px;

  &:nth-child(2) {
    margin-bottom: 0px;
  }
`;

export const CustomDialogContentWrapper = styled.div`
  width: 100%;
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 2px solid #d6d6d6;
  padding-top: 15px;
  width: 100%;

  button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
