import { useEffect } from 'react';
const useKeyPress = (
  saveHandler?: () => void,
  cancelHandler?: () => void,
  deleteHandler?: () => void,
  addNewHandler?: () => void,
  ) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!event.repeat && event.altKey) {
        event.preventDefault()
        if (['ß', 'Í', 's', 'S'].includes(event.key) && saveHandler) {
          saveHandler();
        } else if (['ç', 'Ç', 'c', 'C'].includes(event.key) && cancelHandler) {
          cancelHandler();
        } else if (['∂', 'Î', 'd', 'D'].includes(event.key) && deleteHandler) {
          deleteHandler();
        } else if(['˜', '˜', 'n', 'N'].includes(event.key) && addNewHandler) {
          addNewHandler();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [saveHandler, cancelHandler, deleteHandler, addNewHandler]);
};

export default useKeyPress;
