import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';

import { useKeyPress } from '../../../../hooks';

import { Button, ListingGrid } from '../../../common';
import { ISalesStatusCodeType } from '../../../../data/types';
import { prospectColumns, leadColumns, proposalsColumns } from './model';
import { ButtonWrapper, ListingGridWrapper } from '../SalesCodes.styled';
import { plusIcon } from '@progress/kendo-svg-icons';
import { salesStatusCodesService } from '../../../../services/salesCodesService';
import SalesStatusCodesDialog from './SalesStatusCodesDialog';

type SelectionType = { field?: string; dataItem: any };

interface StatusCodesProps {
  selectedStatusCode: ISalesStatusCodeType | undefined;
  setSelectedStatusCode: React.Dispatch<
    React.SetStateAction<ISalesStatusCodeType | undefined>
  >;
  EntType: 'L' | 'P' | 'X';
}

const SalesStatusCodes: FC<StatusCodesProps> = ({
  selectedStatusCode,
  setSelectedStatusCode,
  EntType,
}) => {
  const [originData, setOriginData] = useState<ISalesStatusCodeType[]>([]);
  const [filterData, setFilterData] = useState<ISalesStatusCodeType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();
  const [statusCodesList, setStatusCodesList] = useState<
    ISalesStatusCodeType[]
  >([]);

  const offset = useRef({ left: 0, top: 0 });

  function updateEntType(codesList: any) {
    return codesList.map((obj: any) => {
      if (obj.EntType === 'L') {
        obj.EntType = 'Leads';
      } else if (obj.EntType === 'X') {
        obj.EntType = 'Proposals';
      } else if (obj.EntType === 'P') {
        obj.StatusPct =
          obj.StatusPct !== null && obj.StatusPct !== undefined
            ? obj.StatusPct * 100
            : 0;
        obj.StatusPctFormatted = `${obj.StatusPct.toFixed(0)}%`;
        obj.EntType = 'Prospects';
      }
      return obj;
    });
  }

  async function fetchData() {
    try {
      const codesList: ISalesStatusCodeType[] =
        await salesStatusCodesService.getList(EntType);

      const formattedList = codesList.map((code: ISalesStatusCodeType) => {
        if (code.StatusPct !== null && code.StatusPct !== undefined) {
          return {
            ...code,
            StatusPctFormatted: `${(code.StatusPct * 100).toFixed(0)}%`,
          };
        }
        return code;
      });

      setStatusCodesList(formattedList);
      setOriginData(updateEntType(formattedList) ?? []);
      setFilterData(formattedList);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {},
    () => {},
    () => {},
    () => {
      setSelectedStatusCode({ IDNo: 0 });
    }
  );

  return (
    <ListingGridWrapper>
      {isLoading ? (
        <></>
      ) : (
        <>
          <ListingGrid
            rowHeight={24}
            data={sort ? orderBy(filterData, sort) : filterData}
            dataItemKey="StatusCode"
            onRowClick={(e) => {
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.IDNo === e.dataItem.IDNo,
                }))
              );
            }}
            onRowDoubleClick={(e) => {
              setSelectedStatusCode(e.dataItem);
            }}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            onContextMenu={(event) => {
              handleContextMenuOpen(event.syntheticEvent);
              const { dataItem, field } = event;
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.IDNo === dataItem.IDNo,
                }))
              );
              setSelectedItem({ field, dataItem });
            }}
          >
            {EntType === 'L'
              ? leadColumns.map((column, index) => (
                  <GridColumn {...column} key={index} sortable={true} />
                ))
              : EntType === 'X'
              ? proposalsColumns.map((column, index) => (
                  <GridColumn {...column} key={index} sortable={true} />
                ))
              : prospectColumns.map((column, index) => (
                  <GridColumn
                    {...column}
                    key={index}
                    sortable={true}
                    field={
                      column.field === 'StatusPct'
                        ? 'StatusPctFormatted'
                        : column.field
                    }
                  />
                ))}
          </ListingGrid>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedStatusCode({ IDNo: 0 });
              }}
              id="create-btn"
              title="Add New"
              svgIcon={plusIcon}
            >
              ADD <u>N</u>EW
            </Button>
          </ButtonWrapper>
        </>
      )}

      <ContextMenu
        show={showContext}
        offset={offset.current}
        onSelect={handleOnSelect}
        onClose={() => setShowContext(false)}
        className="context-menu"
      >
        <MenuItem
          text="Sort Ascending"
          data={{
            action: 'sortAsc',
          }}
        />
        <MenuItem
          text="Sort Descending"
          data={{
            action: 'sortDesc',
          }}
        />
        <MenuItem
          text="Filter In Selected"
          data={{
            action: 'filterIn',
          }}
        />
        <MenuItem
          text="Filter Out Selected"
          data={{
            action: 'filterOut',
          }}
        />
        <MenuItem
          text="Remove All Filters"
          data={{
            action: 'removeFilters',
          }}
        />
      </ContextMenu>
      {selectedStatusCode && (
        <SalesStatusCodesDialog
          handleClose={(shouldRefreshData?: boolean) => {
            setSelectedStatusCode(undefined);
            if (shouldRefreshData) {
              fetchData();
            }
          }}
          handleUpdateColor={(shouldRefreshData?: boolean) => {
            if (shouldRefreshData) fetchData();
          }}
          data={selectedStatusCode}
          isEdit={selectedStatusCode?.StatusCode ? true : false}
          statusCodeList={statusCodesList}
          EntType={EntType}
        />
      )}
    </ListingGridWrapper>
  );
};

export default SalesStatusCodes;
