import { IAcctDefaultCombosData } from '../components/pages/AcctDefaults/AcctDefaults.types';
import { ILocationCombosData } from '../components/pages/Locations/LocationsDialog/LocationDialog.types';
import { orgTypeOptions } from '../constants';
import { IAccount, IState, ITermsCode } from '../data/types';

export const generateAccount = (
  value: 'string' | IAccount | null,
  accounts: Array<IAccount>
) => {
  return typeof value === 'string'
    ? accounts.find((item) => item.AccountNo === value)
    : null;
};

const formatAccount = (value: 'string' | IAccount) => {
  return value
    ? typeof value !== 'string'
      ? value.AccountNo
        ? value.AccountNo
        : ''
      : ''
    : '';
};

export const generateTermsCodeID = (
  value: number,
  terms: Array<ITermsCode>
) => {
  return terms.find((term) => term.IDNo === value)?.TermsCode.toString();
};

const formatTerms = (value: string, terms: Array<ITermsCode>) => {
  const selectedTerm = terms.find((term) => term.TermsCode === value);
  return selectedTerm?.IDNo;
};

const formatDate = (date: Date | string) => {
  if (typeof date === 'string') {
    return date;
  } else {
    return date.toISOString();
  }
};

const formatState = (value: 'string' | IState) => {
  return value
    ? typeof value === 'string'
      ? value
      : value.State || value.state
    : '';
};

const generateState = (
  value: 'string' | IState | null,
  states: Array<IState>
) => {
  return typeof value === 'string'
    ? states.find((item) => item.State === value || item.state === value)
    : value;
};

const formatValueFromOptions = (
  value: string | { name: string; value: string } | undefined,
  options: { name: string; value: string }[]
) => {
  if (!value) {
    return '';
  } else if (typeof value === 'string') {
    return options.find((option) => option.name === value)?.value;
  } else {
    return value?.value;
  }
};

const generateValueFromOptions = (
  value: string,
  options: { name: string; value: string }[],
  returnFullObj?: boolean
) => {
  if (returnFullObj) {
    return options.find((option) => option.value === value);
  } else {
    return options.find((option) => option.value === value)?.name;
  }
};

export const formatAcctDefaultsData = (data: any, terms: ITermsCode[]) => {
  const formattedData = {
    ...data,
    DefAPAcct: formatAccount(data?.DefAPAcct),
    DefAPDiscAcct: formatAccount(data?.DefAPDiscAcct),
    DefAPWOAcct: formatAccount(data?.DefAPWOAcct),
    DefARAcct: formatAccount(data?.DefARAcct),
    DefARDiscAcct: formatAccount(data?.DefARDiscAcct),
    DefARWOAcct: formatAccount(data?.DefARWOAcct),
    DefBankAcct: formatAccount(data?.DefBankAcct),
    DefUnappliedCreditAcct: formatAccount(data?.DefUnappliedCreditAcct),
    DefUnclassified: formatAccount(data?.DefUnclassified),
    DefCreditCardAcct: formatAccount(data?.DefCreditCardAcct),
    DefUndepFundsAcct: formatAccount(data?.DefUndepFundsAcct),
    DefAPTerms: formatTerms(data?.DefAPTerms, terms),
    DefARTerms: formatTerms(data?.DefARTerms, terms),
    FiscalYrStartDate: formatDate(data?.FiscalYrStartDate),
    DefFUTAAcct: formatAccount(data?.DefFUTAAcct),
    DefSUTAAcct: formatAccount(data?.DefSUTAAcct),
    DefRetPayAcct: formatAccount(data?.DefRetPayAcct),
    DefSTPayAcct: formatAccount(data?.DefSTPayAcct),
    DefPayExpAcct: formatAccount(data?.DefPayExpAcct),
    DefPayTaxExpAcct: formatAccount(data?.DefPayTaxExpAcct),
  };
  return formattedData;
};

export const generateAcctDefaultsData = (
  data: any,
  combosData: IAcctDefaultCombosData
) => {
  const {
    TermsCodes,
    ARAccounts,
    APAccounts,
    AssetsNotFixedAccounts,
    BankAccounts,
    ExpensesNoCoGSAccounts,
    CreditCardAccounts,
    IncomeExpenseAccounts,
    CurrentLiability,
    PayrollAccounts
  } = combosData;

  return {
    ...data,
    FiscalYrStartDate: data?.FiscalYrStartDate || null,
    DefARAcct: generateAccount(data?.DefARAcct, ARAccounts) || null,
    DefARTerms:
      generateTermsCodeID(Number(data?.DefARTerms), TermsCodes) || null,
    DefARWOAcct:
      generateAccount(data?.DefARWOAcct, IncomeExpenseAccounts) || null,
    DefARDiscAcct:
      generateAccount(data?.DefARDiscAcct, IncomeExpenseAccounts) || null,
    DefAPTerms:
      generateTermsCodeID(Number(data?.DefAPTerms), TermsCodes) || null,
    DefAPAcct: generateAccount(data?.DefAPAcct, APAccounts) || null,
    DefAPWOAcct:
      generateAccount(data?.DefAPWOAcct, IncomeExpenseAccounts) || null,
    DefAPDiscAcct:
      generateAccount(data?.DefAPDiscAcct, IncomeExpenseAccounts) || null,
    DefBankAcct: generateAccount(data?.DefBankAcct, BankAccounts) || null,
    DefUnclassified:
      generateAccount(data?.DefUnclassified, ExpensesNoCoGSAccounts) || null,
    DefCreditCardAcct:
      generateAccount(data?.DefCreditCardAcct, CreditCardAccounts) || null,
    DefUndepFundsAcct:
      generateAccount(data?.DefUndepFundsAcct, AssetsNotFixedAccounts) || null,
    DefUnappliedCreditAcct:
      generateAccount(data?.DefUnappliedCreditAcct, AssetsNotFixedAccounts) || null,
    DefFUTAAcct:
      generateAccount(data?.DefFUTAAcct, CurrentLiability) || null,
    DefSUTAAcct:
      generateAccount(data?.DefSUTAAcct, CurrentLiability) || null,
    DefRetPayAcct:
      generateAccount(data?.DefRetPayAcct, ExpensesNoCoGSAccounts) || null,
    DefSTPayAcct:
      generateAccount(data?.DefSTPayAcct, ExpensesNoCoGSAccounts) || null,
    DefPayExpAcct:
      generateAccount(data?.DefPayExpAcct, PayrollAccounts) || null,
    DefPayTaxExpAcct:
      generateAccount(data?.DefPayTaxExpAcct, PayrollAccounts) || null,
  };
};

export const formatCompanyData = (data: any) => {
  const formattedData = {
    ...data,
    CompSt: formatState(data?.CompSt),
    ShipSt: formatState(data?.ShipSt),
    OrgType: formatValueFromOptions(data?.OrgType, orgTypeOptions),
  };

  return formattedData;
};

export const generateCompanyData = (data: any, states: Array<any>) => {
  return {
    ...data,
    CompSt: generateState(data?.CompSt, states),
    ShipSt: generateState(data?.ShipSt, states),
    OrgType: generateValueFromOptions(data?.OrgType, orgTypeOptions, true),
  };
};

export const formatLocationData = (data: any) => {
  const formattedData = {
    ...data,
    LocDefARAcct: formatAccount(data?.LocDefARAcct),
    LocDefARDiscAcct: formatAccount(data?.LocDefARDiscAcct),
    LocDefARWOAcct: formatAccount(data?.LocDefARWOAcct),
    LocDefAPAcct: formatAccount(data?.LocDefAPAcct),
    LocDefAPDiscAcct: formatAccount(data?.LocDefAPDiscAcct),
    LocDefAPWOAcct: formatAccount(data?.LocDefAPWOAcct),
    LocDefBankAcct: formatAccount(data?.LocDefBankAcct),
    LocDefCreditCardAcct: formatAccount(data?.LocDefCreditCardAcct),
    LocDefUndepFundsAcct: formatAccount(data?.LocDefUndepFundsAcct),
    LocDefUnappliedCreditAcct: formatAccount(data?.LocDefUnappliedCreditAcct),
    LocSt: formatState(data?.LocSt) || '',
  };
  return formattedData;
};

export const generateLocationData = (
  data: any,
  combosData: ILocationCombosData | null
) => {
  if (!combosData) {
    return data;
  }
  const {
    ARAccounts,
    APAccounts,
    BankAccounts,
    CreditCardAccounts,
    IncomeExpenseAccounts,
    AssetsNotFixedAccounts,
    StatesUS,
  } = combosData;

  return {
    ...data,
    LocDefARAcct: generateAccount(data?.LocDefARAcct, ARAccounts) || null,
    LocDefARWOAcct:
      generateAccount(data?.LocDefARWOAcct, IncomeExpenseAccounts) || null,
    LocDefARDiscAcct:
      generateAccount(data?.LocDefARDiscAcct, IncomeExpenseAccounts) || null,
    LocDefAPAcct: generateAccount(data?.LocDefAPAcct, APAccounts) || null,
    LocDefAPWOAcct:
      generateAccount(data?.LocDefAPWOAcct, IncomeExpenseAccounts) || null,
    LocDefAPDiscAcct:
      generateAccount(data?.LocDefAPDiscAcct, IncomeExpenseAccounts) || null,
    LocDefBankAcct: generateAccount(data?.LocDefBankAcct, BankAccounts) || null,
    LocDefCreditCardAcct:
      generateAccount(data?.LocDefCreditCardAcct, CreditCardAccounts) || null,
    LocDefUndepFundsAcct:
      generateAccount(data?.LocDefUndepFundsAcct, AssetsNotFixedAccounts) ||
      null,
    LocDefUnappliedCreditAcct:
      generateAccount(
        data?.LocDefUnappliedCreditAcct,
        AssetsNotFixedAccounts
      ) || null,
    LocSt: generateState(data?.LocSt, StatesUS),
  };
};
