export function getMonthFromValue(
  monthTemplate: { month: string; value: number }[],
  value?: number
) {
  if (!value) return '';

  return monthTemplate.find((month) => month.value === value)?.month ?? '';
}

export function getValueFromMonth(
  monthTemplate: { month: string; value: number }[],
  monthInput: string
) {
  if (!monthInput) return 0;

  return monthTemplate.find((month) => month.month === monthInput)?.value ?? 0;
}

const months = [
  ['Jan', 'January'],
  ['Feb', 'February'],
  ['Mar', 'March'],
  ['Apr', 'April'],
  ['May', 'May'],
  ['Jun', 'June'],
  ['Jul', 'July'],
  ['Aug', 'August'],
  ['Sep', 'September'],
  ['Oct', 'October'],
  ['Nov', 'November'],
  ['Dec', 'December'],
];
export const monthHelper = {
  getAbbreviationFromFull: (fullMonth: string) => {
    if (!fullMonth) return '';
    const index = months.findIndex((arr) => arr.includes(fullMonth));
    return months[index][0];
  },
  getFullFromAbbreviation: (abbreviation: string) => {
    if (!abbreviation) return '';
    const index = months.findIndex((arr) => arr.includes(abbreviation));
    return months[index][1];
  },
};
