import React, { useEffect, useRef, useState } from 'react';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';

import { Button, InputWrapper } from '../../../../common';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validateGroupForm } from '../../../../../utils';

import { ButtonWrapper, DialogContent } from './GroupDialog.styled';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';

interface IGroupDialogProps {
  isEdit: boolean;
  data: any;
  handleClose: () => void;
  handleSubmit: (params: { isNew: boolean; data: any; id?: any }) => void;
  handleDelete: (params: { id: any }) => void;
}

const GroupDialog: React.FC<IGroupDialogProps> = ({
  isEdit,
  data,
  handleClose,
  handleSubmit,
  handleDelete,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    PermGroupCode: isEdit && data ? data.PermGroupCode : '',
    PermGroupDesc: isEdit && data ? data.PermGroupDesc : '',
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput(isEdit ? 'desc' : 'code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged) {
        handleSubmit({
          isNew: data?.PermGroupCode ? false : true,
          data: formState,
          id: data?.PermGroupCode || '',
        });
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    setIsValid(validateGroupForm(formState));
  }, [formState]);

  const handleOnChange = (event: InputChangeEvent, key: string) => {
    setIsChanged(true);
    if (key === 'PermGroupCode') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value.toUpperCase(),
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state && handleDelete) {
      handleDelete({
        id: data?.PermGroupCode,
      });
    }
    setVisible(!visible);
  };

  return (
    <MovableDialog
      title={`${isEdit ? 'Edit ' : 'Add '}${'Group'}`}
      onClose={() => handleClose()}
      className="group-dialog"
      ref={dialogRef}
      width={328}
    >
      <ReactFocusLock>
        <DialogContent>
          <div
            className="form-fields"
            ref={formRef}
            onKeyDown={(e) => handleKeyDown(e, formRef)}
          >
            <InputWrapper
              name={'PermGroupCode'}
              label="Code"
              onChange={handleOnChange}
              value={formState.PermGroupCode || ''}
              maxLength={6}
              isDisabled={isEdit}
              id="code"
              autoComplete="off"
              width="80px"
            />
            <InputWrapper
              label={`Group Description`}
              name={'PermGroupDesc'}
              onChange={handleOnChange}
              value={formState.PermGroupDesc || ''}
              maxLength={25}
              id="desc"
              autoComplete="off"
              width="200px"
            />
          </div>
        </DialogContent>
        <ButtonWrapper>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit({
                isNew: data?.PermGroupCode ? false : true,
                data: formState,
                id: data?.PermGroupCode || '',
              });
            }}
            disabled={!isValid || !isChanged}
            id="save-btn"
            title="Save"
          >
            <u>S</u>ave
          </Button>
          <Button
            onClick={() => handleClose()}
            type="button"
            id="cancel-btn"
            title="Cancel"
          >
            <u>C</u>ancel
          </Button>
          {isEdit && (
            <Button
              onClick={() => toggleDialog()}
              type="button"
              id="delete-btn"
              title="Delete"
              tabIndex={-1}
            >
              <u>D</u>elete
            </Button>
          )}
        </ButtonWrapper>
      </ReactFocusLock>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MovableDialog>
  );
};

export default GroupDialog;
