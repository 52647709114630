import styled from 'styled-components';

export const FormContainer = styled.div`
  display: block;
  position: relative;
  width: calc(100% - 20px);
  padding: 10px;
`;

export const Form = styled.form`
  border: 1px solid #d6d6d6;
  height: calc(100vh - 116px);

  .form-section-header-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(209 213 219 / 1);
    border-color:;
    background-color: rgb(250 250 250 / 1);
    padding-left: 1.25rem;
    padding-right: 0.375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    .form-title {
      font-weight: 600;
    }

    .form-header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .listing-action-button {
        background-color: transparent;
        border: 1px solid rgb(209, 213, 219);
        cursor: pointer;
        padding: 4px;
        display: flex;
        height: 30px;
        width: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        color: rgb(51 51 51 / 1);

        &:hover {
          border-color: rgb(44 80 152 / 1);
          color: rgb(44 80 152 / 1);
        }
      }
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 75px);
    overflow-y: hidden;
    box-sizing: border-box;
  }

  .form-footer {
    padding: 15px 20px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const ButtonWrapper = styled.div`
  padding: 10px;
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  #create-btn {
    width: 112px;
  }
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  max-width: 600px;

  label {
    padding-right: 12px;
    color: #2c5098;
    font-size: 15px;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 32px;
  outline: none;
  border: 0px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 185px;

  background: ${(props) => (props.isActive ? '#5A7699' : '#f1f1f3')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
`;

export const CustomSpanCellTableWrapper = styled.div`
  tr:has(.vertical-align-base) {
    box-shadow: 0px -1px 0px rgb(214, 214, 214) !important;
  }
  .k-grid .k-table-row.k-table-alt-row {
    background-color: #fff !important;
  }
`;

export const ListingGridWrapper = styled.div`
  box-sizing: border-box;
  border-top: 2px solid #2c5098;
  border-bottom: 2px solid #2c5098;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 253px);
  max-height:calc(100vh);
  overflow: hidden;
`;
