import styled from 'styled-components';

export const DialogContent = styled.form`
  display: flex;
  flex-direction: column;
  padding: 15px 15px;

  .form-container {
    border: 1px solid #d6d6d6;
  }

  .form-fields {
    padding: 12px 20px 20px;
    display: flex;
    flex-direction: row;

    gap: 8px;
  }

  .grid-fields {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 20px 20px;

    border-top: 1px solid #d6d6d6;

    .listing-grid {
      border: 1px solid #d6d6d6;
      width: 100%;
    }

    .k-grid .k-table-row {
      background-color: #ffffff !important;
      color: #424242;
    }
  }
`;

// export const ButtonWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   margin: 10px 0px 0px 0px;

//   button {
//     margin-right: 0.5rem;

//     &:last-child {
//       margin-right: 0px;
//     }
//   }
// `;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-size: 14px;
    color: #424242;
  }

  margin: 0px 0px 8px 0px;

  &:nth-child(2) {
    margin-bottom: 0px;
  }
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 15px;

  button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
