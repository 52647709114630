import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';

import { IDepartmentDialogProps } from './DepartmentDialog.types';

import { Button, InputWrapper } from '../../../../common';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import { handleKeyDown, validateDepartmentForm } from '../../../../../utils';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { departmentsService } from '../../../../../services/payroll/departmentsService';

import {
  DialogContent,
  StyledTitle,
  CustomDialogContentWrapper,
  CustomButtonWrapper
} from './DepartmentDialog.styled';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../../common/MovableDialog';

const DepartmentDialog: React.FC<IDepartmentDialogProps> = ({
  isEdit,
  data,
  handleClose,
  dataTemplate
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    DepartmentCode: isEdit && data ? data.DepartmentCode : '',
    DepartmentDesc: isEdit && data ? data.DepartmentDesc : '',
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput('department-code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    },
  );

  useEffect(() => {
    setIsValid(validateDepartmentForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.DepartmentCode) {
        setIsSaving(true);
        const saved = data.DepartmentCode
          ? await departmentsService.updateDepartment(data.DepartmentCode, {
            DepartmentDesc: formState.DepartmentDesc,
          })
          : await departmentsService.createDepartment(
            formState.DepartmentCode,
            {
              DepartmentDesc: formState.DepartmentDesc,
            }
          );

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.DepartmentCode) {
        setIsDeleting(true);
        const status = await departmentsService.deleteDepartmentById(
          formState.DepartmentCode
        );

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    const capInputValue = event.value.toUpperCase()

    if (key === 'DepartmentCode') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: capInputValue?.replace(/[^a-zA-Z0-9]/g, '')
      }))
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>Setup Department</span>
        {isEdit && (
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
          >
          </button>
        )}
      </StyledTitle>
    );
  };

  const getMaxLength = (inputName: string) => {
    return dataTemplate.fieldProperties.find(
      (prop: any) => prop.columnName === inputName).maxLength;
  };

  return (
    <MovableDialog
      onClose={() => handleClose()}
      className="department-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <CustomDialogContentWrapper>
        <DialogContent>
          <ReactFocusLock>
            <div
              className="form-fields"
              ref={formRef}
              onKeyDown={(e) => handleKeyDown(e, formRef)}
            >
              <InputWrapper
                name="DepartmentCode"
                label="Code"
                id='department-code'
                onChange={handleOnChange}
                value={formState.DepartmentCode}
                maxLength={getMaxLength('DepartmentCode')}
                autoComplete="off"
                width='80px'
                isDisabled={data.DepartmentCode}
              />
              <InputWrapper
                name="DepartmentDesc"
                label="Description"
                onChange={handleOnChange}
                value={formState.DepartmentDesc}
                // maxLength={25}
                maxLength={getMaxLength('DepartmentDesc')}
                autoComplete="off"
                width='200px'
              />
            </div>
            <CustomButtonWrapper>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
                disabled={!isValid || isSaving || isDeleting || !isChanged}
                id="save-btn"
                title="Save"
              >
                <>
                  <u>S</u>ave
                </>
              </Button>
              <Button
                onClick={() => handleClose()}
                type="button"
                id="cancel-btn"
                title="Cancel"
                disabled={isSaving || isDeleting}
              >
                <u>C</u>ancel
              </Button>
              {isEdit && (
                <Button
                  onClick={() => toggleDialog()}
                  type="button"
                  id="delete-btn"
                  title="Delete"
                  tabIndex={-1}
                >
                  <u>D</u>elete
                </Button>
              )}
            </CustomButtonWrapper>
          </ReactFocusLock>
        </DialogContent>
      </CustomDialogContentWrapper>
      {visible && (
        <GenericDialog
          type='Confirmation'
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText='Delete'
          primaryMessage='Delete record?'
        />
      )}
    </MovableDialog>
  );
};

export default DepartmentDialog;
