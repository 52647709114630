import styled from 'styled-components';

export const DialogContent = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 12px !important;

  .form-fields {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    height: fit-content;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 20px 0px 0px 0px;

  button {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-size: 14px;
    color: #424242;
  }

  margin: 0px 0px 8px 0px;

  &:nth-child(2) {
    margin-bottom: 0px;
  }
`;

export const DateInputGrid = styled.div`
  display: grid;
  grid-template-columns: auto 70px 20px 70px;
  align-items: center;
  justify-items: right;
  gap: 8px;
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 2px solid #d6d6d6;
  padding: 15px 20px;

  button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
