import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const salesTaxService = {
    getSalesTaxesList: async () => {
        const data = await axiosClient().get(`Transaction/SalesTax/GetList`,
            {
                headers: { ...commonHeaders },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                }
            }
        );
        return data.data.data.records;
    },
    getSalesTax: async (IDNo: number) => {
        const data = await axiosClient().get(`Transaction/SalesTax?IDNo=${IDNo}`,
            {
                headers: { ...commonHeaders },
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                }
            }
        );
        return data.data.data;
    },
    createSalesTax: async (IDNo: number, payload: any) => {
        return axiosClient().patch(
            `Transaction/SalesTax`,
            { ...payload },
            {
                params: {
                    IDNo: IDNo,
                },
                headers: { ...commonHeaders },
            }
        );
    },
    deleteSalesTax: (IDNo: number) =>
        axiosClient().delete(
            `Transaction/SalesTax`,
            {
                params: {
                    IDNo: IDNo,
                },
                headers: { ...commonHeaders },
            }),
};