import type { GridColumnProps } from '@progress/kendo-react-grid';
import { CheckCell } from '../../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'UnitCode',
    width: 100,
    className: 'text-left',
    sortable: true,
  },
  {
    title: 'Description',
    field: 'UnitDescription',
    className: 'text-left',
    width: 399,
    sortable: true,
  },
  {
    title: 'Group',
    field: 'UnitGroup',
    width: 95,
    className: 'text-left',
    sortable: true,
  },
  {
    title: 'Hide',
    field: 'HideYN',
    className: 'text-center',
    width: 95,
    sortable: true,
    cell: CheckCell,
  },
  {
    title: 'System',
    field: 'SystemYN',
    className: 'text-center',
    sortable: true,
    cell: CheckCell,
  },
];
