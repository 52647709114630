import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import {
  ComboBoxChangeEvent,
  MultiColumnComboBoxHandle,
} from '@progress/kendo-react-dropdowns';

import { IAccountDialogProps } from '../ChartOfAccounts.types';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../hooks';
import { handleKeyDown, validateAccountForm } from '../../../../utils';
import { accountsService } from '../../../../services/account/accountsService';
import { Button, CustomColComboBox, InputWrapper } from '../../../common';

import {
  DialogContent,
  StyledTitle,
  CustomButtonWrapper,
} from './AccountDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../common/MovableDialog';
import { AccountsType } from '../../../../data/types';
import AddCompanyDialog from './AddCompanyDialog';
import { customAxios } from '../../../../services/loginService';

type ComboType = {
  GLAccountTypesAll: AccountsType[];
  Vendors: VendorsType[];
};

type VendorsType = {
  IDNo: number;
  EntityName: string;
  Department?: string;
  PrimaryContact?: string;
  EmailMain?: string;
  JobTitle?: string;
  Address1?: string;
  MainPhone?: string;
  City?: string;
  State?: string;
  Zip?: string;
  Notes?: string;
  TermsCodeID?: number;
};

const AccountDialog: React.FC<IAccountDialogProps> = ({
  isEdit,
  data,
  handleClose,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    AccountName: isEdit && data ? data.AccountName : '',
    AccountNo: isEdit && data ? data.AccountNo : '',
    AccountType: isEdit && data ? data.AccountType : '',
    DisabledYN:
      isEdit && data ? (data.DisabledYN ? 'Inactive' : 'Active') : 'Active',
    EntityID: isEdit && data ? data.EntityID : null,
    EntityName: isEdit && data ? data.EntityName : '',
    TypeGroup: isEdit && data ? data.TypeGroup : '',
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [showAddCompany, setShowAddCompany] = useState<boolean>(false);
  const [entityData, setEntityData] = useState<any>();
  const companyRef = useRef<MultiColumnComboBoxHandle>(null);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput('account-name');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  const [comboData, setComboData] = useState<ComboType>({
    GLAccountTypesAll: [],
    Vendors: [],
  });
  // const [accountsFieldProperties, setAccountsFieldProperties] = useState<FieldPropertiesType[]>([]);

  const [isCreditOrBank, setIsCreditOrBank] = useState<boolean>(false);

  const fetchAccountTypes = useCallback(async () => {
    const accountTypeList = await accountsService.getAccountsList(
      data.AccountNo
    );

    const updatedVendors = [
      { IDNo: 0, EntityName: '' },
      ...accountTypeList.combos.Vendors,
    ];

    setComboData({
      GLAccountTypesAll: accountTypeList.combos.GLAccountTypesAll,
      Vendors: updatedVendors,
    });

    setFormState((prev) => ({
      ...prev,
      EntityName: accountTypeList?.record[0]?.EntityName,
      EntityID: accountTypeList?.record[0]?.EntityID,
    }));
  }, [data.AccountNo]);

  useEffect(() => {
    async function fetchEntityData(ID = 1) {
      const data = await customAxios().get(`/v3/Entity/GetVendor?IDNo=${ID}`);
      setEntityData(data.data.data);
    }

    fetchAccountTypes();
    fetchEntityData();

    if (['Credit Card', 'Bank'].includes(data.AccountType)) {
      setIsCreditOrBank(true);
    } else {
      setIsCreditOrBank(false);
    }
  }, [data, showAddCompany, fetchAccountTypes]);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    setIsValid(validateAccountForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.AccountNo) {
        setIsSaving(true);
        const saved = data.AccountNo
          ? await accountsService.updateAccount(data.AccountNo, {
              AccountName: formState.AccountName,
              AccountType: formState.AccountType,
              DisabledYN: formState.DisabledYN === 'Active' ? false : true,
              EntityID: formState.EntityName ? formState.EntityID : null,
              EntityName: formState.EntityName,
            })
          : await accountsService.createAccount(formState.AccountNo, {
              AccountName: formState.AccountName,
              AccountType: formState.AccountType,
              DisabledYN: formState.DisabledYN === 'Active' ? false : true,
              EntityID: formState.EntityID,
              EntityName: formState.EntityName,
            });

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.AccountNo) {
        setIsDeleting(true);
        const status = await accountsService.deleteAccountById(
          formState.AccountNo
        );

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    if (key === 'AccountNo') {
      const numericValue = event.value.replace(/[^0-9]/g, '');
      setFormState((prevState) => ({
        ...prevState,
        [key]: numericValue,
      }));
    } else if (key === 'AccountType') {
      if (['Credit Card', 'Bank'].includes(event.value.AcctTypeDesc)) {
        setIsCreditOrBank(true);
        setFormState((prev) => ({
          ...prev,
          [key]: event.value.AcctTypeDesc,
        }));
      } else {
        setIsCreditOrBank(false);
        setFormState((prev) => ({
          ...prev,
          EntityID: null,
          EntityName: '',
          [key]: event.value.AcctTypeDesc,
        }));
      }
    } else if (key === 'EntityName') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value.EntityName,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      EntityID: comboData.Vendors.find(
        (vendor) => vendor.EntityName === formState.EntityName
      )?.IDNo,
    }));
  }, [comboData.Vendors, formState.EntityName]);

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>{isEdit ? 'Edit' : 'Add'} Account</span>
        {isEdit && (
          <button
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
          >
            {/* <span className="k-icon k-i-delete" /> */}
          </button>
        )}
      </StyledTitle>
    );
  };

  const handleAddCompanyClose = () => {
    setShowAddCompany(false);
  };

  return (
    <MovableDialog
      onClose={() => handleClose()}
      className="account-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <ReactFocusLock>
        <DialogContent>
          <div
            className="form-fields"
            onKeyDown={(e) => handleKeyDown(e, formRef)}
            ref={formRef}
          >
            <div className="form-fields-left">
              <InputWrapper
                maxWidth="250px"
                width="250px"
                name="AccountName"
                label="Account Name"
                onChange={handleOnChange}
                value={formState.AccountName}
                id="account-name"
                autoComplete="off"
              />
              <CustomColComboBox
                width={250}
                comboBoxType="GLAccountTypesAll"
                dataList={comboData.GLAccountTypesAll}
                onChange={(e) => handleOnChange(e, 'AccountType')}
                filterable
                value={comboData.GLAccountTypesAll.find(
                  (e) => e.AcctTypeDesc === formState.AccountType
                )}
                name="AccountType"
                label="Account Type"
              />
              {isCreditOrBank && (
                <CustomColComboBox
                  ref={companyRef}
                  width={250}
                  comboBoxType="Vendors"
                  dataList={comboData.Vendors}
                  onChange={(e) => handleOnChange(e, 'EntityName')}
                  filterable
                  value={comboData.Vendors.find(
                    (e) => e.EntityName === formState.EntityName
                  )}
                  name="EntityName"
                  label="Company"
                  headerOnClick={() => setShowAddCompany(true)}
                />
              )}
            </div>
            <div className="form-fields-right">
              <InputWrapper
                maxWidth="120px"
                width="120px"
                name="AccountNo"
                label="Account No"
                onChange={handleOnChange}
                value={formState.AccountNo}
                maxLength={20}
                autoComplete="off"
                isDisabled={isEdit}
              />
              <CustomColComboBox
                comboBoxType="ACTIVE_INACTIVE"
                dataList={[]}
                width={120}
                defaultValue={formState.DisabledYN}
                name="DisabledYN"
                label="Status"
                onChange={(e) => handleOnChange(e, 'DisabledYN')}
                value={formState.DisabledYN}
                filterable
              />
            </div>
          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>

            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </DialogContent>
      </ReactFocusLock>

      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
      {showAddCompany && (
        <AddCompanyDialog
          onClose={handleAddCompanyClose}
          entityData={entityData}
        />
      )}
    </MovableDialog>
  );
};

export default AccountDialog;
