import type { GridColumnProps } from '@progress/kendo-react-grid';

export const columns: GridColumnProps[] = [
  {
    title: 'Field Label',
    field: 'FieldLabel',
    width: 305,
  },
  {
    title: 'Field Name',
    field: 'FieldName',
    width: 275,
  },
  {
    title: 'Type',
    field: 'FieldType',
  },
];