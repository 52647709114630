import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const Content = styled.div`
  font-size: 1.2rem;
  line-height: 1.5rem;
  line-height: 2rem;
  text-align: center;

  padding-left: 15px;
  padding-right: 15px;

  color: #294580;
`;
