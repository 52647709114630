import { useQuery } from '@tanstack/react-query';
import { accountsService } from '../account/accountsService';

export const useGetBankAccountsByType = (
  accountType: string,
  key: string = 'get_bank_accounts'
) => {
  return useQuery<any[]>(
    [key],
    async () => {
      const { data } = await accountsService.getAccountsByType(accountType);
      return [...data];
    },
    {
      initialData: [],
    }
  );
};

export const useGetBankAccountsByGroup = (
  accountGroup: string,
  key: string = 'get_bank_accounts'
) => {
  return useQuery<any[]>(
    [key],
    async () => {
      const { data } = await accountsService.getAccountsByGroup(accountGroup);
      return [...data];
    },
    {
      initialData: [],
    }
  );
};

export const useGetAccounts = (
  accountType: string,
  accountGroup: string,
  status: string,
  key: string,
) => {
  return useQuery<any>([key], async () => {
    const response = await accountsService.getAccounts(
      accountType,
      accountGroup,
      status
    );
    return response;
  });
};

export const useGetSetupCOA = (
  status: string,
  key: string,
) => {
  return useQuery<any>([key], async () => {
    const response = await accountsService.getSetupCOA(status);
    return response;
  })
};