import type { GridColumnProps } from '@progress/kendo-react-grid';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'DepartmentCode',
    width: 100,
    className: 'text-left',
  },
  {
    title: 'Description',
    field: 'DepartmentDesc',
    className: 'text-left',
  },
];
