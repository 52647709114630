import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import { plusIcon } from '@progress/kendo-svg-icons';

import { useKeyPress } from '../../../../hooks';

import { payGroupsService } from '../../../../services/payroll/payGroupsService';

import { PayGroupListType } from '../../../../data/types';
import { columns } from './model';

import { Button, ListingGrid } from '../../../common';
import PayGroupDialog from './PayGroupDialog';

import { ButtonWrapper, ListingGridWrapper } from '../PersonnelCodes.styled';

type SelectionType = { field?: string; dataItem: any };

interface PayGroupsProps {
  selectedPayGroup: PayGroupListType | undefined;
  setSelectedPayGroup: React.Dispatch<
    React.SetStateAction<PayGroupListType | undefined>
  >;
};

const PayGroups: FC<PayGroupsProps> = ({
  selectedPayGroup,
  setSelectedPayGroup,
}) => {
  const [originData, setOriginData] = useState<PayGroupListType[]>([]);
  const [filterData, setFilterData] = useState<PayGroupListType[]>([]);
  const [payGroupData, setPayGroupData] = useState<any[]>([]);
  const [ptoCodes, setPtoCodes] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [showContext, setShowContext] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectionType>();

  const offset = useRef({ left: 0, top: 0 });

  async function fetchData() {
    try {
      const payGroupList: any = await payGroupsService.getPayGroups();
      setOriginData(payGroupList ?? []);
      setFilterData(payGroupList ?? []);

      const payGroupDataList: any = await payGroupsService.getPayGroupData(selectedItem?.dataItem.PayGroupCode);
      setPayGroupData(payGroupDataList.data)
      setPtoCodes(payGroupDataList.data.ptoCodes)
    } catch (e) { }

    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  useKeyPress(
    () => {
    },
    () => {
    },
    () => {
    },
    () => {
      setSelectedPayGroup({ PayGroupCode: '' });
    }
  );


  return (
    <ListingGridWrapper>
      {isLoading ? (
        <></>
      ) : (
        <>
          <ListingGrid
            rowHeight={24}
            data={sort ? orderBy(filterData, sort) : filterData}
            dataItemKey="PayGroupCode"
            onRowClick={(e) => {
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.PayGroupCode === e.dataItem.PayGroupCode,
                }))
              );
            }}
            onRowDoubleClick={(e) => {
              setSelectedPayGroup(e.dataItem);
            }}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            onContextMenu={(event) => {
              handleContextMenuOpen(event.syntheticEvent);
              const { dataItem, field } = event;
              setFilterData((prev) =>
                prev.map((item) => ({
                  ...item,
                  selected: item.PayGroupCode === dataItem.PayGroupCode,
                }))
              );
              setSelectedItem({ field, dataItem });
            }}
          >
            {columns.map((column, index) => {
              return <GridColumn {...column} key={index} />;
            })}
          </ListingGrid>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedPayGroup({ PayGroupCode: '' });
              }}
              id="create-btn"
              title="Add New"
              svgIcon={plusIcon}
            >
              ADD <u>N</u>EW
            </Button>
          </ButtonWrapper>
        </>
      )}

      <ContextMenu
        show={showContext}
        offset={offset.current}
        onSelect={handleOnSelect}
        onClose={() => setShowContext(false)}
        className="context-menu"
      >
        <MenuItem
          text="Sort Ascending"
          data={{
            action: 'sortAsc',
          }}
        />
        <MenuItem
          text="Sort Descending"
          data={{
            action: 'sortDesc',
          }}
        />
        <MenuItem
          text="Filter In Selected"
          data={{
            action: 'filterIn',
          }}
        />
        <MenuItem
          text="Filter Out Selected"
          data={{
            action: 'filterOut',
          }}
        />
        <MenuItem
          text="Remove All Filters"
          data={{
            action: 'removeFilters',
          }}
        />
      </ContextMenu>
      {selectedPayGroup && (
        <PayGroupDialog
          handleClose={(shouldRefreshData?: boolean) => {
            setSelectedPayGroup(undefined);
            if (shouldRefreshData) {
              fetchData();
            }
          }}
          data={selectedPayGroup}
          isEdit={selectedPayGroup?.PayGroupCode ? true : false}
          dataTemplate={payGroupData}
          ptoCodes={ptoCodes}
        />
      )}
    </ListingGridWrapper>
  );
};

export default PayGroups;
