import { useEffect } from 'react';

function useDisableRightClick(currentRef: React.RefObject<any>) {
  const handleDisableRightClick = (e: MouseEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    let windowElement = currentRef?.current?.element as any;
    if (!windowElement) {
      windowElement = currentRef?.current as any;
    }
    
    if (windowElement) {
      windowElement.addEventListener('contextmenu', handleDisableRightClick);
    }

    return () => {
      if (windowElement) {
        windowElement.removeEventListener(
          'contextmenu',
          handleDisableRightClick
        );
      }
    };
  }, [currentRef]);
}

export default useDisableRightClick;
