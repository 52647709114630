import { styled } from 'styled-components';

export default styled.button`
  // background: white;
  border: 0px solid #f14e4e !important;
  /* width: 30px; */
  // color: #f14e4e;
  &:hover {
    background: #2c5098 !important;
    color: white !important;
    border: 1px solid #2c5098 !important;
  }
`;
