import type { GridColumnProps } from '@progress/kendo-react-grid';
import { NumericCell, CheckCell } from '../../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'PayCode',
    width: 100,
  },
  {
    title: 'Description',
    field: 'PayDesc',
    width: 200,
  },
  {
    title: 'Paid',
    field: 'AccruePaidYN',
    width: 121,
    cell: CheckCell,
  },
  {
    title: 'Accrues',
    field: 'AccrueYN',
    width: 121,
    cell: CheckCell,
  },
  {
    title: 'Qty',
    field: 'PTOQty',
    width: 121,
    className: 'text-center',
  },
  {
    title: 'Units',
    field: 'PTOUnits',
    // width: 55,
    className: 'text-center',
    cell: NumericCell,
  },
];
