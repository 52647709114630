import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ForwardedRef, ReactNode, forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { GenericConfirmModalProps, GenericDialogType } from './GenericDialog.types';
import { ButtonsContainer, DialogButton, DialogContent, DialogOverlay, PrimaryMessage, SecondaryMessage } from './GenericDialog.styled';
import ReactFocusLock from 'react-focus-lock';

// function getUnderlinedHTMLText(text: string) {
//   return <>
//     <u>{text.charAt(0)}</u>{text.substring(1)}
//   </>
// }

export const GenericDialog = ({
  primaryMessage,
  type,
  secondaryMessage,
  showIcon = true,
  confirmButtonText,
  onCancel,
  onClose,
  onConfirmed
}: GenericConfirmModalProps) => {

  const [dialogPos, setDialogPos] = useState({ top: 0, left: 0 });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const firstFocusBtnRef = useRef<HTMLButtonElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const dialogEl = dialogRef.current;
    if (!dialogEl) return;

    setDialogPos({
      top: window.innerHeight / 2 - (dialogEl.getBoundingClientRect().height / 2),
      left: window.innerWidth / 2 - (dialogEl.getBoundingClientRect().width / 2)
    })
  }, [])

  useEffect(() => {
    firstFocusBtnRef.current?.focus();
  }, [])

  useEffect(() => {
    const dialogEl = dialogRef.current;
    if (!dialogEl) return;

    let offset = { top: 0, left: 0 };

    const handleTab = (event: KeyboardEvent) => {
      const currTabIndex = Number((event.target as HTMLElement).getAttribute('tabindex')) - 1;
      // Get list and order by tabindex in ascending order
      const tabableElements: HTMLButtonElement[] = Array.from(dialogEl.querySelectorAll('[tabindex]'))
        .sort((a, b) => +(a.getAttribute('tabindex') ?? 0) - +(b.getAttribute('tabindex') ?? 0)) as HTMLButtonElement[];

      if (event.shiftKey || event.key === "ArrowLeft") {
        const nextFocusedEl = tabableElements[currTabIndex - 1];
        if (nextFocusedEl) {
          nextFocusedEl.focus();
        } else {
          tabableElements[tabableElements.length - 1].focus();
        }
      } else {
        const nextFocusedEl = tabableElements[currTabIndex + 1];
        if (nextFocusedEl) {
          nextFocusedEl.focus();
        } else {
          tabableElements[0].focus();
        }
      }

    }

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (type === "Error" || type === "Message") onClose();
        else if (type === "Confirmation" || type === "UnsavedClose") onCancel();
      }
      else if (event.key === "Tab" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
        event.preventDefault();
        event.stopPropagation();
        handleTab(event);
      }

      // const sKeys = ['s', 'S', 'ß', 'Í'];
      const cKeys = ['c', 'C', 'ç', 'Ç'];
      const yKeys = ["y", "Y"];
      const nKeys = ["n", "N"];

      if (nKeys.includes(event.key)) {
        if (type === "UnsavedClose") onClose();
        if (type === "Confirmation") onCancel();
      } else if (yKeys.includes(event.key)) {
        if (type === "UnsavedClose" || type === "Confirmation") onConfirmed();
      } else if (cKeys.includes(event.key)) {
        if (type === "Error" || type === "Message") onClose();
      }

    }

    const handleMousemove = (event: MouseEvent) => {
      setIsDragging(true);
      setDialogPos({
        top: event.clientY - offset.top,
        left: event.clientX - offset.left
      });
    }

    const handleMousedown = (event: MouseEvent) => {
      if ((event.target as HTMLElement).tagName === "button" ||
        (event.target as HTMLElement).tagName === "span"
      ) return;
      offset = {
        top: event.clientY - dialogEl.getBoundingClientRect().top,
        left: event.clientX - dialogEl.getBoundingClientRect().left
      }

      event.preventDefault();
      document.addEventListener('mousemove', handleMousemove)
    }

    const handleMouseup = () => {
      setIsDragging(false);
      document.removeEventListener('mousemove', handleMousemove);
    }

    dialogEl.addEventListener('keydown', handleKeyPress);
    dialogEl.addEventListener('mousedown', handleMousedown);
    document.addEventListener('mouseup', handleMouseup);
    return () => {
      dialogEl.removeEventListener('keydown', handleKeyPress);
      dialogEl.removeEventListener('mousedown', handleMousedown);
      document.removeEventListener('mouseup', handleMouseup);
      document.removeEventListener('mousemove', handleMousemove)
    }
  }, [type, onClose, onCancel, onConfirmed])

  const handleButtonMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }

  useEffect(() => {
    console.log(dialogPos)
  }, [dialogPos])

  if (type === "None") return <></>;

  return createPortal(<>
    <ReactFocusLock>
      <DialogOverlay onMouseDown={e => e.preventDefault()} />
      <CustomDialog
        ref={dialogRef}
        style={{ cursor: isDragging ? 'grabbing' : 'grab', ...dialogPos }}
      >
        <DialogContent>

          {showIcon && <Icon type={type} />}

          <PrimaryMessage>{primaryMessage}</PrimaryMessage>

          {secondaryMessage &&
            <SecondaryMessage>{secondaryMessage}</SecondaryMessage>
          }

          <ButtonsContainer>
            {(type === "Message" || type === "Error") &&
              <DialogButton
                className={type === "Message" ? 'blue-button' : 'red-button'}
                onMouseDown={handleButtonMouseDown}
                tabIndex={1}
                // className='bg-blue-500  px-6 py-3 hover:bg-blue-700'
                onClick={onClose}
                ref={firstFocusBtnRef}
              >Close</DialogButton>
            }

            {type === "Confirmation" &&
              <>
                <DialogButton
                  onMouseDown={handleButtonMouseDown}
                  tabIndex={1}
                  className='green-button'
                  onClick={onConfirmed}
                >YES</DialogButton>
                <DialogButton
                  onMouseDown={handleButtonMouseDown}
                  tabIndex={2}
                  className='red-button'
                  onClick={onCancel}
                  ref={firstFocusBtnRef}
                >NO</DialogButton>
              </>
            }

            {type === "UnsavedClose" &&
              <>
                <DialogButton
                  onMouseDown={handleButtonMouseDown}
                  tabIndex={1}
                  className='green-button'
                  onClick={onConfirmed}
                  ref={firstFocusBtnRef}
                >YES</DialogButton>
                <DialogButton
                  onMouseDown={handleButtonMouseDown}
                  tabIndex={2}
                  className='red-button'
                  onClick={onClose}
                >NO</DialogButton>
              </>
            }
          </ButtonsContainer>
        </DialogContent>
      </CustomDialog>
    </ReactFocusLock>
  </>, document.body)
}

const CustomDialog = forwardRef(({ children, ...props }: { children: ReactNode } & React.HTMLAttributes<HTMLDivElement>, ref: ForwardedRef<HTMLDivElement>) => {
  return <div
    {...props}
    ref={ref}
    style={{ "position": "absolute", inset: 0, "width": "fit-content", "height": "fit-content", "backgroundColor": "#ffffff", "padding": "30px", zIndex: 100000000000, borderRadius: 10, ...props.style }}
  >
    {children}
  </div>
})


const Icon = ({ type }: { type: GenericDialogType }) => {

  return <>
    {type === "Message" && <InfoOutlinedIcon sx={{ fontSize: 50, color: ' #3B82F6' }} />}
    {type === "Error" && <ErrorOutlineOutlinedIcon sx={{ fontSize: 50, color: '#EF4444' }} />}
    {(type === "Confirmation" || type === "UnsavedClose") && <HelpOutlineOutlinedIcon sx={{ fontSize: 50, color: ' #3B82F6' }} />}
  </>
}