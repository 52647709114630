import React, { FC, useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent,
} from '@progress/kendo-react-inputs';
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnBeforeRemoveEvent,
  UploadOnBeforeUploadEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { SvgIcon } from '@progress/kendo-react-common';
import { imageAddIcon } from '@progress/kendo-svg-icons';

import { axiosClient } from '../../../services/axiosClient';
import { InputWrapper, Button, CustomColComboBox } from '../../common';
import { Button as KendoButton } from '@progress/kendo-react-buttons';

import { ICompanySetupProps } from './CompanySetup.types';
import { ICompanyInput } from '../../../data/types';

import {
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../hooks';
import {
  formatCompanyData,
  generateCompanyData,
  getChangedValues,
  handleKeyDown,
  validateCompanyForm,
} from '../../../utils';
import { useUpdateCompany } from '../../../services/hooks';
import {
  AK_VAR,
  CID_VAR,
  CLIENT_PLATFORM,
  USER_VAR,
  orgTypeOptions,
} from '../../../constants';

import {
  AddressCheckBoxWrapper,
  AddressFieldWrapper,
  ButtonWrapper,
  CenteredText,
  CompanyGrid,
  CompanyGridItem,
  CompanySubGridItem,
  ContactGrid,
  FieldWrapper,
  FieldsWrapper,
  FileUploadWrapper,
  Form,
  FormContainer,
  FormFieldsWrapper,
  IdentitySection,
  LeftLabelInputsSection,
  RightLabelGridInputWrapper,
  RightLabelInputWrapper,
  Tab,
  TabsContainer,
} from './CompanySetup.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import { companySetupService } from '../../../services/setup/companySetupService';
import SICSearchDialog from './SearchDialog';
import PageNavButton from '../../common/PageNavButton';

type SICCodeType = {
  SICCode: number;
  SICIndustryTitle: string;
  SICOffice: string;
};

const CompanySetup: FC<ICompanySetupProps> = ({
  title,
  currentForm,
  states,
  companyInfo,
  fieldProperties,
  onClose,
  onNextPage,
  onPreviousPage,
}) => {
  const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
  const [previewImg, setPreviewImg] = React.useState<string | undefined>();
  const [formState, setFormState] = useState<ICompanyInput>({});
  const [isValid, setIsValid] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [SICCodes, setSICCodes] = useState<SICCodeType[]>([]);

  const { mutate: updateCompany } = useUpdateCompany(onClose);

  const formRef = useRef<HTMLFormElement>(null);
  const logoUploadRef = useRef(null);
  const formContainerRef = useRef<HTMLDivElement>(null);

  useHighlightInput('company-name');
  useDisableRightClick(formContainerRef);

  useEffect(() => {
    async function fetchData() {
      const data: SICCodeType[] = await companySetupService.getSICCodes();
      setSICCodes(data);
    }

    fetchData();
  }, []);

  useKeyPress(
    () => {
      if (isValid) {
        onSubmit(formState);
      }
    },
    () => {
      onClose();
    }
  );

  useEffect(() => {
    setFormState({});
  }, [currentForm]);

  useEffect(() => {
    // console.log('logoUploadRef', logoUploadRef?.current);
  }, [logoUploadRef]);

  useEffect(() => {
    const logoUpload = document.querySelectorAll('#logo-upload');
    const logoUploadButton = logoUpload[0] as HTMLButtonElement;
    logoUploadButton.tabIndex = -1;
    const logoUploadInput = logoUpload[1];

    const handleFocus = () => {
      setCurrentTab(3);
    };

    logoUploadInput.addEventListener('focus', handleFocus);

    return () => {
      logoUploadInput.removeEventListener('focus', handleFocus);
    };
  }, []);

  useEffect(() => {
    if (!companyInfo || !states) {
      return;
    }
    setFormState(generateCompanyData(companyInfo, states));
  }, [companyInfo, states]);

  useEffect(() => {
    setIsValid(validateCompanyForm(formState));
  }, [formState]);

  useEffect(() => {
    if (companyInfo?.CompLogoID) {
      const url = `/Document/GetFileURL?FileID=${companyInfo?.CompLogoID}`;
      const headers = {
        accept: '*/*',
        CID: CID_VAR,
        AK: AK_VAR,
        User: USER_VAR,
        ClientPlatform: CLIENT_PLATFORM,
      };

      axiosClient()
        .get(url, { headers })
        .then((response) => {
          setPreviewImg(response.data.data.fileURL);
        })
        .catch((error) => {
          console.error('Request Error:', error);
        });
    }
  }, [companyInfo?.CompLogoID]);

  const onSubmit = (variables: ICompanyInput) => {
    try {
      const formattedData = formatCompanyData(variables);
      const changes = getChangedValues(formattedData, companyInfo);

      if (Object.keys(changes).length > 0) {
        updateCompany({
          id: companyInfo?.IDNo,
          ...changes,
        });
      }
    } catch (e: unknown) {
      alert(`error while saving data: ${String(e)}`);
    }
  };

  const handleOnChange = (
    event: ComboBoxChangeEvent | InputChangeEvent | CheckboxChangeEvent,
    key: string
  ) => {
    const isShipSameTrue = event.value === true;
    // If ShipSameYN is checked then populate shipping address fields otherwise clear them
    if (key === 'ShipSameYN') {
      setFormState((prevState) => ({
        ...prevState,
        ShipAddress1: isShipSameTrue ? prevState?.CompAddress1 : '',
        ShipAddress2: isShipSameTrue ? prevState?.CompAddress2 : '',
        ShipCity: isShipSameTrue ? prevState?.CompCity : '',
        ShipSt: isShipSameTrue ? prevState?.CompSt : null,
        ShipZip: isShipSameTrue ? prevState?.CompZip : null,
        [key]: event.value,
      }));
    }
    // If ShipSameYN is checked then populate shipping address fields with main address fields in real-time.
    else if (
      formState.ShipSameYN &&
      (key === 'CompAddress1' ||
        key === 'CompAddress2' ||
        key === 'CompCity' ||
        key === 'CompSt' ||
        key === 'CompZip')
    ) {
      setFormState((prevState) => {
        return {
          ...prevState,
          ShipAddress1:
            key === 'CompAddress1' ? event.value : prevState?.CompAddress1,
          ShipAddress2:
            key === 'CompAddress2' ? event.value : prevState?.CompAddress2,
          ShipCity: key === 'CompCity' ? event.value : prevState?.CompCity,
          ShipSt: key === 'CompSt' ? event.value : prevState?.CompSt,
          ShipZip: key === 'CompZip' ? event.value : prevState?.CompZip,
          [key]: event.value,
        };
      });
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const onBeforeUpload = (event: UploadOnBeforeUploadEvent) => {
    event.headers.AK = window.sessionStorage.getItem('SECRET_AK') as string;
    event.headers.User = window.localStorage.getItem(
      'SECRET_USERNAME'
    ) as string;
    event.headers.ClientPlatform = 'all';
    event.headers.ClientTime = new Date().toISOString();
  };

  const onBeforeRemove = (event: UploadOnBeforeRemoveEvent) => {
    event.headers.AK = window.sessionStorage.getItem('SECRET_AK') as string;
    event.headers.User = window.localStorage.getItem(
      'SECRET_USERNAME'
    ) as string;
    event.headers.ClientPlatform = 'all';
    event.headers.ClientTime = new Date().toISOString();
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    setFiles(event.newState);

    if (event.response?.response.fileIDGenerated) {
      const newFile: UploadFileInfo = event.newState[0];

      if (newFile?.getRawFile instanceof Function && newFile.getRawFile()) {
        const rawFile = newFile.getRawFile();
        if (rawFile) {
          const imageUrl = URL.createObjectURL(rawFile);
          setPreviewImg(imageUrl);
        }
      }
      setFormState({
        ...formState,
        CompLogoID: event.response?.response.fileIDGenerated,
      });
    } else {
      setFormState({
        ...formState,
        CompLogoID: undefined,
      });
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setFiles(event.newState);
  };

  const onProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState);
  };

  const focusInput = (inputId: string) => {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input && input.select) {
      input.select();
    }
  };

  const handleCurrentTab = (e: React.MouseEvent, tabIndex: number) => {
    e.preventDefault();
    tabIndex === 0
      ? focusInput('company-name')
      : tabIndex === 1
      ? focusInput('second-tab-first-input')
      : tabIndex === 2
      ? focusInput('third-tab-first-input')
      : focusInput('logo-upload');
    setCurrentTab(tabIndex);
  };

  return (
    <>
      <FocusLock className="temp-form">
        <FormContainer ref={formContainerRef} className="temp-form-container">
          <Form
            onKeyDown={(e) => handleKeyDown(e, formRef)}
            ref={formRef}
            className="temp-form"
          >
            <div className="form-section-header-container">
              <div className="form-title">{title}</div>
              <div className="form-header-btn-wrapper">
                <PageNavButton
                  className="listing-action-button"
                  onClick={(e) => {
                    e.preventDefault();
                    onPreviousPage();
                  }}
                  title="Previous Page"
                >
                  <span
                    className="k-icon k-i-arrow-left k-button-icon"
                    role="presentation"
                  />
                </PageNavButton>
                <PageNavButton
                  className="listing-action-button"
                  onClick={(e) => {
                    e.preventDefault();
                    onNextPage();
                  }}
                  title="Next Page"
                >
                  <span
                    className="k-icon k-i-arrow-right k-button-icon"
                    role="presentation"
                  />
                </PageNavButton>
                <CloseButton
                  className="listing-action-button"
                  onClick={onClose}
                  title="Close"
                >
                  <span
                    className="k-icon k-i-close k-button-icon"
                    role="presentation"
                  />
                </CloseButton>
              </div>
            </div>
            <div className="form-content">
              <TabsContainer>
                <Tab
                  isActive={currentTab === 0}
                  onClick={(e) => handleCurrentTab(e, 0)}
                >
                  Address
                </Tab>
                <Tab
                  isActive={currentTab === 1}
                  onClick={(e) => handleCurrentTab(e, 1)}
                >
                  Contacts
                </Tab>
                <Tab
                  isActive={currentTab === 2}
                  onClick={(e) => handleCurrentTab(e, 2)}
                >
                  Identity
                </Tab>
                <Tab
                  isActive={currentTab === 3}
                  onClick={(e) => handleCurrentTab(e, 3)}
                >
                  Logo
                </Tab>
              </TabsContainer>
              <FormFieldsWrapper isActive={currentTab === 0}>
                <CompanyGridItem
                  paddingTop="70px"
                  paddingBottom="28px"
                  hideBorder
                >
                  <CustomGrid
                    cols="417px"
                    rowGap="4rem"
                    colGap="1rem"
                    style={{ maxWidth: '417px', margin: '0px auto' }}
                  >
                    <div className="contacts-wrapper">
                      <label className="contacts-label">Mailing Address</label>
                      <CompanySubGridItem style={{ marginTop: '16px' }}>
                        <FieldWrapper width="50%">
                          <InputWrapper
                            name="CompanyName"
                            id="company-name"
                            label="Company Name"
                            value={formState.CompanyName || ''}
                            onChange={handleOnChange}
                            maxLength={
                              fieldProperties?.CompanyName?.maxLength || 50
                            }
                            onFocus={() => {
                              setCurrentTab(0);
                            }}
                          />
                        </FieldWrapper>
                        <FieldWrapper width="50%">
                          <InputWrapper
                            name="CompAddress1"
                            label="Address 1"
                            value={formState.CompAddress1 || ''}
                            onChange={handleOnChange}
                            maxLength={
                              fieldProperties?.CompAddress1?.maxLength || 50
                            }
                          />
                        </FieldWrapper>
                        <FieldWrapper width="50%">
                          <InputWrapper
                            name="CompAddress2"
                            label="Address 2"
                            value={formState.CompAddress2 || ''}
                            onChange={handleOnChange}
                            maxLength={
                              fieldProperties?.CompAddress2?.maxLength || 50
                            }
                          />
                        </FieldWrapper>
                        <FieldsWrapper>
                          <AddressFieldWrapper width="240px">
                            <InputWrapper
                              name="CompCity"
                              label="City"
                              value={formState.CompCity || ''}
                              onChange={handleOnChange}
                              maxLength={
                                fieldProperties?.CompCity?.maxLength || 50
                              }
                            />
                          </AddressFieldWrapper>
                          <AddressFieldWrapper
                            width="70px"
                            className="company-profile-form"
                          >
                            <CustomColComboBox
                              name="CompSt"
                              label="State"
                              dataList={states}
                              textField="State"
                              defaultValue={formState.CompSt}
                              onChange={(e) => {
                                handleOnChange(e, 'CompSt');
                              }}
                              filterable={true}
                              className="state-combobox"
                              value={formState.CompSt}
                              width={70}
                              comboBoxType="StatesUS"
                            />
                          </AddressFieldWrapper>
                          <AddressFieldWrapper width="100px">
                            <InputWrapper
                              name="CompZip"
                              label="Zip"
                              value={formState.CompZip || ''}
                              onChange={handleOnChange}
                              maxLength={
                                fieldProperties?.CompZip?.maxLength || 5
                              }
                            />
                          </AddressFieldWrapper>
                        </FieldsWrapper>
                      </CompanySubGridItem>
                    </div>
                    <div className="contacts-wrapper">
                      <label className="contacts-label">Shipping Address</label>
                      <CompanySubGridItem style={{ marginTop: '16px' }}>
                        <AddressCheckBoxWrapper>
                          <Checkbox
                            name="ShipSameYN"
                            label={'(Same as Mailing Address)'}
                            id="checkbox"
                            value={formState?.ShipSameYN}
                            onChange={(e) => handleOnChange(e, 'ShipSameYN')}
                            onFocus={() => {
                              setCurrentTab(0);
                            }}
                          />
                        </AddressCheckBoxWrapper>
                        <FieldWrapper width="100%">
                          <InputWrapper
                            name="ShipAddress1"
                            label="Address 1"
                            value={formState.ShipAddress1 || ''}
                            onChange={handleOnChange}
                            isDisabled={Boolean(formState?.ShipSameYN)}
                            maxLength={
                              fieldProperties?.ShipAddress1?.maxLength || 50
                            }
                          />
                        </FieldWrapper>
                        <FieldWrapper width="100%">
                          <InputWrapper
                            name="ShipAddress2"
                            label="Address 2"
                            value={formState.ShipAddress2 || ''}
                            onChange={handleOnChange}
                            isDisabled={Boolean(formState?.ShipSameYN)}
                            maxLength={
                              fieldProperties?.ShipAddress2?.maxLength || 50
                            }
                          />
                        </FieldWrapper>
                        <FieldsWrapper>
                          <AddressFieldWrapper width="240px">
                            <InputWrapper
                              name="ShipCity"
                              label="City"
                              value={formState.ShipCity || ''}
                              onChange={handleOnChange}
                              isDisabled={Boolean(formState?.ShipSameYN)}
                              maxLength={
                                fieldProperties?.ShipCity?.maxLength || 50
                              }
                            />
                          </AddressFieldWrapper>
                          <AddressFieldWrapper width="70px">
                            <CustomColComboBox
                              name="ShipSt"
                              label="State"
                              dataList={states}
                              textField="State"
                              defaultValue={formState.ShipSt}
                              onChange={(e) => {
                                handleOnChange(e, 'ShipSt');
                              }}
                              filterable={true}
                              className="state-combobox"
                              value={formState.ShipSt}
                              width={70}
                              comboBoxType="StatesUS"
                              isDisabled={Boolean(formState?.ShipSameYN)}
                            />
                          </AddressFieldWrapper>
                          <AddressFieldWrapper width="100px">
                            <InputWrapper
                              name="ShipZip"
                              label="Zip"
                              value={formState.ShipZip || ''}
                              onChange={handleOnChange}
                              maxLength={
                                fieldProperties?.ShipZip?.maxLength || 5
                              }
                              onFocus={() => {
                                setCurrentTab(0);
                              }}
                              isDisabled={Boolean(formState?.ShipSameYN)}
                            />
                          </AddressFieldWrapper>
                        </FieldsWrapper>
                      </CompanySubGridItem>
                    </div>
                  </CustomGrid>
                </CompanyGridItem>
              </FormFieldsWrapper>
              <FormFieldsWrapper isActive={currentTab === 1}>
                <CompanyGridItem
                  paddingTop="87px"
                  paddingBottom="80px"
                  style={{ width: '700px', margin: '0px auto' }}
                >
                  <CustomGrid cols="1fr" colGap="1rem">
                    <CompanySubGridItem>
                      <LeftLabelInputsSection>
                        <RightLabelInputWrapper>
                          <label>{`Enter your company's primary phone number`}</label>
                          <InputWrapper
                            maxWidth="125px"
                            name="CompPhone1"
                            onChange={handleOnChange}
                            value={formState.CompPhone1 || ''}
                            maxLength={
                              fieldProperties?.CompPhone1?.maxLength || 50
                            }
                            id="second-tab-first-input"
                            onFocus={() => {
                              setCurrentTab(1);
                            }}
                          />
                        </RightLabelInputWrapper>
                        <RightLabelInputWrapper>
                          <label>{`Enter your company's secondary phone number (if any)`}</label>
                          <InputWrapper
                            maxWidth="125px"
                            name="CompPhone2"
                            onChange={handleOnChange}
                            value={formState.CompPhone2 || ''}
                            maxLength={
                              fieldProperties?.CompPhone2?.maxLength || 50
                            }
                          />
                        </RightLabelInputWrapper>
                      </LeftLabelInputsSection>
                    </CompanySubGridItem>
                  </CustomGrid>
                </CompanyGridItem>
                <CompanyGridItem paddingTop="54px" hideBorder>
                  <CenteredText>
                    Setup up to three contacts for different areas of
                    responsibility within your company
                  </CenteredText>
                  <ContactGrid>
                    <span />
                    <span>Contact Name</span>
                    <span>Description</span>
                    <span>Number</span>
                    <span>Email</span>

                    <span className="number">1</span>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactName1"
                        value={formState.ContactName1 || ''}
                        onChange={(e) => handleOnChange(e, 'ContactName1')}
                        maxLength={
                          fieldProperties?.ContactName1?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactDesc1"
                        onChange={handleOnChange}
                        value={formState.ContactDesc1 || ''}
                        maxLength={
                          fieldProperties?.ContactDesc1?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactPhone1"
                        onChange={handleOnChange}
                        value={formState.ContactPhone1 || ''}
                        maxLength={
                          fieldProperties?.ContactPhone1?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactEmail1"
                        onChange={handleOnChange}
                        value={formState.ContactEmail1 || ''}
                        maxLength={
                          fieldProperties?.ContactEmail1?.maxLength || 50
                        }
                      />
                    </FieldWrapper>

                    <span className="number">2</span>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactName2"
                        value={formState.ContactName2 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactName2?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactDesc2"
                        value={formState.ContactDesc2 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactDesc2?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactPhone2"
                        value={formState.ContactPhone2 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactPhone2?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactEmail2"
                        value={formState.ContactEmail2 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactEmail2?.maxLength || 50
                        }
                      />
                    </FieldWrapper>

                    <span className="number">3</span>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactName3"
                        value={formState.ContactName3 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactName3?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactDesc3"
                        value={formState.ContactDesc3 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactDesc3?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactPhone3"
                        value={formState.ContactPhone3 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactPhone3?.maxLength || 50
                        }
                      />
                    </FieldWrapper>
                    <FieldWrapper width="50%">
                      <InputWrapper
                        name="ContactEmail3"
                        value={formState.ContactEmail3 || ''}
                        onChange={handleOnChange}
                        maxLength={
                          fieldProperties?.ContactEmail3?.maxLength || 50
                        }
                        onFocus={() => {
                          setCurrentTab(1);
                        }}
                      />
                    </FieldWrapper>
                  </ContactGrid>
                </CompanyGridItem>
              </FormFieldsWrapper>
              <FormFieldsWrapper isActive={currentTab === 2}>
                <CompanyGridItem
                  paddingTop="104px"
                  paddingBottom="28px"
                  hideBorder
                >
                  <CustomGrid cols="1fr" colGap="1rem">
                    <CompanySubGridItem>
                      <IdentitySection>
                        <RightLabelGridInputWrapper>
                          <label>How is your company legally organized?</label>
                          <CustomColComboBox
                            name="OrgType"
                            dataList={orgTypeOptions}
                            comboBoxType="KEY_NAME"
                            defaultValue={formState.OrgType}
                            onChange={(e) => handleOnChange(e, 'OrgType')}
                            filterable={true}
                            value={formState.OrgType}
                            id="third-tab-first-input"
                            onFocus={() => {
                              setCurrentTab(2);
                            }}
                            width={175}
                          />
                        </RightLabelGridInputWrapper>
                        <RightLabelGridInputWrapper>
                          <label>
                            What is your Federal Employer Identification Number
                            (EIN)?
                          </label>
                          <InputWrapper
                            name="FedTaxID"
                            maxWidth="175px"
                            value={formState.FedTaxID || ''}
                            onChange={handleOnChange}
                            maxLength={
                              fieldProperties?.FedTaxID?.maxLength || 50
                            }
                          />
                        </RightLabelGridInputWrapper>
                        <RightLabelGridInputWrapper>
                          <label>
                            What is your State Tax Identification number?
                          </label>
                          <InputWrapper
                            name="StateTaxID"
                            maxWidth="175px"
                            value={formState.StateTaxID || ''}
                            onChange={handleOnChange}
                            maxLength={
                              fieldProperties?.StateTaxID?.maxLength || 50
                            }
                          />
                        </RightLabelGridInputWrapper>
                        <RightLabelGridInputWrapper>
                          <label>
                            What is your Standard Industrial Classification code
                            (SIC)?
                          </label>
                          <InputWrapper
                            name="SICCode"
                            width="175px"
                            maxWidth="175px"
                            value={formState.SICCode || ''}
                            onChange={handleOnChange}
                            maxLength={
                              fieldProperties?.SICCode?.maxLength || 50
                            }
                          />
                          {SICCodes.length > 0 ? (
                            <KendoButton
                              className="tabable-button"
                              icon="search"
                              fillMode={'solid'}
                              onClick={(e) => {
                                e.preventDefault();
                                setShowSearch(true);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  setShowSearch(true);
                                }
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </RightLabelGridInputWrapper>
                        <RightLabelGridInputWrapper>
                          <label>
                            {`What is your company's primary website address?`}
                          </label>
                          <InputWrapper
                            name="WebsiteURL"
                            maxWidth="175px"
                            value={formState.WebsiteURL || ''}
                            onChange={handleOnChange}
                            maxLength={
                              fieldProperties?.WebsiteURL?.maxLength || 50
                            }
                          />
                        </RightLabelGridInputWrapper>
                      </IdentitySection>
                    </CompanySubGridItem>
                  </CustomGrid>
                </CompanyGridItem>
              </FormFieldsWrapper>
              <FormFieldsWrapper isActive={currentTab === 3}>
                <CompanyGrid>
                  <CompanyGridItem
                    hideBorder
                    paddingTop="140px"
                    paddingBottom="4px"
                  >
                    <CenteredText>Import your company logo</CenteredText>
                    <CustomGrid cols="1fr" colGap="1rem">
                      <CompanySubGridItem>
                        <FileUploadWrapper>
                          <Upload
                            batch={true}
                            multiple={true}
                            saveField="file"
                            files={files}
                            withCredentials={true}
                            saveUrl={
                              'https://dev-api2.probuiltinfra.com/v3/Document/UploadFile?FileCategory=SET_COMP&CategoryID=0&FileType=L&FileDescription=Company_Logo&FileExt=png'
                            }
                            // saveUrl={async (files, options, onProgress) => {
                            //   try {
                            //     options.formData = new FormData();

                            //     files?.forEach((file) => {
                            //       const rawFile = file?.getRawFile?.();
                            //       if (rawFile) {
                            //         options.formData.append(
                            //           'uploadedFiles',
                            //           rawFile
                            //         );
                            //         options.formData.append('fileTypes', 'L');
                            //         options.formData.append(
                            //           'fileDescriptions',
                            //           'Company Logo'
                            //         );
                            //       }
                            //     });

                            //     const response =
                            //       await companySetupService.uploadCompanyLogo(
                            //         options.formData
                            //       );

                            //     const uid = response.data.uid;

                            //     return { uid };
                            //   } catch (error) {
                            //     throw new Error('Failed to upload');
                            //   }
                            // }}
                            removeUrl={`https://api.mtillholdings.com/v3/Document/DeleteFile?FileID=${formState?.CompLogoID}`}
                            onBeforeUpload={onBeforeUpload}
                            onBeforeRemove={onBeforeRemove}
                            onAdd={onAdd}
                            onRemove={onRemove}
                            onProgress={onProgress}
                            onStatusChange={onStatusChange}
                            removeMethod="delete"
                            restrictions={{
                              allowedExtensions: [
                                '.jpg',
                                '.png',
                                '.xlsx',
                                '.docx',
                                '.doc',
                                '.xls',
                                '.pdf',
                              ],
                              maxFileSize: 4000000,
                            }}
                            selectMessageUI={() => (
                              <span className="upload-content">
                                {previewImg ? (
                                  <img
                                    src={previewImg}
                                    alt=""
                                    style={{
                                      maxWidth: '200px',
                                      maxHeight: '110px',
                                    }}
                                  />
                                ) : (
                                  <span
                                    style={{
                                      maxWidth: '200px',
                                      maxHeight: '110px',
                                      marginBottom: '16px',
                                    }}
                                  >
                                    <SvgIcon
                                      icon={imageAddIcon}
                                      width={110}
                                      height={110}
                                    />
                                  </span>
                                )}
                                <span className="bold-message">
                                  Drag and drop a file here.
                                </span>
                                <span className="select-message">
                                  Or select a file...
                                </span>
                              </span>
                            )}
                            id="logo-upload"
                            ref={logoUploadRef}
                          />
                          <div className="file-type-message">
                            We accept PNG and JPEG files.
                          </div>
                          <div className="image-size-message">
                            Images larger than 600x200 pixels will be cropped
                            and resized.
                          </div>
                        </FileUploadWrapper>
                      </CompanySubGridItem>
                    </CustomGrid>
                  </CompanyGridItem>
                </CompanyGrid>
              </FormFieldsWrapper>

              <div className="form-footer">
                <ButtonWrapper>
                  <Button
                    disabled={!isValid}
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit(formState);
                    }}
                    id="save-btn"
                    title="Save"
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        onSubmit(formState);
                      }
                    }}
                  >
                    <u>S</u>ave
                  </Button>
                  <Button
                    onClick={onClose}
                    type="button"
                    id="cancel-btn"
                    title="Cancel"
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        onClose();
                      }
                    }}
                  >
                    <u>C</u>ancel
                  </Button>
                </ButtonWrapper>
              </div>
            </div>
          </Form>
        </FormContainer>
      </FocusLock>
      {showSearch && (
        <SICSearchDialog
          data={SICCodes}
          onClose={() => setShowSearch(false)}
          onSelected={(selectedCode) =>
            setFormState((prev) => ({ ...prev, SICCode: selectedCode }))
          }
        />
      )}
    </>
  );
};

export default CompanySetup;
