import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import ReactFocusLock from 'react-focus-lock';
import {
    useDisableEscape,
    useDisableRightClick,
    useHighlightInput,
    useKeyPress,
} from '../../../../hooks';
import { Button } from '@progress/kendo-react-buttons';
import MovableDialog from '../../../common/MovableDialog';
import EditableGrid from '../../../common/EditableGrid';
import {
    Grid,
    GridColumn as Column,
    GridItemChangeEvent,
} from '@progress/kendo-react-grid';
import { AccountImportItemType } from '../../../../data/types';
import { DialogContent, ButtonWrapper, ImportGridContainer, ColumnsContainer } from './AccountImportDialog.styled';
import { accountsService } from '../../../../services/account/accountsService';
import { CustomColComboBox } from '../../../common';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

import { ReadOnlyCell, CheckboxCell } from '../../../common/EditableGrid';
import { ImportCheckCell } from '../../../common/ListingView/ImportCheckCell';
import { ItemCell } from '../../../common/EditableGrid';
import { GridCellProps } from '@progress/kendo-react-grid';

import { ChartOfAccountsContext } from '../ChartOfAccountsContext';

type GridItemType = Record<string, any>;

interface IAccountImportProps {
    currentData?: any[];
    importedData?: any[];
    handleClose: (shouldRefreshData?: boolean) => void;
};

const AccountsImportModal: React.FC<IAccountImportProps> = ({
    currentData,
    importedData,
    handleClose,
}) => {
    const { accountTypesList } = useContext(ChartOfAccountsContext);
    const [gridItems, setGridItems] = useState<any[]>([]);
    const [invalidGrid, setInvalidGrid] = useState<boolean>(true);
    const [selectedDataItem, setSelectedDataItem] = useState<
        AccountImportItemType | undefined
    >(undefined);
    const [selectedRow, setSelectedRow] = useState<{
        prev: AccountImportItemType | undefined;
        current: AccountImportItemType | undefined;
    }>({
        prev: undefined,
        current: undefined,
    });

    const rename = (data: any[]) => {
        const keys = Object.keys(data[0]);

        const newImportedData = data.map((obj) => {
            const newObj = { ...obj };

            if (!newObj.hasOwnProperty('Account No')) {
                newObj['Account No'] = newObj[keys[0]];
                delete newObj[keys[0]];
            }

            if (!newObj.hasOwnProperty('Account Name')) {
                newObj['Account Name'] = newObj[keys[1]];
                delete newObj[keys[1]];
            }

            if (!newObj.hasOwnProperty('Account Type')) {
                newObj['Account Type'] = newObj[keys[2]];
                delete newObj[keys[2]];
            }

            return newObj;
        })
        return newImportedData;
    };

    const truncatedValue = (value: string, maxLength: number) => value ? value.substring(0, maxLength).toString() : '';

    const [gridItemsState, setGridItemsState] = useState(
        rename(importedData || [])?.map((item, index) => ({
            ...item,
            IDNo: index,
            'Account No': item['Account No'] ? truncatedValue(item['Account No'], 25) : '',
            'Account Name': truncatedValue(item['Account Name'], 50),
            'Account Type': truncatedValue(item['Account Type'], 50),
            Error: true,
            Reason: '',
            Action: false,
        }))
    );

    const [gridColumnState, setGridColumnState] = useState({
        accountNoColumn: 'Column 1',
        accountNameColumn: 'Column 2',
        accountTypeColumn: 'Column 3',
    });

    const dialogRef = useRef<any>(null);
    const gridRef = useRef<Grid>(null);

    useHighlightInput('account-name');
    useDisableRightClick(dialogRef);
    useDisableEscape(dialogRef);

    useKeyPress(
        () => {
        },
        () => {
            handleClose();
        },
        () => {
        }
    );

    const itemField = (columnTitle: keyof AccountImportItemType): keyof AccountImportItemType => {
        const column: any = columns.find(col => col.title === columnTitle);
        return column?.field;
    };

    const keys = gridItemsState && gridItemsState.length > 0 ? Object.keys(gridItemsState[0]) : [];

    const [columns, setColumns] = useState(() => {
        const updatedColumns = keys.map(key => {

            let width = 200
            let className = 'text-left'
            let cellComponent = ReadOnlyCell;

            if (key === 'Error') {
                cellComponent = ImportCheckCell;
                className = 'text-center'
                width = 65
            } else if (key === 'Action') {
                cellComponent = CheckboxCell;
                width = 65
            } else if (key === 'Reason') {
                width = 170
            } else if (key === 'IDNo') {
                width = 0
            }

            return {
                title: key === 'Action' ? 'Omit' : key,
                field: key,
                width: width,
                className: className,
                cell: cellComponent,
            };
        });
        return updatedColumns;
    });

    const updateColumns = () => {
        const updatedColumns = columns.map(column => {
            const AccountTypesItemCell = (props: GridCellProps) => {
                return (
                    <ItemCell
                        {...props}
                        dataList={accountTypesList}
                        fieldKey={itemField('Account Type')}
                        fileKeys={keys}
                        columns={columns}
                    />
                )
            };

            let width = 200;
            let className = 'text-left';
            let cellComponent = column.cell;

            if (column.field === itemField('Account Type')) {
                cellComponent = AccountTypesItemCell;
            } else if (column.field === itemField('Account No')) {
                cellComponent = ReadOnlyCell
            } else if (column.field === itemField('Account Name')) {
                cellComponent = ReadOnlyCell
            }

            else if (column.field === 'Error') {
                cellComponent = ImportCheckCell;
                className = 'text-center';
                width = 65;
            } else if (column.field === 'Action') {
                cellComponent = CheckboxCell;
                width = 65;
            } else if (column.field === 'Reason') {
                width = 170;
            } else if (column.field === 'IDNo' || column.field === 'inEdit') {
                width = 0;
            }

            return {
                ...column,
                width: width,
                className: className,
                cell: cellComponent,
                title: column.field === 'Action' ? 'Omit' : column.title,
            };
        });

        setColumns(updatedColumns);
    };

    const handleCompare = async () => {
        updateColumns()
        if (gridItemsState && currentData) {
            // existing accounts
            const existingAccounts = currentData.filter(item =>
                gridItemsState?.some(importedItem => importedItem[itemField('Account No')] === item.AccountNo)
            );
            console.log('existing accounts', existingAccounts)

            // invalid account types
            const noAccountTypes = gridItemsState?.filter(data =>
                !accountTypesList.map((account: any) => account.AcctTypeDesc.toLowerCase()).includes(data[itemField('Account Type')].toLowerCase().trim())
            );
            console.log('no types', noAccountTypes)

            setGridItemsState(prevList => {
                let lastAssignedStartNo = 0;

                return prevList?.map(item => {
                    let newItem = { ...item };

                    if (item[itemField('Account No')] === '') {
                        const matchedAccountType = currentData.find(data => data.AccountType === newItem[itemField('Account Type')]);

                        if (matchedAccountType) {
                            lastAssignedStartNo++;
                            newItem[itemField('Account No')] = (matchedAccountType.StartNo + lastAssignedStartNo - 1).toString();
                        }
                    }

                    newItem = {
                        ...newItem,
                        Error: true,
                        Reason: '',
                    };

                    return newItem;
                });
            });

            if (existingAccounts.length > 0) {
                setGridItemsState(prevList =>
                    prevList?.map(item => {

                        if (currentData.some(account => account.AccountNo === item[itemField('Account No')])) {
                            return {
                                ...item,
                                Error: false,
                                Reason: 'Duplicate Account No',
                                Action: true,
                            };
                        }

                        if (noAccountTypes.some(account => account[itemField('Account Type')].toLowerCase() === item[itemField('Account Type')].toLowerCase())) {
                            return {
                                ...item,
                                Error: false,
                                Reason: 'Invalid Account Type',
                            };
                        }

                        return {
                            ...item,
                            Error: true,
                            Reason: '',
                        };
                    })
                );
            }

            else if (!existingAccounts.length && noAccountTypes) {
                setGridItemsState(prevList =>
                    prevList?.map(item => {

                        if (noAccountTypes.some(account => account[itemField('Account Type')].toLowerCase() === item[itemField('Account Type')].toLowerCase())) {
                            return {
                                ...item,
                                Error: false,
                                Reason: 'Invalid Account Type',
                            };
                        }

                        return item;
                    })
                );
            }

            else if (existingAccounts.length <= 0) {
                setGridItemsState(prevList =>
                    prevList?.map(item => {

                        if (currentData.some(account => account.AccountNo !== item[itemField('Account No')].toString() && item[itemField('Account No')])) {
                            return {
                                ...item,
                                Error: false,
                                Reason: 'Invalid Account No',
                            };
                        }

                        return item;
                    })
                );
            }

            else {
                setGridItemsState(gridItemsState);
                setGridItems(gridItemsState || []);
            }
        }
    };

    useEffect(() => {
        handleCompare();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        console.log(selectedDataItem, selectedRow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setGridItems(gridItemsState || []);
        setInvalidGrid(false);

        if (gridItemsState?.every(item => !item.Error)) {
            setInvalidGrid(true);
        }
    }, [gridItemsState]);

    const itemChange = useCallback(
        (event: GridItemChangeEvent) => {
            console.log('DataGrid: onItemChange', event);
            const dataItem = event.dataItem;

            if (event.field === itemField('Account Type') && accountTypesList) {
                if (accountTypesList.some(account => account.AcctTypeDesc === event.value)) {

                    const newData = gridItemsState?.map((item) => {

                        if (item[itemField('Account No')] === dataItem[itemField('Account No')] && event.field === (itemField('Account Type') as string) && item[itemField('Account No')] !== '') {
                            console.log('acc # change')
                            return {
                                ...item,
                                [event.field]: event.value,
                                Error: true,
                                Reason: '',
                                Action: false
                            };
                        }

                        if (item.IDNo === dataItem.IDNo && item[itemField('Account No')] === '') {
                            console.log('no acc # change')
                            const matchedAccountType = currentData?.find(data => data.AccountType === event.value);
                            if (matchedAccountType && item[itemField('Account No')] === '') {

                                let startNo = matchedAccountType.StartNo;
                                const existingStartNos = gridItemsState?.map(item => item['Account No'].toString());

                                if (existingStartNos && existingStartNos.includes(startNo.toString())) {
                                    while (existingStartNos.includes(startNo.toString())) {
                                        startNo++;
                                    }
                                }

                                item['Account No'] = startNo.toString();

                                if (event.field) {
                                    item[event.field] = event.value;
                                }
                            }

                            return {
                                ...item,
                                Error: true,
                                Reason: '',
                                Action: false
                            };
                        }

                        return item;
                    });

                    setGridItemsState(newData || [])

                    const newDataItem = newData?.find(item => item?.[itemField('Account No')] === dataItem[itemField('Account No')]);
                    if (newDataItem) {
                        setSelectedRow(prevData => ({
                            prev: prevData.current,
                            current: newDataItem,
                        }));
                    }
                }
            }

            else {
                const newData = gridItemsState?.map((item) => {
                    if (item[itemField('Account No')] === dataItem[itemField('Account No')] && event.field) {
                        return {
                            ...item,
                            [event.field]: event.value,
                        };
                    }
                    return item;
                });
                setGridItemsState(newData)
                setGridItems(newData || []);

                const newDataItem = newData?.find(item => item?.[itemField('Account No')] === dataItem[itemField('Account No')]);
                if (newDataItem) {
                    setSelectedRow(prevData => ({
                        prev: prevData.current,
                        current: newDataItem,
                    }));
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gridItems, setGridItems, setSelectedRow, accountTypesList, gridItemsState],
    );

    const enterEdit = (dataItem: GridItemType, field: string | undefined) => {
        console.log('enterEdit -', { dataItem, field });
        setSelectedDataItem(dataItem as AccountImportItemType);
        setSelectedRow(prevData => {
            return {
                prev: prevData.current,
                current: dataItem as AccountImportItemType,
            };
        });

        setTimeout(() => {
            setGridItems(prev =>
                prev.map(item => ({
                    ...item,
                    inEdit: item[itemField('Account No')] === dataItem[itemField('Account No')] ? field : undefined,
                })),
            );
        }, 50);
    };

    const exitEdit = (dataItem: GridItemType) => {
        console.log('exitEdit -', dataItem);
        setSelectedDataItem(dataItem as AccountImportItemType);
        setSelectedRow(prevData => {
            return {
                prev: prevData.current,
                current: dataItem as AccountImportItemType,
            };
        });

        let newData = gridItems.map(item => ({ ...item, inEdit: undefined }));
        setGridItems(newData);
    };

    const handleImport = async () => {
        const validList = gridItemsState?.filter(item => item.Error === true && item.Action === false);

        const itemField = (columnTitle: keyof AccountImportItemType) => {
            const column = columns.find(col => col.title === columnTitle);
            return column?.field;
        };

        const payload = validList?.map(item => {
            const accountNoField = itemField('Account No')?.trim();
            const accountNameField = itemField('Account Name')?.trim();
            const accountTypeField = itemField('Account Type')?.trim();

            const matchedAccount = accountTypesList.find(account => account.AcctTypeDesc.toLowerCase() === item[accountTypeField || ''].toLowerCase());

            if (
                accountNoField !== undefined &&
                accountNameField !== undefined &&
                accountTypeField !== undefined
            ) {
                return {
                    accountNo: item[accountNoField],
                    accountName: item[accountNameField],
                    accountType: accountTypesList.find(account =>
                        account.AcctTypeDesc.toLowerCase() === item[accountTypeField].toLowerCase()
                    )?.AcctTypeDesc,
                    typeGroup: matchedAccount.TypeGroup,
                };
            }
            return item;
        });

        try {
            const response = await accountsService.importAccounts(payload as any[]);
            if (response) {
                handleClose(true);
            }
            console.log(payload);
        } catch (error) {
            console.log(error);
        }
    };

    const onChangeColumns = (event: ComboBoxChangeEvent, key: string) => {
        const newValue = event.value;

        setGridColumnState((prevState: any) => {
            const updatedState = {
                ...prevState,
                [key]: newValue,
            };

            setInvalidGrid(true);

            if (key === 'accountNoColumn') {
                let newAccountNameValue, newAccountTypeValue;
                switch (newValue) {
                    case 'Column 1':
                        newAccountNameValue = 'Column 2';
                        newAccountTypeValue = 'Column 3';
                        break;
                    case 'Column 2':
                        newAccountNameValue = 'Column 3';
                        newAccountTypeValue = 'Column 1';
                        break;
                    case 'Column 3':
                        newAccountNameValue = 'Column 1';
                        newAccountTypeValue = 'Column 2';
                        break;
                    default:
                        newAccountNameValue = prevState.accountNameColumn;
                        newAccountTypeValue = prevState.accountTypeColumn;
                        break;
                }
                updatedState.accountNameColumn = newAccountNameValue;
                updatedState.accountTypeColumn = newAccountTypeValue;
            }

            else if (key === 'accountNameColumn') {
                let newAccountNoValue, newAccountTypeValue;
                switch (newValue) {
                    case 'Column 1':
                        newAccountNoValue = 'Column 2';
                        newAccountTypeValue = 'Column 3';
                        break;
                    case 'Column 2':
                        newAccountNoValue = 'Column 3';
                        newAccountTypeValue = 'Column 1';
                        break;
                    case 'Column 3':
                        newAccountNoValue = 'Column 1';
                        newAccountTypeValue = 'Column 2';
                        break;
                    default:
                        newAccountNoValue = prevState.accountNoColumn;
                        newAccountTypeValue = prevState.accountTypeColumn;
                        break;
                }
                updatedState.accountNoColumn = newAccountNoValue;
                updatedState.accountTypeColumn = newAccountTypeValue;
            }

            else if (key === 'accountTypeColumn') {
                let newAccountNoValue, newAccountNameValue;
                switch (newValue) {
                    case 'Column 1':
                        newAccountNoValue = 'Column 2';
                        newAccountNameValue = 'Column 3';
                        break;
                    case 'Column 2':
                        newAccountNoValue = 'Column 3';
                        newAccountNameValue = 'Column 1';
                        break;
                    case 'Column 3':
                        newAccountNoValue = 'Column 1';
                        newAccountNameValue = 'Column 2';
                        break;
                    default:
                        newAccountNoValue = prevState.accountNoColumn;
                        newAccountNameValue = prevState.accountNameColumn;
                        break;
                }
                updatedState.accountNoColumn = newAccountNoValue;
                updatedState.accountNameColumn = newAccountNameValue;
            }
            return updatedState;
        });
    };

    const handleUpdateHeader = () => {
        const updatedColumns = columns.map((column, index) => {

            // account no
            if (gridColumnState.accountNoColumn === 'Column 1' && index === 0) {
                column.title = 'Account No';
            } else if (gridColumnState.accountNoColumn === 'Column 2' && index === 1) {
                column.title = 'Account No';
            } else if (gridColumnState.accountNoColumn === 'Column 3' && index === 2) {
                column.title = 'Account No';
            }

            // account name
            if (gridColumnState.accountNameColumn === 'Column 1' && index === 0) {
                column.title = 'Account Name';
            } else if (gridColumnState.accountNameColumn === 'Column 2' && index === 1) {
                column.title = 'Account Name';
            } else if (gridColumnState.accountNameColumn === 'Column 3' && index === 2) {
                column.title = 'Account Name';
            }

            // account type
            if (gridColumnState.accountTypeColumn === 'Column 1' && index === 0) {
                column.title = 'Account Type';
            } else if (gridColumnState.accountTypeColumn === 'Column 2' && index === 1) {
                column.title = 'Account Type';
            } else if (gridColumnState.accountTypeColumn === 'Column 3' && index === 2) {
                column.title = 'Account Type';
            }

            return column;
        });
        setColumns(updatedColumns);
        setInvalidGrid(false);
    };

    return (
        <MovableDialog
            onClose={() => handleClose()}
            className="account-dialog"
            title={'Import Accounts'}
            ref={dialogRef}
        >
            <ReactFocusLock>
                <DialogContent>
                    <ImportGridContainer>
                        <ColumnsContainer>

                            <div className='column-combo'>
                                <label>Account No</label>
                                <CustomColComboBox
                                    comboBoxType='ColumnIndex'
                                    dataList={[]}
                                    width={120}
                                    value={gridColumnState.accountNoColumn}
                                    onChange={(e) => onChangeColumns(e, 'accountNoColumn')}
                                />
                            </div>
                            <div className='column-combo'>
                                <label>Account Name</label>
                                <CustomColComboBox
                                    comboBoxType='ColumnIndex'
                                    dataList={[]}
                                    width={120}
                                    value={gridColumnState.accountNameColumn}
                                    onChange={(e) => onChangeColumns(e, 'accountNameColumn')}
                                />
                            </div>

                            <div className='column-combo'>
                                <label>Account Type</label>
                                <CustomColComboBox
                                    comboBoxType='ColumnIndex'
                                    dataList={[]}
                                    width={120}
                                    value={gridColumnState.accountTypeColumn}
                                    onChange={(e) => onChangeColumns(e, 'accountTypeColumn')}
                                />
                            </div>

                            <div>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleUpdateHeader();
                                        handleCompare();
                                    }}
                                    id="update-btn"
                                    title="Update"
                                >
                                    <>
                                        Update
                                    </>
                                </Button>
                            </div>
                        </ColumnsContainer>
                        <div>
                            <EditableGrid
                                scrollable='scrollable'
                                style={{ pointerEvents: 'auto', height: '600px' }}
                                onItemChange={itemChange}
                                enterEdit={enterEdit}
                                exitEdit={exitEdit}
                                undoEdit={() => { }}
                                data={gridItems}
                                tabIndex={-1}
                                ref={gridRef}
                            >
                                {columns.map((column, index) => (
                                    <Column {...column} key={index} />
                                ))}
                            </EditableGrid>
                        </div>
                    </ImportGridContainer>

                    <ButtonWrapper>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                handleImport();
                            }}
                            id="save-btn"
                            title="Save"
                            disabled={invalidGrid}
                        >
                            <>
                                Import
                            </>
                        </Button>
                    </ButtonWrapper>
                </DialogContent>
            </ReactFocusLock>
        </MovableDialog>
    );
};

export default AccountsImportModal;