import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const payCodesAddsService = {
  getPayCodes: async () => {
    const response = await axiosClient().get(`Payroll/PayCodes/GetAdds`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  createPayCode: (payCode: string, payload: any) => {
    return axiosClient().patch(
      'Payroll/PayCodes',
      { ...payload, PayAD: 'A' },
      {
        params: {
          PayCode: payCode,
          IDNo: 0,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  updatePayCode: (IDNo: number, payload: any) => {
    return axiosClient().patch(
      'Payroll/PayCodes',
      { ...payload },
      {
        params: {
          IDNo,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deletePayCode: (IDNo: number) =>
    axiosClient().delete('Payroll/PayCodes', {
      params: {
        IDNo,
      },
      headers: { ...commonHeaders },
    }),
  getSinglePayCode: async (PayCode: string) => {
    const response = await axiosClient().get(`Payroll/PayCodes/GetAdd`, {
      headers: { ...commonHeaders },
      params: {
        PayCode,
      },
    });
    return response.data.data.combos;
  },
};
