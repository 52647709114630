import axios from 'axios';
import { formatClientDateTime } from '../utils';

export const customAxios = () =>
  axios.create({
    baseURL: window.sessionStorage.getItem('apiURL') ?? '',
    headers: {
      Ak: window.sessionStorage.getItem('SECRET_AK'),
      Cid: window.sessionStorage.getItem('SECRET_CID'),
      User: window.sessionStorage.getItem('SECRET_USERNAME') ?? 'Unknown',
      ClientPlatform: 'all',
      ClientTime: formatClientDateTime(new Date()),
    },
  });

export const authenticateUserAPPSHELLTEMP = async ({
  email,
  password,
  abortSignal,
}: {
  email: string;
  password: string;
  abortSignal?: AbortSignal;
}) => {
  const loginAxios = () =>
    axios.create({
      baseURL: process.env.REACT_APP_LOGIN_URI,
    });
  console.log('sig', abortSignal);
  const { data } = await loginAxios().post(
    '/Login/AuthenticateUser',
    {
      email,
      password,
      ePlatformType: 'Web',
      clientPlatform: 'Browser',
    },
    { signal: abortSignal }
  );

  return data;
};

export const authenticateSetup = async (token: string) => {
  const loginAxios = () =>
    axios.create({
      baseURL: process.env.REACT_APP_LOGIN_URI,
    });
  const { data } = await loginAxios().post('/Login/AuthenticateSetup', {
    ak: token,
    ePlatformType: 'Web',
  });

  return data;
};

export const userLogout = async () => {
  const { data } = await customAxios().post(`v3/Setup/AccountingDefaults/UserLogOut`);
  return data;
};
