import React, { FC, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses, menuClasses } from "react-pro-sidebar";
import cx from "classnames";

import { default as ProBuiltLogo } from '../../../assets/images/New_ProbuiltLogo/Logo-11.png'
import { default as ProBuiltText } from '../../../assets/images/New_ProbuiltLogo/ProBuiltERP150.svg'

import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalculateIcon from '@mui/icons-material/Calculate';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// import DevicesIcon from '@mui/icons-material/Devices';


interface NewSideBarProps {
  currentForm: string;
  handleOpenForm: (formType: string) => void;
  handleCloseForm: () => void;
};

const NewSideBar: FC<NewSideBarProps> = ({
  currentForm,
  handleOpenForm,
  handleCloseForm,
}) => {
  const [popoutTopOffset, setPopoutTopOffset] = useState<number>(0);
  // eslint-disable-next-line
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState<boolean>(false);

  const showActiveBar = (formName: string) => {
    return currentForm === formName
      ? (<div style={{ width: '5px', height: '24px', backgroundColor: '#528FCB' }} />)
      : (<div style={{ width: '5px', height: '30px' }} />)
  };

  const handleShowForm = (formName: string) => {
    if (currentForm === formName) {
      handleCloseForm();
    } else {
      handleOpenForm(formName);
    }
  };

  return (
    <div className="new-sidebar-container">
      <div className="new-sidebar-div">
        <Sidebar
          className="sidebar"
          collapsed={isSideBarCollapsed}
          backgroundColor="#FFFFFF"
          width="280px"
          transitionDuration={300}
          collapsedWidth="55px"
          rootStyles={isSideBarCollapsed
            ? {
              [`.${sidebarClasses.container} .${menuClasses.subMenuContent}:not(.${menuClasses.subMenuContent} .${menuClasses.subMenuContent})`]
                : {
                transform: `translate(${60}px, ${isSideBarCollapsed
                  ? popoutTopOffset
                  : 0}px) !important`,
              }
            }
            :
            undefined
          }
        >
          <Menu
            renderExpandIcon={({ open, level }) => <span>{level === 0 ? "" : open ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowLeftIcon fontSize="small" />} </span>}
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                return {
                  color: disabled ? "#8E8E8E" : "#90A2B5",
                  backgroundColor: active
                    ? "#FFFFFF"
                    : "#FFFFFF",
                  // width: collapsed ? "400px" : "200px",
                  paddingRight: level === 0 ? "10px" : "20px",
                };
              },
              label: ({ level, disabled }) => {
                return {
                  color: disabled ? "#8E8E8E" : level === 0 ? "#ededed" : "#212121",
                }
              },
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={cx("new-sidebar", /* !isSideBarCollapsed? 'w-[280px] ' : 'w-[55px] ' */)}>
                <div className="sidebar-logo-container"
                // onClick={() => setIsSideBarCollapsed(!isSideBarCollapsed)}
                >
                  <div className="header-logo">
                    <img
                      src={ProBuiltLogo}
                      style={{ height: '25px', width: '25px' }}
                      alt="ProBuilt Logo"
                    />
                    <img
                      src={ProBuiltText}
                      style={{ width: '130px' }}
                      alt="ProBuilt Logo-Text"
                    />
                  </div>
                </div>
                <div className="sidebar-logo-divider" />
              </div>
            
              <SubMenu
                onClick={(e) => setPopoutTopOffset(e.currentTarget.getBoundingClientRect().top)}
                defaultOpen label="COMPANY SETUP" icon={<HouseRoundedIcon fontSize="small" style={{ color: '#528FCB' }} />}>
                <MenuItem onClick={() => handleShowForm("company")} prefix={showActiveBar("company")}>Company Profile</MenuItem>
                <MenuItem onClick={() => handleShowForm("locations")} prefix={showActiveBar("locations")}>Locations & Offices</MenuItem>
                <MenuItem onClick={() => handleShowForm("security")} prefix={showActiveBar("security")}>User Permissions</MenuItem>
              </SubMenu>

              {/* <SubMenu
                onClick={(e) => setPopoutTopOffset(e.currentTarget.getBoundingClientRect().top)}
                defaultOpen label="OPERATIONS SETUP" icon={<DevicesIcon fontSize="small" style={{ color: '#528FCB' }} />}>
                <MenuItem onClick={() => handleShowForm("projectTypes")} prefix={showActiveBar("projectTypes")}>Project Types</MenuItem>
                <MenuItem onClick={() => handleShowForm("projectEventCodes")} prefix={showActiveBar("projectEventCodes")}>Project Event Codes</MenuItem>
                <MenuItem onClick={() => handleShowForm("projectStatusCodes")} prefix={showActiveBar("projectStatusCodes")}>Project Status Codes</MenuItem>
              </SubMenu> */}

              <SubMenu
                onClick={(e) => setPopoutTopOffset(e.currentTarget.getBoundingClientRect().top)}
                defaultOpen label="ACCOUNTING SETUP" icon={<CalculateIcon fontSize="small" style={{ color: '#528FCB' }} />}>
                <MenuItem onClick={() => handleShowForm("accountTypes")} prefix={showActiveBar("accountTypes")}>Account Types</MenuItem>
                <MenuItem onClick={() => handleShowForm("chartOfAccounts")} prefix={showActiveBar("chartOfAccounts")}>Chart of Accounts</MenuItem>
                <MenuItem onClick={() => handleShowForm("paymentTermsCodes")} prefix={showActiveBar("paymentTermsCodes")}>Payment Terms</MenuItem>
                <MenuItem onClick={() => handleShowForm("acctDefaults")} prefix={showActiveBar("acctDefaults")}>Default Settings</MenuItem>
                <MenuItem onClick={() => handleShowForm("personnelCodes")} prefix={showActiveBar("personnelCodes")}>Personnel Codes</MenuItem>
                <MenuItem onClick={() => handleShowForm("payrollSetup")} prefix={showActiveBar("payrollSetup")}>Payroll Codes</MenuItem>
                <MenuItem onClick={() => handleShowForm("salesTaxes")} prefix={showActiveBar("salesTaxes")}>Sales Taxes</MenuItem>
              </SubMenu>

              <SubMenu
                onClick={(e) => setPopoutTopOffset(e.currentTarget.getBoundingClientRect().top)}
                defaultOpen label="SALES SETUP" icon={<TrendingUpIcon fontSize="small" style={{ color: '#528FCB' }} />}>
                <MenuItem onClick={() => handleShowForm("productsAndServices")} prefix={showActiveBar("productsAndServices")}>Products & Services</MenuItem>
                <MenuItem onClick={() => handleShowForm("leads")} prefix={showActiveBar("leads")}>Lead Qualifying</MenuItem>
                <MenuItem onClick={() => handleShowForm("statusCodes")} prefix={showActiveBar("statusCodes")}>Status Codes</MenuItem>
                <MenuItem onClick={() => handleShowForm("eventCodes")} prefix={showActiveBar("eventCodes")}>Event Codes</MenuItem>
              </SubMenu>

              <SubMenu
                onClick={(e) => setPopoutTopOffset(e.currentTarget.getBoundingClientRect().top)}
                defaultOpen label="CUSTOMIZATIONS" icon={<AutoFixHighIcon fontSize="small" style={{ color: '#528FCB' }} />}>
                <MenuItem onClick={() => handleShowForm("customers")} prefix={showActiveBar("customers")}>Customers</MenuItem>
                <MenuItem onClick={() => handleShowForm("vendors")} prefix={showActiveBar("vendors")}>Vendors</MenuItem>
                <MenuItem onClick={() => handleShowForm("templates")} prefix={showActiveBar("templates")}>Templates</MenuItem>
              </SubMenu>

              <SubMenu
                onClick={(e) => setPopoutTopOffset(e.currentTarget.getBoundingClientRect().top)}
                defaultOpen label="CHECK COMPONENTS" icon={<HouseRoundedIcon fontSize="small" style={{ color: '#528FCB' }} />}>
                <MenuItem onClick={() => handleShowForm("companycomp")} prefix={showActiveBar("companycomp")}>Company Onboard</MenuItem>
              </SubMenu>

            </div>
          </Menu>
        </Sidebar>
      </div>
    </div>
    // <></>
  );
};

export default NewSideBar;