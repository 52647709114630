import type { GridColumnProps } from '@progress/kendo-react-grid';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'PayGroupCode',
    width: 100,
    className: 'text-left',
  },
  {
    title: 'Description',
    field: 'PayGroupDesc',
    className: 'text-left',
    width: 543,
  },
  {
    title: 'Interval',
    field: 'PayFrequency',
    className: 'text-left',
  }
];