import React, { FC, Fragment, useContext } from 'react';
import { useQueries } from '@tanstack/react-query';
import ChartOfAccounts from '../pages/ChartOfAccounts';
import PayrollSetup from '../pages/PayrollSetup';
import AcctDefaults from '../pages/AcctDefaults';
import CompanySetup from '../pages/CompanySetup';
import Locations from '../pages/Locations';
import PaymentTerms from '../pages/PaymentTerms';
import UserPerm from '../pages/UserPerm';
import PersonnelCodes from '../pages/PersonnelCodes';
import Customers from '../pages/Customers';
import Vendors from '../pages/Vendors';
import Leads from '../pages/Leads';
import ProductsAndServices from '../pages/ProductsAndServices';
import SalesStatusCodes from '../pages/SalesStatusCodes/';
import SalesEventCodes from '../pages/SalesEventCodes/';
import CompanyOnboard from '../pages/CompanyOnboard';
import SalesTaxes from '../pages/SalesTaxes';
import ProjectTypes from '../pages/ProjectTypes';
import ProjectEventCodes from '../pages/ProjectEventCodes';
import ProjectStatusCodes from '../pages/ProjectStatusCodes';
import Templates from '../pages/Templates';
import AccountTypes from '../pages/AccountTypes';

import { accountingDefaultsService } from '../../services/setup/accountingDefaultsService';
import { IPagesRendererProps } from './PagesRenderer.types';
import { companiesService } from '../../services/setup/companiesService';
import { paymentTermsService } from '../../services/setup/paymentTermsService';
import { locationsService } from '../../services/setup/locationsService';
import { FormContext } from '../MainComponent';
import { sideBarItems } from '../layout/Sidebar/model';

import { ChartOfAccountsProvider } from '../pages/ChartOfAccounts/ChartOfAccountsContext';
import { CustomersProvider } from '../pages/Customers/CustomerContext';
import { VendorsProvider } from '../pages/Vendors/VendorsContext';

const PagesRenderer: FC<IPagesRendererProps> = ({
  handleCloseForm,
  states,
}) => {
  const { currentForm, setCurrentForm } = useContext(FormContext);

  const pages = sideBarItems
    .map((menuItem) => menuItem.subMenuItems.map((subitem) => subitem))
    .flat();

  const pageValues = sideBarItems
    .map((mainItem) => mainItem.subMenuItems.map((item) => item.value))
    .flat();

  const handleNextPage = () => {
    let nextIndex = pageValues.indexOf(currentForm) + 1;

    // find disabled sub menu
    while (
      nextIndex < pageValues.length &&
      pages[nextIndex].disabled === true
    ) {
      nextIndex++;
    }

    //  go back to the beginning
    if (nextIndex === pageValues.length) {
      nextIndex = 0;
      while (
        nextIndex < pageValues.length &&
        pages[nextIndex].disabled === true
      ) {
        nextIndex++;
      }
    }
    setCurrentForm(pageValues.at(nextIndex) ?? '');
  };

  const handlePrevPage = () => {
    let previousIndex = pageValues.indexOf(currentForm) - 1;

    while (previousIndex >= 0 && pages[previousIndex].disabled === true) {
      previousIndex--;
    }

    setCurrentForm(pageValues.at(previousIndex) ?? '');
  };

  const arrayOfRequests: { serviceFunc: any; key: string }[] = [];
  switch (currentForm) {
    case 'acctDefaults':
      arrayOfRequests.push({
        serviceFunc: accountingDefaultsService.getDefaultsWithCombosData,
        key: `get_acct_defaults`,
      });
      break;
    case 'company':
      arrayOfRequests.push({
        serviceFunc: companiesService.getCompanyById,
        key: `get_company_setup`,
      });
      break;
    case 'paymentTermsCodes':
      arrayOfRequests.push({
        serviceFunc: paymentTermsService.getPaymentTerms,
        key: `get_payment_terms`,
      });
      break;
    case 'locations':
      arrayOfRequests.push({
        serviceFunc: locationsService.getLocations,
        key: `get_locations`,
      });
      break;
    default:
      break;
  }

  const resultData = useQueries({
    queries: arrayOfRequests.map((req) => {
      return {
        queryKey: [req.key],
        queryFn: async () => {
          const { data } = await req.serviceFunc();
          return data;
        },
      };
    }),
  });

  const editDialog = () => {
    switch (currentForm) {
      // company setup
      case 'company':
        return resultData[0].status === 'success' ? (
          <CompanySetup
            title="Company Profile"
            currentForm={currentForm}
            companyInfo={resultData[0].data?.record[0]}
            fieldProperties={resultData[0].data?.fieldProperties}
            onClose={handleCloseForm}
            states={states}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        ) : null;
      case 'locations':
        return resultData[0].status === 'success' ? (
          <Locations
            title="Locations & Offices"
            onClose={handleCloseForm}
            resultData={resultData}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        ) : null;
      case 'security':
        return (
          <UserPerm
            title="User Permissions"
            onClose={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      // accounting setup
      case 'accountTypes':
        return (
          <AccountTypes
            title="Account Types"
            onClose={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      case 'chartOfAccounts':
        return (
          <ChartOfAccountsProvider>
            <ChartOfAccounts
              onNextPage={handleNextPage}
              onPreviousPage={handlePrevPage}
              title="Chart of Accounts"
              handleCloseForm={handleCloseForm}
            />
          </ChartOfAccountsProvider>
        );
      case 'paymentTermsCodes':
        return resultData[0].status === 'success' ? (
          <PaymentTerms
            title="Payment Terms"
            onClose={handleCloseForm}
            resultData={resultData}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        ) : null;
      case 'acctDefaults':
        return resultData[0].status === 'success' ? (
          <AcctDefaults
            title="Default Settings"
            currentForm={currentForm}
            onClose={handleCloseForm}
            acctDefaults={resultData[0]?.data?.data[0]}
            combosData={resultData[0]?.data?.combosData}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        ) : null;
      case 'personnelCodes':
        return (
          <PersonnelCodes
            handleCloseForm={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      case 'payrollSetup':
        return (
          <PayrollSetup
            handleCloseForm={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      case 'salesTaxes':
        return (
          <SalesTaxes
            title="Sales Taxes"
            handleCloseForm={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      // sales setup
      case 'productsAndServices':
        return (
          <ProductsAndServices
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
            title="Products & Services"
            onClose={handleCloseForm}
            resultData={resultData}
          />
        );
      case 'leads':
        return (
          <Leads
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
            title="Custom Fields - Leads"
            onClose={handleCloseForm}
            resultData={resultData}
          />
        );
      case 'statusCodes':
        return (
          <SalesStatusCodes
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
            title="Sales Status Codes"
            onClose={handleCloseForm}
            resultData={resultData}
          />
        );
      case 'eventCodes':
        return (
          <SalesEventCodes
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
            title="Sales Event Codes"
            onClose={handleCloseForm}
            resultData={resultData}
          />
        );
      // customizations
      case 'customers':
        return (
          <CustomersProvider>
            <Customers
              onNextPage={handleNextPage}
              onPreviousPage={handlePrevPage}
              title="Custom Fields - Customers"
              onClose={handleCloseForm}
              resultData={resultData}
            />
          </CustomersProvider>
        );
      case 'vendors':
        return (
          <VendorsProvider>
            <Vendors
              onNextPage={handleNextPage}
              onPreviousPage={handlePrevPage}
              title="Custom Fields - Vendors"
              onClose={handleCloseForm}
              resultData={resultData}
            />
          </VendorsProvider>
        );
      case 'templates':
        return (
          <Templates
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
            title="Templates"
            onClose={handleCloseForm}
            resultData={resultData}
          />
        );
      // project manaagement
      case 'projectTypes':
        return (
          <ProjectTypes
            title="Project Types"
            handleCloseForm={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      case 'projectEventCodes':
        return (
          <ProjectEventCodes
            title="Project Event Codes"
            handleCloseForm={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      case 'projectStatusCodes':
        return (
          <ProjectStatusCodes
            title="Project Status Codes"
            handleCloseForm={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
      // check components
      case 'companycomp':
        return (
          <CompanyOnboard
            title="Company Onboard"
            onClose={handleCloseForm}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
          />
        );
    }
  };

  return <Fragment>{editDialog()}</Fragment>;
};

export default PagesRenderer;
