import PersonAddIcon from "@mui/icons-material/PersonAdd";
type HeaderButtonProps = {
  onClick: () => void;
}

const HeaderButton = ({ onClick }: HeaderButtonProps) => {
  console.log('Header Button Rendering')
  const handleOnClick = (e: any) => {
    e.preventDefault();
    onClick();
  }
  return (
    <>
      <div className="flex flex-row items-center gap-[12px]" onClick={(e) => handleOnClick(e)}>
        <PersonAddIcon
          fontSize="small"
          className="text-blue-301"
        />
        <span className="text-[13px]">Add Company</span>
      </div>
    </>
  );
}

export default HeaderButton;