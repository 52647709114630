import styled from 'styled-components';

export const DialogContent = styled.form`
  .form-fields {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 12px 20px 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-top: 2px solid #d6d6d6;
  justify-content: center;
  gap: 8px;
`;
