import type { GridColumnProps } from '@progress/kendo-react-grid';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'PayCode',
    width: 100,
    className: 'text-left',
    sortable: true,
  },
  {
    title: 'Description',
    field: 'PayDesc',
    width: 200,
    className: 'text-left',
    sortable: true,
  },
  {
    title: 'Month',
    field: 'Month',
    width: 114,
    className: 'text-center',
    sortable: true,
  },
  {
    title: 'Date of Month',
    field: 'DateOfMonth',
    width: 125,
    className: 'text-center',
    sortable: true,
  },
  {
    title: 'Week of Month',
    field: 'WeekOfMonth',
    width: 125,
    className: 'text-center',
    sortable: true,
  },
  {
    title: 'Day of Week',
    field: 'DayOfWeek',
    // width: 125,
    className: 'text-center',
    sortable: true,
  },
];
