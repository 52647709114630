import React, { useEffect, useRef, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { InputChangeEvent } from '@progress/kendo-react-inputs';

import { IProjectEventCodesDialogProps } from '../ProjectEventCodes.types';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../hooks';
import { handleKeyDown, validateProjectEventCodesForm } from '../../../../utils';
import { Button, InputWrapper } from '../../../common';

import {
  DialogContent,
  StyledTitle,
  ButtonWrapper,
} from './ProjectEventCodesDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../common/MovableDialog';
import { projectEventCodesService } from '../../../../services/operations/projectService';

const ProjectEventCodesDialog: React.FC<IProjectEventCodesDialogProps> = ({
  isEdit,
  data,
  handleClose,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    IDNo: isEdit && data ? data.IDNo : 0,
    EventCode: isEdit && data ? data.EventCode : '',
    EventDesc: isEdit && data ? data.EventDesc : '',
  });
  const [fieldProperties, setFieldProperties] = useState<any[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput('project-code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  const fetchProject = async () => {
    const response = await projectEventCodesService.getEventCode(formState.IDNo);
    console.log(response.fieldProperties);
    setFieldProperties(response.fieldProperties);
  };

  useEffect(() => {
    fetchProject();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsValid(validateProjectEventCodesForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo || formState.IDNo === 0) {
        setIsSaving(true);

        const saved = await projectEventCodesService.CreateUpdateEventCode(
          isEdit ? formState.IDNo : 0,
          {
            EventCode: formState.EventCode,
            EventDesc: formState.EventDesc,
          }
        );

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.IDNo) {
        setIsDeleting(true);
        const status = await projectEventCodesService.deleteEventCode(formState.IDNo);

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (event: InputChangeEvent, key: string) => {
    setIsChanged(true);

    if (key === 'EventCode') {
      const formmattedValue = event.value.toUpperCase();
      setFormState((prevState) => ({
        ...prevState,
        [key]: formmattedValue.replace(/[^a-zA-Z0-9]/g, ''),
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>{isEdit ? 'Edit' : 'Add'} Project Event Code</span>
        {isEdit && (
          <button
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
          ></button>
        )}
      </StyledTitle>
    );
  };

  return (
    <MovableDialog
      onClose={() => handleClose()}
      className="project-types-dialog"
      title={<CustomWindowTitle />}
      ref={dialogRef}
    >
      <ReactFocusLock>
        <DialogContent>
          <div
            className="form-fields"
            onKeyDown={(e) => handleKeyDown(e, formRef)}
            ref={formRef}
          >
            <InputWrapper
              name="EventCode"
              label="Code"
              id="project-code"
              onChange={(e) => handleOnChange(e, 'EventCode')}
              value={formState.EventCode}
              maxLength={6} // call from api
              autoComplete="off"
              width="100px"
              isDisabled={data.EventCode}
            />
            <InputWrapper
              name="EventDesc"
              label="Description"
              onChange={(e) => handleOnChange(e, 'EventDesc')}
              value={formState.EventDesc}
              maxLength={
                fieldProperties.find(
                  (field: any) => field.columnName === 'EventDesc'
                )?.maxLength
              }
              autoComplete="off"
              width="220px"
            />
          </div>
          <ButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>

            <Button
              onClick={() => handleClose()}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </ButtonWrapper>
        </DialogContent>
      </ReactFocusLock>

      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MovableDialog>
  );
};

export default ProjectEventCodesDialog;
