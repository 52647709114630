import ReactFocusLock from 'react-focus-lock';
import MovableDialog from '../../../common/MovableDialog';
import { Button, CustomColComboBox, InputWrapper } from '../../../common';
import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  ComboBoxChangeEvent,
  MultiColumnComboBoxHandle,
} from '@progress/kendo-react-dropdowns';
import {
  InputChangeEvent,
  InputHandle,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import { useGetStates } from '../../../../services/hooks';
import { IState } from '../../../../data/types';
import { customAxios } from '../../../../services/loginService';

type AddCompanyDialogProps = {
  onClose: () => void;
  entityData: any;
  taxable?: boolean;
};

type AccountType = {
  AccountNo: string;
  AccountName: string;
  AccountType: string;
  DisabledYN: boolean;
  TypeGroup: string;
};

type FormState = {
  CompanyName: string;
  Address1: string;
  Address2: string;
  City: string;
  State: IState;
  Zip: string;
  PaymentAccount: AccountType;
  ChargeAccount: AccountType;
};
type PayloadType = Record<
  string,
  string | number | boolean | ArrayBuffer | Date | null | undefined
>;

const AddCompanyDialog = forwardRef(
  ({ onClose, entityData, taxable }: AddCompanyDialogProps, ref) => {
    const { data: states } = useGetStates() as {
      data: Array<IState>;
    };
    const vendorExpenseAccounts = entityData.combos.VendorExpenseAccounts;
    const bankCCAccounts = entityData.combos.BankCCAccounts;
    const [formState, setFormState] = useState<FormState>({
      CompanyName: '',
      Address1: '',
      Address2: '',
      City: '',
      State: states[0],
      Zip: '',
      PaymentAccount: bankCCAccounts[0],
      ChargeAccount: vendorExpenseAccounts[0],
    });
    const [isValid, setIsValid] = useState<boolean>(false);
    const companyRef = useRef<InputHandle>(null);
    const stateRef = useRef<MultiColumnComboBoxHandle>(null);

    const handleOnChange = (
      event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
      key: string
    ) => {
      if(key === 'CompanyName'){
        setIsValid(event.value !== '');
      }
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    };

    const handleSave = async () => {
      const patchEntity = async (idNo: number, payload: PayloadType) => {
        const { data } = await customAxios().patch(
          `/v3/Entity?IDNo=${idNo}`,
          payload
        );
        return data;
      };
      const payload = taxable ? {
        BusinessYN: true,
        DefExpAcct: formState.ChargeAccount.AccountNo,
        DefPayAcct: formState.PaymentAccount.AccountNo,
        EntityName: formState.CompanyName,
        EntityType: 'V',
        State: states[0].State,
        Zip: formState.Zip,
        City: formState.City,
        Address1: formState.Address1,
        Address2: formState.Address2,
        TaxEntityYN: 1,
      } 
      :
      {
        BusinessYN: true,
        DefExpAcct: formState.ChargeAccount.AccountNo,
        DefPayAcct: formState.PaymentAccount.AccountNo,
        EntityName: formState.CompanyName,
        EntityType: 'V',
        State: states[0].State,
        Zip: formState.Zip,
        City: formState.City,
        Address1: formState.Address1,
        Address2: formState.Address2,
        TaxEntityYN: 0 
      }
      await patchEntity(0, payload);
    };

    useEffect(() => {
      companyRef.current?.focus();
    }, []);

    return (
      <MovableDialog onClose={onClose} title={'Add Company'}>
        <ReactFocusLock>
          <div className="pt-[20px] pl-[25px] pr-[25px] pb-[25px]">
            <div className="flex flex-row gap-[15px]">
              <div className="flex flex-col">
                <InputWrapper
                  ref={companyRef}
                  label="Company Name"
                  width="350px"
                  name="CompanyName"
                  value={formState.CompanyName}
                  onChange={handleOnChange}
                />
                <InputWrapper
                  label="Address 1"
                  width="350px"
                  name="Address1"
                  value={formState.Address1}
                  onChange={handleOnChange}
                />
                <InputWrapper
                  label="Address 2"
                  width="350px"
                  name="Address2"
                  value={formState.Address2}
                  onChange={handleOnChange}
                />
                <div className="flex flex-row gap-[8px] w-[350px]">
                  <InputWrapper
                    label="City"
                    width="50%"
                    name="City"
                    value={formState.City}
                    onChange={handleOnChange}
                  />
                  <CustomColComboBox
                    ref={stateRef}
                    label="State"
                    width={(350 - 16) * 0.2}
                    name="State"
                    value={formState.State}
                    onChange={(e) => handleOnChange(e, 'State')}
                    comboBoxType="StatesUS"
                    dataList={states}
                  />
                  <InputWrapper
                    label="Zip"
                    width="30%"
                    name="Zip"
                    value={formState.Zip}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <CustomColComboBox
                  comboBoxType="BankCCAccounts"
                  width={140}
                  label="Payment Account"
                  textField="AccountNo"
                  onChange={(e) => handleOnChange(e, 'PaymentAccount')}
                  dataList={bankCCAccounts}
                  defaultValue={bankCCAccounts[0]}
                />
                <CustomColComboBox
                  comboBoxType="VendorExpenseAccounts"
                  label="Charge Account"
                  width={140}
                  name="ChargeAccount"
                  value={formState.ChargeAccount}
                  onChange={(e) => handleOnChange(e, 'ChargeAccount')}
                  dataList={vendorExpenseAccounts}
                  defaultValue={vendorExpenseAccounts[0]}
                />
              </div>
            </div>
            <div className="pt-[25px] pb-[15px]">
              <div style={{ width: '100%', outline: '1px solid #587699' }} />
            </div>
            <div className="flex flex-row gap-[8px] justify-end">
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  await handleSave();
                  onClose();
                }}
                id="save-btn"
                title="Save"
                onKeyDown={async (event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    await handleSave();
                    onClose();
                  }
                }}
                disabled={!isValid}
              >
                <u>S</u>ave
              </Button>
              <Button
                onClick={onClose}
                type="button"
                id="cancel-btn"
                title="Cancel"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    onClose();
                  }
                }}
              >
                <u>C</u>ancel
              </Button>
            </div>
          </div>
        </ReactFocusLock>
      </MovableDialog>
    );
  }
);
export default AddCompanyDialog;
