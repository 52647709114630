import qs from 'qs';
import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const schedulesService = {
  getList: async () => {
    const response = await axiosClient().get(`Setup/Schedules/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  getCombos: async () => {
    const response = await axiosClient().get(`Setup/Schedules?SchedCode=0`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.combos;
  },
  getCode: async (code?: string) => {
    const response = await axiosClient().get(`Setup/Schedules?SchedCode=${code ? code : '0'}`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.record[0];
    // return response.data.data.record[0];
  },
  updateSchedule: async (payload: any, SchedCode: string) => {
    const response = await axiosClient().patch(
      'Setup/Schedules',
      { ...payload },
      { params: { SchedCode }, headers: { ...commonHeaders } }
    );
    return response.data.status;
  },
  createSchedule: async (payload: any, SchedCode: string) => {
    const response = await axiosClient().post(
      'Setup/Schedules',
      { ...payload },
      { params: { SchedCode }, headers: { ...commonHeaders } }
    );
    return response.data.status;
  },
  deleteSchedule: async (SchedCode: string) => {
    const response = await axiosClient().delete('Setup/Schedules', {
      params: { SchedCode },
      headers: { ...commonHeaders },
    });
    return response.data.status;
  },
};
