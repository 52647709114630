import styled from 'styled-components';

export const Form = styled.form`
  border: 1px solid #d6d6d6;
  height: 100%;
  width: 860px !important;
  padding: 0px !important;

  .form-section-header-container {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(209 213 219 / 1);
    /* border-color:; */
    background-color: rgb(250 250 250 / 1);
    padding-left: 1.25rem;
    padding-right: 0.375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    // .form-title {
    //   font-weight: 600;
    // }

    .form-header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .listing-action-button {
        background-color: transparent;
        border: 1px solid rgb(209, 213, 219);
        cursor: pointer;
        padding: 4px;
        display: flex;
        height: 30px;
        width: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        color: rgb(51 51 51 / 1);

        &:hover {
          border-color: rgb(44 80 152 / 1);
          color: rgb(44 80 152 / 1);
        }
      }
    }
  }

  .form-content {
    height: calc(100% - 155px);
    max-height: calc(100vh - 177px);
    overflow-y: hidden;
  }

  .form-footer {
    border: 1px solid #c3c3c3;
    border-bottom: 2px solid #2c5098;
    border-top: 0px;

    width: 800px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.16);
  }

  .contacts-wrapper {
    .contacts-label {
      font-size: 16px;
      font-weight: 400;
      color: #2c5098;
    }
  }

`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin: 10px 10px 10px 0px;
  align-items: center;
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  display: block;
  position: relative;
  width: calc(100% - 20px);
  padding: 10px;
`;

export const FormFieldsWrapper = styled.div<{ isActive?: boolean }>`
  border-top: ${(props) => (props.isActive ? '2px solid #2c5098' : '0px')};
  border-bottom: ${(props) => (props.isActive ? '1px solid #c3c3c3' : '0px')};
  border-inline: ${(props) => (props.isActive ? '1px solid #c3c3c3' : '0px')};

  opacity: ${(props) => (props.isActive ? '1' : '0')};
  width: ${(props) => (props.isActive ? '800px' : '0px')};
  position: relative;
  box-sizing: border-box;
  height: ${(props) => (props.isActive ? 'calc(100vh - 325px)' : '0px')};
  max-height: ${(props) => (props.isActive ? 'calc(100vh - 325px)' : '0px')};
  overflow: hidden;

  * {
    ${(props) =>
      !props.isActive &&
      `
    height: 0px !important;
    width: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    opacity: 0 !important;
    border: 0px !important;`}
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 32px;
  outline: none;
  border: 0px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;

  background: ${(props) => (props.isActive ? '#5A7699' : '#f1f1f3')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
`;
