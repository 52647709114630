import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';
import { useDisableEscape, useKeyPress } from '../../../../hooks';

import {
  Button,
  InputWrapper,
  CustomColComboBox,
} from '../../../common';

import {
  ILocationCombosData,
  ILocationDialogProps,
} from './LocationDialog.types';
import { ILocation } from '../../../../data/types';

import { useDisableRightClick, useHighlightInput } from '../../../../hooks';
import {
  formatLocationData,
  generateLocationData,
  handleKeyDown,
  validateLocationForm,
} from '../../../../utils';

import { locationsService } from '../../../../services/setup/locationsService';

import { FieldsWrapper } from '../../CompanySetup/CompanySetup.styled';
import {
  AddressFieldWrapper,
  AddressWrapper,
  ButtonWrapper,
  DialogContent,
  LeftLabelInputWrapper,
  LeftLabelInputWrapper2,
  LocationSection,
  LocationSectionContent,
  LocationSectionsContainer,
  LocationSectionHeader,
} from './LocationDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../common/MovableDialog';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

const LocationsDialog: React.FC<ILocationDialogProps> = ({
  isEdit,
  id,
  handleClose,
  handleSubmitLocation,
  handleDeleteLocation,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [combosData, setCombosData] = useState<ILocationCombosData | null>(
    null
  );
  const [formState, setFormState] = useState<ILocation>({
    LocCode: '',
    DisabledYN: false,
    DisabledUser: '',
    DisabledDT: new Date(),
    LocDescription: '',
    LocAddress1: '',
    LocAddress2: '',
    LocCity: '',
    LocSt: '',
    LocZip: '',
    LocOpsDesc1: '',
    // LocOpsDesc2: '',
    LocAdmDesc1: '',
    // LocAdmDesc2: '',
    LocOpsPhone1: '',
    // LocOpsPhone2: '',
    LocAdmPhone1: '',
    // LocAdmPhone2: '',
    LocOpsEmail1: '',
    // LocOpsEmail2: '',
    LocAdmEmail1: '',
    // LocAdmEmail2: '',
    LocDefARAcct: '',
    LocDefARWOAcct: '',
    LocDefARDiscAcct: '',
    LocDefAPAcct: '',
    LocDefAPWOAcct: '',
    LocDefAPDiscAcct: '',
    LocDefBankAcct: '',
    LocDefCreditCardAcct: '',
    LocDefUndepFundsAcct: '',
    LocDefUnappliedCreditAcct: '',
  });

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput(isEdit ? 'description' : 'code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  async function fetchData() {
    try {
      let locationInfo: any = await locationsService.getLocationById(id || '0');

      const generatedData = generateLocationData(
        locationInfo.record[0],
        locationInfo.combosData
      );

      setCombosData(locationInfo.combosData);

      const {
        LocSt: { state },
        ...restOfData
      } = generatedData;

      const filteredData = {
        ...restOfData,
        LocSt: state,
      };

      setFormState(filteredData);
    } catch (e) {
      console.log('error', e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsValid(validateLocationForm(formState));
  }, [formState]);

  const handleOnChange = (
    event: InputChangeEvent | NumericTextBoxChangeEvent | ComboBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);

    if (key === 'LocSt') {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value.state,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: event.value,
      }));
    }
  };

  const toggleDialog = (state?: boolean) => {
    if (state && handleDeleteLocation) {
      handleDeleteLocation({
        id,
      });
    }
    setVisible(!visible);
  };

  useKeyPress(
    () => {
      if (isValid && isChanged) {
        const formattedData = formatLocationData(formState);
        handleSubmitLocation({
          isNew: id ? false : true,
          data: formattedData,
        });
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  return (
    <MovableDialog
      title="Setup Operating Location/Office"
      onClose={() => handleClose()}
      className="locations-dialog"
      ref={dialogRef}
    >
      <ReactFocusLock>
        <DialogContent>
          <LocationSectionsContainer
            ref={formRef}
            onKeyDown={(e) => handleKeyDown(e, formRef)}
          >
            <LocationSection>
              <LocationSectionHeader>
                <p>Location Details</p>
              </LocationSectionHeader>
              <LocationSectionContent padding="10px 40px 20px 40px">
                <div>
                  <AddressWrapper>
                    <div style={{ display: 'flex', gap: 8 }}>
                      <InputWrapper
                        name="LocCode"
                        id="code"
                        label="Location Code"
                        value={formState.LocCode || ''}
                        onChange={handleOnChange}
                        width="50%"
                        isDisabled={isEdit}
                      />
                      <InputWrapper
                        name="LocDescription"
                        id="description"
                        label="Description"
                        value={formState.LocDescription || ''}
                        onChange={handleOnChange}
                        width="50%"
                      />
                    </div>
                    <InputWrapper
                      name="LocAddress1"
                      label="Address 1"
                      value={formState.LocAddress1 || ''}
                      onChange={handleOnChange}
                    />
                    <InputWrapper
                      name="LocAddress2"
                      label="Address 2"
                      value={formState.LocAddress2 || ''}
                      onChange={handleOnChange}
                    />
                    <FieldsWrapper>
                      <AddressFieldWrapper width="60%">
                        <InputWrapper
                          name="LocCity"
                          label="City"
                          value={formState.LocCity || ''}
                          onChange={handleOnChange}
                        />
                      </AddressFieldWrapper>
                      <AddressFieldWrapper width="20%" className='locations-form'>
                        <CustomColComboBox
                          name="LocSt"
                          label="State"
                          dataList={combosData?.StatesUS}
                          textField="state"
                          onChange={(e) => {
                            handleOnChange(e, 'LocSt');
                          }}
                          filterable={true}
                          className="state-combobox"
                          value={combosData?.StatesUS.find(
                            (type: any) => type.state === formState?.LocSt
                          )}
                          width={100}
                          comboBoxType="LocationStates"
                        />
                      </AddressFieldWrapper>
                      <AddressFieldWrapper width="20%">
                        <InputWrapper
                          name="LocZip"
                          label="Zip"
                          value={formState.LocZip || ''}
                          onChange={handleOnChange}
                        />
                      </AddressFieldWrapper>
                    </FieldsWrapper>
                  </AddressWrapper>
                </div>
                <div className="contact-info-wrapper">
                  <label className="contact-info-label">
                    Operations Contact
                  </label>
                  <div className="contact-info">
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocOpsName1">Contact Name</label>
                      <InputWrapper
                        id="LocOpsName1"
                        name="LocOpsName1"
                        value={formState.LocOpsName1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocOpsDesc1">Description</label>
                      <InputWrapper
                        id="LocOpsDesc1"
                        name="LocOpsDesc1"
                        value={formState.LocOpsDesc1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocOpsPhone1">Phone</label>
                      <InputWrapper
                        id="LocOpsPhone1"
                        name="LocOpsPhone1"
                        value={formState.LocOpsPhone1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocOpsEmail1">Email</label>
                      <InputWrapper
                        id="LocOpsEmail1"
                        name="LocOpsEmail1"
                        value={formState.LocOpsEmail1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                  </div>
                </div>
                <div className="contact-info-wrapper">
                  <label className="contact-info-label">
                    Administrative Contact
                  </label>
                  <div className="contact-info">
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocAdmName1">Contact Name</label>
                      <InputWrapper
                        id="LocAdmName1"
                        name="LocAdmName1"
                        value={formState.LocAdmName1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocAdmDesc1">Description</label>
                      <InputWrapper
                        id="LocAdmDesc1"
                        name="LocAdmDesc1"
                        value={formState.LocAdmDesc1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocAdmPhone1">Phone</label>
                      <InputWrapper
                        id="LocAdmPhone1"
                        name="LocAdmPhone1"
                        value={formState.LocAdmPhone1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                    <LeftLabelInputWrapper2>
                      <label htmlFor="LocAdmEmail1">Email</label>
                      <InputWrapper
                        id="LocAdmEmail1"
                        name="LocAdmEmail1"
                        value={formState.LocAdmEmail1 || ''}
                        onChange={handleOnChange}
                        width="230px"
                      />
                    </LeftLabelInputWrapper2>
                  </div>
                </div>
              </LocationSectionContent>
            </LocationSection>
            <LocationSection>
              <LocationSectionHeader>
                <p>Default Accounts</p>
              </LocationSectionHeader>
              <LocationSectionContent padding="16px 24px 20px 24px">
                <h2>Invoices and Receivables</h2>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefARAcct">Receivables Account</label>
                  <CustomColComboBox
                    name="LocDefARAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.ARAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefARAcct')}
                    filterable={true}
                    value={formState.LocDefARAcct}
                    id="LocDefARAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefUndepFundsAcct">
                    Undeposited Payments
                  </label>
                  <CustomColComboBox
                    name="LocDefUndepFundsAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.AssetsNotFixedAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefUndepFundsAcct')}
                    filterable={true}
                    value={formState.LocDefUndepFundsAcct}
                    id="LocDefUndepFundsAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefARWOAcct">Invoice Write-Offs</label>
                  <CustomColComboBox
                    name="LocDefARWOAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.IncomeExpenseAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefARWOAcct')}
                    filterable={true}
                    value={formState.LocDefARWOAcct}
                    id="LocDefARWOAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefARDiscAcct">Invoice Discounts</label>
                  <CustomColComboBox
                    name="LocDefARDiscAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.IncomeExpenseAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefARDiscAcct')}
                    filterable={true}
                    value={formState.LocDefARDiscAcct}
                    id="LocDefARDiscAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefUnappliedCreditAcct">
                    Unapplied Payments
                  </label>
                  <CustomColComboBox
                    name="LocDefUnappliedCreditAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.AssetsNotFixedAccounts}
                    onChange={(e) =>
                      handleOnChange(e, 'LocDefUnappliedCreditAcct')
                    }
                    filterable={true}
                    value={formState.LocDefUnappliedCreditAcct}
                    id="LocDefUnappliedCreditAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>

                <h2>Bills and Payables</h2>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefAPAcct">Payables Account</label>
                  <CustomColComboBox
                    name="LocDefAPAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.APAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefAPAcct')}
                    filterable={true}
                    value={formState.LocDefAPAcct}
                    id="LocDefAPAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefAPWOAcct">Bill Write-Offs</label>
                  <CustomColComboBox
                    name="LocDefAPWOAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.IncomeExpenseAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefAPWOAcct')}
                    filterable={true}
                    value={formState.LocDefAPWOAcct}
                    id="LocDefAPWOAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefAPDiscAcct">Bill Discounts</label>
                  <CustomColComboBox
                    name="LocDefAPDiscAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.IncomeExpenseAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefAPDiscAcct')}
                    filterable={true}
                    value={formState.LocDefAPDiscAcct}
                    id="LocDefAPDiscAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>

                <h2>Cash and Credit</h2>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefBankAcct">Primary Bank Account</label>
                  <CustomColComboBox
                    name="LocDefBankAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.BankAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefBankAcct')}
                    filterable={true}
                    value={formState.LocDefBankAcct}
                    id="LocDefBankAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
                <LeftLabelInputWrapper>
                  <label htmlFor="LocDefCreditCardAcct">
                    Primary Credit Card
                  </label>
                  <CustomColComboBox
                    name="LocDefCreditCardAcct"
                    width={150}
                    textField="AccountNo"
                    dataList={combosData?.CreditCardAccounts}
                    onChange={(e) => handleOnChange(e, 'LocDefCreditCardAcct')}
                    filterable={true}
                    value={formState.LocDefCreditCardAcct}
                    id="LocDefCreditCardAcct"
                    comboBoxType="AllAccounts"
                  />
                </LeftLabelInputWrapper>
              </LocationSectionContent>
            </LocationSection>
          </LocationSectionsContainer>
        </DialogContent>
        <ButtonWrapper>
          <Button
            onClick={(e) => {
              e.preventDefault();
              const formattedData = formatLocationData(formState);
              handleSubmitLocation({
                isNew: id ? false : true,
                data: formattedData,
              });
            }}
            disabled={!isValid || !isChanged}
            id="save-btn"
            title="Save"
          >
            <u>S</u>ave
          </Button>
          <Button
            onClick={() => handleClose()}
            type="button"
            id="cancel-btn"
            title="Cancel"
          >
            <u>C</u>ancel
          </Button>
          {isEdit && (
            <Button
              onClick={() => toggleDialog()}
              type="button"
              id="delete-btn"
              title="Delete"
              tabIndex={-1}
            >
              <u>D</u>elete
            </Button>
          )}
        </ButtonWrapper>
      </ReactFocusLock>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MovableDialog>
  );
};

export default LocationsDialog;
