import React, {
  ComponentPropsWithoutRef,
  ReactNode,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
  RadioButton,
} from '@progress/kendo-react-inputs';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import ReactFocusLock from 'react-focus-lock';

import {
  ISchedulesProps,
  SchedulesPayLoad,
  SchedulesPayloadMap,
} from './Schedules.types';

import { Button, InputWrapper, CustomColComboBox } from '../../../../common';
import CustomTimePicker from '../../../../common/CustomTimePicker';
import moment from 'moment';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../../hooks';
import {
  convertTimeStringToDate,
  formatClientTime,
  getObjectFromName,
  handleKeyDown,
  validateSchedulesForm,
} from '../../../../../utils';

import { CustomButtonWrapper, DialogContent } from './Schedules.styled';
import { StyledTitle } from '../../Departments/DepartmentDialog/DepartmentDialog.styled';
import {
  DatePickerChangeEvent,
  TimePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';
import { GenericDialog } from '../../../../common/GenericDialog/GenericDialog';
import { schedulesService } from '../../../../../services/schedulesService';
import MovableDialog from '../../../../common/MovableDialog';

type CustomScheduleEvent = {
  value: { Start: Date | null; End: Date | null };
};

const ScheduleDialog: React.FC<ISchedulesProps> = ({
  dataTemplate,
  isEdit,
  data,
  handleClose,
}) => {
  let lunchDuration =
    isEdit && data?.LunchDuration !== undefined && data?.LunchDuration !== null
      ? data.LunchDuration.toString()
      : '60';
  let breakDuration =
    isEdit && data?.BreakDuration !== undefined && data?.BreakDuration !== null
      ? data.BreakDuration.toString()
      : '15';

  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    IDNo: isEdit && data ? data.IDNo : 0,
    Code: isEdit && data ? data.SchedCode : '',
    Description: isEdit && data ? data.SchedDesc : '',
    SchedIntv: isEdit && data ? data.SchedIntv : 'Weekly',
    WeekStart: isEdit && data ? data.WeekStart : 'Monday',
    LunchDuration: lunchDuration,
    BreaksPaidYN: isEdit && data ? data.BreaksPaidYN : false,
    BreakDuration: breakDuration,
    WeeklyData: {
      Monday: { Start: null, End: null },
      Tuesday: { Start: null, End: null },
      Wednesday: { Start: null, End: null },
      Thursday: { Start: null, End: null },
      Friday: { Start: null, End: null },
      Saturday: { Start: null, End: null },
      Sunday: { Start: null, End: null },
    },
    BiweeklyData: {
      Schedule: {
        BiWeekStart: 'Monday',
        Start: null as Date | null,
        End: null as Date | null,
      },
      OffMon: isEdit && data ? data.OffMon : false,
      OffTue: isEdit && data ? data.OffTue : false,
      OffWed: isEdit && data ? data.OffWed : false,
      OffThu: isEdit && data ? data.OffThu : false,
      OffFri: isEdit && data ? data.OffFri : false,
      OffSat: isEdit && data ? data.OffSat : false,
      OffSun: isEdit && data ? data.OffSun : false,
      Week: isEdit && data ? data.WeekEO : 'Even',
    },
    MonthlyData: {
      Schedule: {
        Start: null as Date | null,
        End: null as Date | null,
      },
      DaysOffDuration: isEdit && data ? data.DaysOffDuration : null,
      DaysOffEveryMonth: isEdit && data ? data.DaysOffEveryMonth : null,
      OrdinalDayOfEveryMonth: 'First',
      WeekDayOfEveryMonth: 'Monday',
      RadioOption: 0,
    },
  });
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput(isEdit ? 'description' : 'code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged && !isSaving && !isDeleting) {
        handleSave();
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  const fetchData = async () => {
    const response = await schedulesService.getCode(data.SchedCode);
    setFormState((prevState) => ({
      ...prevState,
      BreaksPaidYN: response.BreaksPaidYN,
      BreakDuration: response.BreakDuration.toString(),
      MonthlyData: {
        ...prevState.MonthlyData,
        DaysOffDuration: response.DaysOffDuration,
        DaysOffEveryMonth: response.DaysOffEveryMonth,
        OrdinalDayOfEveryMonth: response.OrdinalDayOfEveryMonth,
        WeekDayOfEveryMonth: response.WeekDayOfEveryMonth,
      },
    }));
  };

  useEffect(() => {
    if (isEdit) fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log('form state', formState);
    setIsValid(validateSchedulesForm(formState));
  }, [formState]);

  const handleSave = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.Code) {
        setIsSaving(true);
        const payload: SchedulesPayloadMap = new Map([
          ['SchedDesc', formState.Description],
          [
            'SchedIntv',
            getObjectFromName(
              formState.SchedIntv,
              dataTemplate?.SchedInterval ?? []
            )?.key,
          ],
          [
            'WeekStart',
            getObjectFromName(
              formState.WeekStart,
              dataTemplate?.DayOfWeek ?? []
            )?.key,
          ],
        ]);

        if (formState.SchedIntv === 'Weekly') {
          (
            [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ] as const
          ).forEach((day) => {
            const startKey = (day.substring(0, 3) +
              'Start') as keyof SchedulesPayLoad;
            const endKey = (day.substring(0, 3) +
              'End') as keyof SchedulesPayLoad;
            const startValue = formState.WeeklyData[day].Start ?? undefined;
            const endValue = formState.WeeklyData[day].End ?? undefined;
            payload.set(startKey, formatClientTime(startValue) ?? undefined);
            payload.set(endKey, formatClientTime(endValue) ?? undefined);
          });

          payload.set('BreaksPaidYN', formState.BreaksPaidYN);
          payload.set(
            'BreakDuration',
            getObjectFromName(
              formState.BreakDuration,
              dataTemplate?.BreakDuration ?? []
            )?.key
          );
          payload.set(
            'LunchDuration',
            getObjectFromName(
              formState.LunchDuration,
              dataTemplate?.LunchDuration ?? []
            )?.key
          );
        }

        if (formState.SchedIntv === 'Biweekly') {
          (
            [
              'OffMon',
              'OffTue',
              'OffWed',
              'OffThu',
              'OffFri',
              'OffSat',
              'OffSun',
            ] as const
          ).forEach((key) => {
            payload.set(key, formState.BiweeklyData[key]);
          });
          payload.set(
            'WeekEO',
            getObjectFromName(
              formState.BiweeklyData.Week,
              dataTemplate?.ShiftWeekEO ?? []
            )?.key
          );
          payload.set('BreaksPaidYN', formState.BreaksPaidYN);
          payload.set(
            'BreakDuration',
            getObjectFromName(
              formState.BreakDuration,
              dataTemplate?.BreakDuration ?? []
            )?.key
          );
          payload.set(
            'LunchDuration',
            getObjectFromName(
              formState.LunchDuration,
              dataTemplate?.LunchDuration ?? []
            )?.key
          );
          const startValue = formState.BiweeklyData.Schedule.Start ?? undefined;
          const endValue = formState.BiweeklyData.Schedule.End ?? undefined;
          payload.set('SchedStart', formatClientTime(startValue) ?? undefined);
          payload.set('SchedEnd', formatClientTime(endValue) ?? undefined);
        }

        if (formState.SchedIntv === 'Monthly') {
          const {
            DaysOffEveryMonth,
            DaysOffDuration,
            OrdinalDayOfEveryMonth,
            WeekDayOfEveryMonth,
          } = formState.MonthlyData;
          payload.set('DaysOffEveryMonth', DaysOffEveryMonth);
          payload.set('DaysOffDuration', DaysOffDuration);
          payload.set(
            'OrdinalDayOfEveryMonth',
            getObjectFromName(
              OrdinalDayOfEveryMonth,
              dataTemplate?.OrdinalDayOfEveryMonth ?? []
            )?.key
          );
          payload.set(
            'WeekDayOfEveryMonth',
            getObjectFromName(
              WeekDayOfEveryMonth,
              dataTemplate?.DayOfWeek ?? []
            )?.key
          );
          payload.set('BreaksPaidYN', formState.BreaksPaidYN);
          payload.set(
            'BreakDuration',
            getObjectFromName(
              formState.BreakDuration,
              dataTemplate?.BreakDuration ?? []
            )?.key
          );
          payload.set(
            'LunchDuration',
            getObjectFromName(
              formState.LunchDuration,
              dataTemplate?.LunchDuration ?? []
            )?.key
          );
          const startValue = formState.MonthlyData.Schedule.Start ?? undefined;
          const endValue = formState.MonthlyData.Schedule.End ?? undefined;
          payload.set('SchedStart', formatClientTime(startValue) ?? undefined);
          payload.set('SchedEnd', formatClientTime(endValue) ?? undefined);
        }

        const payloadObj = Object.fromEntries(payload);
        const saved = !isEdit
          ? await schedulesService.createSchedule(payloadObj, formState.Code)
          : await schedulesService.updateSchedule(payloadObj, formState.Code);

        if (saved) {
          setIsSaving(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnDelete = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      if (formState.Code) {
        setIsDeleting(true);
        const status = await schedulesService.deleteSchedule(formState.Code);

        if (status) {
          setIsDeleting(false);
          handleClose(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnChange = (
    dataGroup?: 'WeeklyData' | 'BiweeklyData' | 'MonthlyData'
  ) => {
    return (
      event:
        | InputChangeEvent
        | NumericTextBoxChangeEvent
        | ComboBoxChangeEvent
        | CheckboxChangeEvent
        | DatePickerChangeEvent
        | CustomScheduleEvent,
      key: string
    ) => {
      const value =
        key === 'Code'
          ? event.value.toUpperCase()
          : [
              'SchedIntv',
              'WeekStart',
              'LunchDuration',
              'BreakDuration',
              'OrdinalDayOfEveryMonth',
              'WeekDayOfEveryMonth',
            ].includes(key)
          ? event.value.name
          : event.value;

      setIsChanged(true);

      if (dataGroup) {
        setFormState((prevState) => ({
          ...prevState,
          [dataGroup]: {
            ...prevState[dataGroup],
            [key]: value,
          },
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    };
  };

  const toggleDialog = (state?: boolean) => {
    if (state) {
      handleOnDelete();
    }
    setVisible(!visible);
  };

  const CustomWindowTitle = () => {
    return (
      <StyledTitle>
        <span>Setup Schedule</span>
        {isEdit && (
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleDialog();
            }}
            className="k-button k-button-md k-button-flat k-button-flat-base k-rounded-md k-icon-button k-window-titlebar-action"
            title="Delete"
            disabled={isSaving || isDeleting}
          >
            {/* <span className="k-icon k-i-delete" /> */}
          </button>
        )}
      </StyledTitle>
    );
  };

  return (
    <MovableDialog
      onClose={() => handleClose()}
      title={<CustomWindowTitle />}
      width={830}
      ref={dialogRef}
    >
      <DialogContent>
        <ReactFocusLock>
          <div
            className="form-fields"
            ref={formRef}
            onKeyDown={(e) => handleKeyDown(e, formRef)}
          >
            <FormGrid>
              <LeftColumn>
                <InputGroup>
                  <InputContainer>
                    <InputContainer.Label label="Code" />
                    <InputWrapper
                      width="120px"
                      maxWidth="120px"
                      name="Code"
                      maxLength={6}
                      onChange={handleOnChange()}
                      value={formState.Code}
                      id="code"
                      autoComplete="off"
                      isDisabled={isEdit}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputContainer.Label label="Description" />
                    <InputWrapper
                      width="200px"
                      maxWidth="200px"
                      name="Description"
                      id="description"
                      onChange={handleOnChange()}
                      value={formState.Description}
                      autoComplete="off"
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputContainer.Label label="Interval" />
                    <CustomColComboBox
                      dataList={dataTemplate?.SchedInterval}
                      comboBoxType="KEY_NAME"
                      defaultValue={'Weekly'}
                      width={120}
                      name="SchedIntv"
                      onChange={(e) => handleOnChange()(e, 'SchedIntv')}
                      value={dataTemplate?.SchedInterval.find(
                        (e) => e.name === formState.SchedIntv
                      )}
                      filterable
                      allowBlank={false}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputContainer.Label label="Week Start" />
                    <CustomColComboBox
                      dataList={dataTemplate?.DayOfWeek}
                      comboBoxType="KEY_NAME"
                      defaultValue={'Monday'}
                      width={120}
                      name="WeekStart"
                      onChange={(e) => handleOnChange()(e, 'WeekStart')}
                      value={dataTemplate?.DayOfWeek.find(
                        (e) => e.name === formState.WeekStart
                      )}
                      filterable
                      allowBlank={false}
                    />
                  </InputContainer>
                </InputGroup>
                <>
                  <WeeklyInputGrid.LunchInputGroup>
                    <InputContainer.Label label="Duration" />
                    <CustomColComboBox
                      dataList={dataTemplate?.LunchDuration}
                      comboBoxType="KEY_NAME"
                      defaultValue={'60'}
                      width={80}
                      name="LunchDuration"
                      onChange={(e) => handleOnChange()(e, 'LunchDuration')}
                      value={dataTemplate?.LunchDuration.find(
                        (e) => e.name === formState.LunchDuration
                      )}
                      filterable
                    />
                    <InputContainer.Label label="Min" />
                  </WeeklyInputGrid.LunchInputGroup>
                  <WeeklyInputGrid.BreaksInputGroup>
                    <InputContainer.Label
                      label="Paid"
                      style={{ textAlign: 'right', alignSelf: 'center' }}
                    />

                    {/* Stupid kendo */}
                    <div style={{ alignSelf: 'center' }}>
                      <Checkbox
                        onChange={(e) => handleOnChange()(e, 'BreaksPaidYN')}
                        style={{
                          height: 20,
                          width: 20,
                          border: '2px solid #d6d6d6',
                        }}
                        value={formState.BreaksPaidYN}
                      />
                    </div>

                    <InputContainer.Label
                      label="Duration"
                      style={{ textAlign: 'right', alignSelf: 'center' }}
                    />
                    <CustomColComboBox
                      dataList={dataTemplate?.BreakDuration}
                      comboBoxType="KEY_NAME"
                      defaultValue={'15'}
                      width={80}
                      name="LunchDuration"
                      onChange={(e) => handleOnChange()(e, 'BreakDuration')}
                      value={dataTemplate?.BreakDuration.find(
                        (e) => e.name === formState.BreakDuration
                      )}
                      filterable
                    />
                  </WeeklyInputGrid.BreaksInputGroup>
                </>
              </LeftColumn>

              {formState.SchedIntv === 'Weekly' ? (
                <ScheduleInputWeekly
                  SchedCode={data.SchedCode}
                  handleOnChange={handleOnChange('WeeklyData')}
                />
              ) : formState.SchedIntv === 'Biweekly' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <ScheduleInputBiweekly
                    SchedCode={data.SchedCode}
                    handleOnChange={handleOnChange('BiweeklyData')}
                  />
                  <BiWeeklyInputGrid>
                    {[
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday',
                    ].map((day) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexBasis: '31.3%',
                            gap: 8,
                          }}
                        >
                          <Checkbox
                            value={
                              formState.BiweeklyData[
                                ('Off' +
                                  day.substring(
                                    0,
                                    3
                                  )) as keyof typeof formState.BiweeklyData
                              ]
                            }
                            onChange={(e) =>
                              handleOnChange('BiweeklyData')(
                                e,
                                'Off' + day.substring(0, 3)
                              )
                            }
                            width={10}
                          />
                          <InputContainer.Label label={day} />
                        </div>
                      );
                    })}
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                        alignItems: 'center',
                      }}
                    >
                      <InputContainer.Label label="Week" />
                      <CustomColComboBox
                        dataList={[]}
                        comboBoxType="Even_Odd"
                        defaultValue={'Even'}
                        width={100}
                        onChange={(e) =>
                          handleOnChange('BiweeklyData')(e, 'Week')
                        }
                        value={formState.BiweeklyData.Week}
                        filterable
                      />
                    </div>
                  </BiWeeklyInputGrid>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ScheduleInputMonthly
                    SchedCode={data.SchedCode}
                    handleOnChange={handleOnChange('MonthlyData')}
                  />
                  <MonthlyInputGrid>
                    <RadioOptions setFormState={setFormState}>
                      <RadioOptions.Option>
                        {/* <div style={{
                          display: 'grid',
                          
                        }}> */}
                        {/* </div> */} Day{' '}
                        <NumericTextBox
                          onChange={(e) =>
                            handleOnChange('MonthlyData')(
                              e,
                              'DaysOffEveryMonth'
                            )
                          }
                          max={31}
                          min={1}
                          defaultValue={formState.MonthlyData.DaysOffEveryMonth}
                          name="Option1"
                          width={'70px'}
                          value={formState.MonthlyData.DaysOffEveryMonth}
                        />{' '}
                        of every month for{' '}
                        <NumericTextBox
                          onChange={(e) =>
                            handleOnChange('MonthlyData')(e, 'DaysOffDuration')
                          }
                          max={365}
                          min={0}
                          // style={{ marginLeft: 49 }}
                          defaultValue={formState.MonthlyData.DaysOffDuration}
                          width={'70px'}
                          value={formState.MonthlyData.DaysOffDuration}
                        />{' '}
                        Day(s)
                      </RadioOptions.Option>
                      <div
                        style={{
                          color: '#294580',
                          width: '330px',
                        }}
                        className="line-behind-text"
                      >
                        OR
                      </div>
                      <RadioOptions.Option>
                        The{' '}
                        <CustomColComboBox
                          dataList={dataTemplate?.OrdinalDayOfEveryMonth}
                          comboBoxType="KEY_NAME"
                          defaultValue={'First'}
                          width={100}
                          onChange={(e) =>
                            handleOnChange('MonthlyData')(
                              e,
                              'OrdinalDayOfEveryMonth'
                            )
                          }
                          value={dataTemplate?.OrdinalDayOfEveryMonth.find(
                            (item) =>
                              item.key ===
                              formState.MonthlyData.OrdinalDayOfEveryMonth.toString()
                          )}
                          filterable
                        />{' '}
                        <CustomColComboBox
                          dataList={dataTemplate?.DayOfWeek}
                          comboBoxType="KEY_NAME"
                          defaultValue={'Monday'}
                          width={120}
                          onChange={(e) =>
                            handleOnChange('MonthlyData')(
                              e,
                              'WeekDayOfEveryMonth'
                            )
                          }
                          value={dataTemplate?.DayOfWeek.find(
                            (e) =>
                              e.key ===
                              formState.MonthlyData.WeekDayOfEveryMonth.toString()
                          )}
                          filterable
                        />
                        <div style={{ width: 20, height: 8 }} />
                        <span
                          style={{
                            marginLeft: 23,
                          }}
                        >
                          of every month for{' '}
                        </span>
                        <NumericTextBox
                          onChange={(e) =>
                            handleOnChange('MonthlyData')(e, 'DaysOffDuration')
                          }
                          max={365}
                          min={0}
                          defaultValue={formState.MonthlyData.DaysOffDuration}
                          width={'70px'}
                          value={formState.MonthlyData.DaysOffDuration}
                        />{' '}
                        Day(s)
                      </RadioOptions.Option>
                    </RadioOptions>
                  </MonthlyInputGrid>
                </div>
              )}
            </FormGrid>
          </div>
          <CustomButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              disabled={!isValid || isSaving || isDeleting || !isChanged}
              id="save-btn"
              title="Save"
            >
              <>
                <u>S</u>ave
              </>
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
              type="button"
              id="cancel-btn"
              title="Cancel"
              disabled={isSaving || isDeleting}
            >
              <u>C</u>ancel
            </Button>
            {isEdit && (
              <Button
                onClick={() => toggleDialog()}
                type="button"
                id="delete-btn"
                title="Delete"
                tabIndex={-1}
              >
                <u>D</u>elete
              </Button>
            )}
          </CustomButtonWrapper>
        </ReactFocusLock>
      </DialogContent>
      {visible && (
        <GenericDialog
          type="Confirmation"
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText="Delete"
          primaryMessage="Delete record?"
        />
      )}
    </MovableDialog>
  );
};

const InputGroup = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        gap: 10,
        paddingBottom: 20,
        // border: '1px solid #d6d6d6',
      }}
    >
      {children}
    </div>
  );
};

const FloatingTitle = ({ text }: { text: string }) => {
  return (
    <span
      style={{
        position: 'absolute',
        inset: 0,
        top: -1,
        transform: 'translateY(-50%)',
        left: '15px',
        backgroundColor: 'white',
        color: '#294580',
        padding: `0px 10px`,
        width: 'fit-content',
        height: 'fit-content',
      }}
    >
      {text}
    </span>
  );
};

const InputContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '100px 1fr',
        gap: '8px',
        alignItems: 'center',
        fontSize: '12px',
      }}
    >
      {children}
    </div>
  );
};

InputContainer.Label = ({
  label,
  ...props
}: { label: string } & ComponentPropsWithoutRef<'label'>) => {
  return (
    <label {...props} htmlFor={label}>
      {label}
    </label>
  );
};

const WeeklyInputGrid = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '2fr 3fr',
        columnGap: 8,
        rowGap: 20,
        width: '100%',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

WeeklyInputGrid.LunchInputGroup = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderTop: '2px solid #d6d6d6',
        gap: 8,
        padding: 15,
      }}
    >
      <FloatingTitle text="Lunch" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8,
          position: 'relative',
          left: 18,
        }}
      >
        {children}
      </div>
    </div>
  );
};
WeeklyInputGrid.BreaksInputGroup = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderTop: '2px solid #d6d6d6',
        padding: 15,
        paddingBottom: 0,
        // gap: 8,
      }}
    >
      <FloatingTitle text="Breaks" />
      <div
        style={{
          columnGap: 8,
          rowGap: 8,
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          position: 'relative',
          left: -9,
        }}
      >
        {children}
      </div>
    </div>
  );
};

type ScheduleInputProps = {
  SchedCode: string;
  handleOnChange: (
    event:
      | InputChangeEvent
      | NumericTextBoxChangeEvent
      | ComboBoxChangeEvent
      | CheckboxChangeEvent
      | CustomScheduleEvent,
    key: string
  ) => void;
};

const ScheduleInputWeekly = ({
  handleOnChange,
  SchedCode,
}: ScheduleInputProps) => {
  const [dateState, setDateState] = useState<{
    [index: string]: { Start: Date | null; End: Date | null };
  }>({
    Monday: { Start: null, End: null },
    Tuesday: { Start: null, End: null },
    Wednesday: { Start: null, End: null },
    Thursday: { Start: null, End: null },
    Friday: { Start: null, End: null },
    Saturday: { Start: null, End: null },
    Sunday: { Start: null, End: null },
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await schedulesService.getCode(SchedCode);

      setDateState({
        Monday: {
          Start: convertTimeStringToDate(response.MonStart),
          End: convertTimeStringToDate(response.MonEnd),
        },
        Tuesday: {
          Start: convertTimeStringToDate(response.TueStart),
          End: convertTimeStringToDate(response.TueEnd),
        },
        Wednesday: {
          Start: convertTimeStringToDate(response.WedStart),
          End: convertTimeStringToDate(response.WedEnd),
        },
        Thursday: {
          Start: convertTimeStringToDate(response.ThuStart),
          End: convertTimeStringToDate(response.ThuEnd),
        },
        Friday: {
          Start: convertTimeStringToDate(response.FriStart),
          End: convertTimeStringToDate(response.FriEnd),
        },
        Saturday: {
          Start: convertTimeStringToDate(response.SatStart),
          End: convertTimeStringToDate(response.SatEnd),
        },
        Sunday: {
          Start: convertTimeStringToDate(response.SunStart),
          End: convertTimeStringToDate(response.SunEnd),
        },
      });
    };

    if (SchedCode) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SchedCode]);

  const onChange = (
    e: TimePickerChangeEvent,
    inputKey: 'Start' | 'End',
    mainKey: string
  ) => {
    setDateState((prev) => {
      const newState = {
        ...prev,
        [mainKey]: {
          ...prev[mainKey],
          [inputKey]: e.value,
        },
      };
      handleOnChange({ value: newState[mainKey] }, mainKey);
      return newState;
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        border: '1px solid #d6d6d6',
        padding: 20,
        gap: 4,
      }}
    >
      <FloatingTitle text="Schedule" />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {[
          '',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
        ].map((day, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 110px 2px 110px',
                alignItems: 'center',
                justifyItems: 'right',
                gap: 8,
              }}
            >
              {index < 1 ? (
                <>
                  <label
                    style={{
                      gridColumnStart: 2,
                      placeSelf: 'center',
                      marginBottom: 4,
                    }}
                  >
                    Start
                  </label>

                  <label
                    style={{
                      gridColumnStart: 4,
                      placeSelf: 'center',
                      marginBottom: 4,
                    }}
                  >
                    End
                  </label>
                </>
              ) : (
                <>
                  <InputContainer.Label label={day} className="mt-[10px]" />
                  <CustomTimePicker
                    value={
                      dateState[day]?.Start
                        ? moment(dateState[day]?.Start).toDate()
                        : undefined
                    }
                    onChange={(e) => onChange(e, 'Start', day)}
                  />
                  <span className="place-self-center mt-2">-</span>
                  <CustomTimePicker
                    value={
                      dateState[day]?.End
                        ? moment(dateState[day]?.End).toDate()
                        : undefined
                    }
                    onChange={(e) => onChange(e, 'End', day)}
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ScheduleInputMonthly = ({
  handleOnChange,
  SchedCode,
}: ScheduleInputProps) => {
  const [dateState, setDateState] = useState<{
    Start: Date | null;
    End: Date | null;
  }>({ Start: null, End: null });

  useEffect(() => {
    const fetchData = async () => {
      const response = await schedulesService.getCode(SchedCode);
      setDateState({
        Start: convertTimeStringToDate(response.SchedStart) || null,
        End: convertTimeStringToDate(response.SchedEnd) || null,
      });
    };
    if (SchedCode) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SchedCode]);

  const onChange = (
    e: TimePickerChangeEvent,
    inputKey: 'Start' | 'End',
    mainKey: string
  ) => {
    const newValue = { ...dateState, [inputKey]: e.value };
    setDateState(newValue);
    handleOnChange({ value: newValue }, mainKey);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        border: '1px solid #d6d6d6',
        padding: 20,
        gap: 4,
      }}
    >
      <FloatingTitle text="Schedule" />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '110px 2px 110px',
            alignItems: 'center',
            justifyItems: 'center',
            gap: 8,
          }}
        >
          <>
            <label
              style={{
                gridColumnStart: 1,
                placeSelf: 'center',
                marginBottom: 8,
              }}
            >
              Start
            </label>

            <label
              style={{
                gridColumnStart: 3,
                placeSelf: 'center',
                marginBottom: 8,
              }}
            >
              End
            </label>
          </>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '110px 2px 110px',
            alignItems: 'center',
            justifyItems: 'center',
            gap: 8,
          }}
        >
          <>
            <CustomTimePicker
              value={
                dateState.Start ? moment(dateState.Start).toDate() : undefined
              }
              onChange={(e) => onChange(e, 'Start', 'Schedule')}
            />
            <span style={{ placeSelf: 'center' }}>-</span>
            <CustomTimePicker
              value={dateState.End ? moment(dateState.End).toDate() : undefined}
              onChange={(e) => onChange(e, 'End', 'Schedule')}
            />
          </>
        </div>
      </div>
    </div>
  );
};

const ScheduleInputBiweekly = ({
  handleOnChange,
  SchedCode,
}: ScheduleInputProps) => {
  const [dateState, setDateState] = useState<{
    Start: Date | null;
    End: Date | null;
  }>({ Start: null, End: null });

  useEffect(() => {
    const fetchData = async () => {
      const response = await schedulesService.getCode(SchedCode);
      setDateState({
        Start: convertTimeStringToDate(response.SchedStart) || null,
        End: convertTimeStringToDate(response.SchedEnd) || null,
      });
    };
    if (SchedCode) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SchedCode]);

  const onChange = (
    e: TimePickerChangeEvent,
    inputKey: 'Start' | 'End',
    mainKey: string
  ) => {
    const newValue = { ...dateState, [inputKey]: e.value };
    setDateState(newValue);
    handleOnChange({ value: newValue }, mainKey);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        border: '1px solid #d6d6d6',
        padding: 20,
        gap: 4,
      }}
    >
      <FloatingTitle text="Schedule" />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '110px 2px 110px',
            alignItems: 'center',
            justifyItems: 'center',
            gap: 8,
          }}
        >
          <>
            <label
              style={{
                gridColumnStart: 1,
                placeSelf: 'center',
                marginBottom: 8,
              }}
            >
              Start
            </label>

            <label
              style={{
                gridColumnStart: 3,
                placeSelf: 'center',
                marginBottom: 8,
              }}
            >
              End
            </label>
          </>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '110px 2px 110px',
            alignItems: 'center',
            justifyItems: 'center',
            gap: 8,
          }}
        >
          <>
            <CustomTimePicker
              value={
                dateState.Start ? moment(dateState.Start).toDate() : undefined
              }
              onChange={(e) => onChange(e, 'Start', 'Schedule')}
            />
            <span style={{ gridColumnStart: 2, placeSelf: 'center' }}>-</span>
            <CustomTimePicker
              value={dateState.End ? moment(dateState.End).toDate() : undefined}
              onChange={(e) => onChange(e, 'End', 'Schedule')}
            />
          </>
        </div>
      </div>
    </div>
  );
};

const BiWeeklyInputGrid = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid #d6d6d6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 5,
        padding: 20,
        flex: 1,
        marginTop: 15,
      }}
    >
      <FloatingTitle text="Days Off" />
      {children}
    </div>
  );
};

const MonthlyInputGrid = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #d6d6d6',
        padding: 20,
        flex: 1,
        marginTop: 15,
      }}
    >
      <FloatingTitle text="Days Off" />
      {children}
    </div>
  );
};

const RadioOptions = ({
  children,
  setFormState,
}: {
  children: React.ReactNode;
  setFormState: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [radioIndex, setRadioIndex] = useState(0);
  const childElements = Array.isArray(children) ? children : [children];

  const handleRadioChange = (index: number) => {
    setRadioIndex(index);
    setFormState((prev: any) => ({
      ...prev,
      MonthlyData: {
        ...prev.MonthlyData,
        RadioOption: index,
      },
    }));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 15,
      }}
    >
      {childElements.map((el, index) => {
        return React.cloneElement(el as React.ReactElement<any>, {
          key: index,
          checked: radioIndex === index,
          onRadioChange: () => handleRadioChange(index),
        });
      })}
    </div>
  );
};

RadioOptions.Option = ({
  checked,
  onRadioChange,
  children,
}: {
  checked?: boolean;
  onRadioChange?: () => void;
  children: ReactNode;
}) => {
  const childElements = Array.isArray(children) ? children : [children];
  const handleClick = () => {
    if (onRadioChange) onRadioChange();
  };
  return (
    <div>
      <RadioButton
        className="custom-radio-button"
        checked={checked}
        onClick={() => handleClick()}
        style={{ marginRight: 5 }}
      />
      {childElements.map((el) => {
        if (typeof el === 'string') return el;
        return cloneElement(el, { isDisabled: !checked, disabled: !checked });
      })}
    </div>
  );
};

const LeftColumn = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        border: '1px solid #d6d6d6',
        padding: 20,
      }}
    >
      {children}
    </div>
  );
};

const FormGrid = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: 15,
      }}
    >
      {children}
    </div>
  );
};

export default ScheduleDialog;
