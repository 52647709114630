import type { GridColumnProps } from "@progress/kendo-react-grid";

export const columns: GridColumnProps[] = [
    {
        title: 'Code',
        field: 'EventCode',
        width: 100,
        className: 'text-left',
    },
    {
        title: 'Description',
        field: 'EventDesc',
        className: 'text-left',
    },
];