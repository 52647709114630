import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import ReactFocusLock from 'react-focus-lock';

import { Button, InputWrapper } from '../../../common';

import { IPaymentTermsCodesDialogProps } from './PaymentTermsCodesDialog.types';

import {
  useDisableEscape,
  useDisableRightClick,
  useHighlightInput,
  useKeyPress,
} from '../../../../hooks';
import { handleKeyDown, validatePaymentTermsForm } from '../../../../utils';

import {
  DialogContent,
  LeftLabelInputWrapper,
  TwoLabelsInputWrapper,
  CustomButtonWrapper,
  CustomDialogContentWrapper
} from './PaymentTermsCodesDialog.styled';
import { GenericDialog } from '../../../common/GenericDialog/GenericDialog';
import MovableDialog from '../../../common/MovableDialog';

const PaymentTermsCodesDialog: React.FC<IPaymentTermsCodesDialogProps> = ({
  isEdit,
  data,
  handleClose,
  handleSubmitPaymentTerm,
  handleDeletePaymentTerm,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    DiscDays: isEdit && data ? Number(data.DiscDays) : 0,
    DiscPct: isEdit && data ? Number(data.DiscPct) : 0,
    DueDays: isEdit && data ? Number(data.DueDays) : 0,
    IDNo: isEdit && data ? data.IDNo : '0',
    TermsCode: isEdit && data ? data.TermsCode : '',
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<any>(null);

  useHighlightInput('code');
  useDisableRightClick(dialogRef);
  useDisableEscape(dialogRef);

  useKeyPress(
    () => {
      if (isValid && isChanged) {
        handleSubmitPaymentTerm({
          isNew: data?.IDNo ? false : true,
          data: formState,
          id: data?.IDNo,
        });
      }
    },
    () => {
      handleClose();
    },
    () => {
      toggleDialog();
    }
  );

  useEffect(() => {
    setIsValid(validatePaymentTermsForm(formState));
  }, [formState]);

  const handleOnChange = (
    event: InputChangeEvent | NumericTextBoxChangeEvent,
    key: string
  ) => {
    setIsChanged(true);
    setFormState((prevState) => ({
      ...prevState,
      [key]: event.value,
    }));
  };

  const toggleDialog = (state?: boolean) => {
    if (state && handleDeletePaymentTerm) {
      handleDeletePaymentTerm({
        id: data?.IDNo,
      });
    }
    setVisible(!visible);
  };

  return (
    <MovableDialog
      title="Setup Payment Terms"
      onClose={() => handleClose()}
      className="payment-terms-codes-dialog"
      ref={dialogRef}
    >
      <CustomDialogContentWrapper>
        <DialogContent>
          <ReactFocusLock>
            <div
              className="form-fields"
              ref={formRef}
              onKeyDown={(e) => handleKeyDown(e, formRef)}
            >
              <LeftLabelInputWrapper>
                <label>Terms Code</label>
                <InputWrapper
                  maxWidth="150px"
                  name="TermsCode"
                  onChange={handleOnChange}
                  value={formState.TermsCode}
                  maxLength={20}
                  id="code"
                  autoComplete="off"
                />
              </LeftLabelInputWrapper>
              <TwoLabelsInputWrapper style={{ marginRight: '20px' }}>
                <label style={{ marginRight: '10px' }}>
                  Discount if paid within
                </label>
                <NumericTextBox
                  spinners={false}
                  name="DiscDays"
                  value={formState?.DiscDays || 0}
                  onChange={(e) => {
                    handleOnChange(e, 'DiscDays');
                  }}
                  style={{ width: '75px' }}
                  format="n0"
                  max={365}
                />
                <label style={{ marginLeft: '10px' }}>days</label>
              </TwoLabelsInputWrapper>
              <TwoLabelsInputWrapper style={{ marginRight: '40px' }}>
                <label style={{ marginRight: '10px' }}>Discount amount</label>
                <NumericTextBox
                  spinners={false}
                  name="DiscPct"
                  value={formState?.DiscPct || 0}
                  onChange={(e) => {
                    handleOnChange(e, 'DiscPct');
                  }}
                  style={{ width: '75px' }}
                  format="n2"
                  max={99.99}
                />
                <label style={{ marginLeft: '10px' }}>%</label>
              </TwoLabelsInputWrapper>
              <TwoLabelsInputWrapper style={{ marginRight: '20px' }}>
                <label style={{ marginRight: '10px' }}>Payment due in</label>
                <NumericTextBox
                  spinners={false}
                  name="DueDays"
                  value={formState?.DueDays || 0}
                  onChange={(e) => {
                    handleOnChange(e, 'DueDays');
                  }}
                  style={{ width: '75px' }}
                  format="n0"
                  max={365}
                />
                <label style={{ marginLeft: '10px' }}>days</label>
              </TwoLabelsInputWrapper>
            </div>
            <CustomButtonWrapper>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmitPaymentTerm({
                    isNew: data?.IDNo ? false : true,
                    data: formState,
                    id: data?.IDNo,
                  });
                }}
                disabled={!isValid || !isChanged}
                id="save-btn"
                title="Save"
              >
                <u>S</u>ave
              </Button>
              <Button
                onClick={() => handleClose()}
                type="button"
                id="cancel-btn"
                title="Cancel"
              >
                <u>C</u>ancel
              </Button>
              {isEdit && (
                <Button
                  onClick={() => toggleDialog()}
                  type="button"
                  id="delete-btn"
                  title="Delete"
                  tabIndex={-1}
                >
                  <u>D</u>elete
                </Button>
              )}
            </CustomButtonWrapper>
          </ReactFocusLock>
        </DialogContent>
      </CustomDialogContentWrapper>
      {visible && (
        <GenericDialog
          type='Confirmation'
          onCancel={() => setVisible(false)}
          onConfirmed={() => toggleDialog(true)}
          confirmButtonText='Delete'
          primaryMessage='Delete record?'
        />
      )}
    </MovableDialog>
  );
};

export default PaymentTermsCodesDialog;
