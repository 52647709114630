import { useMutation, useQuery } from '@tanstack/react-query';
import { locationsService } from '../setup/locationsService';

export const useGetLocations = () => {
  return useQuery<any[]>(
    ['locations'],
    async () => {
      const { data } = await locationsService.getLocations();
      return data;
    },
    {
      initialData: [],
    }
  );
};

export const useCreateLocation = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(
    async (params: any) => locationsService.createLocation(params?.formattedData),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};

export const useUpdateLocation = (
  callback: (shouldRefetch?: boolean) => void
) => {
  return useMutation(
    async (params: any) => locationsService.updateLocation(params?.formattedData),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};

export const useDeleteLocationById = (
  callback: (shouldRefetch?: boolean) => void
) => {
  return useMutation(
    async (params: any) => {
      return await locationsService.deleteLocationById(params.id);
    },
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};