import React, { useRef } from "react";
import {
  GridCellProps,
  GridRowProps,
  GridContextMenuEvent,
} from "@progress/kendo-react-grid"

type GridItemType = Record<string, any>;

interface CellRenderProps {
  td: React.ReactElement<HTMLTableCellElement> | null;
  originalProps: GridCellProps;
  enterEdit: (dataItem: GridItemType, cellField: string | undefined) => void;
  editField: string | undefined;
  tabIndex?: number;
}

export const CellRender = (props: CellRenderProps) => {
  const mouseRightClicked = useRef(false);
  const focused = useRef(false);
  if (props.td === null) return null;

  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const rowIndex = props.originalProps.dataIndex;
  const colIndex = props.originalProps.columnIndex;

  const inEditField = dataItem[props.editField || ""];

  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td: HTMLTableCellElement) => {
            const input = td && td.querySelector("input");
            const activeElement = document.activeElement;

            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }

            input.focus();
            input.select();
          },
        }
      : {
          onMouseDown: (event: React.MouseEvent) => {
            mouseRightClicked.current = event.button === 2;
            if (event.button === 0 && focused.current) {
              focused.current = false;
              props.enterEdit(dataItem, cellField);
            }
          },
          onFocus: (event: React.FocusEvent) => {
            if (mouseRightClicked.current) {
              event.stopPropagation();
              mouseRightClicked.current = false;
              focused.current = true;
              return;
            }
            props.enterEdit(dataItem, cellField);
          },
          onBlur: () => {
            focused.current = false;
          },
        };

  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
    style: {
      ...props.td.props.style,
      cursor: "text",
      background: "transparent",
      height: "30px",
    },
    tabIndex: (rowIndex === 0 && colIndex === 0 && props.tabIndex) ? props.tabIndex : -1,
  };

  const childNodes = props.td.props.children as React.ReactNode;

  return React.cloneElement(props.td, clonedProps, childNodes);
};


function getParentWindow(element: HTMLElement): HTMLElement | null {
  if (element?.tagName === "DIV" && element?.className === "k-window-content") {
    return element
  }
  if (element.parentElement) {
    return getParentWindow(element.parentElement)
  }
  return null;
}

interface RowRenderProps {
  originalProps: GridRowProps;
  tr: React.ReactElement<HTMLTableRowElement>;
  tabIndex: number;
  exitEdit: (dataItem: GridItemType) => void;
  undoEdit: (rowIndex: number) => void;
  checkRequired?: (item: GridItemType) => void;
  onContextMenuOpen?: (e: React.MouseEvent, dataItem: GridItemType) => void;
}

export const RowRender = ({
  tabIndex,
  undoEdit,
  checkRequired=() => {},
  onContextMenuOpen=() => {},
  ...props
}: RowRenderProps) => {
  const rowIndex = props.originalProps.absoluteRowIndex;
  let lastKeypressTime = 0;

  const trProps = {
    ...props.tr.props,
    onContextMenu: (e: GridContextMenuEvent) => {
      const target = e.target as unknown as HTMLElement;
      // if target is dropdown item
      if (target.classList.contains("k-table-td")) {
        e.nativeEvent.preventDefault();
        return;
      }
      onContextMenuOpen(e.nativeEvent, props.originalProps.dataItem);
    },
    onBlur: (e: React.FocusEvent<HTMLTableCellElement>) => {
      if (e.target.tagName !== "TD") {
        const dataItem = props.originalProps.dataItem;
        if (dataItem.IsUpdated) {
          const sourceTr = e.target.parentElement?.parentElement?.parentElement?.parentElement;
          const targetTr = e.relatedTarget?.parentElement;
          
          // if not same row,
          if (sourceTr !== targetTr) {
            const targetWindow = targetTr ? getParentWindow(targetTr) : null;
            const sourceWindow = sourceTr ? getParentWindow(sourceTr) : null;
            // if not same window
            if (targetWindow !== sourceWindow) return;
            // emit data-item
            checkRequired(dataItem);
          }
        }

        props.exitEdit(dataItem);
        e.preventDefault();
      }
    },
    onKeyDown: (event: React.KeyboardEvent<HTMLTableCellElement>) => {
      if (!event.repeat) {
        const target = event.target as HTMLInputElement;

        if (target.tagName !== "INPUT" || !event.currentTarget) {
          event.preventDefault();
          return;
        }

        if (target.ariaExpanded === "true" && event.key !== "Tab") return;

        const tdElement = target.parentElement?.parentElement?.parentElement as HTMLTableColElement;
        if (!tdElement) return;

        const trElement = tdElement.parentElement as HTMLTableRowElement;

        const colCount = trElement.children.length;
        const colIndex = Number(tdElement.ariaColIndex || '1') - 1;
        console.log(`editable: colCount: ${colCount} colIndex:${colIndex}`)

        switch (event.key) {
          case 'Escape':
            let thisKeypressTime = new Date().getTime();
            if (thisKeypressTime - lastKeypressTime <= 500) {
              undoEdit(rowIndex);
              thisKeypressTime = 0;
            }
            lastKeypressTime = thisKeypressTime;
            break;
          case 'Tab':
            const parentWindow = getParentWindow(event.currentTarget);
            if (event.shiftKey && tabIndex > 0) { // Shift + Tab
              (parentWindow?.querySelector(`[tabindex='${tabIndex-1}']`) as HTMLInputElement)?.select();
            } else {
              // take cursor out of the grid and send it to Save or Cancel button.
              (parentWindow?.querySelector(".submit button.k-button:not([disabled])[tabindex]") as HTMLButtonElement)?.focus();
            }
            event.preventDefault();
            break;
          case 'Enter':
            if (colIndex === colCount - 1) {
              const nextEle = trElement.nextElementSibling?.firstElementChild as HTMLTableColElement
          
              if(nextEle?.ariaReadOnly !== undefined && nextEle?.ariaReadOnly === "true"){
                (nextEle?.nextElementSibling as HTMLTableColElement)?.focus();
              } else nextEle?.focus();
            } else {
              const nextEle = tdElement.nextElementSibling as HTMLTableColElement;
              if(nextEle.ariaReadOnly === 'true') (nextEle.nextElementSibling as HTMLTableColElement)?.focus();
              else nextEle?.focus();
            }
            event.preventDefault();
            break;
          case 'ArrowRight':
            if (
              target.selectionStart !== target.selectionEnd
              || (target.selectionStart || 0) < target.value.length
            ) return;
            
            if (colIndex === colCount - 1) {
              const nextRowFirstCell = (trElement.nextElementSibling?.firstElementChild as HTMLTableColElement);
              if (nextRowFirstCell && nextRowFirstCell.ariaReadOnly !== 'true') {
                  nextRowFirstCell.focus();
              }
            } else {
              const nextEle = tdElement.nextElementSibling as HTMLTableColElement;
              if(nextEle.ariaReadOnly === 'true') (nextEle.nextElementSibling as HTMLTableColElement)?.focus();
              else nextEle?.focus();
            }
            event.preventDefault();
            break;
          case 'ArrowLeft':
            if (
              target.selectionStart !== target.selectionEnd
              || (target.selectionStart || 0) > 0
            ) return;

            if (colIndex === 0) {
              (trElement.previousElementSibling?.lastElementChild as HTMLTableColElement)?.focus();
            } else {
              const prevEle = tdElement.previousElementSibling as HTMLTableColElement;
              if(prevEle.ariaReadOnly === 'true') (prevEle.previousElementSibling as HTMLTableColElement)?.focus();
              else prevEle?.focus();
            }
            event.preventDefault();
            break;
          case 'ArrowUp':
            if (event.altKey) return;
            (trElement.previousElementSibling?.children[colIndex] as HTMLTableColElement)?.focus();
            event.preventDefault();
            break;
          case 'ArrowDown':
            if (event.altKey) return;
            (trElement.nextElementSibling?.children[colIndex] as HTMLTableColElement)?.focus();
            event.preventDefault();
            break;
          default:
            break;
        }
      }
    }
  };

  const childNodes = props.tr.props.children as React.ReactNode;
  return React.cloneElement(props.tr, { ...trProps }, childNodes);
};
