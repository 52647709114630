import type { GridColumnProps } from '@progress/kendo-react-grid';
import { NumericCell } from '../../common';

export const columns: GridColumnProps[] = [
  {
    title: 'Code',
    field: 'LocCode',
    width: 100,
  },
  {
    title: 'Description',
    field: 'LocDescription',
    width: 225,
  },
  {
    title: 'Address',
    field: 'LocAddress1',
    width: 200,
    cell: NumericCell,
  },
  {
    title: 'City',
    field: 'LocCity',
    width: 183,
  },
  {
    title: 'State',
    field: 'LocSt',
  },
];
