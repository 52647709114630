import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';

export const SelectableCheckboxCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render, className } =
    props;

  if (!render || !field) return null;

  const onChange = (e: CheckboxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: props.dataIndex,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.value ? true : false,
      });
    }
    if (props.selectionChange) {
      props.selectionChange(e);
    }
  };

  const defaultRendering = (
    <td
      style={{ padding: 0 }}
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      tabIndex={6}
      role="gridcell"
      className={
        className ? `k-table-td ${className}` : 'k-table-td'
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Checkbox
          onChange={onChange}
          checked={dataItem.checked}
          style={{
            borderColor: '#d6d6d6',
          }}
        />
      </div>
    </td>
  );

  return render.call(undefined, defaultRendering, props);
};
