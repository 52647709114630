import styled from 'styled-components';

export const DialogContent = styled.form`
  padding: 12px 20px 20px 20px;

  .form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-top: 2px solid #d6d6d6;
  justify-content: center;
  gap: 12px;
`;
