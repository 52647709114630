import qs from 'qs';
import { axiosClient } from '../axiosClient';
import { commonHeaders } from '../common';

export const departmentsService = {
  getDepartments: async () => {
    const response = await axiosClient().get(`Payroll/PayDepartments/GetList`, {
      headers: { ...commonHeaders },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data.data.records;
  },
  createDepartment: (departmentCode: string, payload: any) =>
    axiosClient().post(
      'Payroll/PayDepartments',
      { ...payload },
      {
        params: {
          DepartmentCode: departmentCode,
        },
        headers: { ...commonHeaders },
      }
    ),
  updateDepartment: (departmentCode: string, payload: any) => {
    return axiosClient().patch(
      'Payroll/PayDepartments',
      { ...payload },
      {
        params: {
          DepartmentCode: departmentCode,
        },
        headers: { ...commonHeaders },
      }
    );
  },
  deleteDepartmentById: (departmentCode: string) =>
    axiosClient().delete('Payroll/PayDepartments', {
      params: {
        DepartmentCode: departmentCode,
      },
      headers: { ...commonHeaders },
    }),
  getDepartmentData: async () => {
    const response = await axiosClient().get(
      `Payroll/PayDepartments?DepartmentCode=1`,
      {
        headers: { ...commonHeaders },
      }
    );
    return response.data.data;
  },
};
