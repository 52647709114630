import type { GridCellProps } from "@progress/kendo-react-grid";
import {
  Checkbox,
  CheckboxChangeEvent,
  CheckboxFocusEvent,
} from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";

//TABINDEXES
const TABINDEX = 101;

export const CheckboxCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const [isChecked, setIsChecked] = useState(dataItem.Action === true ? true : false);

  useEffect(() => {
    if (dataItem.Action === true) {
      setIsChecked(true);
    }
  }, [dataItem]);

  if (!render || !field) return null;

  const onChange = (e: CheckboxChangeEvent) => {
  console.log(`selectable checkbox cell onChange`);
  const newValue = e.value; // The new value of the checkbox
  setIsChecked(newValue); // Update the state with the new value

  if (props.onChange) {
    props.onChange({
      dataIndex: props.dataIndex,
      dataItem,
      field,
      syntheticEvent: e.syntheticEvent,
      value: newValue, // Pass the new value to onChange callback
    });
  }
  if (props.selectionChange) {
    props.selectionChange(e);
  }
};


  const onFocus = (e: CheckboxFocusEvent) => {
    console.log(`SelectableCheckboxCell: onFocus`);
  };

  const defaultRendering = (
    <td
      style={{ padding: 0 }}
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role="gridcell"
    >
      <Checkbox
        tabIndex={TABINDEX}
        onChange={onChange}
        onFocus={onFocus}
        checked={isChecked}
        style={{ borderColor: "#d6d6d6", }}
      />
    </td>
  );

  return render.call(undefined, defaultRendering, props);
};