import { ExpensesWithLiabilities } from '../../../../data/types';
type GenericKeyNameObject = { key: string; name: string };

// Experiemental
export function getObjectFromKey<T extends GenericKeyNameObject[]>(
  originObject: T,
  key: string
): T[0] | null {
  return originObject.find((item) => item.key === `${key}`) ?? null;
}

export function getObjectFromName<T extends GenericKeyNameObject[]>(
  originObject: T,
  name: string
): T[0] | null {
  return originObject.find((item) => item.name === `${name}`) ?? null;
}

export function getAccountFromAccountNo(
  expenses: ExpensesWithLiabilities,
  accountNo: string
): ExpensesWithLiabilities[0] | null {
  if (!accountNo) return null;

  return (
    expenses.find(
      (expense) => Number(expense.AccountNo) === Number(accountNo)
    ) ?? null
  );
}

export function getAccountFromAccountName(
  expenses: ExpensesWithLiabilities,
  accountName: string
): ExpensesWithLiabilities[0] | null {
  if (!accountName) return null;

  return (
    expenses.find((expense) => expense.AccountName === accountName) ?? null
  );
}
