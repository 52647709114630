import { axiosClient } from './axiosClient';
import { commonHeaders } from './common';

export const TemplateService = {
  getTemplate: async (docCode: string) => {
    const response = await axiosClient().get(`EmailTemplate/GetTemplate`, {
      headers: { ...commonHeaders },
      params: {
        EmailDocCode: docCode,
      },
    });
    return response.data;
  },
  createPatchTemplate: (idNo: number, docCOde: string, payload: any) =>
    axiosClient().patch(
      '/EmailTemplate',
      { ...payload },
      {
        params: {
          IDNo: idNo,
          EmailDocCode: docCOde,
        },
        headers: { ...commonHeaders },
      }
    ),
  deleteTemplate: (IDNo: number) =>
    axiosClient().delete('/EmailTemplate', {
      params: {
        IDNo: IDNo,
      },
      headers: { ...commonHeaders },
    }),
  async uploadImage(file: File, fileExt: string) {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('PermaLinkYN', 'true');

    const url = (
      await axiosClient().post(`Document/UploadFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          FileCategory: 'EMAILTEMP',
          CategoryID: 0,
          FileType: 'I',
          FileDescription: 'Image for email template',
          FileExt: fileExt,
        },
      })
    )?.data?.permanentLink;
    return url;
  },
};
