import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { useDisableRightClick } from '../../../hooks';
import {
  ContextMenu,
  MenuItem,
  MenuSelectEvent,
} from '@progress/kendo-react-layout';
import CloseButton from '../../common/CloseButton';

import { useKeyPress } from '../../../hooks';

import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { CustomGrid } from '../../../themes/common.styled';
import { ListingGrid, Button } from '../../common';
import { GridColumn } from '@progress/kendo-react-grid';
import { plusIcon } from '@progress/kendo-svg-icons';
import { columns } from './model';

import {
  FormContainer,
  Form,
  ListingGridContainer,
  ButtonWrapper,
} from './AccountTypes.styled';

import PageNavButton from '../../common/PageNavButton';
import { AccountTypesProps, AcctTypes } from './types';
// import { accountTypesAPI } from '../../../services/AccountTypes/accountTypesAPI';
import AcctTypesDialog from './AccountTypesDialog';
import { accountTypesAPI } from '../../../services/AccountTypes/accountTypesAPI';

type SelectionType = { field?: string; dataItem: any };

const AccountTypes: FC<AccountTypesProps> = ({
  title,
  onClose,
  onNextPage,
  onPreviousPage,
}) => {
  const [dataTemplate, setDataTemplate] = useState<any>([])
  // eslint-disable-next-line
  const [originData, setOriginData] = useState<AcctTypes[]>([]);
  const [filterData, setFilterData] = useState<AcctTypes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showContext, setShowContext] = useState<boolean>(false);
  const [sort, setSort] = useState<SortDescriptor[]>();
  const [selectedItem, setSelectedItem] = useState<SelectionType>();
  // eslint-disable-next-line
  const [showDialog, setShowDialog] = useState<{
    show: boolean;
    isEdit: boolean;
    data: AcctTypes | null;
  }>({
    show: false,
    isEdit: false,
    data: null,
  });

  const offset = useRef({ left: 0, top: 0 });

  const fetchData = async () => {
    try {
      const res = await accountTypesAPI.getList();
      if (res) {
        setOriginData(res ?? []);
        setFilterData(res ?? []);
        setIsLoading(false);
        const template: any = await accountTypesAPI.getSingleAcctType(1)
        setDataTemplate(template)
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    offset.current = {
      left: e.pageX,
      top: e.pageY,
    };
    setShowContext(true);
  };

  const handleOpenDialog = (isEdit: boolean, data: null) => {
    setShowDialog({
      show: true,
      isEdit,
      data,
    });
  };
  // eslint-disable-next-line
  const handleCloseDialog = (shouldRefetch?: boolean) => {
    setShowDialog({
      show: false,
      isEdit: false,
      data: null,
    });
    if (shouldRefetch) {
      fetchData();
    }
  };

  const handleOnSelect = useCallback(
    (e: MenuSelectEvent) => {
      if (selectedItem) {
        const { field, dataItem } = selectedItem;
        switch (e.item.data.action) {
          case 'sortAsc':
            setSort([
              {
                dir: 'asc',
                field: field ?? '',
              },
            ]);
            break;
          case 'sortDesc':
            setSort([
              {
                dir: 'desc',
                field: field ?? '',
              },
            ]);
            break;
          case 'filterIn':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] === dataItem[field]
                )
              );
            }
            break;
          case 'filterOut':
            if (field) {
              setFilterData(
                originData.filter(
                  (data: any) => data[field] !== dataItem[field]
                )
              );
            }
            break;
          case 'removeFilters':
            setFilterData(originData);
            break;
          default:
        }
      }
      setShowContext(false);
    },
    [selectedItem, originData]
  );

  // const formRef = useRef<HTMLFormElement>(null);
  const formContainerRef = useRef<HTMLDivElement>(null);
  useDisableRightClick(formContainerRef);

  useKeyPress(
    () => {},
    () => {},
    () => {},
    () => {
      handleOpenDialog(false, null);
    }
  );

  return (
    <>
      <FormContainer>
        <Form>
          <div className="form-section-header-container">
            <div className="form-title">{title}</div>
            <div className="form-header-btn-wrapper">
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviousPage();
                }}
                title="Previous Page"
              >
                <span
                  className="k-icon k-i-arrow-left k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <PageNavButton
                className="listing-action-button"
                onClick={(e) => {
                  e.preventDefault();
                  onNextPage();
                }}
                title="Next Page"
              >
                <span
                  className="k-icon k-i-arrow-right k-button-icon"
                  role="presentation"
                />
              </PageNavButton>
              <CloseButton
                className="listing-action-button"
                onClick={onClose}
                title="Close"
              >
                <span
                  className="k-icon k-i-close k-button-icon"
                  role="presentation"
                />
              </CloseButton>
            </div>
          </div>
          <div className="form-content">
            <CustomGrid
              cols="800px"
              colGap="0px"
              width="auto"
              style={{ padding: '30px 0px 30px 30px' }}
            >
              <ListingGridContainer>
                {isLoading ? (
                  <></>
                ) : (
                  <ListingGrid
                    data={sort ? orderBy(filterData, sort) : filterData}
                    dataItemKey="IDNo"
                    onRowClick={(e) => {
                        setFilterData((prev) =>
                          prev.map((item) => ({
                            ...item,
                            selected: item.IDNo === e.dataItem.IDNo,
                          }))
                        );
                    }}
                    onRowDoubleClick={(e) => {
                      handleOpenDialog(true, e.dataItem);
                    }}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    onContextMenu={(event) => {
                      handleContextMenuOpen(event.syntheticEvent);
                      const { dataItem, field } = event;
                        setFilterData((prev) =>
                          prev.map((item) => ({
                            ...item,
                            selected: item.IDNo === dataItem.IDNo,
                          }))
                        );
                      setSelectedItem({ field, dataItem });
                    }}
                  >
                    {columns.map((column, index) => (
                      <GridColumn {...column} key={index} />
                    ))}
                  </ListingGrid>
                )}
                <ContextMenu
                  show={showContext}
                  offset={offset.current}
                  onSelect={handleOnSelect}
                  onClose={() => setShowContext(false)}
                  className="context-menu"
                >
                  <MenuItem
                    text="Sort Ascending"
                    data={{
                      action: 'sortAsc',
                    }}
                  />
                  <MenuItem
                    text="Sort Descending"
                    data={{
                      action: 'sortDesc',
                    }}
                  />
                  <MenuItem
                    text="Filter In Selected"
                    data={{
                      action: 'filterIn',
                    }}
                  />
                  <MenuItem
                    text="Filter Out Selected"
                    data={{
                      action: 'filterOut',
                    }}
                  />
                  <MenuItem
                    text="Remove All Filters"
                    data={{
                      action: 'removeFilters',
                    }}
                  />
                </ContextMenu>
              </ListingGridContainer>
              {isLoading ? (
                <></>
              ) : (
                <ButtonWrapper>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenDialog(false, null);
                    }}
                    id="create-btn"
                    title="Add New"
                    svgIcon={plusIcon}
                  >
                    ADD <u>N</u>EW
                  </Button>
                </ButtonWrapper>
              )}
            </CustomGrid>
            {showDialog.show && (
              <AcctTypesDialog
                handleClose={(shouldRefreshData?: boolean) => {
                  setShowDialog({
                    show: false,
                    isEdit: false,
                    data: null,
                  });
                  if (shouldRefreshData) {
                    fetchData();
                  }
                }}
                isEdit={showDialog.isEdit}
                data={showDialog.data}
                dataTemplate={dataTemplate}
                dataList={dataTemplate?.combos?.SystemAcctTypes ?? []}
              />
            )}
          </div>
        </Form>
      </FormContainer>
    </>
  );
};

export default AccountTypes;
