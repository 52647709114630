import type { GridColumnProps } from "@progress/kendo-react-grid";

export const columns: GridColumnProps[] = [
    {
        title: 'Code',
        field: 'ProjType',
        width: 100,
        className: 'text-left',
      },
      {
        title: 'Description',
        field: 'ProjDesc',
        className: 'text-left',
      },
];