import React, { FC, useRef, useState } from 'react';

import MeasurementUnits from './MeasurementUnits';
import Products from './Products'
import Services from './Services';
import Bundles from './Bundles';

import { IProductsAndServices } from './ProductsAndServices.types';
import {
    IMeasurementUnitsType,
    ItemsType,
} from '../../../data/types';

import { useDisableRightClick } from '../../../hooks';
import { FormContainer, Form, TabsContainer, Tab } from './ProductsAndServices.styled';
import { CustomGrid } from '../../../themes/common.styled';
import CloseButton from '../../common/CloseButton';
import PageNavButton from '../../common/PageNavButton';

const ProductsAndServices: FC<IProductsAndServices> = ({ onClose, onNextPage, onPreviousPage, title, resultData }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState<IMeasurementUnitsType>();
    const [selectedProduct, setSelectedProduct] = useState<ItemsType>();
    const [selectedService, setSelectedService] = useState<ItemsType>();
    const [selectedBundle, setSelectedBundle] = useState<ItemsType>();

    useDisableRightClick(containerRef);

    const handleCurrentTab = (e: React.MouseEvent, tabIndex: number) => {
        e.preventDefault();
        setCurrentTab(tabIndex);
    };

    return (
        <>
            <FormContainer ref={containerRef}>
                <Form>
                    <div className="form-section-header-container">
                        <div className="form-title">{title}</div>
                        <div className="form-header-btn-wrapper">
                            <div className="listing-action-seperate-line" />
                            <PageNavButton
                                className="listing-action-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPreviousPage();
                                }}
                                title="Previous Page"
                            >
                                <span
                                    className="k-icon k-i-arrow-left k-button-icon"
                                    role="presentation"
                                />
                            </PageNavButton>
                            <PageNavButton
                                className="listing-action-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onNextPage();
                                }}
                                title="Next Page"
                            >
                                <span
                                    className="k-icon k-i-arrow-right k-button-icon"
                                    role="presentation"
                                />
                            </PageNavButton>
                            <CloseButton
                                className="listing-action-button"
                                onClick={onClose}
                                title="Close"
                            >
                                <span
                                    className="k-icon k-i-close k-button-icon"
                                    role="presentation"
                                />
                            </CloseButton>
                        </div >
                    </div >
                    <div className="form-content">
                        <CustomGrid
                            cols="1fr"
                            colGap="0px"
                            width="830px"
                            style={{ padding: '30px 0px 30px 30px' }}
                        >
                            <TabsContainer>
                                <Tab
                                    isActive={currentTab === 0}
                                    onClick={(e) => handleCurrentTab(e, 0)}
                                >
                                    Measurement Units
                                </Tab>
                                <Tab
                                    isActive={currentTab === 1}
                                    onClick={(e) => handleCurrentTab(e, 1)}
                                >
                                    Product Categories
                                </Tab>
                                <Tab
                                    isActive={currentTab === 2}
                                    onClick={(e) => handleCurrentTab(e, 2)}
                                >
                                    Service Categories
                                </Tab>
                                <Tab
                                    isActive={currentTab === 3}
                                    onClick={(e) => handleCurrentTab(e, 3)}
                                >
                                    Bundle Categories
                                </Tab>
                            </TabsContainer>
                            {currentTab === 0 && (
                                <MeasurementUnits
                                    setSelectedMeasurementUnit={setSelectedMeasurementUnit}
                                    selectedMeasurementUnit={selectedMeasurementUnit}
                                />
                            )}
                            {currentTab === 1 && (
                                <Products
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={setSelectedProduct}
                                />
                            )}
                            {currentTab === 2 && (
                                <Services
                                    selectedService={selectedService}
                                    setSelectedService={setSelectedService}
                                />
                            )}
                            {currentTab === 3 && (
                                <Bundles
                                    selectedBundle={selectedBundle}
                                    setSelectedBundle={setSelectedBundle}
                                />
                            )}
                        </CustomGrid>
                    </div>
                </Form >
            </FormContainer >
        </>
    );
};

export default ProductsAndServices;