import type { GridColumnProps } from '@progress/kendo-react-grid';

export const columns: GridColumnProps[] = [
  {
    title: 'Role Code',
    field: 'PermRoleCode',
    width: 142,
  },
  {
    title: 'Role Description',
    field: 'PermRoleDesc',
  },
];
