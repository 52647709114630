interface SubMenuItem {
  title: string;
  value: string;
  disabled: boolean;
};

export interface SideBarMenuType {
  title: string;
  subMenuItems: SubMenuItem[];
};

export const sideBarItems= [
  {
    title: 'Company Setup'.toUpperCase(),
    subMenuItems: [
      {
        title: 'Company Profile',
        value: 'company',
        disabled: false,
      },
      {
        title: 'Locations & Offices',
        value: 'locations',
        disabled: false,
      },
      {
        title: 'User Permissions',
        value: 'security',
        disabled: false,
      },
    ],
  },
  // hiding until release
  // {
  //   title: 'Operations Setup'.toUpperCase(),
  //   subMenuItems: [
  //     {
  //       title: 'Project Types',
  //       value: 'projectTypes',
  //       disabled: false,
  //     },
  //     {
  //       title: 'Project Event Codes',
  //       value: 'projectEventCodes',
  //       disabled: false,
  //     },
  //     {
  //       title: 'Project Status Codes',
  //       value: 'projectStatusCodes',
  //       disabled: false,
  //     },
  //   ],
  // },
  {
    title: 'Accounting Setup'.toUpperCase(),
    subMenuItems: [
      {
        title: 'Account Types',
        value: 'accountTypes',
        disabled: false,
      },
      {
        title: 'Chart of Accounts',
        value: 'chartOfAccounts',
        disabled: false,
      },
      {
        title: 'Payment Terms',
        value: 'paymentTermsCodes',
        disabled: false,
      },
      {
        title: 'Default Settings',
        value: 'acctDefaults',
        disabled: false,
      },
      {
        title: 'Personnel Codes',
        value: 'personnelCodes',
        disabled: false,
      },
      {
        title: 'Payroll Codes',
        value: 'payrollSetup',
        disabled: false,
      },
      {
        title: 'Sales Taxes',
        value: 'salesTaxes',
        disabled: false,
      }
    ],
  },
  {
    title: 'Sales Setup'.toUpperCase(),
    subMenuItems: [
      {
        title: 'Products & Services',
        value: 'productsAndServices',
        disabled: false,
      },
      {
        title: 'Lead Qualifying',
        value: 'leads',
        disabled: false,
      },
      {
        title: 'Status Codes',
        value: 'statusCodes',
        disabled: false,
      },
      {
        title: 'Events Codes',
        value: 'eventCodes',
        disabled: false,
      },

    ],
  },
  {
    title: 'Customizations'.toUpperCase(),
    subMenuItems: [
      {
        title: 'Customers',
        value: 'customers',
        disabled: false,
      },
      {
        title: 'Vendors',
        value: 'vendors',
        disabled: false,
      },
      {
        title: 'Templates',
        value: 'templates',
        disabled: false,
      },
    ],
  },
  // {
  //   title: 'Check Components'.toUpperCase(),
  //   subMenuItems: [
  //     {
  //       title: 'Company Onboard',
  //       value: 'companycomp',
  //       disabled: true,
  //     },
  //   ],
  // },
];
