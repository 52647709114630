import { useMutation, useQuery } from '@tanstack/react-query';
import { paymentTermsService } from '../setup/paymentTermsService';

export const useGetPaymentTerms = () => {
  return useQuery<any[]>(
    ['paymentTerms'],
    async () => {
      const { data } = await paymentTermsService.getPaymentTerms();
      return data;
    },
    {
      initialData: [],
    }
  );
};

export const useCreatePaymentTerm = (callback: (shouldRefetch?: boolean) => void) => {
  return useMutation(
    async (params: any) => paymentTermsService.createPaymentTerm(params?.formattedData),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};

export const useUpdatePaymentTerms = (
  callback: (shouldRefetch?: boolean) => void
) => {
  return useMutation(
    async (params: any) => paymentTermsService.updatePaymentTerms(params),
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};

export const useDeletePaymentTermById = (
  callback: (shouldRefetch?: boolean) => void
) => {
  return useMutation(
    async (params: any) => {
      return await paymentTermsService.deletePaymentTermById(params.id);
    },
    {
      onSuccess: () => {
        callback(true);
      },
    }
  );
};