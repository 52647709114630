import styled from 'styled-components';

export const DialogContent = styled.form`
  // height: 280px;

  .form-fields {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  .fields-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px 15px 20px 15px;

    //Used when an element is above another in a container
    .fields-row-input-above {
      display: flex;
      gap: 10px;
      justify-content: center;
      //Padding to re-align any rows with inputs after text-align is used on a portion of text
      padding: 0px 15px 20px 15px;
    }

    .fields-row-input-bottom {
      display: flex;
      gap: 10px;
      justify-content: center;
      //Padding to re-align any rows with inputs after text-align is used on a portion of text
      padding: 0px 15px;
    }

    .fields-row-default {
      display: flex;
      gap: 10px;
      justify-content: center;
      padding-top: 10px;
    }

    .renew-on-fields-row-input {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      padding: 0px 15px;
    }

    .text-align {
      display: flex;
      justify-content: center;
      flex-direction: column;
      //Padding to align text properly with any input fields that automatically have a 10px top padding
      padding-top: 10px;
    }

    .text-align-default {
      display: flex;
      justify-content: center;
      flex-direction: column;
      //Padding to align text properly with any input fields that automatically have a 10px top padding
      padding-top: 0px;
    }

    .bounding-box {
      border: 2px solid #d6d6d6;
      padding: 15px;
    }

    .bounding-box-top-label {
      border: 2px solid #d6d6d6;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .title {
      position: relative;
      font-family: 'Calibri Light';
      font-size: 15px;
      color: #00628c;
      text-align: center;
      outline: #00628c 1px solid !important;
      z-index: 2;
      margin-bottom: 5px;
    }

    .left-label-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    .vertical-label-container {
      display: flex; 
      flex-direction: column;
      gap: 8px;
    }

    .vertical-input-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .underline {
      text-decoration: underline;
      text-decoration-color: #00628c;
    }

    .roll-over-container-above {
      text-align: center;
      padding-bottom: 8px;
    }

    .roll-over-container-bottom {
      text-align: center;
    }

    .roll-over-label {
      display: inline-block;
    }

    .center-vertical {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .justify-right {
      display: flex;
      justify-content: end;
      flex-direction: row;
    }

  }
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0px !important;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-top: 2px solid #d6d6d6;
  padding: 15px;
  flex-direction: row;
`;

export const LeftLabelInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
  width: 100%;

  label {
    width: 150px;
    font-size: 14px;
    color: #424242;
  }
`;