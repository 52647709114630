import React, { memo } from 'react';
import {
  ListItemProps,
  MultiColumnComboBoxHandle,
  MultiColumnComboBoxProps,
  MultiColumnComboBoxColumn,
} from '@progress/kendo-react-dropdowns';

import CustomDropdown from './CustomDropdown';

import {
  ACCOUNT_COLUMNS,
  LOCATION_STATE_COLUMNS,
  ORG_OPTIONS_COLUMNS,
  PAYFREQ_COLUMNS,
  SINGLE_COLUMNS,
  US_STATE_COLUMNS,
  ENTITY_COLUMNS,
  PERM_GROUPS_COLUMNS,
  FICA_COLUMNS,
  BANKACCT_COLUMNS,
  VENDOR_EXPENSE_ACCOUNTS_COLUMNS,
} from './model';
import {
  AccountsType,
  EntityListType,
  FieldType,
  GenericKeyNameObject,
  GenericKeyNameIntObject,
  HolidayMonthsType,
  HolidayWeekOfMonth,
  IAccount,
  ICompany,
  IState,
  ITermsCode,
  OrgTypeOptions,
  PayFreqType,
  TimeOffType,
  MeasurementUnitGroupType,
  ISalesStatusCodeType,
  IGroup,
  AccountListType,
} from '../../../data/types';
import { FICAFormData } from '../../pages/PersonnelCodes/PayGroups/PayGroupDialog';
import HeaderButton from '../../pages/ChartOfAccounts/AccountDialog/AccountDialogAddAccountHeader';
import { AcctTypes } from '../../pages/AccountTypes/types';

type ComboBoxType =
  | CustomComBoxType
  | 'Status'
  | 'Disabled'
  | 'StatesUS'
  | 'DateRange'
  | 'SalesTaxBasis'
  | 'PricingWR'
  | 'TransPayMethod'
  | 'JournalType'
  | 'CPS'
  | 'FilingStatus'
  | 'PayMethod'
  | 'PayFrequency'
  | 'Type1099'
  | 'EmploymentType'
  | 'HolidayRule'
  | 'PTOResponse'
  | 'ShiftInterval'
  | 'DayOfWeek'
  | 'ShiftWeekEO'
  | 'BreakDuration'
  | 'LunchDuration'
  | 'OrdinalDayOfEveryMonth'
  | 'PayCodeAddMethod'
  | 'PayCodeDedMethod'
  | 'PayCodeTaxable'
  | 'PayCodeDeductibility'
  | 'PersonnelAddsDeducts'
  | 'PersonnelGroups'
  | 'PersonnelByGroup'
  | 'PersonnelByDepartment'
  | 'AllEntities'
  | 'Customers'
  | 'Vendors'
  | 'CustomersWithTerms'
  | 'VendorsWithTerms'
  | 'CustomersVendors'
  | 'Employees'
  | 'Salesmen'
  | 'ReportCustomers'
  | 'ReportVendors'
  | 'AllAccounts'
  | 'AllAccountsNoARAP'
  | 'ARAccounts'
  | 'APAccounts'
  | 'BankAccounts'
  | 'DepositAccounts'
  | 'BankCCAccounts'
  | 'CreditCardAccounts'
  | 'IncomeAccounts'
  | 'ExpenseAccounts'
  | 'VendorExpenseAccounts'
  | 'IncomeExpenseAccounts'
  | 'InventoryAccounts'
  | 'AssetsNotFixedAccounts'
  | 'ExpensesNoCoGSAccounts'
  | 'ExpensesWithLiabilities'
  | 'ExpensesWithLiabilitiesAndAssets'
  | 'PayGroups'
  | 'PayDepartments'
  | 'PayDepartmentsALL'
  | 'PayPositions'
  | 'PayCodes'
  | 'PayCodesTO'
  | 'PayCodesHolidays'
  | 'PayCodesAdds'
  | 'PayCodesDeducts'
  | 'PayRunYears'
  | 'PTOCodes'
  | 'ProductCategories'
  | 'ServiceCategories'
  | 'ProductUnits'
  | 'ProductWeightUnits'
  | 'ProductSizeUnits'
  | 'ServiceUnits'
  | 'ItemCodes'
  | 'TermsCodes'
  | 'CustomFieldTypes'
  | 'GLAccountTypesAll'
  | 'LocationStates'
  | 'PermGroups'
  | 'MeasurementUnits'
  | 'SystemAcctTypes'

type CustomComBoxType =
  | 'CUSTOM_YesNo'
  | 'ACTIVE_INACTIVE'
  | 'PAY_FREQUENCY'
  | 'FICA_PAY'
  | 'Even_Odd'
  | 'KEY_NAME'
  | 'TIMEOFF'
  | 'KEY_VALUE'
  | 'MONTH_VALUE'
  | 'ORG_OPTIONS'
  | 'StatusCodes'
  | 'ColumnIndex'
  | 'QualStat'
  | 'PTO'
  | 'DeductionSvcCodes'

interface CustomColComboBoxProps
  extends Omit<MultiColumnComboBoxProps, 'ref' | 'columns'> {
  comboBoxType: ComboBoxType;
  width: number;
  dataList:
    | IAccount[]
    | FieldType[]
    | IState[]
    | AccountsType[]
    | ICompany[]
    | ITermsCode[]
    | PayFreqType[]
    | GenericKeyNameObject[]
    | GenericKeyNameIntObject[]
    | TimeOffType[]
    | HolidayWeekOfMonth[]
    | HolidayMonthsType[]
    | OrgTypeOptions[]
    | MeasurementUnitGroupType[]
    | ISalesStatusCodeType[]
    | IGroup[]
    | FICAFormData[]
    | AcctTypes[]
    | undefined;
  /**
   * Whether to allow a blank value in the combobox.
   * If set to true, user clear the value with backspace.
   */
  allowBlank?: boolean;
  /**
   * Pass columns to override default columns in component
   */
  customColumns?: MultiColumnComboBoxColumn[];
  headerOnClick?: () => void;
  isDisabled?: boolean;
}

function getCommonLeftAlignedItem(dataList: MultiColumnComboBoxColumn[]) {
  return (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const children = dataList.map((col, i) => {
      return (
        <span
          className="k-table-td"
          style={{ width: col.width, textAlign: 'left' }}
          key={(col.field ?? '') + i}
        >
          {itemProps.dataItem[col.field || '']}
        </span>
      );
    });
    return React.cloneElement(li, { ...li.props }, children);
  };
}

const CustomComboBox = React.forwardRef<
  MultiColumnComboBoxHandle,
  CustomColComboBoxProps
>((props, ref) => {
  const {
    comboBoxType,
    width,
    allowBlank,
    dataList,
    customColumns,
    isDisabled,
    headerOnClick,
  } = props;

  switch (comboBoxType) {
    case 'AllAccounts':
      const accountColumns = customColumns?.length
        ? [...customColumns]
        : ACCOUNT_COLUMNS(width, (dataList as IAccount[]) || []);

      const accountItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const children = accountColumns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="AccountNo"
          dataItemKey="AccountNo"
          columns={accountColumns}
          style={{ width }}
          itemRender={accountItemRender}
        />
      );
    case 'BankAccounts':
      const bankacctsColumn = customColumns?.length
        ? [...customColumns]
        : BANKACCT_COLUMNS(width, (props.dataList as AccountListType[]) || []);

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={props.dataList}
          restrictBlank={allowBlank ? false : true}
          textField="AccountNo"
          dataItemKey="AccountNo"
          columns={bankacctsColumn}
          itemRender={getCommonLeftAlignedItem(bankacctsColumn)}
          style={{ width }}
        />
      );

    case 'BankCCAccounts':
      const bankCCAccountsColumns = customColumns?.length
        ? [...customColumns]
        : VENDOR_EXPENSE_ACCOUNTS_COLUMNS(width, props.dataList as any[]);
      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={props.dataList}
          restrictBlank={allowBlank ? false : true}
          textField="AccountNo"
          dataItemKey="AccountNo"
          columns={bankCCAccountsColumns}
          itemRender={getCommonLeftAlignedItem(bankCCAccountsColumns)}
          style={{ width }}
        />
      );
    case 'CustomFieldTypes':
      const fieldTypeColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'FieldType');

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="FieldType"
          dataItemKey="FieldType"
          columns={fieldTypeColumns}
          style={{ width }}
        />
      );

    case 'CUSTOM_YesNo':
      const yesNoColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'value');
      const yesNoData = [{ value: 'Yes' }, { value: 'No' }];
      return (
        <CustomDropdown
          {...props}
          onChange={(e) => props.onChange?.({ ...e, value: e.value?.value })}
          ref={ref}
          value={yesNoData.find((item) => item.value === props.value)}
          data={yesNoData}
          restrictBlank={allowBlank ? false : true}
          textField="value"
          dataItemKey="value"
          columns={yesNoColumns}
          style={{ width }}
        />
      );
    case 'ColumnIndex':
      const columnIndexColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'value');
      const columnIndexes = [
        { value: 'Column 1' },
        { value: 'Column 2' },
        { value: 'Column 3' },
      ];
      return (
        <CustomDropdown
          {...props}
          onChange={(e) => props.onChange?.({ ...e, value: e.value?.value })}
          ref={ref}
          value={columnIndexes.find((item) => item.value === props.value)}
          data={columnIndexes}
          restrictBlank={allowBlank ? false : true}
          textField="value"
          dataItemKey="value"
          columns={columnIndexColumns}
          style={{ width }}
        />
      );
    case 'Even_Odd':
      const eoColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'value');
      const eoData = [{ value: 'Even' }, { value: 'Odd' }];
      return (
        <CustomDropdown
          {...props}
          onChange={(e) => props.onChange?.({ ...e, value: e.value?.value })}
          ref={ref}
          value={eoData.find((item) => item.value === props.value)}
          data={eoData}
          restrictBlank={allowBlank ? false : true}
          textField="value"
          dataItemKey="value"
          columns={eoColumns}
          style={{ width }}
        />
      );

    case 'ACTIVE_INACTIVE':
      const actinaColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'value');
      const actinaData = [{ value: 'Active' }, { value: 'InActive' }];
      return (
        <CustomDropdown
          {...props}
          onChange={(e) => props.onChange?.({ ...e, value: e.value?.value })}
          ref={ref}
          value={actinaData.find((item) => item.value === props.value)}
          data={actinaData}
          restrictBlank={allowBlank ? false : true}
          textField="value"
          dataItemKey="value"
          columns={actinaColumns}
          style={{ width }}
        />
      );

    case 'StatesUS':
      const stateUSColumns = customColumns?.length
        ? [...customColumns]
        : US_STATE_COLUMNS(width);
      const stateItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const children = stateUSColumns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="State"
          dataItemKey="State"
          columns={stateUSColumns}
          style={{ width }}
          itemRender={stateItemRender}
          disabled={isDisabled}
        />
      );

    case 'LocationStates':
      const locStatesColumns = customColumns?.length
        ? [...customColumns]
        : LOCATION_STATE_COLUMNS(width);
      const locStateItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const children = locStatesColumns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="state"
          dataItemKey="state"
          columns={locStatesColumns}
          style={{ width }}
          itemRender={locStateItemRender}
        />
      );

    case 'GLAccountTypesAll':
      const accountsTypeColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'AcctTypeDesc');

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="AcctTypeDesc"
          dataItemKey="AcctTypeDesc"
          columns={accountsTypeColumns}
          style={{ width }}
        />
      );
    case 'Vendors':
      let header: JSX.Element | undefined;
      if (headerOnClick) {
        header = <HeaderButton onClick={headerOnClick} />;
      }
      const vendorColumns = customColumns?.length
        ? [...customColumns]
        : ENTITY_COLUMNS(width, (dataList as EntityListType[]) || []);
      const vendorItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        let children = vendorColumns.map((col, i) => {
          if (itemProps.index === 0 && header && i === 0) {
            return (
              <span className="k-table-td" style={{ width: col.width }}>
                {header}
              </span>
            );
          } else if (itemProps.index === 0 && header) {
            return (
              <span
                className="k-table-td"
                style={{ width: col.width }}
                onClick={headerOnClick}
              />
            );
          }
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="EntityName"
          dataItemKey="EntityName"
          columns={vendorColumns}
          style={{ width }}
          itemRender={vendorItemRender}
          header={
            <HeaderButton
              onClick={
                headerOnClick ??
                (() => {
                  return;
                })
              }
            />
          }
        />
      );
    case 'Employees':
      const employeeColumns = customColumns?.length
        ? [...customColumns]
        : ENTITY_COLUMNS(width, (dataList as EntityListType[]) || []);

      const employeeItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const children = employeeColumns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="EntityName"
          dataItemKey="EntityName"
          columns={employeeColumns}
          style={{ width }}
          itemRender={employeeItemRender}
        />
      );

    case 'TermsCodes':
      const termsCodesColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'TermsCode');
      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={props.dataList}
          restrictBlank={allowBlank ? false : true}
          textField="TermsCode"
          dataItemKey="IDNo"
          columns={termsCodesColumns}
          style={{ width }}
        />
      );

    case 'PermGroups':
      const permGroupsColumns = customColumns?.length
        ? [...customColumns]
        : PERM_GROUPS_COLUMNS(width);
      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={props.dataList}
          restrictBlank={allowBlank ? false : true}
          textField="PermGroupCode"
          dataItemKey="PermGroupCode"
          columns={permGroupsColumns}
          style={{ width }}
        />
      );

    case 'PAY_FREQUENCY':
      const freqColumns = customColumns?.length
        ? [...customColumns]
        : PAYFREQ_COLUMNS(width);
      const freqItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const children = freqColumns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="value"
          dataItemKey="value"
          columns={freqColumns}
          style={{ width }}
          itemRender={freqItemRender}
        />
      );

    case 'FICA_PAY':
      const FICAColumns = customColumns?.length
        ? [...customColumns]
        : FICA_COLUMNS(width);
      const FICAItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const children = FICAColumns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={!allowBlank}
          textField="AccountNo"
          dataItemKey="AccountNo"
          columns={FICAColumns}
          style={{ width }}
          itemRender={FICAItemRender}
        />
      );

    case 'KEY_NAME':
      const shiftIntColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'name');

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="name"
          dataItemKey="name"
          columns={shiftIntColumns}
          style={{ width }}
        />
      );

    case 'TIMEOFF':
      const timeOffColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'label');

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="label"
          dataItemKey="label"
          columns={timeOffColumns}
          style={{ width }}
        />
      );

    case 'KEY_VALUE':
      const kvColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'value');

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="value"
          dataItemKey="value"
          columns={kvColumns}
          style={{ width }}
        />
      );

    case 'MONTH_VALUE':
      const mvColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'month');

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="month"
          dataItemKey="month"
          columns={mvColumns}
          style={{ width }}
        />
      );

    case 'ORG_OPTIONS':
      const orgOptionsColumns = customColumns?.length
        ? [...customColumns]
        : ORG_OPTIONS_COLUMNS(width);
      const orgOptionsItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const children = orgOptionsColumns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{ width: col.width, textAlign: 'left' }}
              key={(col.field ?? '') + i}
            >
              {itemProps.dataItem[col.field || '']}
            </span>
          );
        });
        return React.cloneElement(li, { ...li.props }, children);
      };

      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="value"
          dataItemKey="value"
          columns={orgOptionsColumns}
          style={{ width }}
          itemRender={orgOptionsItemRender}
        />
      );
    case 'MeasurementUnits':
      const measurementUnitsColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'UnitGroup');

      return (
        <CustomDropdown
          {...props}
          onChange={(e) => props.onChange?.({ ...e, value: e.value.UnitGroup })}
          ref={ref}
          value={(props?.dataList as MeasurementUnitGroupType[]).find(
            (item) => item.UnitGroup === props.value
          )}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="UnitGroup"
          dataItemKey="UnitGroup"
          columns={measurementUnitsColumns}
          style={{ width }}
        />
      );
    case 'StatusCodes':
      const statusCodesColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'StatusCode');

      return (
        <CustomDropdown
          {...props}
          onChange={(e) =>
            props.onChange?.({ ...e, value: e.value.StatusCode })
          }
          ref={ref}
          value={(props?.dataList as ISalesStatusCodeType[]).find(
            (code) => code.StatusCode === props.value
          )}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="StatusCode"
          dataItemKey="StatusCode"
          columns={statusCodesColumns}
          style={{ width }}
        />
      );
    case 'QualStat':
      const qualStateColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'name');

      return (
        <CustomDropdown
          {...props}
          onChange={(e) => props.onChange?.({ ...e, value: e.value.key })}
          value={(props?.dataList as GenericKeyNameObject[]).find(
            (code) => code.key === props.value
          )}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="name"
          dataItemKey="key"
          columns={qualStateColumns}
          style={{ width }}
        />
      );
    case 'PTO':
      const ptoColumns = customColumns?.length
        ? [...customColumns]
        : SINGLE_COLUMNS(width, 'name');

      return (
        <CustomDropdown
          {...props}
          onChange={(e) => props.onChange?.({ ...e, value: e.value.key })}
          value={(props?.dataList as GenericKeyNameIntObject[]).find(
            (code) => code.key === props.value
          )}
          ref={ref}
          data={dataList}
          restrictBlank={allowBlank ? false : true}
          textField="name"
          dataItemKey="key"
          columns={ptoColumns}
          style={{ width }}
        />
      );
    case 'VendorExpenseAccounts':
      const vendorExpenseAccountsColumns = customColumns?.length
        ? [...customColumns]
        : VENDOR_EXPENSE_ACCOUNTS_COLUMNS(width, props.dataList as any[]);
      return (
        <CustomDropdown
          {...props}
          ref={ref}
          data={props.dataList}
          restrictBlank={allowBlank ? false : true}
          textField="AccountNo"
          dataItemKey="AccountNo"
          columns={vendorExpenseAccountsColumns}
          itemRender={getCommonLeftAlignedItem(vendorExpenseAccountsColumns)}
          style={{ width }}
        />
      );
      case 'SystemAcctTypes':
        const sysAcctTypesColumns = customColumns?.length
          ? [...customColumns]
          : SINGLE_COLUMNS(width, 'AcctTypeDesc');
  
        return (
          <CustomDropdown
            {...props}
            ref={ref}
            data={dataList}
            restrictBlank={allowBlank ? false : true}
            textField="AcctTypeDesc"
            dataItemKey="IDNo"
            columns={sysAcctTypesColumns}
            style={{ width }}
          />
        );
    default:
      return null;
  }
});

const areEqual = (prevProps: any, nextProps: any) => {
  const isEqual =
    prevProps?.allowBlank === nextProps?.allowBlank &&
    prevProps.comboBoxType === nextProps.comboBoxType &&
    prevProps?.label === nextProps?.label &&
    prevProps?.dataList?.length === nextProps?.dataList?.length &&
    prevProps?.customColumns?.length === nextProps?.customColumns?.length &&
    prevProps?.value === nextProps?.value &&
    prevProps?.width === nextProps?.width &&
    prevProps?.tabIndex === nextProps?.tabIndex &&
    prevProps?.readOnly === nextProps?.readOnly &&
    prevProps?.className === nextProps?.className &&
    prevProps?.disabled === nextProps?.disabled;

  // if (!isEqual) {
  //   console.log('RR: 1 prevProps,', prevProps)
  //   console.log('RR: 2 nextProps,', nextProps)
  //   console.log('RR: 3 areEqual,', isEqual)
  // }

  return isEqual;
};

const CustomColComboBox = memo(CustomComboBox, areEqual);

export default CustomColComboBox;
