import type { GridColumnProps } from '@progress/kendo-react-grid';
import { ImportCheckCell } from '../../../common/ListingView/ImportCheckCell';
import { ReadOnlyCell, CheckboxCell } from '../../../common/EditableGrid';

export const columns: GridColumnProps[] = [
  {
    title: 'Email',
    field: 'Email',
    width: 170,
    className: 'text-left',
    cell: ReadOnlyCell,
  },
  {
    title: 'Name',
    field: 'Name',
    width: 170,
    className: 'text-left',
    cell: ReadOnlyCell,
  },
  {
    title: 'Address',
    field: 'Address',
    width: 170,
    className: 'text-left',
    cell: ReadOnlyCell,
  },
  {
    title: 'City',
    field: 'City',
    width: 130,
    className: 'text-left',
    cell: ReadOnlyCell,
  },
  {
    title: 'ST',
    field: 'State',
    className: 'state-text-center',
    width: 45,
    cell: ReadOnlyCell,
  },
  {
    title: 'Zip',
    field: 'Zip',
    width: 65,
    className: 'text-left',
    cell: ReadOnlyCell,
  },
  {
    title: 'Contact',
    field: 'Contact',
    width: 120,
    cell: ReadOnlyCell,
  },
  {
    title: 'Phone',
    field: 'Phone',
    width: 120,
    cell: ReadOnlyCell,
  },
  {
    title: 'Error',
    field: 'Error',
    className: 'text-center',
    width: 50,
    cell: ImportCheckCell,
  },
  {
    title: 'Reason',
    field: 'Reason',
    width: 95,
    cell: ReadOnlyCell,
  },
  {
    title: 'Omit',
    field: 'Action',
    width: 50,
    cell: CheckboxCell,
  },
];